import React, {Component} from 'react';

import { 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
 } from '@material-ui/core';

 class CreateNewProductDialog extends Component{
  render(){
    const {
      isCreateNewProductDialogOpened,
      productInput
    } = this.props;
    return(
      <Dialog
        open={isCreateNewProductDialogOpened}
      >
        <DialogTitle>
          СОЗДАНИЕ НОВОГО ИЗДЕЛИЯ
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={productInput}
            onChange={(event) => {
              this.props.SetProductInput(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={()=>{
              this.props.CloseCreatingProductDialog();
              this.props.SetProductInput('');
            }}
          >
            ОТМЕНИТЬ
          </Button>
            <Button
              color='primary'
              disabled={productInput === ''}
              onClick={() => {     
                this.props.CreateNewProduct(productInput);
                this.props.SetProductInput('');
              }}
            >
              ДОБАВИТЬ
            </Button>
        </DialogActions>
      </Dialog>
    );
  }
 }

 export default CreateNewProductDialog;