import React, { Component } from 'react';
import {
  Typography
} from '@material-ui/core';

const styles = {
  taskDescriptionText:{
    marginBottom:'12px',
    marginRight: '12px',
    marginTop: '12px',
    whiteSpace:'pre-wrap'
  },
  taskDescription:{
    display:'flex',
    flexDirection:'column', 
    alignContent:'space-around'  
  }
}

class OblationalLayersFacingDescription extends Component{
  render(){
    const{
      additionalDescription
    } = this.props;
  
    return(
      <Typography
        headlineMapping={{body1:'p'}}
        variant='body1'
        align='left'
        style={styles.taskDescriptionText}
      >
        <b>Дополнительная информация</b>: {additionalDescription.length > 0 ? additionalDescription: 'нет информации'}
      </Typography>
    );
  }
}

export default OblationalLayersFacingDescription;