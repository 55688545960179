const HTTPConstants = {
  REQUEST_ERROR: 'REQUEST_ERROR',
	SET_TECHDOC_LOADED: 'SET_TECHDOC_LOADED',
	SET_TECHDOC_NOT_LOADED: 'SET_TECHDOC_NOT_LOADED',

	SET_WARNING: 'SET_WARNING',

	CLOSE_WARNING: 'CLOSE_WARNING',
}

export default HTTPConstants;