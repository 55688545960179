import React, {Component} from 'react';
import {
  Typography,
} from '@material-ui/core';
import OutlinedTextfield from '../_NewDesignComponents/OutlinedTextfield.js';

import _ from 'lodash';

const styles = {
  timeDurationDescription:{
      flex: 1,
      alignSelf: 'center',
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 470,
      letterSpacing: -0.2
  },
  inputProps:{
    paddingTop: 0
  },
	timeInputContainer: {
    width: '50%',
  }
};

class SetTimeDurationTaskTemplates extends Component {
  constructor(props){
    super(props);
    this.state = {
      hours: 0,
      minutes: 0
    }
    this.changeTimeDuration = 
      _.debounce(() => this.props.SetCustomTaskDescription(this.props.stageId, 'durationMinutes',
        _.toNumber(this.state.hours) * 60 + _.toNumber(this.state.minutes)),
      1000);
  }

  componentDidMount(){
    this.setState({
      hours: Math.floor(this.props.timeDuration / 60),
      minutes: this.props.timeDuration % 60
    })
  }

  render(){
    const {
      hours,
      minutes
    } = this.state;
    return(
      <div
        className='setTimeDurationTaskTemplates'
      >
        <Typography
          variant='body1'
          style={styles.timeDurationDescription}
        >
          Общее время выполнения
        </Typography>
        <div
          className='timeDurationInputTaskTemplate'
        >
          <OutlinedTextfield
            value={hours}
            onChange={(event) => this.setState({hours: event.target.value < 0 ? 0 : event.target.value },
              () => this.changeTimeDuration()
            )}
            type='number'
            measureUnits='ч'
            style={{
              flex: 1,
              marginRight: 8
            }}
          />
          <OutlinedTextfield
            value={minutes}
            onChange={(event) => {if(event.target.value < 60 && event.target.value >= 0 ) {
              this.setState({minutes: event.target.value}, () => {
              this.changeTimeDuration();
            })}}}
            type='number'
            measureUnits={'мин'}
            style={{
                flex: 1
            }}
          />
      </div>
      </div>
    )
  }
}

export default SetTimeDurationTaskTemplates;
