 import {
  ProductionChainConstants,
} from '../Constants';

import _ from 'lodash';

const initState = {
  selectedTask :{
    taskId: -1,
    stepId: -1
  },
  newStage:{
    attachmentsList: [],
    durationMinutes: 0,
    estimatedWorkerPrice: 0,
    QCCriteria:[],
    template: {
      durationMinutes: 0,
      title: 'Этап сборки',
      type: 'custom',
      isQCRequired: true,
      order:1
    }
  },
  productionChain: [],
};

const ADD_STAGE = (newState,oldState) => {
  let newProductionChain = 
    JSON.parse(JSON.stringify(newState));

    //newProductionChain[0].stages.push(oldState.newStage);

  return{
    ...oldState,
    productionChain: newProductionChain
  }
} 

const SET_EXECUTOR = (newState, oldState) => {
  let newProductionChain =
    JSON.parse(JSON.stringify(oldState.productionChain));

  newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId].executor = newState.executor;
  return{
    ...oldState,
    productionChain: newProductionChain
  }  
}

const SET_EXECUTION_DATE = (newState, oldState) => {
  let newProductionChain =
    JSON.parse(JSON.stringify(oldState.productionChain));

  newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId].executionDate = newState.executionDate;
  return{
    ...oldState,
    productionChain: newProductionChain
  }  
}

const SELECT_TASK = (newState, oldState) => {
  let newSelectedTask = 
    JSON.parse(JSON.stringify(oldState.selectedTask));

    newSelectedTask.taskId = newState.taskId;
    newSelectedTask.stepId = newState.stepId;

  return{
    ...oldState,
    selectedTask: newSelectedTask
  }
}

const SET_STEP_PRICE = (newState, oldState) => {
  let newProductionChain =
    JSON.parse(JSON.stringify(oldState.productionChain));

  newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId].price = newState.price;

  newProductionChain[newState.stepId].price = 
    _.sumBy(newProductionChain[newState.stepId]
      .taskTemplatesList, 'price');
  
  return{
    ...oldState,
    productionChain: newProductionChain
  }
}

const EDIT_SCREENSHOT = (newState, oldState) => {
  let newProductionChain =
    JSON.parse(JSON.stringify(oldState.productionChain));

  newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId]
    .description.attachmentsList[newState.attachmentId].src = newState.newImageSrc;

  return{
    ...oldState,
    productionChain: newProductionChain
  }
};

const EDIT_QC_CRITERION = (newState, oldState) => {
  let newProductionChain =
    JSON.parse(JSON.stringify(oldState.productionChain));
  
  newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId]
    .criteriaQC[newState.criterionId].type = newState.type;
  
  newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId]
    .criteriaQC[newState.criterionId].text = newState.text;

  return{
    ...oldState,
    productionChain: newProductionChain
  }
};

const ADD_QC_CRITERION = (newState, oldState) => {
  let newProductionChain =
    JSON.parse(JSON.stringify(oldState.productionChain));
  
  newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId]
    .criteriaQC.push({
      text: newState.text,
      type: newState.type
    });
  return{
    ...oldState,
    productionChain: newProductionChain
  }
};

const REMOVE_QC_CRITERION = (newState, oldState) => {
  let newProductionChain =
    JSON.parse(JSON.stringify(oldState.productionChain));
  
  newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId]
    .criteriaQC.splice(newState.criterionId, 1);

    return{
      ...oldState,
      productionChain: newProductionChain
    }
}

const CLOSE_ATTACHMENT = (newState, oldState) => {
  let newAttachmentState = 
    JSON.parse(JSON.stringify(oldState.productionChain));

  newAttachmentState[newState.stepId]
    .taskTemplatesList[newState.taskId]
    .description.lightboxIsOpened = false;
  
  return{
    ...oldState,
    productionChain: newAttachmentState
  }
}

const NEXT_ATTACHMENT = (newState, oldState) => {
  let newAttachmentState = 
    JSON.parse(JSON.stringify(oldState.productionChain));

    newAttachmentState[newState.stepId]
    .taskTemplatesList[newState.taskId]
    .description.currentAttachment++;

  return {
    ...oldState,
    productionChain: newAttachmentState,
  }
}

const PREVIOUS_ATTACHMENT = (newState, oldState) => {
  let newAttachmentState = 
    JSON.parse(JSON.stringify(oldState.productionChain));

  newAttachmentState[newState.stepId]
    .taskTemplatesList[newState.taskId]
    .description.currentAttachment--;

  return {
    ...oldState,
    productionChain: newAttachmentState,
  }
}

const DELETE_ATTACHMENT = (newState, oldState) => {
  let newAttachmentState = 
    JSON.parse(JSON.stringify(oldState.productionChain));

  newAttachmentState[newState.stepId]
    .taskTemplatesList[newState.taskId]
    .description.attachmentsList.splice(newState.currentAttachment, 1);

  return{
    ...oldState,
    productionChain: newAttachmentState
  }
}

const OPEN_ATTACHMENT = (newState, oldState) => {
  let newAttachmentState = 
    JSON.parse(JSON.stringify(oldState.productionChain));

  newAttachmentState[newState.stepId]
    .taskTemplatesList[newState.taskId]
    .description.currentAttachment = newState.currentAttachment;

  newAttachmentState[newState.stepId]
    .taskTemplatesList[newState.taskId]
    .description.lightboxIsOpened = true;
  
  return{
    ...oldState,
    productionChain: newAttachmentState
  }
}

const UPDATE_LAYERS_ORDER = (newState, oldState) => {
  let newProductionChain =
    JSON.parse(JSON.stringify(oldState.productionChain));

  const [removed] = newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId]
    .description.layers.splice(newState.newOrder.source.index, 1);

  try{
    newProductionChain[newState.stepId]
      .taskTemplatesList[newState.taskId]
      .description.layers.splice(newState.newOrder.destination.index === null ? newState.newOrder.source.index 
        : newState.newOrder.destination.index, 0, removed)
  }catch(e){
    newProductionChain =
      JSON.parse(JSON.stringify(oldState.productionChain));
  }
  return {
	  ...oldState,
	  productionChain: newProductionChain,
  }
};

const SET_INITIAL_PRODUCTION_CHAIN = (newState, oldState) => {
  return {
    ...oldState,
    productionChain: newState.productionChain,
  }
}

const SET_INTERMEDIATE_CONTROL = (newState, oldState) => {
  let newProductionChain = 
    JSON.parse(JSON.stringify(oldState.productionChain)); 
  newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId].isICRequired = newState.newIC;
  return {
    ...oldState,
    productionChain: newProductionChain,
  }
};

const SET_TIME_DURATION_HOURS = (newState, oldState) => {
  let newProductionChain = 
    JSON.parse(JSON.stringify(oldState.productionChain));

  const oldTimeDuration = newProductionChain[newState.stepId]
	  .taskTemplatesList[newState.taskId].timeDuration;
    
  newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId].timeDuration = parseInt(newState.timeDuration * 60, 10) + parseInt(oldTimeDuration % 60, 10);

  
  newProductionChain[newState.stepId].timeDuration = 
    _.sumBy(newProductionChain[newState.stepId]
      .taskTemplatesList, 'timeDuration');

  return {
    ...oldState,
    productionChain: newProductionChain,
  }
};

 const SET_TIME_DURATION_MINUTES = (newState, oldState) => {
	 let newProductionChain =
		 JSON.parse(JSON.stringify(oldState.productionChain));

	 const oldTimeDuration = newProductionChain[newState.stepId]
		 .taskTemplatesList[newState.taskId].timeDuration;

	 newProductionChain[newState.stepId]
		 .taskTemplatesList[newState.taskId].timeDuration = parseInt(Math.floor(oldTimeDuration / 60) * 60, 10) + parseInt(newState.timeDuration, 10);


	 newProductionChain[newState.stepId].timeDuration =
     _.sumBy(newProductionChain[newState.stepId]
      .taskTemplatesList, 'timeDuration');

	 return {
		 ...oldState,
		 productionChain: newProductionChain,
	 }
 };

const SET_CUSTOM_TASK_DESCRIPTION = (newState, oldState) => {
  let newProductionChain =
    JSON.parse(JSON.stringify(oldState.productionChain)); 
  newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId].description.textDescription = newState.taskDescription;
  return {
    ...oldState,
    productionChain: newProductionChain,
  }
};

const SET_QUALITY_CONTROL = (newState, oldState) => {
  let newProductionChain = 
    JSON.parse(JSON.stringify(oldState.productionChain));
  newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId].isQCRequired = newState.newQC;
  return{
    ...oldState,
    productionChain: newProductionChain,
  }
};

const SELECT_SEPARATIVE_LAYER = (newState, oldState) => {
  let newProductionChain = 
    JSON.parse(JSON.stringify(oldState.productionChain));
  newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId].description.separativeLayer = newState.separativeLayer;
  return{
    ...oldState,
    productionChain: newProductionChain,
  } 
};

const SELECT_SPRAY = (newState, oldState) => {
  let newProductionChain = 
    JSON.parse(JSON.stringify(oldState.productionChain));
  newProductionChain[newState.stepId]
    .taskTemplatesList[newState.taskId].description.spray = newState.spray;
  return{
    ...oldState,
    productionChain: newProductionChain,
  }
};

export default (state = initState, action) => {
  switch (action.type) {
    case ProductionChainConstants.SET_QUALITY_CONTROL:
      return SET_QUALITY_CONTROL(action.data, state);

    case ProductionChainConstants.SET_INTERMEDIATE_CONTROL:
      return SET_INTERMEDIATE_CONTROL(action.data, state);

    case ProductionChainConstants.SET_TIME_DURATION_HOURS:
      return SET_TIME_DURATION_HOURS(action.data, state);

	  case ProductionChainConstants.SET_TIME_DURATION_MINUTES:
		  return SET_TIME_DURATION_MINUTES(action.data, state);
    
    case ProductionChainConstants.SELECT_SEPARATIVE_LAYER:
      return SELECT_SEPARATIVE_LAYER(action.data, state);
    
    case ProductionChainConstants.SELECT_SPRAY:
      return SELECT_SPRAY(action.data, state);

    case ProductionChainConstants.SET_CUSTOM_TASK_DESCRIPTION:
      return SET_CUSTOM_TASK_DESCRIPTION(action.data, state);

    case ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN:
      return SET_INITIAL_PRODUCTION_CHAIN(action.data, state);

    case ProductionChainConstants.UPDATE_LAYERS_ORDER:
      return UPDATE_LAYERS_ORDER(action.data, state);
    
    case ProductionChainConstants.OPEN_ATTACHMENT:
      return OPEN_ATTACHMENT(action.data, state);

    case ProductionChainConstants.PREVIOUS_ATTACHMENT:
      return PREVIOUS_ATTACHMENT(action.data, state);

    case ProductionChainConstants.NEXT_ATTACHMENT:
      return NEXT_ATTACHMENT(action.data, state);

    case ProductionChainConstants.CLOSE_ATTACHMENT:
      return CLOSE_ATTACHMENT(action.data, state);

    case ProductionChainConstants.DELETE_ATTACHMENT:
      return DELETE_ATTACHMENT(action.data, state);
      
    case ProductionChainConstants.REMOVE_QC_CRITERION:
      return REMOVE_QC_CRITERION(action.data, state);

    case ProductionChainConstants.ADD_QC_CRITERION:
      return ADD_QC_CRITERION(action.data, state);

    case ProductionChainConstants.EDIT_QC_CRITERION:
      return EDIT_QC_CRITERION(action.data, state);
    
    case ProductionChainConstants.EDIT_SCREENSHOT:
      return EDIT_SCREENSHOT(action.data, state);

    case ProductionChainConstants.SET_STEP_PRICE:
      return SET_STEP_PRICE(action.data, state);

    case ProductionChainConstants.SELECT_TASK:
      return SELECT_TASK(action.data, state);  

    case ProductionChainConstants.SET_EXECUTION_DATE:
      return SET_EXECUTION_DATE(action.data, state);

    case ProductionChainConstants.SET_EXECUTOR:
      return SET_EXECUTOR(action.data, state);

    case ProductionChainConstants.ADD_STAGE:
      return ADD_STAGE(action.data, state);

    default:
      return state;
  }
}
