import React, { Component } from 'react';

class DnDColorPicker extends Component {
  render() {
    const {
      colorPanel
    } = this.props;
    return (
      <div 
        onClick={this.props.onClick}
        style={{background: colorPanel}}
        className='dndColorPick'
      />
    );
  }
}

export default DnDColorPicker;
