import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
	Typography,
} from '@material-ui/core';

const styles = {
	previewDescContainer: {
		display: 'flex',
		flexDirection: 'column',
	}
}

class MaterialPreviewDescription extends PureComponent {
	render() {
		const {
			description,
			category,
		} = this.props;
		const data = !_.isUndefined(description.data) ? description.data: {};
		return(
			<div
				style={styles.previewDescContainer}
			>
				{!_.isUndefined(category.title) && <Typography
					variant='body1'
				>
					Категория материала: <b>{category.title}</b>
				</Typography>}
				{!_.isUndefined(data.width) && <Typography
					variant='body1'
				>
					Ширина рулона: <b>{data.width}</b> м.п.
				</Typography>}
				{!_.isUndefined(data.dens) && <Typography
					variant='body1'
				>
					Плотность: <b>{data.dens}</b> г/м
				</Typography>}
				{!_.isUndefined(data.thickness) && <Typography
					variant='body1'
				>
					Толщина: <b>{data.thickness}</b> мм
				</Typography>}
				{!_.isUndefined(data.evacuationTypes) && <Typography
					variant='body1'
				>
					Графиков полимеризации: <b>{_.size(data.evacuationTypes)}</b>
				</Typography>}
			</div>
		)
	}
};

export default MaterialPreviewDescription;
