const ProductionChainConstants = {
  SET_INTERMEDIATE_CONTROL: 'SET_INTERMEDIATE_CONTROL',
  SET_QUALITY_CONTROL: 'SET_QUALITY_CONTROL',
  SET_TIME_DURATION_HOURS: 'SET_TIME_DURATION_HOURS',
	SET_TIME_DURATION_MINUTES: 'SET_TIME_DURATION_MINUTES',
  SELECT_SEPARATIVE_LAYER: 'SELECT_SEPARATIVE_LAYER',
  SELECT_SPRAY: 'SELECT_SPRAY',
  SET_CUSTOM_TASK_DESCRIPTION:'SET_CUSTOM_TASK_DESCRIPTION',
  
  UPDATE_LAYERS_ORDER:'UPDATE_LAYERS_ORDER',
  OPEN_ATTACHMENT: 'OPEN_ATTACHMENT',
  CLOSE_ATTACHMENT: 'CLOSE_ATTACHMENT',
  NEXT_ATTACHMENT: 'NEXT_ATTACHMENT',
  PREVIOUS_ATTACHMENT: 'PREVIOUS_ATTACHMENT',
  DELETE_ATTACHMENT: 'DELETE_ATTACHMENT',
  REMOVE_QC_CRITERION: 'REMOVE_QC_CRITERION',
  ADD_QC_CRITERION: 'ADD_QC_CRITERION',
  EDIT_QC_CRITERION: 'EDIT_QC_CRITERION',
  EDIT_SCREENSHOT: 'EDIT_SCREENSHOT',
  SET_STEP_PRICE: 'SET_STEP_PRICE',

  SET_INITIAL_PRODUCTION_CHAIN: 'SET_INITIAL_PRODUCTION_CHAIN',
  
  SELECT_TASK: 'SELECT_TASK',
  SET_EXECUTION_DATE: 'SET_EXECUTION_DATE',
  SET_EXECUTOR: 'SET_EXECUTOR',

  ADD_STAGE: 'ADD_STAGE'
};

export default ProductionChainConstants;
