import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import _ from 'lodash';
import {COLORS} from '../../../Styles/AppColors';

const styles = () => ({
  root: {
    borderRight: `1px solid ${COLORS.EXPANSION_BORDER}`,
    borderLeft: `1px solid ${COLORS.EXPANSION_BORDER}`,
    boxShadow: `0px 1px ${COLORS.EXPANSION_BORDER}, 0px -1px ${COLORS.EXPANSION_BORDER}`,
    position: 'relative',
    '&:last-child': {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      borderBottom: `1px solid ${COLORS.EXPANSION_BORDER}`,
      boxShadow: `0px -1px ${COLORS.EXPANSION_BORDER}`,
      // marginBottom: 24
    },
    '&:first-child': {
      borderTopRightRadius: '4px',
      borderTopLeftRadius: '4px',
      borderTop: `1px solid ${COLORS.EXPANSION_BORDER}`,
      boxShadow: `0px 1px ${COLORS.EXPANSION_BORDER}`,
      '&:before': {
        display: 'none',
      },
      '&:last-child': {
        boxShadow: 'none'
      }
    },
    '&:before': {
      backgroundColor: COLORS.EXPANSION_BORDER,
    }
  },
  expanded: {
    border: `1px solid ${COLORS.EXPANSION_BORDER}`,
    borderRadius: '4px 4px 4px 4px',
    boxShadow: 'none',
    '&:first-child': {
      borderTopRightRadius: '4px',
      borderTopLeftRadius: '4px',
      boxShadow: 'none',
    },
    '&:last-child': {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px', 
      boxShadow: 'none',
      marginBottom: 24
    }
  }
});

class OutlinedExpansionPanel extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      isOpened: false
    }
  }
  
  render() {
    const {
      classes,
      expanded
    } = this.props;
    return(
      <ExpansionPanel
        // onChange={() => this.setState({isOpened: !isOpened})}
        // className= {isOpened ? classes.expanded : classes.root}
        classes={{
          root: classes.root,
          expanded: classes.expanded
        }}
        expanded={expanded}
        style={_.assign(
          {},
          this.props.style
        )} 
      >
        {this.props.children}
      </ExpansionPanel>
    )
  }
}

OutlinedExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
}

export default withStyles(styles)(OutlinedExpansionPanel);
