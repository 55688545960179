import React, { Component } from 'react';

import {
  Add
} from '@material-ui/icons';

import {
  Button,
} from '@material-ui/core';

import ProductsList from '../DumbComponents/Products/ProductsList';

import CreateNewProductDialog from '../DumbComponents/Products/CreateNewProductDialog';

import {
  connect,
} from 'react-redux';

import {
  InterfaceActions,
  ProductsActions
} from '../../Redux/Actions';
import { RemoveStructureDialog } from '../DumbComponents/_NewDesignComponents';


const styles = {
  title: {
    alignSelf: 'center',
    paddingTop: 24,
    paddingBottom: 12
  },
  card: {
    maxWidth: 290,
  },
  mediaStyle: {
    height: 0,
    paddingTop: '56.25%'
  },
  addButton: {
    right: 24,
    bottom: 24,
    position: 'fixed'
  }
}

class ProductsListScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productInput: '',
      isRemoveProductDialogOpened: false,
      selectedProduct: null
    }
  }

  selectProductForRemove = (productId) => {
    this.setState({ selectedProduct: productId, isRemoveProductDialogOpened: true })
  }

  closeRemoveProductDialog = () => {
    this.setState({ isRemoveProductDialogOpened: false });
  }

  removeProductByIdDialog = (productId) => {
    this.props.RemoveProductById(productId);
    this.setState({ isRemoveProductDialogOpened: false });
  }

  SetProductInput(text) {
    this.setState({ productInput: text })
  };

  componentDidMount() {
    this.props.GetProducts();
  }

  render() {
    const {
      isCreateNewProductDialogOpened,
      productsList
    } = this.props;

    return (
      <div
        className='productsListScreen'
      >
        <ProductsList
          selectProductForRemove={this.selectProductForRemove}
          productsList={productsList}
          SelectProduct={this.props.SelectProduct.bind(this)}
        />
        <Button
          variant='fab'
          color='primary'
          aria-label='add'
          style={styles.addButton}
          onClick={this.props.OpenCreatingProductDialog}
        >
          <Add />
        </Button>
        <RemoveStructureDialog
          title='Удалить изделие?'
          content='Это действие удалит изделие, все ее тех. карты и все связанное с ними без возможности отмены.'
          isRemoveStructureDialogOpened={this.state.isRemoveProductDialogOpened}
          structureId={this.state.selectedProduct}
          closeStructureProductDialog={this.closeRemoveProductDialog}
          RemoveStructureById={this.removeProductByIdDialog}
        />
        <CreateNewProductDialog
          isCreateNewProductDialogOpened={isCreateNewProductDialogOpened}
          productInput={this.state.productInput}

          SetProductInput={this.SetProductInput.bind(this)}
          CloseCreatingProductDialog={this.props.CloseCreatingProductDialog.bind(this)}
          CreateNewProduct={this.props.CreateNewProduct.bind(this)}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    isCreateNewProductDialogOpened: state.Interface.isCreateNewProductDialogOpened,
    productsList: state.Products.productsList
  }),
  dispatch => ({
    CreateNewProduct: (title) => {
      dispatch(ProductsActions.CreateNewProduct(title));
      dispatch(InterfaceActions.CloseCreatingProductDialog());
    },
    OpenCreatingProductDialog: () => {
      dispatch(InterfaceActions.OpenCreatingProductDialog());
    },
    CloseCreatingProductDialog: () => {
      dispatch(InterfaceActions.CloseCreatingProductDialog());
    },
    GetProducts: () => {
      dispatch(ProductsActions.GetProducts());
    },
    SelectProduct: (id) => {
      dispatch(ProductsActions.SelectProduct(id));
    },
    RemoveProductById: (productId) => {
      dispatch(ProductsActions.RemoveProductById(productId));
    }
  })
)(ProductsListScreen);