export const COLORS = {
	BLUE: '#2785e2',
	YELLOW: '#fbc02d',
	HOVER_BLUE: 'rgba(39, 133, 226, 0.08)',
	GRAY: '#f3f3f4',
	ICON_GRAY: 'rgba(0, 0, 0, 0.54)',
	TEXT_COLOR: '#212121',
	TEXT_GRAY: 'rgba(0, 0, 0, 0.6)',
	STRAIGHT_EDGE_BG_COLOR: '#e2e2e3',
	GRAY_STRAIGHTEN: '#686868',
	EXPANSION_BORDER: '#dbdbdb',
	CUT_LINE_COLOR: '#850d0d',
	BORDER_GREY: '#e7e7e7',
	SWITCH_BLUE: '#1976d2',
	VACUUM_MEMBRANE_COLOR: '#BDDBEE',
	OBLATIONAL_LAYER_COLOR: '#959495',
	YELLOW_BUTTON: '#fbde4c',
	TECHDOC_TEXT_COLOR: '#181818',
};
