import React, { Component } from 'react'

import _ from 'lodash';

import {
  Stage,
  Layer,
  Rect,
  Text,
  Group
} from 'react-konva'

import Rectangle from './Rectangle';
import {CONTAINER_CONSTANTS} from "../../../../Styles/ContainerConstants";
import {COLORS} from '../../../../Styles/AppColors';

const styles = {
  stageContainer: {
	  borderWidth: 4,
	  borderColor: '#000000',
	  display: 'flex',
	  width: 'auto',
  }
};

const length = CONTAINER_CONSTANTS.EDITOR_MATERIAL_LENGTH;

class EditorBody extends Component {
  render() {
    const {
      editorRealHeight,
      layers,
      scale,
      editorSelectedLayer,
      selectedMaterialCut,
      backgroundStyle
    } = this.props;
    return (
      <Stage
        width={length}
        height={editorRealHeight}
        style={_.assign({}, styles.stageContainer, backgroundStyle)}
        ref={node => { this.stageRef = node}}
      >
        <Layer>
          {_.map(layers, layer => {
            return (
              <Rectangle
                layer={layer}
                key={layer._id}
                scale={scale}
                editorSelectedLayer={editorSelectedLayer}
                ChangeEditorSelectedLayer={this.props.ChangeEditorSelectedLayer}
                ChangeLayerPosition={this.props.ChangeLayerPosition}
              />
            )
          })}
          {selectedMaterialCut !== -1 &&
            <Group>
	            <Text
                text={selectedMaterialCut + ' м.п.'}
                y={editorRealHeight - 56}
                fontSize={14}
                fill={COLORS.CUT_LINE_COLOR}
                rotation={-90}
                fontWeight={'600'}
                fontFamily={'Roboto'}
                width={editorRealHeight}
                align={'left'}
                x={selectedMaterialCut * 1000 * scale + 5}
	            />
              <Rect
                width={1}
                height={editorRealHeight}
                x={selectedMaterialCut * 1000 * scale}
                y={0}
                fill={COLORS.CUT_LINE_COLOR}
              />
            </Group>
          }
        </Layer>
      </Stage>
    )
  }
};

export default EditorBody;
