import React, { PureComponent } from 'react';
import { Stage, Layer, Group, Rect, Text } from 'react-konva';
import { CONTAINER_CONSTANTS } from "../../../../Styles/ContainerConstants";
import { COLORS } from '../../../../Styles/AppColors';

export default class VerticalStraightenEdge extends PureComponent {
	render() {
		const { heightSETrait, editorRealHeight } = this.props;
		return (
			<Stage
				width={CONTAINER_CONSTANTS.VERTICAL_STRAIGHT_EDGE_WIDTH}
				height={editorRealHeight + 2 * CONTAINER_CONSTANTS.HORIZONTAL_STRAIGHT_EDGE_WIDTH + 8}
				style={{
					background: COLORS.STRAIGHT_EDGE_BG_COLOR,
				}}
			>
				<Layer>
					{heightSETrait.map((htrait, htraitIndex) => {
						return <Group
							key={'vertical' + htraitIndex}
							y={htrait.y + CONTAINER_CONSTANTS.HORIZONTAL_STRAIGHT_EDGE_WIDTH}
						>
							<Text
								height={CONTAINER_CONSTANTS.HORIZONTAL_STRAIGHT_EDGE_WIDTH + 8}
								text={htrait.label}
								fontFamily={'Roboto'}
								fontSize={11}
								y={-5}
								x={0}
								align={'right'}
								width={CONTAINER_CONSTANTS.VERTICAL_STRAIGHT_EDGE_WIDTH - 17}
								fill={COLORS.GRAY_STRAIGHTEN}
							/>
							<Rect
								width={12}
								height={2}
								x={CONTAINER_CONSTANTS.VERTICAL_STRAIGHT_EDGE_WIDTH - 12}
								y={-1}
								verticalAlign={'bottom'}
								fill={COLORS.GRAY_STRAIGHTEN}
								draggable={false}
							/>
						</Group>
					})}
				</Layer>
			</Stage>
		)
	}
}