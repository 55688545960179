import React, {Component} from 'react';

import {
  connect,
} from 'react-redux';

import _ from 'lodash';

import {
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';

import {
  Remove,
} from '@material-ui/icons'

import {
  TestActions
} from '../../Redux/Actions/';

import api from '../../Utils/API';

class Test extends Component {
  componentDidMount() {
    this.props.FetchFiles();
  }

  render(){
    const {
      testFiles
    } = this.props;
    return(
      <div>
        {_.map(testFiles, testFile => {
          return <div
	          key={testFile.name}
            style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
          >
            <a href={api.getHost() + testFile.url} download>
              <Typography
                variant='body1'
              >
                {testFile.name}
              </Typography>
            </a>
            <IconButton
              onClick={() => this.props.RemoveFile(testFile.name)}
            >
              <Remove/>
            </IconButton>
          </div>
        })}
        <div>
          <input
            type ='file'
            style={{display:'none'}}
            onChange={event => {this.props.UploadToServer(event.target.files[0]);}}
            id='select-file'
            multiple
          />
          <label
            htmlFor='select-file'
          >
            <Button
              variant="outlined"
              component="span"
            >
              Выбрать файл
            </Button>
          </label>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
	  testFiles: state.su.testFiles,
  }),
  dispatch => ({
    UploadToServer: (file) => {
      dispatch(TestActions.UploadToServer(file));
    },
    FetchFiles: () => {
      dispatch(TestActions.FetchFiles())
    },
    RemoveFile: (name) => {
      dispatch(TestActions.RemoveFile(name))
    }
  })
)(Test)