import {
  ProductionChainConstants,
  InterfaceConstants,
  TechDocsConstants,
} from '../Constants';

import _ from 'lodash';

import api from '../../Utils/API';

//Refactored

const DeleteStage = (stageId) => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		return api.del(`techDocs/${techDocId}/customStage/${stageId}`)
			.then(() => {
				return api.get(`techDocs/${techDocId}`)
			})
			.then((techdoc) => {
				dispatch({
					type: ProductionChainConstants.ADD_STAGE,
					data: techdoc.productionChain
				})
			});
	}
};

const AddStage = () => {
  return (dispatch, getState) => {
    const techDocId = getState().TechDocs.SelectedTechDoc._id;
    return api.post(`techDocs/${techDocId}/customStage`)
      .then(()=> {
        return api.get(`techDocs/${techDocId}`)
      })
      .then((techdoc) => {
        dispatch({
          type: ProductionChainConstants.ADD_STAGE,
          data: techdoc.productionChain
        })
      })
      .catch((err) => console.error('StageErr' ,err))
  }
}

const AddScreenshot = (stageId) => {
  return (dispatch, getState) => {
	  const techDocId = getState().TechDocs.SelectedTechDoc._id;
	  const form = new FormData();
	  form.append('stageId', stageId);
	  return api.post(`techDocs/${techDocId}/docker/screen_shot`, form)
      .then(() => {
	      return api.get(`techDocs/${techDocId}`)
      })
      .then((techdoc) => {
        dispatch({
	        type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
	        data: {
		        productionChain: techdoc.productionChain
	        }
        })
      })
  }
};

const EditScreenshot = (fileName, newImageSrc) => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const form = new FormData();
		form.append('filename', fileName);
		form.append('image', newImageSrc);
		return api.put(`techDocs/${techDocId}/screenShot`, form)
			.then(() => {
				return api.get(`techDocs/${techDocId}`)
			})
			.then((techdoc) => {
				dispatch({
					type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
					data: {
						productionChain: techdoc.productionChain
          }
        });
        dispatch({
          type: InterfaceConstants.CLOSE_GRAPHICS_EDITOR,
        })
			});
	}
};

const DeleteScreenshot = (stageId, screenId) => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		api.del(`techDocs/${techDocId}/stages/${stageId}/screenShot`, {
			id: screenId,
		})
			.then(() => {
				return api.get(`techDocs/${techDocId}`)
			})
			.then((techdoc) => {
				dispatch({
					type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
					data: {
						productionChain: techdoc.productionChain
					}
				})
			});
	}
};

const SetTimeDurationHours = (stageId, durationHours) => {
	if (durationHours >= 0) {
		return (dispatch, getState) => {
			const techDocId = getState().TechDocs.SelectedTechDoc._id;
			const productionChain = getState().ProductionChain.productionChain;
			const foundStage = _.find(_.reduce(productionChain, (concattedStages, step) => {
					return _.concat(concattedStages, step.stages)
				},
				[]), {_id: stageId});
      const foundStageTime = !_.isUndefined(foundStage.durationMinutes) 
        ? foundStage.durationMinutes : foundStage.template.durationMinutes;
			const foundStageTimeMinutes = foundStageTime % 60;
			const form = new FormData();
			form.append('durationMinutes', JSON.stringify(_.toNumber(durationHours) * 60 + _.toNumber(foundStageTimeMinutes)));
			return api.put(`techDocs/${techDocId}/stages/${stageId}`, form)
				.then(() => {
					return api.get(`techDocs/${techDocId}`)
				})
				.then((techdoc) => {
					dispatch({
						type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
						data: {
							productionChain: techdoc.productionChain
						}
					})
				})
		};
	};
};

const SetTimeDurationMinutes = (stageId, durationMinutes) => {
  if (durationMinutes >= 0 && durationMinutes < 60) {
	  return (dispatch, getState) => {
		  const techDocId = getState().TechDocs.SelectedTechDoc._id;
		  const productionChain = getState().ProductionChain.productionChain;
		  const foundStage = _.find(_.reduce(productionChain, (concattedStages, step) => {
				  return _.concat(concattedStages, step.stages)
			  },
			  []), {_id: stageId});
		  const foundStageTime = !_.isUndefined(foundStage.durationMinutes) ? foundStage.durationMinutes : foundStage.template.durationMinutes;
		  const foundStageTimeHours = Math.floor(foundStageTime / 60);
		  const form = new FormData();
		  form.append('durationMinutes', JSON.stringify(_.toNumber(foundStageTimeHours) * 60 + _.toNumber(durationMinutes)));
		  return api.put(`techDocs/${techDocId}/stages/${stageId}`, form)
			  .then(() => {
				  return api.get(`techDocs/${techDocId}`)
			  })
			  .then((techdoc) => {
				  dispatch({
					  type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
					  data: {
						  productionChain: techdoc.productionChain
					  }
				  })
			  })
	  };
  };
};

const SetCustomTaskDescription = (stageId, parameter, value) => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const form = new FormData();
		form.append(_.toString(parameter), JSON.stringify(value));
		return api.put(`techDocs/${techDocId}/stages/${stageId}`, form)
			.then(() => {
				return api.get(`techDocs/${techDocId}`)
			})
			.then((techdoc) => {
				dispatch({
					type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
					data: {
						productionChain: techdoc.productionChain
					}
        });
        return api.get(`techDocs/${techDocId}/prices`)
      })
      .then((price) => {
				dispatch({
					type: TechDocsConstants.SET_TECHDOC_PRICE,
					data: price,
				})
			})
	};
};

const EditQCCriterion = (text, type, criterionId, stageId) => {
  return (dispatch, getState) => {
    const techDocId = getState().TechDocs.SelectedTechDoc._id;
    const productionChain = getState().ProductionChain.productionChain;
    const foundStage = _.find(_.reduce(productionChain, (concattedStages, step) => {
        return _.concat(concattedStages, step.stages)
      }, []), {_id: stageId});

    foundStage.QCCriteria[criterionId] = {text:text, type:type };
    const form = new FormData();
    form.append('QCCriteria', JSON.stringify(foundStage.QCCriteria));
    return api.put(`techDocs/${techDocId}/stages/${stageId}`, form)
    .then(() => {
      return api.get(`techDocs/${techDocId}`)
    })
    .then((techdoc) => {
      dispatch({
        type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
        data: {
          productionChain: techdoc.productionChain
        }
      })
    })
  }
};

const CheckQCCriterion = (stageId, criterionId, checked) => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const productionChain = getState().ProductionChain.productionChain;
		const foundStage = _.find(_.reduce(productionChain, (concattedStages, step) => {
			return _.concat(concattedStages, step.stages)
		}, []), {_id: stageId});
		const foundCriterionIndex = _.findIndex(foundStage.QCCriteria, {'_id': criterionId});
		foundStage.QCCriteria[foundCriterionIndex].checked = checked;
		const form = new FormData();
		form.append('QCCriteria', JSON.stringify(foundStage.QCCriteria));
		return api.put(`techDocs/${techDocId}/stages/${stageId}`, form)
			.then(() => {
				return api.get(`techDocs/${techDocId}`)
			})
			.then((techdoc) => {
				dispatch({
					type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
					data: {
						productionChain: techdoc.productionChain
					}
				})
			})
	}
};

const AddQCCriterion = (text, type, stageId) => {
  return (dispatch, getState) => {
    const techDocId = getState().TechDocs.SelectedTechDoc._id;
    const productionChain = getState().ProductionChain.productionChain;
    const foundStage = _.find(_.reduce(productionChain, (concattedStages, step) => {
        return _.concat(concattedStages, step.stages)
      }, []), {_id: stageId});
    const newQCCriteria = _.concat(foundStage.QCCriteria, {text:text, type:type });
    const form = new FormData();
    form.append('QCCriteria', JSON.stringify(newQCCriteria));
    return api.put(`techDocs/${techDocId}/stages/${stageId}`, form)
      .then(() => {
        return api.get(`techDocs/${techDocId}`)
      })
      .then((techdoc) => {
        dispatch({
          type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
          data: {
            productionChain: techdoc.productionChain
          }
        })
      })
  }
};

const RemoveQCCriterion = (criterionId, stageId) => {
  return (dispatch, getState) => {
    const techDocId = getState().TechDocs.SelectedTechDoc._id;
    const productionChain = getState().ProductionChain.productionChain;
    const foundStage = _.find(_.reduce(productionChain, (concattedStages, step) => {
        return _.concat(concattedStages, step.stages)
      }, []), {_id: stageId});
    const newQCCriteria = _.remove(foundStage.QCCriteria, (qc) => {
      return qc._id !== criterionId;
    });
    const form = new FormData();
    form.append('QCCriteria', JSON.stringify(newQCCriteria));
    return api.put(`techDocs/${techDocId}/stages/${stageId}`, form)
    .then(() => {
      return api.get(`techDocs/${techDocId}`);
    })
    .then((techdoc) => {
      dispatch({
        type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
        data: {
          productionChain: techdoc.productionChain
        }
      })
    })
  }
};

////notRefactored

const SetExecutor = (stepId, taskId, executor) => {
  return{
    type: ProductionChainConstants.SET_EXECUTOR,
    data:{
      stepId: stepId,
      taskId: taskId,
      executor: executor
    }
  }
}

const SetExecutionDate = (stepId, taskId, executionDate) => {
  return{
    type: ProductionChainConstants.SET_EXECUTION_DATE,
    data:{
      stepId: stepId,
      taskId: taskId,
      executionDate: executionDate
    }
  }
}

const SelectTask = (stepId, taskId) => {
  return{
    type: ProductionChainConstants.SELECT_TASK,
    data:{
      stepId: stepId,
      taskId: taskId
    }
  }
}

const SetStepPrice = (taskId, price) => {
  return (dispatch, getState) => {
    const stepId = getState().Interface.ActiveStep - 1;
    dispatch({
      type: ProductionChainConstants.SET_STEP_PRICE,
      data:{
        taskId: taskId,
        stepId: stepId,
        price: price,
      }
    }) 
  }
}

const OpenAttachments = (attachmentId, taskId) => {
  return (dispatch, getState) => {
    const stepId = getState().Interface.ActiveStep - 1;
    dispatch({
      type: ProductionChainConstants.OPEN_ATTACHMENT,
      data: {
        lightboxIsOpened: true,
        currentAttachment: attachmentId,
        taskId: taskId,
        stepId: stepId,
      }
    })
  }
};

const NextAttachment = (taskId) => {
  return (dispatch, getState) => {
    const stepId = getState().Interface.ActiveStep - 1;
    dispatch({
      type: ProductionChainConstants.NEXT_ATTACHMENT,
      data: {
        taskId: taskId,
        stepId: stepId,
      }
    })
  }
};

const PreviousAttachment = (taskId) => {
  return (dispatch, getState) => {
    const stepId = getState().Interface.ActiveStep - 1;
    dispatch({
      type: ProductionChainConstants.PREVIOUS_ATTACHMENT,
      data: {
        taskId: taskId,
        stepId: stepId,
      }
    })
  }
};

const CloseAttachments = (taskId) => {
  return (dispatch, getState) => {
    const stepId = getState().Interface.ActiveStep - 1;
    dispatch({
      type: ProductionChainConstants.CLOSE_ATTACHMENT,
      data: {
        taskId: taskId,
        stepId: stepId,
      }
    })
  }  
};

const UpdateLayersOrder = (taskId, newOrder) => {
  return (dispatch, getState) => {
    const  stepId = getState().Interface.ActiveStep - 1;
    dispatch({
      type:ProductionChainConstants.UPDATE_LAYERS_ORDER,
      data:{
        stepId: stepId,
        taskId: taskId,
        newOrder: newOrder
      }
    })
  }
};

const SetIntermediateControl = ( taskId, newIC) => {
  return (dispatch, getState) => {
    const  stepId = getState().Interface.ActiveStep - 1;
    dispatch({
      type: ProductionChainConstants.SET_INTERMEDIATE_CONTROL,
      data:{
        stepId: stepId,
        taskId: taskId,
        newIC: newIC,
      },
    })
  };
};

const SetQualityControl = (taskId, newQC) => {
  return (dispatch, getState) => {
    const  stepId = getState().Interface.ActiveStep - 1;
    dispatch({
      type: ProductionChainConstants.SET_QUALITY_CONTROL,
      data:{
        stepId: stepId,
        taskId: taskId,
        newQC: newQC,
      },
    })
  };
};

const SelectSeparativeLayer = (taskId, separativeLayer) => {
  return (dispatch, getState) => {
    const stepId = getState().Interface.ActiveStep - 1;
    dispatch({
      type: ProductionChainConstants.SELECT_SEPARATIVE_LAYER,
      data:{ 
        taskId: taskId,
        stepId: stepId,
        separativeLayer: separativeLayer
      },
    })
  };
};

const SelectSpray = (taskId, spray) => {
  return (dispatch, getState) => {
    const stepId = getState().Interface.ActiveStep - 1;
    dispatch({
      type: ProductionChainConstants.SELECT_SPRAY,
      data:{ 
        taskId: taskId,
        stepId: stepId,
        spray: spray
      },
    })
  };
};


export default {
  AddStage,
  SetQualityControl,
  SetIntermediateControl,
  SetTimeDurationHours,
	SetTimeDurationMinutes,
  SelectSeparativeLayer,
  SetCustomTaskDescription,
  SelectSpray,
  UpdateLayersOrder,
  OpenAttachments,
  CloseAttachments,
  NextAttachment,
  PreviousAttachment,
  DeleteScreenshot,
  RemoveQCCriterion,
  AddQCCriterion,
  EditQCCriterion,
  EditScreenshot,
  SetStepPrice,

  AddScreenshot,
  
  SelectTask,
  SetExecutionDate,
  SetExecutor,

	CheckQCCriterion,

	DeleteStage
}