import {
  HTTPConstants
} from '../Constants';

const initState = {
  errorCode: 0,
  errorText: '',
  isError: false,
  cryticalError: {

  },
  warning: {
    isWarning: false,
    warningText: '',
    warningCode: 0,
  },
  isTechDocLoaded: false,
};

const SET_WARNING = (newState, oldState) => {
  return {
    ...oldState,
    warning: {
      isWarning: true,
      warningText: newState.warningText,
      warningCode: newState.warningCode,
    },
  }
};

const CLOSE_WARNING = (oldState) => {
  return {
    ...oldState,
    warning: {
      isWarning: false,
      warningText: '',
      warningCode: 0,
    }
  }
}

const SET_TECHDOC_LOADED = (oldState) => {
  return {
    ...oldState,
    isTechDocLoaded: true
  };
};

const SET_TECHDOC_NOT_LOADED = (oldState) => {
  return {
    ...oldState,
    isTechDocLoaded: false,
  }
}

const REQUEST_ERROR = (newState, oldState) => {
  let newErrorText = '';

  switch (newState){
    case 500:
      newErrorText=' ОШИБКА СЕРВЕРА ' + newState; 
      break;

    case 404:
      newErrorText=' СТРАНИЦА НЕ НАЙДЕНА ' + newState; 
      break;

    default:
      newErrorText='НЕИЗВЕСТНАЯ ОШИБКА'
      break;
  }

  return{
    ...oldState,
    errorCode: newState,
    isError: true,
    errorText: newErrorText
  }
};

export default (state = initState, action) => {
  switch (action.type) {
    case HTTPConstants.REQUEST_ERROR:
      return REQUEST_ERROR(action.data, state);

    case HTTPConstants.SET_TECHDOC_LOADED:
      return SET_TECHDOC_LOADED(state);

    case HTTPConstants.SET_TECHDOC_NOT_LOADED:
      return SET_TECHDOC_NOT_LOADED(state);

    case HTTPConstants.SET_WARNING:
      return SET_WARNING(action.data, state);

    case HTTPConstants.CLOSE_WARNING:
      return CLOSE_WARNING(state);

    default:
      return state;
  }
}