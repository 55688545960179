import React, { PureComponent } from 'react';
import {
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';

import {
  Delete
} from '@material-ui/icons'

const styles = {
  addingModelButton: {
    marginTop: 12,
    marginBottom: 12,
    width: '100%',
  },
  input: {
    display: 'none',
  },
  modelTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
}

class ModelUploader extends PureComponent {
  render(){
    const {
      newTechDocModelDeviceLink
    } = this.props;
    return(
      <div>
        {newTechDocModelDeviceLink == null ?
        <div>
          <input
            style={styles.input}
            multiple
            type="file"
            onChange={(event) => this.props.ChangeNewTechDocModelDeviceLink(event.target.files[0])}
            id='adding-model'
          />
          <label
            htmlFor='adding-model'
          >
            <Button
              variant="outlined"
              style={styles.addingModelButton}
              component="span"
            >
              Добавить модель
            </Button>
          </label>
        </div>
        :
        <div
          style={styles.modelTitle}
        >
          <Typography
            variant='body2'
            align='left'
          >
            {newTechDocModelDeviceLink.name}
          </Typography>
          <IconButton
            onClick={() => this.props.ChangeNewTechDocModelDeviceLink(null)}
          >
            <Delete/>
          </IconButton>
        </div>
      }
      </div>
    )
  }
}

export default ModelUploader;