import React, { Component, Fragment } from "react";

import {
  Typography
} from '@material-ui/core';

import _ from 'lodash';

const styles = {
  taskDescriptionText:{
    marginBottom:'12px',
    whiteSpace:'pre-wrap'
  },
  taskDescription:{
    display:'flex',
    flexDirection:'column', 
    alignContent:'space-around' 
  }
}

class RiggingPreparingDescription extends Component{
  render(){
    const {
      data,
      textTemplate,
      additionalDescription
    } = this.props;
    const textDescription = _.template(textTemplate);
    const separativeLayer = !_.isUndefined(data.separativeLayer) ? data.separativeLayer : '';
    const timeBetweenLayers = !_.isUndefined(data.timeBetweenLayers) ? data.timeBetweenLayers : '';
    return(
      <Fragment>
        <Typography
          headlineMapping={{body1:'p'}}
          variant='body1'
          align='left'
          style={styles.taskDescriptionText}
        >
          {(separativeLayer !== '' && timeBetweenLayers !== '') ? textDescription({'separativeLayer': separativeLayer, 'timeBetweenLayers': timeBetweenLayers}) : 'Разделительный слой не выбран'}
        </Typography>
        <Typography
          headlineMapping={{body1:'p'}}
          variant='body1'
          align='left'
          style={styles.taskDescriptionText}
        >
          Дополнительная информация: {additionalDescription}
        </Typography>
      </Fragment>
    );
  }
}

export default RiggingPreparingDescription;