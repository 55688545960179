import React, {Component} from 'react';

import {
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import _ from 'lodash';
const head = ['№','Наименование материала', 'Количество','Цена'];
class HeadMaterialsInfo extends Component {
  render(){
	  const {
		  needsDeleting
	  } = this.props;
    return(
      <TableHead>
        <TableRow>
          {_.map(needsDeleting ? _.concat(head, 'Удаление') : head, (headItem, headId) => {
              return(
                <TableCell
                  key={headId}
                >
                  {headItem}
                </TableCell>
              )})
          }
        </TableRow>
      </TableHead>
    );
  }
}

export default HeadMaterialsInfo;