import React, { Component } from 'react';

import _ from 'lodash';

import TaskStatesCard from './TaskStatesCard';

import OverdueCard from './OverdueCard';

class StatesList extends Component {
  componentDidMount(){
    this.props.GetProductionActualInfo();
  }

  render(){
    const {
      statesList
    } = this.props;
    return(
      <div
        style={{
          overflowX: 'scroll',
          display:'flex',
          flex: 1,
          whiteSpace: 'nowrap',
          position: 'relative',
        }}
      >
        <div
          style={{
            flexWrap: 'nowrap',
            position:'absolute',
            width: '100%',
            height: '100%',
            display: 'inline-block',
          }}
        >
          {_.map(statesList, (stateItem, stateTitle) => {
            return(
              stateTitle !== 'overdue' ?
                <TaskStatesCard
                  key={stateTitle}
                  state={stateTitle}
                  stateList={stateItem}
                /> 
                :
                <OverdueCard
                  overdueList={stateItem}
                  SelectOverdueTask={this.props.SelectOverdueTask}
                />
            )})
          }
        </div>
      </div>
    )
  }
}

export default StatesList;