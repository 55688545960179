import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import {NotCutMaterialSize} from ".";
import {IconButton} from "@material-ui/core";
import {Add} from "@material-ui/icons";

export default class NotSealingOblational extends Component {
	changeLength(sizeId, newLength) {
		const { cutGroupId, sizes } = this.props;
		let newSizes = sizes;
		newSizes[sizeId].length = newLength;
		this.props.SaveONCG(cutGroupId, 'sizes', newSizes)
	};

	changeCount(sizeId, newCount) {
		const { cutGroupId, sizes } = this.props;
		let newSizes = sizes;
		newSizes[sizeId].count = newCount;
		this.props.SaveONCG(cutGroupId, 'sizes', newSizes)
	};

	deleteSize(sizeId) {
		const { cutGroupId, sizes } = this.props;
		let newSizes = sizes;
		sizes.splice(sizeId, 1);
		this.props.SaveONCG(cutGroupId, 'sizes', newSizes)
	};

	render() {
		const { sizes, cutGroupId } = this.props;
		return (
			<Fragment>
				{sizes.map((size, sizeId) => {
					return <NotCutMaterialSize
						sizeIndex={sizeId}
						key={size._id}
						onChangeLength={this.changeLength.bind(this)}
						onChangeCount={this.changeCount.bind(this)}
						onClickDelete={() => this.deleteSize(sizeId)}
						length={size.length}
						count={size.count}
					/>
				})}
				<div
					style={{
						display: 'flex',
						marginTop: 8,
						flexDirection: 'row',
						alignItems: 'center',
					}}
				>
					<IconButton
						onClick={() => this.props.SaveONCG(cutGroupId, 'sizes', _.concat(sizes, { count: 1, length: 0 }))}
					>
						<Add/>
					</IconButton>
					<span
						style={{
							marginLeft: 3,
							fontSize: 14,
							letterSpacing: 0,
							lineHeight: '16px',
							fontWeight: '400',
							fontFamily: 'Roboto'
						}}
					>
						Добавить размер
					</span>
				</div>
			</Fragment>
		)
	}
};
