import React, { Component } from 'react';

import _ from 'lodash';

import {
	Typography,
} from '@material-ui/core';

import {
	MyStoreMaterialsTable
} from '../DumbComponents'

export default class MyStoreSortedTables extends Component {
	render() {
		const {
			materials
		} = this.props;
		const groupedByConfirmingMaterials = _.groupBy(_.sortBy(materials, 'isConfirmed'), 'isConfirmed');
		return(
			<div
				style={{
					padding: 24,
					paddingBottom: 56,
					width: '100%',
				}}
			>
				{_.map(groupedByConfirmingMaterials, (group, groupKey) => {
					const groupedBySuperCategoryMaterials = _.groupBy(_.sortBy(group, 'category.superCategory.title'), 'category.superCategory.title');
					return <div
						key={('material' + groupKey)}
					>
						<Typography
							variant='title'
							style={groupKey !== 'false' ? { marginTop: 36 } : {  }}
							color={groupKey !== 'false' ? 'textPrimary' : 'error'}
						>
							{groupKey === 'false' ? 'Неподтвержденные материалы' : 'Подтвержденные материалы'}
						</Typography>
						{_.map(groupedBySuperCategoryMaterials, (scMaterials, scTitle) => {
							return <div
								key={('superCatTitle' + scTitle)}
							>
								<Typography
									variant='subheading'
									style={{
										marginTop: 24,
										marginBottom: 12,
									}}
								>
									<b>{scTitle}</b>
								</Typography>
								<MyStoreMaterialsTable
									materials={scMaterials}
									OpenChangingMyStoreMaterialDialog={this.props.OpenChangingMyStoreMaterialDialog}
									DeleteMaterialById={this.props.DeleteMaterialById}
								/>
							</div>
						})}
					</div>
				})}
			</div>
		)
	}
}
