import React, { PureComponent } from 'react';

import {
	Menu,
} from '@material-ui/core';

import { PhotoshopPicker } from 'react-color'

class ColorPicker extends PureComponent {
	render() {
		const {
			isColorPickerOpened,
			selectedColor,
		} = this.props;
		return (
			<Menu
				MenuListProps={{ style: { padding: 0 } }}
				open={isColorPickerOpened}
			>
				<PhotoshopPicker
					header='Выберите цвет'
					color={selectedColor}
					onChangeComplete={(color, event) => {
						this.props.ChangeSelectedColor(color.hex);
						event.stopPropagation();
					}}
					onAccept={(event) => {
						this.props.ChangeSurfaceColor();
						event.stopPropagation();
					}}
					onCancel={(event) => {
						this.props.CloseColorPicker();
						event.stopPropagation();
					}}
				/>
			</Menu>
		)
	}
};

export default ColorPicker;
