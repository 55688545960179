import React, { Component, Fragment } from 'react';

import {
  Typography
} from '@material-ui/core';

const styles = {
  taskDescriptionText: {
    marginBottom: '12px',
    marginRight: '12px',
    marginTop: '12px',
    whiteSpace: 'pre-wrap'
  },
  taskDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-around'
  }
}

class CustomDescription extends Component {
  render() {
    const {
      textDescription,
    } = this.props;
    return (
      <Fragment>
        <Typography
          headlineMapping={{ body1: 'p' }}
          variant='body1'
          align='left'
          style={styles.taskDescriptionText}
        >
          <b>Описание задачи:</b> {textDescription}
        </Typography>
      </Fragment>
    );
  }
}

export default CustomDescription;