import React, {Component} from 'react';

import {
  Typography,
} from '@material-ui/core';

const styles = {
  description: {
    marginRight: '12px'
  }
}

class ResinLifecycleAndType extends Component{
  render(){
    const {
      resinTitle,
      lifeCycle,
      typeTitle
    } = this.props.polymerizationGraph;
    return(
      <div>
        <div
          className='coveringLayersInfoTemplate'
        >
          <Typography
            variant='body1'
            style={styles.description}
          >
            График полимеризации смолы:
          </Typography>
          <Typography
            variant='body1'
          >
            <b>{resinTitle}</b>
          </Typography>
        </div>
        <div>
          <Typography
            variant='body1'
            style={styles.description}
          >
            Время жизни: <b>{lifeCycle}</b> мин.
          </Typography>
          <div
            className='coveringLayersInfoTemplate'
          >
            <Typography
              variant='body1'
              style={styles.description}
            >
              Тип отверждения: 
            </Typography>
            <Typography
              variant='body1'
              style={styles.description}
            >
              <b>{typeTitle}</b>
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default ResinLifecycleAndType;