import React, { Component } from 'react';

import {
	ExpansionPanelSummary,
	ExpansionPanelDetails,
} from '@material-ui/core';

import {
	ExpandMore,
} from '@material-ui/icons';

import {OutlinedExpansionPanel} from "../_NewDesignComponents";
import OblationalMaterialsWithCut from "./OblationalMaterialWithCut";
import OblationalMaterialsNoCut from './OblationalMaterialNoCut';

const styles = {
	oblationalLayerInfoContainer: {
		display: 'block',
	},
};

class OblationalMaterialCutInfo extends Component {
	constructor(props){
		super(props);
		this.state = {
			isOpen: false
		}
	}

	onExpansionOpenedHandler = () => {
		this.setState({isOpen: !this.state.isOpen})
	}
	
	render() {
		const {
			cutGroup,
			filteredOblationalCutMaterials,
		} = this.props;

		const {
			isOpen
		} = this.state;
		return(
			<OutlinedExpansionPanel
				key={cutGroup._id}
			>
				<ExpansionPanelSummary
					onClick={this.onExpansionOpenedHandler}
					expandIcon={<ExpandMore/>}
					style={isOpen ? 
						{
							fontSize: 16,
							fontFamily: 'Roboto',
							letterSpacing: -0.3,
							fontWeight: '500',
						} 
						: 
						{ 
							fontSize: 14,
							fontFamily: 'Roboto',
							letterSpacing: -0.2,
							lineHeight: '16px'
						}
					}
				>
					{cutGroup.groupTag.title}
				</ExpansionPanelSummary>
				<ExpansionPanelDetails
					style={styles.oblationalLayerInfoContainer}
				>
					{cutGroup.groupTag.needsCutting ? <OblationalMaterialsWithCut
						cutGroup={cutGroup}
						filteredOblationalCutMaterials={filteredOblationalCutMaterials}

						AddOblationalSurface={this.props.AddOblationalSurface}
						ChangeCuttingGroupMaterial={this.props.ChangeCuttingGroupMaterial}
						SaveCuttingGroup={this.props.SaveCuttingGroup}
						DeleteOblationalSurface={this.props.DeleteOblationalSurface}
						SetCuttingGroupIsBag={this.props.SetCuttingGroupIsBag}
					/>
					:
					<OblationalMaterialsNoCut
						cutGroup={cutGroup}
						filteredOblationalCutMaterials={filteredOblationalCutMaterials}

						SaveONCG={this.props.SaveONCG}
					/>}
				</ExpansionPanelDetails>
			</OutlinedExpansionPanel>
		)
	}
}

export default OblationalMaterialCutInfo;
