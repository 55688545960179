import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  TextField,
  Button,
} from '@material-ui/core';

class AddingResinDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      materialToMix: '',
    };
  }

  componentDidMount() {
    const {
      selectedResin,
      newResinAmount,
    } = this.props;
    if (_.isUndefined(selectedResin.material)) {
      this.props.SetSelectedResin({material: ''});
    };
    if (_.isUndefined(selectedResin.amount)) {
      this.props.SetSelectedResin({amount: _.ceil(newResinAmount * 10) / 10});
    }
  }

  render() {
    const {
      isAddingResinDialogOpened,
      resinsList,
      selectedResin,
    } = this.props;
    const mixedResins = _.reduce(resinsList, (newResinsList, resin) => {
      return _.concat(newResinsList, _.map(resin.description.complementaryRules, cr => {
        return {
          resinTitle: resin.title,
          resinId: resin._id,
          hardenerTitle: cr.material.title,
          hardenerId: cr.material._id
        }
      }))
    }, []);
    return(
      <Dialog
        open={isAddingResinDialogOpened}
        PaperProps={{ style: { width: 1080 } }}
      >
        <DialogTitle>
          ДОБАВЛЕНИЕ НОВОЙ СМОЛЫ
        </DialogTitle>
        <DialogContent
        >
          <FormControl
            fullWidth
          >
            <Select
              value={selectedResin.material}
              onChange={(event) => {
                this.props.SetSelectedResin({material: event.target.value});
              }}
            >
              {_.map(mixedResins, resin => {
                return <MenuItem
                  key={resin.resinId + '/' + resin.hardenerId}
                  value={resin.resinId + '/' + resin.hardenerId}
                >
                  {resin.resinTitle + ' + ' + resin.hardenerTitle}
                </MenuItem>
              })}
            </Select>
            <FormHelperText>
              Смола
            </FormHelperText>
          </FormControl>

          <TextField
            fullWidth
            helperText='Количество смолы'
            type='number'
            value={selectedResin.amount}
            onChange={(event) => {
              if (event.target.value > 0) {
	              this.props.SetSelectedResin({amount: event.target.value})
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.CloseResinAddingDialog}
          >
            Отменить
          </Button>
          <Button
            disabled={selectedResin.material === ''}
            onClick={() => this.props.SaveResin()}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
};

export default AddingResinDialog;
