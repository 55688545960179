import React, {Component} from 'react';

import {
  Table,
  Typography
} from '@material-ui/core';

import BodyArmorMaterialsInfo from './BodyArmorMaterialsInfo';
import HeadMaterialsInfo from '../HeadMaterialsInfo';

const styles = {
  materialInfo: {
    overflowX: 'auto',
    display: 'block'
  },
  noMaterial:{
    width: '100%', 
    display:'flex', 
    justifyContent:'center' 
  }
}

class ArmorMaterialsInfo extends Component {
  render() {
    const {
      armorMaterials,
      needsDeleting,
    } = this.props;
  
    if (armorMaterials.length > 0)
     return(
      <div
        className='materialsInfo'
      >
        <Table
	        style={styles.materialInfo}
        >
          <HeadMaterialsInfo
            needsDeleting={needsDeleting}
          />
          <BodyArmorMaterialsInfo
            armorMaterials={armorMaterials}
          />
        </Table>
      </div>
    )
      else 
        return(
          <Typography
            variant='body1'
            style={styles.noMaterial}
          > 
            <b>Добавьте армирующие материалы</b>
          </Typography>
        )
  }
}

export default ArmorMaterialsInfo;
