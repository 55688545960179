import React, {Component} from 'react';

import {
  Typography,
  Avatar
} from '@material-ui/core'

const styles = {
  techDocDescriptionTextContainer: {
    width: '100%',
    height: 'auto',
    justifyContent: 'center',
  },
  avatar: {
    height: '100%',
    width: 'auto',
    justifyContent: 'center',
    marginRight: 12,
  },
  pic:{
    width: 48, 
    height: 48, 
    border: '1px solid grey',
    cursor: 'pointer',
  }
};

class TitleAndTechnologyDescription extends Component{
  render(){
    const{
      title,
      technology,
      cover,
    } = this.props;
    return(
      <div 
        className='headStepperLeftSide'
      >
        <div
	        style={styles.avatar}
        >
          <Avatar
            src={cover}
            style={styles.pic}
            onClick={() => this.props.SetAvatar()}
          />
        </div>
        <div
          style={styles.techDocDescriptionTextContainer}
        >
          <Typography
            variant='subheading'
          >
            <b>{title}</b>
          </Typography>
          <Typography
            variant='subheading'
          >
            {technology}
          </Typography>
        </div>
      </div>
    );
  }
}

export default TitleAndTechnologyDescription;