import React, {Component} from 'react';

import {
  TableCell,
  TableBody,
  TableRow,
  Typography,
} from '@material-ui/core';

import _ from 'lodash';

class BodyArmorMaterialsInfo extends Component {
  render(){
    const {
      armorMaterials
    } = this.props
    return(
      <TableBody>
        {_.map(armorMaterials, (armorMaterial, armorMaterialId) => {
          return(
            <TableRow
              key={armorMaterial.material._id}
            >
              <TableCell>
                {armorMaterialId + 1}
              </TableCell>
              <TableCell>
                <Typography
                  variant='body1'
                >
                  {armorMaterial.material.title}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant='body1'
                >
                  {`${_.floor(armorMaterial.amount * 100) / 100} ${armorMaterial.material.category.measureUnits}` }
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant='body1'
                > 
                  {_.floor(armorMaterial.material.price * armorMaterial.amount, 2)} ₽
                </Typography>
              </TableCell>
            </TableRow>
          )
        })

        }
      </TableBody>
    )
  }
}

export default BodyArmorMaterialsInfo;