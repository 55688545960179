import React from 'react';
import ReactDOM from 'react-dom';

import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';

import './Styles/appStyles.css';
import './literallycanvas.css';
import './Styles/headStyles.css';
import ReduxThunk from 'redux-thunk';
import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';

import {
  Provider,
} from 'react-redux';

import {
  Router,
  Route,
  browserHistory,
} from 'react-router';

import {
  routerReducer,
  routerMiddleware,
  syncHistoryWithStore,
} from 'react-router-redux';

import {
  TechDocsReducer,
  ProductionChainReducer,
  InterfaceReducer,
  TechnologiesReducer,
  NewTechDocReducer,
  TechDocCutReducer,
  TechDocMaterialsReducer,
  TechDocMaterialsAmountReducer,
  NewSurfaceMaterialReducer,
  ProductsReducer,
  MyStoreReducer,
  UsersReducer,
  TestReducer,
  HTTPReducer,
  ProjectsReducer,
  AuthReducer,
  MeReducer,
  NavigationReducer,
  AnalyticsReducer
} from './Redux/Reducers';

import {
  TechDocsActions,
  NavigationActions,
  ProjectsActions,
  ProductsActions
} from './Redux/Actions'

import {
  TechDocsListScreen,
  TechDocCreatingPage,
  Test,
  ProductsListScreen,
  MyStoreMaterialsAdding,
  Tasks,
  Projects,
  AuthorizationPage,
  Realizations,
  UsersAdding,
  App,
  AnalyticsScreen,
  WorkerCut,
  TechDocReadOnly
} from './Components/Scens/';

const styleNode = document.createComment("jss-insertion-point");
document.head.insertBefore(styleNode, document.head.firstChild);

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());
jss.options.insertionPoint = 'jss-insertion-point';
const Middleware = routerMiddleware(browserHistory);

const store = createStore(
  combineReducers({
    TechDocs: TechDocsReducer,
    ProductionChain: ProductionChainReducer,
    Interface: InterfaceReducer,
    Technologies: TechnologiesReducer,
    NewTechDoc: NewTechDocReducer,
    TechDocCut: TechDocCutReducer,
    TechDocMaterials: TechDocMaterialsReducer,
    TechDocMaterialsAmount: TechDocMaterialsAmountReducer,
    su: TestReducer,
    NewSurfaceMaterial: NewSurfaceMaterialReducer,
    Products: ProductsReducer,
    MyStore: MyStoreReducer,
    Users: UsersReducer,
    HTTP: HTTPReducer,
    Projects: ProjectsReducer,
    Auth: AuthReducer,
    Me: MeReducer,
    Navigation: NavigationReducer,
    Analytics: AnalyticsReducer,
    routing: routerReducer,
  }),
  compose(
    // applyMiddleware(logger),
    applyMiddleware(ReduxThunk),
    applyMiddleware(Middleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  ),
);

const history = syncHistoryWithStore(browserHistory, store);

ReactDOM.render(
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <Provider store={store}>
      <Router history={history}>
        <Route
          path='/'
          exact
          component={AuthorizationPage}
          onEnter={() => store.dispatch(NavigationActions.AuthPageHandle())}
        />
        <Route
          path='/workerinfo/:techDocId/:materialId'
          component={WorkerCut}
        />
        <Route
          path='/techdocdescription/:techDocId'
          component={TechDocReadOnly}
        />
        {/*<Route*/}
        {/*path='/workerinfo/:techDocId/oblational/:materialId'*/}
        {/*component={WorkerCut}*/}
        {/*/>*/}
        <Route
          onEnter={() => store.dispatch(NavigationActions.AppHandle())}
        >
          <Route
            path="/product/:productId/:techDocId"
            component={TechDocCreatingPage}
            onLeave={() => store.dispatch(TechDocsActions.ClearTechDoc())}
            routeTitle='Технологическая карта'
            routeKey='techdoc'
            onEnter={() => store.dispatch(NavigationActions.SetAccess('tech'))}
          />
          <Route component={App}>
            <Route
              onEnter={() => store.dispatch(NavigationActions.SetAccess('head'))}
            >
              <Route
                path='/project'
                component={Projects}
                onLeave={() => store.dispatch(ProjectsActions.CleanProjects())}
                routeTitle='Проекты'
                routeKey='projects'
              />
              <Route
                path='/project/:projectId'
                onLeave={() => store.dispatch(ProjectsActions.CleanCurrentProject())}
                component={Realizations}
                routeTitle='Реализации'
                routeKey='realizations'
              />
              <Route
                path='/project/:projectId/:realizationId'
                onLeave={() => store.dispatch(ProjectsActions.CleanCurrentRealization())}
                component={Tasks}
                routeTitle='Реализация'
                routeKey='realization'
              />
            </Route>
            <Route
              path='/analytics'
              component={AnalyticsScreen}
              routeKey='analytics'
              routeTitle='Аналитика'
              onEnter={() => store.dispatch(NavigationActions.SetAccess('head'))}
            />
            <Route
              path='/users'
              component={UsersAdding}
              routeTitle='Пользователи'
              routeKey='users'
              onEnter={() => store.dispatch(NavigationActions.SetAccess('admin_de'))}
            />
            <Route
              path="/test"
              component={Test}
              routeTitle='Тест'
            />
            <Route
              onEnter={() => store.dispatch(NavigationActions.SetAccess('tech'))}
            >
              <Route
                path='/product'
                onLeave={() => store.dispatch(ProductsActions.CleanProducts())}
                component={ProductsListScreen}
                routeTitle='Изделия'
                routeKey='products'
              />
              <Route
                path="/product/:productId"
                onLeave={() => store.dispatch(ProductsActions.CleanTechDocsList())}
                component={TechDocsListScreen}
                routeTitle='Технологические карты'
                routeKey='techdocs'
              />
            </Route>
            <Route
              path="/materials"
              component={MyStoreMaterialsAdding}
              onEnter={() => store.dispatch(NavigationActions.SetAccess('tech'))}
              routeTitle='Материалы'
              routeKey='materials'
            />
          </Route>
        </Route>
      </Router>
    </Provider>
  </JssProvider>,
  document.getElementById('root')
);

export default store;
