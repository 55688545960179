import {
  InterfaceConstants, 
} from '../Constants';
//import { stat } from 'fs';

const initState = {
  isStepperOpen: false,
  ActiveStep: 0,
  isCreatingTechDocDialogOpened: false,
  currentImageInEditing: {},
  isGraphicsEditorOpened: false,
  isAddingMaterialDialogOpened: false,
  isAddingQCCriterionDialogOpened: false,
  isEditQCCriterionDialogOpened: false,
  isCreateNewProductDialogOpened: false,

  isColorPickerOpened: false,
  selectedColor: '',

  isCutEditorOpened: false,

  isChangingSurfaceMaterialDialogOpened: false,

	editorSelectedMaterial: '',
  editorSelectedLayer: '',

	currentMeasureResult: '0',

  isChangingMyStoreMaterialDialogOpened: false,
  isCreateNewProjectDialogOpened: false,

  isAddingResinDialogOpened: false,

  isCreateNewSubProjectDialogOpened: false,

  isAddingOblationalMaterialDialogOpened: false,

  isAddUserDialogOpened: false,

  isAreYouSureDialogOpened: false,

  isMaterialDialogOpened: false,
};

const OPEN_MATERIAL_DIALOG = (oldState) => {
  return {
    ...oldState,
    isMaterialDialogOpened: true
  }
}

const CLOSE_MATERIAL_DIALOG = (oldState) => {
  return { 
    ...oldState,
    isMaterialDialogOpened: false
  }
}

const OPEN_SET_NFC_REALIZATION_DIALOG = (oldState) => {
  return {
    ...oldState,
    isSetNFCToRealisationOpened: true
  }
}

const CLOSE_SET_NFC_REALIZATION_DIALOG = (oldState) => {
  return {
    ...oldState,
    isSetNFCToRealisationOpened: false
  }
}

const OPEN_ARE_YOU_SURE_DIALOG = (oldState) => {
  return {
    ...oldState,
    isAreYouSureDialogOpened: true
  }
}

const CLOSE_ARE_YOU_SURE_DIALOG = (oldState) => {
  return {
    ...oldState,
    isAreYouSureDialogOpened: false
  }
}

const OPEN_ADDING_USER_DIALOG = (oldState) => {
  return{
    ...oldState,
    isAddUserDialogOpened: true
  };
};

const CLOSE_ADDING_USER_DIALOG = (oldState) => {
  return{
    ...oldState,
    isAddUserDialogOpened: false
  };
};

const OPEN_OBLATIONAL_MATERIAL_ADDING_DIALOG = (oldState) => {
  return {
    ...oldState,
    isAddingOblationalMaterialDialogOpened: true
  };
};

const CLOSE_OBLATIONAL_MATERIAL_ADDING_DIALOG = (oldState) => {
	return {
		...oldState,
		isAddingOblationalMaterialDialogOpened: false
	}
}

const OPEN_CREATING_NEW_SUB_PROJECT_DIALOG = (oldState) => {
  return {
    ...oldState,
    isCreateNewSubProjectDialogOpened: true
  }
}

const CLOSE_CREATING_NEW_SUB_PROJECT_DIALOG = (oldState) => {
  return {
    ...oldState,
    isCreateNewSubProjectDialogOpened: false
  }
}

const OPEN_RESIN_ADDING_DIALOG = (oldState) => {
  return {
    ...oldState,
    isAddingResinDialogOpened: true,
  };
};

const CLOSE_RESIN_ADDING_DIALOG = (oldState) => {
  return {
    ...oldState,
    isAddingResinDialogOpened: false,
  }
}

const OPEN_CREATING_NEW_PROJECT_DIALOG = (oldState) => {
  return {
    ...oldState,
    isCreateNewProjectDialogOpened: true,
  };
};

const CLOSE_CREATING_NEW_PROJECT_DIALOG = (oldState) => {
  return {
    ...oldState,
    isCreateNewProjectDialogOpened: false,
  };
};

const OPEN_CHANGING_MY_STORE_MATERIAL_DIALOG = (oldState) => {
  return {
    ...oldState,
    isChangingMyStoreMaterialDialogOpened: true,
  };
};

const CLOSE_CHANGING_MY_STORE_MATERIAL_DIALOG = (oldState) => {
  return {
    ...oldState,
    isChangingMyStoreMaterialDialogOpened: false
  }
}

const SET_MEASURE_RESULT = (newState, oldState) => {
	return {
		...oldState,
		currentMeasureResult: newState,
	}
}

const SET_CURRENT_IMAGE = (newState, oldState) => {
  return {
    ...oldState,
    currentImageInEditing: JSON.parse(JSON.stringify(newState)),
  };
};

const CHANGE_EDITOR_SELECTED_LAYER = (newState, oldState) => {
  return {
    ...oldState,
    editorSelectedLayer: newState,
  }
}

const CHANGE_EDITOR_SELECTED_MATERIAL = (newState, oldState) => {
	return {
		...oldState,
		editorSelectedMaterial: newState,
	};
};

const OPEN_CHANGING_SURFACE_MATERIAL_DIALOG = (oldState) => {
  return {
    ...oldState,
    isChangingSurfaceMaterialDialogOpened: true,
  };
};

const CLOSE_CHANGING_SURFACE_MATERIAL_DIALOG = (oldState) => {
	return {
		...oldState,
		isChangingSurfaceMaterialDialogOpened: false,
	};
};

const OPEN_CUT_EDITOR = (oldState) => {
  return {
    ...oldState,
    isCutEditorOpened: true,
  };
};

const CLOSE_CUT_EDITOR = (oldState) => {
  return {
    ...oldState,
    isCutEditorOpened: false,
  };
};

const CHANGE_SELECTED_COLOR = (newState, oldState) => {
	return {
		...oldState,
		selectedColor: newState,
	}
};

const OPEN_COLOR_PICKER = (oldState) => {
  return {
    ...oldState,
    isColorPickerOpened: true,
  };
};

const CLOSE_COLOR_PICKER = (oldState) => {
	return {
		...oldState,
		isColorPickerOpened: false,
	}
};

const OPEN_GRAPHICS_EDITOR = (oldState) => {
  return {
    ...oldState,
    isGraphicsEditorOpened:true
  }
};

const CLOSE_GRAPHICS_EDITOR = (oldState) => {
  return {
    ...oldState,
    isGraphicsEditorOpened:false
  }
};

const OPEN_CREATING_NEW_PRODUCT_DIALOG = (oldState) => {
  return {
    ...oldState,
    isCreateNewProductDialogOpened: true
  }
};

const CLOSE_CREATING_NEW_PRODUCT_DIALOG = (oldState) => {
  return {
    ...oldState,
    isCreateNewProductDialogOpened: false
  }
};

const CLOSE_EDIT_QC_CRITERION_DIALOG = (oldState) => {
  return {
    ...oldState,
    isEditQCCriterionDialogOpened: false
  }
};

const OPEN_EDIT_QC_CRITERION_DIALOG = (oldState) => {
  return {
    ...oldState,
    isEditQCCriterionDialogOpened: true
  }
}

const CLOSE_ADDING_QC_CRITERION_DIALOG = (oldState) => {
  return {
    ...oldState,
    isAddingQCCriterionDialogOpened: false
  }
};

const OPEN_ADDING_QC_CRITERION_DIALOG = (oldState) => {
  return {
    ...oldState,
    isAddingQCCriterionDialogOpened: true
  }
}

const CHANGE_STEPPER_OPEN = (newState, oldState) => {
  return {
    ...oldState,
    isStepperOpen: newState,
  }
}

const SET_ACTIVE_STEP = (newState, oldState) => {
  return {
    ...oldState,
    ActiveStep: newState,
  }
}

const OPEN_CREATING_TECHDOC_DIALOG = (oldState) => {
  return {
    ...oldState,
    isCreatingTechDocDialogOpened: true,
  }
}

const CLOSE_CREATING_TECHDOC_DIALOG = (oldState) => {
  return {
    ...oldState,
    isCreatingTechDocDialogOpened: false,
  };
};

const OPEN_ADDING_SURFACE_MATERIAL_DIALOG = (oldState) => {
  return {
    ...oldState,
    isAddingMaterialDialogOpened: true,
  };
};

const CLOSE_ADDING_SURFACE_MATERIAL_DIALOG = (oldState) => {
  return {
    ...oldState,
    isAddingMaterialDialogOpened: false,
  };
};

export default (state = initState, action) => {
  switch (action.type) {
    case InterfaceConstants.SET_CURRENT_IMAGE:
      return SET_CURRENT_IMAGE(action.data, state);

    case InterfaceConstants.CHANGE_STEPPER_OPEN:
      return CHANGE_STEPPER_OPEN(action.data, state);

    case InterfaceConstants.SET_ACTIVE_STEP:
      return SET_ACTIVE_STEP(action.data, state);

    case InterfaceConstants.OPEN_CREATING_TECHDOC_DIALOG:
      return OPEN_CREATING_TECHDOC_DIALOG(state);
    
    case InterfaceConstants.CLOSE_CREATING_TECHDOC_DIALOG:
      return CLOSE_CREATING_TECHDOC_DIALOG(state);

    case InterfaceConstants.OPEN_ADDING_SURFACE_MATERIAL_DIALOG:
      return OPEN_ADDING_SURFACE_MATERIAL_DIALOG(state);

    case InterfaceConstants.CLOSE_ADDING_SURFACE_MATERIAL_DIALOG:
      return CLOSE_ADDING_SURFACE_MATERIAL_DIALOG(state);

    case InterfaceConstants.OPEN_ADDING_QC_CRITERION_DIALOG:
      return OPEN_ADDING_QC_CRITERION_DIALOG(state);

    case InterfaceConstants.CLOSE_ADDING_QC_CRITERION_DIALOG:
      return CLOSE_ADDING_QC_CRITERION_DIALOG(state);

    case InterfaceConstants.OPEN_EDIT_QC_CRITERION_DIALOG:
      return OPEN_EDIT_QC_CRITERION_DIALOG(state);

    case InterfaceConstants.CLOSE_EDIT_QC_CRITERION_DIALOG:
      return CLOSE_EDIT_QC_CRITERION_DIALOG(state);
    
    case InterfaceConstants.OPEN_CREATING_NEW_PRODUCT_DIALOG:
      return OPEN_CREATING_NEW_PRODUCT_DIALOG(state);

    case InterfaceConstants.CLOSE_CREATING_NEW_PRODUCT_DIALOG:
      return CLOSE_CREATING_NEW_PRODUCT_DIALOG(state);

    case InterfaceConstants.CHANGE_SELECTED_COLOR:
      return CHANGE_SELECTED_COLOR(action.data, state);

    case InterfaceConstants.OPEN_COLOR_PICKER:
      return OPEN_COLOR_PICKER(state);

    case InterfaceConstants.CLOSE_COLOR_PICKER:
      return CLOSE_COLOR_PICKER(state);

    case InterfaceConstants.CLOSE_GRAPHICS_EDITOR:
      return CLOSE_GRAPHICS_EDITOR(state);

    case InterfaceConstants.OPEN_GRAPHICS_EDITOR:
      return OPEN_GRAPHICS_EDITOR(state);

    case InterfaceConstants.OPEN_CUT_EDITOR:
      return OPEN_CUT_EDITOR(state);

    case InterfaceConstants.CLOSE_CUT_EDITOR:
      return CLOSE_CUT_EDITOR(state);

    case InterfaceConstants.OPEN_CHANGING_SURFACE_MATERIAL_DIALOG:
      return OPEN_CHANGING_SURFACE_MATERIAL_DIALOG(state);

    case InterfaceConstants.CLOSE_CHANGING_SURFACE_MATERIAL_DIALOG:
      return CLOSE_CHANGING_SURFACE_MATERIAL_DIALOG(state);

	  case InterfaceConstants.CHANGE_EDITOR_SELECTED_MATERIAL:
		  return CHANGE_EDITOR_SELECTED_MATERIAL(action.data, state);

    case InterfaceConstants.CHANGE_EDITOR_SELECTED_LAYER:
      return CHANGE_EDITOR_SELECTED_LAYER(action.data, state);

	  case InterfaceConstants.SET_MEASURE_RESULT:
	  	return SET_MEASURE_RESULT(action.data, state);

    case InterfaceConstants.OPEN_CHANGING_MY_STORE_MATERIAL_DIALOG:
      return OPEN_CHANGING_MY_STORE_MATERIAL_DIALOG(state);

    case InterfaceConstants.CLOSE_CHANGING_MY_STORE_MATERIAL_DIALOG:
      return CLOSE_CHANGING_MY_STORE_MATERIAL_DIALOG(state);

    case InterfaceConstants.OPEN_CREATING_NEW_PROJECT_DIALOG:
      return OPEN_CREATING_NEW_PROJECT_DIALOG(state);

    case InterfaceConstants.CLOSE_CREATING_NEW_PROJECT_DIALOG:
      return CLOSE_CREATING_NEW_PROJECT_DIALOG(state);

    case InterfaceConstants.OPEN_RESIN_ADDING_DIALOG:
      return OPEN_RESIN_ADDING_DIALOG(state);

    case InterfaceConstants.CLOSE_RESIN_ADDING_DIALOG:
      return CLOSE_RESIN_ADDING_DIALOG(state);

    case InterfaceConstants.CLOSE_CREATING_NEW_SUB_PROJECT_DIALOG:
      return CLOSE_CREATING_NEW_SUB_PROJECT_DIALOG(state);

    case InterfaceConstants.OPEN_CREATING_NEW_SUB_PROJECT_DIALOG:
      return OPEN_CREATING_NEW_SUB_PROJECT_DIALOG(state);

    case InterfaceConstants.OPEN_OBLATIONAL_MATERIAL_ADDING_DIALOG:
      return OPEN_OBLATIONAL_MATERIAL_ADDING_DIALOG(state);

    case InterfaceConstants.CLOSE_OBLATIONAL_MATERIAL_ADDING_DIALOG:
      return CLOSE_OBLATIONAL_MATERIAL_ADDING_DIALOG(state);

    case InterfaceConstants.CLOSE_ADDING_USER_DIALOG:
      return CLOSE_ADDING_USER_DIALOG(state);
    
    case InterfaceConstants.OPEN_ADDING_USER_DIALOG:
      return OPEN_ADDING_USER_DIALOG(state);

    case InterfaceConstants.OPEN_ARE_YOU_SURE_DIALOG:
      return OPEN_ARE_YOU_SURE_DIALOG(state);

    case InterfaceConstants.CLOSE_ARE_YOU_SURE_DIALOG:
      return CLOSE_ARE_YOU_SURE_DIALOG(state);

    case InterfaceConstants.OPEN_MATERIAL_DIALOG: 
      return OPEN_MATERIAL_DIALOG(state);

    case InterfaceConstants.CLOSE_MATERIAL_DIALOG:
      return CLOSE_MATERIAL_DIALOG(state);


    case InterfaceConstants.OPEN_SET_NFC_REALIZATION_DIALOG:
      return OPEN_SET_NFC_REALIZATION_DIALOG(state);

    case InterfaceConstants.CLOSE_SET_NFC_REALIZATION_DIALOG:
      return CLOSE_SET_NFC_REALIZATION_DIALOG(state);
      
    default:
      return state;
  }
}
