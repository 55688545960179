import React, { Component, Fragment } from 'react';

import HeadMaterials from './HeadMaterials';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import{
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';

import ResinMaterialsInfo from './ResinMaterials/ResinMaterialsInfo';
import OblationalMaterialsInfo from './OblationalMaterials/OblationalMaterialsInfo';
import ArmorMaterialsInfo from './ArmorMaterials/ArmorMaterialsInfo';

class Materials extends Component{
  render() {
    const {
      superCategory,
      filteredMaterialsAmount,
      isAddingResinDialogOpened,
      resinsList,
      price,
	    selectedResin,
	    addingOblationalMaterials,
	    isAddingOblationalMaterialDialogOpened,
      selectedOblationalMaterial,
      newResinAmount,
      technology,
    } = this.props;

    return(
      <Fragment>
        {!(superCategory.title === 'Смолы' && technology === 'Препрег') &&
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <HeadMaterials
            title={superCategory.title}
            price={price}
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{overflowX: 'hidden'}}
        >
          {superCategory.title === 'Армирующие материалы' && 
            <ArmorMaterialsInfo
              armorMaterials={filteredMaterialsAmount}
              needsDeleting={false}
            />}
	        {superCategory.title === 'Жертвенные материалы' &&
            <OblationalMaterialsInfo
              oblationalMaterials={filteredMaterialsAmount}
              addingOblationalMaterials={addingOblationalMaterials}

              isAddingOblationalMaterialDialogOpened={isAddingOblationalMaterialDialogOpened}
              OpenOblationalMaterialAddingDialog={this.props.OpenOblationalMaterialAddingDialog}
              CloseOblationalMaterialAddingDialog={this.props.CloseOblationalMaterialAddingDialog}
              selectedOblationalMaterial={selectedOblationalMaterial}
              SetSelectedOblationalMaterial={this.props.SetSelectedOblationalMaterial}
              SaveOblationalMaterial={this.props.SaveOblationalMaterial}

              needsDeleting={true}

              DeleteMaterial={this.props.DeleteMaterial}
            />
	        }
          {superCategory.title === 'Смолы' && 
            <ResinMaterialsInfo
              resinMaterials={filteredMaterialsAmount}

              newResinAmount={newResinAmount}

              OpenResinAddingDialog={this.props.OpenResinAddingDialog}
              CloseResinAddingDialog={this.props.CloseResinAddingDialog}
              isAddingResinDialogOpened={isAddingResinDialogOpened}
              resinsList={resinsList}
              SetSelectedResin={this.props.SetSelectedResin}
              selectedResin={selectedResin}
              SaveResin={this.props.SaveResin}

              needsDeleting={true}

              DeleteMaterial={this.props.DeleteMaterial}
            />}
        </ExpansionPanelDetails>
      </ExpansionPanel>
        }
      </Fragment>
    );
  }
}

export default Materials;