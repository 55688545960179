import React, { Component } from 'react';

import CustomDescription from './CustomDescription';
import ProjectAttachments from '../ProjectAttachments';

import {
  CardContent
} from '@material-ui/core';

class Custom extends Component {
  render(){
    const {
      stage
    } = this.props;
    return(
      <CardContent>
        <CustomDescription
          textDescription={stage.textTemplate}
        />
        <ProjectAttachments
          attachmentsList={stage.attachmentsList}
        />
      </CardContent>
    );
  }
}

export default Custom;