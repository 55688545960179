import _ from 'lodash';

import {
  AuthConstants,
} from '../Constants';

import { push } from 'react-router-redux';

import api from '../../Utils/API';

const ChangeUsername = (username) => {
  return {
    type: AuthConstants.CHANGE_USERNAME,
    data: username,
  };
};

const ChangePassword = (password) => {
  return {
    type: AuthConstants.CHANGE_PASSWORD,
    data: password,
  };
};

const AuthLogin = () => {
  return (dispatch, getState) => {
    const authInfo = _.pick(getState().Auth, ['username', 'password']);
    const form = new FormData();
    _.each(authInfo, (v, k) => {
      form.append(_.toString(k), v);
    });
    return api.auth(form)
      .then((auth) => {
        api.addToken(auth.token);
        localStorage.setItem('token', auth.token)
      })
      .then(() => {
        dispatch(push('/project'))
      })
      .catch((err) => {
        dispatch({
          type: AuthConstants.SET_AUTH_ERROR,
        })
      })
  }
}

export default {
  ChangeUsername,
  ChangePassword,
  AuthLogin,
};
