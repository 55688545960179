const TechDocCutConstants = {
  ADD_ARMOR_SURFACE: 'ADD_ARMOR_SURFACE',
  SET_ACTIVE_ARMOR_SURFACE: 'SET_ACTIVE_ARMOR_SURFACE',
  DELETE_ARMOR_SURFACE: 'DELETE_ARMOR_SURFACE',
  SET_SURFACE_HEIGHT: 'SET_SURFACE_HEIGHT',
  SET_SURFACE_WIDTH: 'SET_SURFACE_WIDTH',

	SET_SURFACES: 'SET_SURFACES',

	CHANGE_EDITOR_SELECTED_MATERIAL: 'CHANGE_EDITOR_SELECTED_MATERIAL',

	SET_OBLATIONAL_LAYERS: 'SET_OBLATIONAL_LAYERS',

	SET_ONCG: 'SET_ONCG',
};

export default TechDocCutConstants;