import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../Styles/AppColors';

const styles = {
  root: {
    color: '#727272',
    '&$checked': {
      color: COLORS.BLUE,
    },
  },
  checked: {},
};

class BlueCheck extends Component {
  render() {
    const {
      checked,
      label,
      classes,
    } = this.props;
    return(
      <FormControlLabel
        checked={checked}
        control={
          <Checkbox
            onChange={this.props.onChange}
            classes={{
              root: classes.root,
              checked: classes.checked,
            }}
          />
        }
        label={label}
      />
    )
  }
}

BlueCheck.propTypes = {
  classes: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(BlueCheck)
