import _ from 'lodash';

import api from '../../Utils/API'
import {
  NewTechDocConstants,
  InterfaceConstants,
} from '../Constants';

import { push } from 'react-router-redux'

const ChangeNewTechDocTitle = (newTitle) => {
  return {
    type: NewTechDocConstants.CHANGE_NEW_TECH_DOC_TITLE,
    data: newTitle,
  };
};

const ChangeNewTechDocType = (newType) => {
  return {
    type: NewTechDocConstants.CHANGE_NEW_TECH_DOC_TYPE,
    data: newType,
  };
};

const ChangeNewTechDocTechnology = (newTechnology) => {
  return {
    type: NewTechDocConstants.CHANGE_NEW_TECH_DOC_TECHNOLOGY,
    data: newTechnology,
  };
};

const ChangeNewTechDocModelDeviceLink = (newModelLink) => {
  return {
    type: NewTechDocConstants.CHANGE_MODEL_LINK,
    data: newModelLink,
  };
};

const SetNewTechDocDrawingRequirement = (isRequired) => {
  return {
    type: NewTechDocConstants.SET_DRAWING_REQUIREMENT,
    data: isRequired,
  }
};

const SetNewTechDocAssemblingRequirement = (isRequired) => {
  return {
    type: NewTechDocConstants.SET_ASSEMBLING_REQUIREMENT,
    data: isRequired,
  }
};

const GetNewTechDocCreatingData = () => {
  return (dispatch) => {
    Promise.all([
      api.get('technologies'),
      api.get('productionStepTemplates', { isRequired: false, assembly: false }),
      api.get('techDocTypes')
    ])
      .then(([technologies, notRequiredProductionSteps, techDocTypes]) => {
        return [technologies.results, notRequiredProductionSteps.results, techDocTypes.results]
      })
      .then(([technologies, notRequiredProductionSteps, techDocTypes]) =>{
        dispatch({
          type: NewTechDocConstants.GET_NEW_TECH_DOC_CREATING_DATA,
          data: {
            technologies,
            notRequiredProductionSteps,
            techDocTypes,
          }
        })
      })
  }
};

const ChangeChosenProductionSteps = (stepId, checked) => {
  return {
    type: NewTechDocConstants.CHANGE_CHOSEN_PRODUCTION_STEPS,
    data: {
      stepId: stepId,
      checked: checked,
    }
  }
}

const SendCreateTechDoc = (productId) => {
	return (dispatch, getState) => {
		const form = new FormData()
		form.append('model', getState().NewTechDoc.model);
		_.each(getState().NewTechDoc.newTechDoc, (v, k) => {
			if (k === 'productionChain') {
				form.append(k, JSON.stringify(v))
			} else {
        if (!(k === 'technology' && v === '')) {
          form.append(k, v)
        }
      } 
    });
    return Promise.resolve()
      .then(() => {
        dispatch({
          type: NewTechDocConstants.SET_TECHDOC_UPLOADING,
        })
      })
      .then(() => {
        return api.post(`products/${productId}/techDocs`, form)
      })
			.then((techdoc) => {
        dispatch(push(`/product/${productId}/${techdoc._id}`));
        dispatch({
          type: NewTechDocConstants.SET_TECHDOC_UPLOADED,
        })
				dispatch({
          type: InterfaceConstants.CLOSE_CREATING_TECHDOC_DIALOG,
        })
			})
	}
};

const ResetNewTechDocCreating = () => {
	return {
		type: NewTechDocConstants.RESET_NEW_TECH_DOC_CREATING,
	}
}

export default {
  ChangeNewTechDocTitle,
  ChangeNewTechDocType,
  ChangeNewTechDocTechnology,
  ChangeNewTechDocModelDeviceLink,
  SetNewTechDocDrawingRequirement,
  SetNewTechDocAssemblingRequirement,

  GetNewTechDocCreatingData,
  ChangeChosenProductionSteps,
  SendCreateTechDoc,

	ResetNewTechDocCreating,
};
