import React, {PureComponent} from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

import AddingMaterialsContent from './AddingMaterialsContent';

class AddingMaterialDialog extends PureComponent {
  render() {
    const {
	    selectedMaterialId,
	    layersCount,
	    selectedAngle,

      armorMaterials,

      angles,
    } = this.props;
    const {
      isAddingMaterialDialogOpened
    } = this.props;
    return(
      <Dialog
        open={isAddingMaterialDialogOpened}
      >
        <DialogTitle>
          ИНФОРМАЦИЯ О МАТЕРИАЛЕ ПОВЕРХНОСТИ
        </DialogTitle>
        <DialogContent>
          <AddingMaterialsContent
	          selectedMaterialId={selectedMaterialId}
	          layersCount={layersCount}
	          selectedAngle={selectedAngle}

            IncrementCount={this.props.IncrementCount}
            DecrementCount={this.props.DecrementCount}

            armorMaterials={armorMaterials}

	          ChangeSelectedMaterial={this.props.ChangeSelectedMaterial}

	          ChangeSelectedAngle={this.props.ChangeSelectedAngle}
            angles={angles}
          />
        </DialogContent>
        <DialogActions>
        <Button 
              onClick={this.props.CloseAddingSurfaceMaterialDialog} 
              color="primary"
            >
              ОТМЕНИТЬ
            </Button>
            <Button 
              color="primary"
              onClick={this.props.AddSurfaceLayers}
            >
              СОХРАНИТЬ
            </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AddingMaterialDialog;
