import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';

import EditorContent from './EditorContent';

const styles = {
  editorContainer: {
    position: 'fixed',
  },
  dialogContent: {
    height: '100%',
    padding: 0,
	  borderBottom: '1px solid grey',
  }
};

class Editor extends PureComponent {
  componentWillUnmount() {
    this.props.ChangeEditorSelectedMaterial('');
  }

  sortSurfaces = (armorSurfaces, oblationalCuttingGroups) => {
    const allLayers = _.reduce(armorSurfaces, (generatedLayers, armorSurface, armorSurfaceId) => {
      return _.concat(generatedLayers, _.map(armorSurface.layers, layer => {
        return _.assign(layer, {
          color: armorSurface.color,
          width: armorSurface.width,
          height: armorSurface.height,
          surfaceTitle: 'Поверхность ' + (armorSurfaceId + 1),
        });
      }));
    }, []);
    const filteredCuttingGroups = _.filter(oblationalCuttingGroups, cutGroup => {
      return !_.isUndefined(cutGroup.material);
    });
    if (_.isEmpty(filteredCuttingGroups) || _.isEmpty(allLayers)) {
      return [];
    }
    const groupedOblationalLayers = _.reduce(filteredCuttingGroups, (groupedOL, ocg) => {
      const ocgLayers = _.reduce(ocg.oblationalSurfaces, (generatedLayers, surface) => {
        return _.concat(generatedLayers, _.map(surface.layers, layer => {
          return _.assign(layer, {
            width: !ocg.isBag ? surface.width : surface.width * 2,
            height: surface.height,
            color: '#000',
            isOblational: true,
            surfaceTitle: ocg.groupTag.title
          })
        }));
      }, []);
      return _.assign(groupedOL, { [ocg.material._id]: ocgLayers })
    }, {});
    return _.assign(_.groupBy(allLayers, layer => {
      return layer.material._id
    }), groupedOblationalLayers);
  };

  render() {
    const {
      armorSurfacesList,
      armorMaterials,
	    oblationalCutMaterials,
	    isCutEditorOpened,
	    editorSelectedMaterial,
      editorSelectedLayer,
      materialsAmount,
	    oblationalCuttingGroups,
    } = this.props;
    const sortSurfaces = this.sortSurfaces(armorSurfacesList, oblationalCuttingGroups);
	  const isEditorAvailable = !_.isEmpty(sortSurfaces);
    return (
      <Dialog
        fullScreen
        open={isCutEditorOpened}
        PaperProps={{
          style: styles.editorContainer
        }}
      >
        <DialogContent
          style={styles.dialogContent}
        >
	        {!isEditorAvailable ?
		        <Typography
			        variant='body1'
		        >
			        Добавьте материалы для продолжения
		        </Typography>
            :
            <EditorContent
              sortSurfaces={sortSurfaces}
              armorMaterials={armorMaterials}
              oblationalCutMaterials={oblationalCutMaterials}
              RotateLayer={this.props.RotateLayer}

              ChangeEditorSelectedMaterial={this.props.ChangeEditorSelectedMaterial}
              editorSelectedMaterial={editorSelectedMaterial}
              ChangeEditorSelectedLayer={this.props.ChangeEditorSelectedLayer}
              editorSelectedLayer={editorSelectedLayer}

              ChangeLayerPosition={this.props.ChangeLayerPosition}

              // SelectArmorMaterial={this.props.SelectArmorMaterial}
              CalculateCuttingMaterials={this.props.CalculateCuttingMaterials}
              materialsAmount={materialsAmount}

              CloseCutEditor={this.props.CloseCutEditor}
            />
	        }
        </DialogContent>
        {!isEditorAvailable && <DialogActions>
          <Button
            onClick={this.props.CloseCutEditor}
          >
            Выйти из редактора
          </Button>
        </DialogActions>}
      </Dialog>
    )
  }
};

export default Editor;