import React, { Component } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Button,
  FormControl,
  FormHelperText,
  Slide
} from '@material-ui/core';

import _ from 'lodash';

import {
  Add,
  Remove
} from '@material-ui/icons';


const styles = {
  dialogCOntent:{
    display:'flex', 
    flexDirection:'column',
    overflowY: 'auto',
    height: '100%'
  },
  flexbox:{
    flex: 1
  }
}

export const UserRoleTranslate = ( userRole ) => {
  switch (userRole) {
    case 'admin':
      return 'Администратор';

    case 'admin_de':
      return 'Администратор ТЯ';

    case 'tech':
      return 'Технолог';

    case 'head':
      return 'Руководитель производства';
      
    case 'worker':
      return 'Кладовщик';

    case 'worker_moulder': 
      return 'Формовщик';

    case 'worker_sawmaker':
      return 'Опиловщик';

    case 'worker_painter':
      return 'Маляр';

    case 'worker_assembler':
      return 'Сборщик';

    default:
      return;
  }
}

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
}

class AddUserDialog extends Component{
  constructor(props){
    super(props);
    this.state = {
      first_name: '',
      second_name: '',
      password: '',
      rate: 0,
      roles: [],
      username: '',
      nfc: ''
    }

    this.changeFirstName = 
      _.debounce(() => this.props.ChangeNewUserData('first_name', this.state.first_name), 500);
    this.changeSecondName = 
      _.debounce(() => this.props.ChangeNewUserData('second_name', this.state.second_name), 500);
    this.changePassword =
      _.debounce(() => this.props.ChangeNewUserData('password', this.state.password), 500);
    this.changeRate =
      _.debounce(() => this.props.ChangeNewUserData('rate', _.toNumber(this.state.rate)), 500);
    this.changeNfc =
      _.debounce(() => this.props.ChangeNewUserData('nfc', this.state.nfc), 500);
    this.changeUsername =
      _.debounce(() => this.props.ChangeNewUserData('username', this.state.username), 500);   
  }

  componentDidMount(){
    this.props.GetAllRoles();

    this.props.selectedUserId !== '' ?
      this.setState({
        first_name: this.props.newUser.first_name,
        second_name: this.props.newUser.second_name,
        roles: this.props.newUser.roles,
        rate: _.isUndefined(this.props.newUser.rate) ? 0 : this.props.newUser.rate,
        username: this.props.newUser.username,
        password: _.isUndefined(this.props.newUser.password) ? '' : this.props.newUser.password,
        nfc: _.isUndefined(this.props.newUser.nfc) ? '' : this.props.newUser.nfc
      })
      :
      this.setState({
        first_name: '',
        second_name: '',
        roles: [],
        rate: 0,
        username: '',
        password: '',
        nfc: ''
      })
  }

  render(){
    const {
      isAddUserDialogOpened,
      selectedUserId,
      usersRoles
    } = this.props;
    const {
      first_name,
      second_name,
      roles,
      password,
      rate,
      nfc,
      username
    } = this.state;    
    return(
      <Dialog
        open={isAddUserDialogOpened}
        PaperProps={{style: {width: 400, height: 500}}}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          ИНФОРМАЦИЯ О РАБОТНИКЕ
        </DialogTitle>
        <DialogContent>
          <div
            className='usersDialogInputRow'
          >
            <Typography
              style={styles.flexbox}
            > 
              Имя
            </Typography>
            <TextField
              style={styles.flexbox}
              value={first_name}
              onChange={(event) => this.setState({
                first_name: event.target.value
              }, () => this.changeFirstName() )}
              fullWidth
            />
          </div>
          <div
            className='usersDialogInputRow'
          >
            <Typography
              style={styles.flexbox}
            > 
              Фамилия
            </Typography>
            <TextField
              style={styles.flexbox}
              value={second_name}
              onChange={(event) => this.setState({
                second_name: event.target.value
              }, () => this.changeSecondName())}
              fullWidth
            />
          </div>
          <div
            className='usersDialogInputRow'
          >
            <Typography
              style={styles.flexbox}
            > 
              Логин
            </Typography>
            <TextField
              style={styles.flexbox}
              value={username}
              onChange={(event) => this.setState({
                username: event.target.value
              }, () => this.changeUsername())}
              fullWidth
            />
          </div>
          <div
            className='usersDialogInputRow'
          >
            <Typography
              style={styles.flexbox}
            > 
              Пароль
            </Typography>
            <TextField
              style={styles.flexbox}
              value={password}
              onChange={(event) => this.setState({
                password: event.target.value
              }, () => this.changePassword())}
              fullWidth
            />
          </div>
          <div
            className='usersDialogInputRow'
          >
            <Typography
              style={styles.flexbox}
            > 
              NFC метка
            </Typography>
            <TextField
              style={styles.flexbox}
              value={nfc}
              onChange={(event) => this.setState({
                nfc: event.target.value
              }, () => this.changeNfc())}
              fullWidth
            />
          </div>
          <div
            className='usersDialogInputRow'
          >
            <Typography
              style={styles.flexbox}
            > 
              Ставка
            </Typography>
            <TextField
              style={styles.flexbox}
              value={rate}
              type='number'
              onChange={(event) => this.setState({
                rate: event.target.value < 0 ? 0 : event.target.value 
              }, () => this.changeRate())}
              fullWidth
            />
          </div>
          {_.map(roles, (role, roleId) => {
            return(
            <div
              key={roleId}
              className='usersRoleRow'
            >
              <IconButton
                onClick={() => {
                  let array = [...roles];
                  array.splice(roleId, 1);
                  this.setState({
                    roles: array
                  })
                }}
              >
                <Remove />
              </IconButton>
              <FormControl
                fullWidth
              >
                <Select
                  value={roles[roleId].type}
                  onChange={(event) => {
                    let array = [...roles]
                    array[roleId].type = event.target.value; 
                    this.setState({
                      roles: array
                    })
                  }}
                >
                  {_.map(usersRoles, (userRole, id) =>{
                    return(
                      <MenuItem
                        value={userRole}
                        key={id}
                      >
                        {UserRoleTranslate(userRole)}
                      </MenuItem>
                    )})
                  }
                </Select>
                <FormHelperText>
                  Выберите роль
                </FormHelperText>
              </FormControl>
            </div>
            )
          })}
          <div
            className='usersAdding'
          >
            <Typography> 
              Добавить роль
            </Typography>
            <IconButton
              onClick={()=> this.setState({
                roles: [...roles, {type: ''}]
              })}
            >
              <Add />
            </IconButton>
          </div>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              this.props.CloseAddingUserDialog();
            }}
            color="primary"
          >
            ОТМЕНИТЬ
          </Button>
          <Button 
            disabled={_.some([first_name, second_name, username, nfc], (item) => item === '')}
            color="primary"
            onClick={()=>{
              this.props.ChangeNewUserData('roles', roles);
              selectedUserId === '' ? this.props.AddUser() : this.props.EditUser(selectedUserId);
            }}
          >
            СОХРАНИТЬ
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AddUserDialog;
