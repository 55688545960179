import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
	Chip
} from '@material-ui/core';

class TaskState extends PureComponent {
	getChipStyle = (state) => {
		switch(_.toLower(state)){
			case 'todo': 
				return {
					color: '#9E9E9E',
					text: 'Сделать'
				}

			case 'blocked': 
				return {
					color: '#888',
					text: 'Заблокировано',
					opacity: 0.5,
				}

			case 'failed':
				return {
					color: '#f44366',
					text: 'Брак',
				}

			case 'doing': 
				return {
					color: '#2196F3',
					text: 'В работе'
				}

			case 'inreview': 
				return {
					color: '#FF9800',
					text: 'В проверке'
				}

			case 'done': 
				return {
					color: '#009688',
					text: 'Сделано'
				}

			case 'waiting': 
				return {
					color: '#3F51B5',
					text: 'Ожидание'
				}

			default:
				return null
		}
	
	}
	
	render() {
		const chipStyle = this.getChipStyle(this.props.state)
		return (
			<Chip
				style={{backgroundColor: chipStyle.color, width: 128, color: 'white', cursor: 'pointer', opacity: chipStyle.opacity ? chipStyle.opacity : 1}}
				label={chipStyle.text}
			/>
		)
	}
}

export default TaskState;