import React, { Component } from 'react';

import { ProductCard } from '../_NewDesignComponents/Product'

import {
  Grid,
} from '@material-ui/core';

import _ from 'lodash';

class ProductsList extends Component {
  render() {
    const {
      productsList
    } = this.props;
    return (
      <Grid
        container
        style={{ width: '100%' }}
      >
        {_.map(productsList, (product) => {
          return (
            <Grid
              item
              sm={4}
              lg={3}
              key={product._id}
            >
              <ProductCard
                selectProductForRemove={this.props.selectProductForRemove}
                product={product}
                SelectProduct={this.props.SelectProduct}
              />
            </Grid>
          )
        })
        }
      </Grid>
    );
  }
}

export default ProductsList;
