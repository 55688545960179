import React, { Component, Fragment } from 'react';

import OblationalNotCutMaterialInfo from './OblationalNotCutMaterialInfo';

// const styles = {
//   oblationalNotCutTitle: {
//     marginLeft: 12,
// 		marginTop: 24,
// 		marginBottom: 24,
//   }
// };

class OblationalNotCutMaterials extends Component {
  render(){
    const {
	    oblationalNotCuttingGroups,
	    oblationalNotCutMaterials,
    } = this.props;
    return(
      <Fragment>
	      <OblationalNotCutMaterialInfo
		      oblationalNotCuttingGroups={oblationalNotCuttingGroups}
		      oblationalNotCutMaterials={oblationalNotCutMaterials}

		      AddONCG={this.props.AddONCG}
		      DeleteONCG={this.props.DeleteONCG}
		      SaveONCGWithDemand={this.props.SaveONCGWithDemand}
	      />
      </Fragment>
    )
  }
}

export default OblationalNotCutMaterials;