import _ from 'lodash';

import {
  MeConstants,
} from '../Constants';

import {
  push,
} from 'react-router-redux';

import api from '../../Utils/API';

const GetMe = () => {
  return (dispatch) => {
    return api.get(`me`)
      .then((me) => {
        dispatch({
          type: MeConstants.SET_ME,
          data: me,
        })
      })
      .catch(() => {
        localStorage.removeItem('token')
        dispatch(PushPage('/'))
      })
  }
};

const PushPage = (path) => {
  return dispatch => {
    dispatch(push(path))
  };
};

const PushToPDF = () => {
  return (dispatch, getState) => {
    const currentTechDoc = getState().TechDocs.SelectedTechDoc._id;
    dispatch(PushPage(`/techdocdescription/${currentTechDoc}`))
  }
}

const SaveTechDoc = () => {
  return (dispatch, getState) => {
    const selectedProduct = getState().Products.currentProduct;
    dispatch(PushPage(`/product/${selectedProduct}`))
  }
};

const AuthPageHandle = () => {
  return (dispatch) => {
    if (!_.isEmpty(localStorage.getItem('token'))) {
      dispatch(PushPage('/project'))
    };
  }
};

const AppHandle = () => {
  return (dispatch) => {
    if (_.isEmpty(localStorage.getItem('token'))) {
      dispatch(PushPage('/'))
    }
  }
};

const SetAccess = (role) => {
  return (dispatch, getState) => {
    return Promise.resolve()
      .then(() => {
        if (_.isEmpty(getState().Me.me)) {
          return dispatch(GetMe())
        }
      })
      .then(() => {
        const roles = !_.isUndefined(getState().Me.me.roles) ? getState().Me.me.roles : [];
        if (_.some(roles, { type: role })) {
          dispatch({
            type: MeConstants.SET_ACCESS,
          })
        } else {
          dispatch({
            type: MeConstants.SET_NO_ACCESS,
          })
        }
      })
  }
};

const AppLogout = () => {
  return (dispatch, getState) => {
    return Promise.resolve()
      .then(() => {
        localStorage.removeItem('token')
      })
      .then(() => {
        dispatch({
          type: MeConstants.CLEAR_ME
        })
      })
      .then(() => {
        dispatch(PushPage('/'))
      })
  }
}

export default {
  PushPage,
  AuthPageHandle,
  AppHandle,
  SetAccess,
  AppLogout,
  SaveTechDoc,
  PushToPDF
};
