export default {
  AddButtonStyle: {
    right: 24,
    bottom: 24,
    position: 'fixed',
    zIndex: 10
  },
  TechDocsListContainer: {
    padding: 12,
    marginBottom: 56,
  },
  TechDocCellContainer: {
    margin: 12,
    width: 230,
    height: 155,
    cursor: 'pointer',
  },
  TechDocsGridListStyle: {
    listStyleType: 'none',
  },
  ScreenContainer: {
    width: '100%',
    height: '100%',
  },
  StepperContainer: {
    width: '100%',
    zIndex: '100',
  },
  StepContainer: {
    padding: '10px',
    paddingTop: '24px',
    width: '100%'
  }
};
