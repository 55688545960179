import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../Styles/AppColors';
import Table from '@material-ui/core/Table';

const styles = () => ({
  root:{
    // border: `1px solid ${COLORS.EXPANSION_BORDER}`,
    borderRadius: '4px',
    marginBottom: 24,
    display: 'table',
    borderCollapse: 'collapse',
    borderStyle: 'hidden',
    boxShadow: `0 0 0 1px ${COLORS.EXPANSION_BORDER}`
    
  }
});

class OutlinedTable extends Component {
  render(){
    const {
      classes
    } = this.props;
    return(
      <Table
        classes={{
          root: classes.root
        }}
        {...this.props}
      >
        {this.props.children}
      </Table>
    )
  }
}

OutlinedTable.proptypes = {
  classes: PropTypes.object.isRequired,

}

export default withStyles(styles)(OutlinedTable);