import React, { Component } from 'react';

import styles from '../../Styles/AppThemStyles';

import {
  Add,
} from '@material-ui/icons';

import _ from 'lodash';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Typography,
  IconButton,
  Card,
  CircularProgress,
} from '@material-ui/core';

import {
  Remove,
} from '@material-ui/icons'

import {
  connect,
} from 'react-redux';

import {
  TechDocsActions,
  InterfaceActions,
  NewTechDocActions,
  ProductsActions,
} from '../../Redux/Actions/';

import DocsList from '../SmartComponents/DocsList';

import {
  NewTechDocDescription,
} from '../DumbComponents/index';

import { RemoveStructureDialog } from '../DumbComponents/_NewDesignComponents';

import api from '../../Utils/API';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
}

const componentStyles = {
  filesListCard: {
    display: 'flex',
    width: '80%',
    padding: 12,
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 24,
    backgroundColor: '#EBEBEB'
  }
}

class TechDocsListScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadButtonWidth: 0,
      uploadButtonHeight: 0,
      selectedTechDoc: null,
      isRemoveTechDocDialogOpened: false
    };
  };

  selectTechDocForRemove = (techDocId) => {
    this.setState({ selectedTechDoc: techDocId, isRemoveTechDocDialogOpened: true })
  }

  closeTechDocDialog = () => {
    this.setState({ isRemoveTechDocDialogOpened: false });
  }

  removeTechDocByIdDialog = (techDocId) => {
    this.props.RemoveTechDocById(techDocId)
    this.setState({ isRemoveTechDocDialogOpened: false })
  }

  componentDidMount() {
    this.props.GetTechDocsList(this.props.params.productId);
    this.props.FetchFiles(this.props.params.productId);
  };

  setButtonSize = () => {
    this.setState({
      uploadButtonWidth: this.uploadTechDocButton.clientWidth,
      uploadButtonHeight: this.uploadTechDocButton.clientHeight
    })
  }

  render() {
    const {
      isCreatingTechDocDialogOpened,

      newTechDocTitle,

      newTechDocType,
      techDocTypes,

      newTechDocTechnology,
      techDocTechnologies,

      newTechDocModelDeviceLink,

      notRequiredProductionSteps,
      chosenProductionSteps,
      testFiles,
      isTechDocUploading
    } = this.props;
    return (
      <div
        className='techDocsListContainer'
      >
        <div
          className='techDocsColumn'
        >
          <Typography
            variant='headline'
          >
            <b>ТЕХКАРТЫ</b>
          </Typography>
          <DocsList
            selectTechDocForRemove={this.selectTechDocForRemove}
            type='default'
            TechDocsList={this.props.TechDocsList}
            selectTechDoc={this.props.SelectTechDoc.bind(this)}
            productId={this.props.params.productId}
          />
        </div>
        <div
          className='techDocsColumn'
        >
          <Typography
            variant='headline'
          >
            <b>СБОРОЧНЫЕ ТЕХКАРТЫ</b>
          </Typography>
          <DocsList
            type='assembly'
            selectTechDocForRemove={this.selectTechDocForRemove}
            TechDocsList={this.props.TechDocsList}
            selectTechDoc={this.props.SelectTechDoc.bind(this)}
            productId={this.props.params.productId}
          />
        </div>
        <div
          className='filesColumn'
        >
          <Typography
            variant='headline'
          >
            <b>ФАЙЛЫ</b>
          </Typography>
          <Card
            style={componentStyles.filesListCard}
          >
            {testFiles.length === 0 &&
              <Typography
                style={{ alignSelf: 'center' }}
                variant='body1'
              >
                Выберите файлы для загрузки на сервер
              </Typography>
            }
            {testFiles.length !== 0 && _.map(testFiles, testFile => {
              return (
                <div
                  key={testFile.name}
                  className='fileDescriptionRow'
                >
                  <a href={api.getHost() + testFile.url} download>
                    <Typography
                      variant='body1'
                    >
                      {testFile.name}
                    </Typography>
                  </a>
                  <IconButton
                    onClick={() => this.props.RemoveFile(testFile.name, this.props.params.productId)}
                  >
                    <Remove />
                  </IconButton>
                </div>
              )
            })}
          </Card>
          <div
            className='uploadToServerButton'
          >
            <input
              type='file'
              style={{ display: 'none' }}
              onChange={event => { this.props.UploadToServer(event.target.files[0], this.props.params.productId); }}
              id='select-file'
              multiple
            />
            <label
              htmlFor='select-file'
              className='buttonContainer'
            >
              <Button
                variant="outlined"
                component="span"
              >
                Выбрать файл
              </Button>
            </label>
          </div>
        </div>

        <RemoveStructureDialog
          title='Удалить технологическую карту?'
          content='Это действие удалит техкарту и все связанное с ней без возможности отмены.'
          isRemoveStructureDialogOpened={this.state.isRemoveTechDocDialogOpened}
          structureId={this.state.selectedTechDoc}
          closeStructureProductDialog={this.closeTechDocDialog}
          RemoveStructureById={this.removeTechDocByIdDialog}
        />
        <Button
          variant='fab'
          color='primary'
          aria-label='add'
          onClick={this.props.OpenCreatingTechDocDialog}
          style={styles.AddButtonStyle}
        >
          <Add />
        </Button>

        <Dialog
          open={isCreatingTechDocDialogOpened}
          TransitionComponent={Transition}
          onEnter={this.setButtonSize}
        >
          <DialogTitle>
            ИНФОРМАЦИЯ О НОВОЙ ТЕХНОЛОГИЧЕСКОЙ КАРТЕ
          </DialogTitle>
          <DialogContent>
            <NewTechDocDescription
              newTechDocTitle={newTechDocTitle}
              ChangeNewTechDocTitle={this.props.ChangeNewTechDocTitle.bind(this)}

              newTechDocType={newTechDocType}
              ChangeNewTechDocType={this.props.ChangeNewTechDocType.bind(this)}
              techDocTypes={techDocTypes}

              newTechDocTechnology={newTechDocTechnology}
              ChangeNewTechDocTechnology={this.props.ChangeNewTechDocTechnology.bind(this)}
              techDocTechnologies={techDocTechnologies}

              newTechDocModelDeviceLink={newTechDocModelDeviceLink}
              ChangeNewTechDocModelDeviceLink={this.props.ChangeNewTechDocModelDeviceLink.bind(this)}
              notRequiredProductionSteps={notRequiredProductionSteps}
              chosenProductionSteps={chosenProductionSteps}
              ChangeChosenProductionSteps={this.props.ChangeChosenProductionSteps.bind(this)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.CloseCreatingTechDocDialog}
              color="primary"
            >
              ОТМЕНИТЬ
            </Button>
            {!isTechDocUploading ? <div
              ref={(uploadTechDocButton) => { this.uploadTechDocButton = uploadTechDocButton }}
            >
              <Button
                disabled={newTechDocType !== 'assembly' ?
                  newTechDocTitle === '' ||
                  newTechDocType === '' ||
                  newTechDocTechnology === '' ||
                  newTechDocModelDeviceLink === null
                  :
                  newTechDocTitle === '' ||
                  newTechDocType === '' ||
                  newTechDocModelDeviceLink === null
                }
                color="primary"
                onClick={() => { this.props.SendCreateTechDoc(this.props.params.productId) }}
              >
                СОХРАНИТЬ
            </Button>
            </div>
              :
              <div
                style={{
                  width: this.state.uploadButtonWidth,
                  height: this.state.uploadButtonHeight,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress
                  size={this.state.uploadButtonHeight - 8}
                />
              </div>}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default connect(
  state => ({
    TechDocsList: state.Products.TechDocsList,
    isCreatingTechDocDialogOpened: state.Interface.isCreatingTechDocDialogOpened,
    newTechDocTitle: state.NewTechDoc.newTechDoc.title,
    newTechDocType: state.NewTechDoc.newTechDoc.type,
    techDocTypes: state.NewTechDoc.techDocTypes,
    newTechDocTechnology: state.NewTechDoc.newTechDoc.technology,
    newTechDocModelDeviceLink: state.NewTechDoc.model,
    techDocTechnologies: state.NewTechDoc.techDocTechnologies,
    notRequiredProductionSteps: state.NewTechDoc.notRequiredProductionSteps,
    chosenProductionSteps: state.NewTechDoc.newTechDoc.productionChain,
    testFiles: state.Products.testFiles,
    isTechDocUploading: state.NewTechDoc.isTechDocUploading,
  }),
  dispatch => ({
    UploadToServer: (file, productId) => {
      dispatch(ProductsActions.UploadToServer(file, productId));
    },
    FetchFiles: (productId) => {
      dispatch(ProductsActions.FetchFiles(productId))
    },
    RemoveFile: (name, productId) => {
      dispatch(ProductsActions.RemoveFile(name, productId))
    },
    ChangeNewTechDocTitle: (newTitle) => {
      dispatch(NewTechDocActions.ChangeNewTechDocTitle(newTitle));
    },
    ChangeNewTechDocType: (newType) => {
      dispatch(NewTechDocActions.ChangeNewTechDocType(newType));
    },
    ChangeNewTechDocTechnology: (newTechnology) => {
      dispatch(NewTechDocActions.ChangeNewTechDocTechnology(newTechnology));
    },
    ChangeNewTechDocModelDeviceLink: (newModelLink) => {
      dispatch(NewTechDocActions.ChangeNewTechDocModelDeviceLink(newModelLink));
    },
    GetTechDocsList: (productId) => {
      dispatch(ProductsActions.GetTechDocsList(productId));
    },
    OpenCreatingTechDocDialog: () => {
      dispatch(NewTechDocActions.ResetNewTechDocCreating());
      dispatch(NewTechDocActions.GetNewTechDocCreatingData());
      dispatch(InterfaceActions.OpenCreatingTechDocDialog());
    },
    CloseCreatingTechDocDialog: () => {
      dispatch(InterfaceActions.CloseCreatingTechDocDialog());
    },
    CreateTechDoc: () => {
      // dispatch(techDocsActions.CreateTechDoc());
    },
    SelectTechDoc: (productId, techDocId) => {
      dispatch(TechDocsActions.SelectTechDoc(productId, techDocId));
    },
    ChangeChosenProductionSteps: (stepId, checked) => {
      dispatch(NewTechDocActions.ChangeChosenProductionSteps(stepId, checked))
    },
    SendCreateTechDoc: (productId) => {
      dispatch(NewTechDocActions.SendCreateTechDoc(productId));
    },
    RemoveTechDocById: (techDocId) => {
      dispatch(TechDocsActions.RemoveTechDocById(techDocId));
    }
  }),
)(TechDocsListScreen);
