import React, { Component } from 'react';

import {
	connect,
} from 'react-redux';

import _ from 'lodash';

import {
	MyStoreActions,
} from '../../Redux/Actions/';

import {
	MyStoreChangingMaterialsDialog,
} from '../DumbComponents'
import { MyStoreSortedTables } from "../SmartComponents";

const styles = {
	myStoreContainer: {
		display: 'flex',
		height: '100%',
		width: '100%',
		overflowY: 'scroll'
	}
};

class MyStoreMaterialsAdding extends Component {
	componentDidMount() {
		this.props.GetAllMyStoreMaterials();
	}

	render() {
		const {
			materials,
			isChangingMyStoreMaterialDialogOpened,
			selectedMaterialId,

			materialsCategories,
			materialsSuperCategories,
			technologies,

			selectedSuperCategory,
			selectedCategory,
			data,
			chosenTechnologies,
			complementaryRules,

			isConfirmed,
			groupTag,

			oblationalGroupTags,

			myStoreCode,
		} = this.props;
		return (
			<div
				style={styles.myStoreContainer}
			>
				<MyStoreSortedTables
					materials={materials}
					OpenChangingMyStoreMaterialDialog={this.props.OpenChangingMyStoreMaterialDialog.bind(this)}

					DeleteMaterialById={this.props.DeleteMaterialById}
				/>
				{/*<MyStoreMaterialsTable*/}
				{/*materials={materials}*/}
				{/*OpenChangingMyStoreMaterialDialog={this.props.OpenChangingMyStoreMaterialDialog.bind(this)}*/}
				{/*/>*/}
				{selectedMaterialId !== '' && <MyStoreChangingMaterialsDialog
					isChangingMyStoreMaterialDialogOpened={isChangingMyStoreMaterialDialogOpened}
					CloseChangingMyStoreMaterialDialog={this.props.CloseChangingMyStoreMaterialDialog.bind(this)}
					selectedMaterial={_.find(materials, { _id: selectedMaterialId })}

					materialsCategories={materialsCategories}
					materialsSuperCategories={materialsSuperCategories}
					technologies={technologies}

					selectedCategory={selectedCategory}
					selectedSuperCategory={selectedSuperCategory}
					isConfirmed={isConfirmed}
					data={data}
					chosenTechnologies={chosenTechnologies}
					groupTag={groupTag}
					oblationalGroupTags={oblationalGroupTags}

					ChangeMaterialSuperCategory={this.props.ChangeMaterialSuperCategory.bind(this)}
					ChangeMaterialCategory={this.props.ChangeMaterialCategory.bind(this)}

					ChangeMaterialConfirmed={this.props.ChangeMaterialConfirmed.bind(this)}
					ChangeMaterialData={this.props.ChangeMaterialData.bind(this)}

					SetTechnology={this.props.SetTechnology.bind(this)}
					SaveMaterial={this.props.SaveMaterial.bind(this)}

					ChangeMaterialGroupTag={this.props.ChangeMaterialGroupTag.bind(this)}

					hardenerMaterials={_.filter(materials, (material) => {
						return material.isConfirmed && material.category.key === 'rh';
					})}
					complementaryRules={complementaryRules}

					SetComplementaryRules={this.props.SetComplementaryRules.bind(this)}

					myStoreCode={myStoreCode}
					ChangeMaterialMyStoreCode={this.props.ChangeMaterialMyStoreCode}
				/>
				}
			</div>
		)
	}
};

export default connect(
	state => ({
		materials: _.sortBy(state.MyStore.myStoreMaterials, ['isConfirmed', 'title']),
		selectedMaterialId: state.MyStore.selectedMaterialId,
		materialsCategories: state.MyStore.materialsCategories,
		materialsSuperCategories: state.MyStore.materialsSuperCategories,
		technologies: state.MyStore.technologies,
		isChangingMyStoreMaterialDialogOpened: state.Interface.isChangingMyStoreMaterialDialogOpened,

		selectedSuperCategory: state.MyStore.selectedSuperCategory,
		selectedCategory: state.MyStore.selectedCategory,
		data: state.MyStore.data,
		chosenTechnologies: state.MyStore.chosenTechnologies,
		myStoreCode: state.MyStore.selectedMyStoreCode,

		isConfirmed: state.MyStore.isConfirmed,
		groupTag: state.MyStore.groupTag,
		oblationalGroupTags: state.MyStore.groupTags,
		complementaryRules: state.MyStore.complementaryRules,
	}),
	dispatch => ({
		GetAllMyStoreMaterials: () => {
			dispatch(MyStoreActions.GetAllMyStoreMaterials())
		},
		OpenChangingMyStoreMaterialDialog: (materialId) => {
			dispatch(MyStoreActions.OpenChangingMyStoreMaterialDialog(materialId));
		},
		CloseChangingMyStoreMaterialDialog: () => {
			dispatch(MyStoreActions.CloseChangingMyStoreMaterialDialog());
		},
		ChangeMaterialSuperCategory: (superCategory) => {
			dispatch(MyStoreActions.ChangeMaterialSuperCategory(superCategory))
		},
		ChangeMaterialCategory: (category) => {
			dispatch(MyStoreActions.ChangeMaterialCategory(category));
		},
		ChangeMaterialConfirmed: (isConfirmed) => {
			dispatch(MyStoreActions.ChangeMaterialConfirmed(isConfirmed));
		},
		ChangeMaterialMyStoreCode: (newCode) => {
			dispatch(MyStoreActions.ChangeMaterialMyStoreCode(newCode))
		},
		ChangeMaterialData: (data) => {
			dispatch(MyStoreActions.ChangeMaterialData(data));
		},
		SetComplementaryRules: (rules) => {
			dispatch(MyStoreActions.ChangeComplementaryRules(rules))
		},
		SetTechnology: (technologyId, checked) => {
			dispatch(MyStoreActions.SetTechnology(technologyId, checked));
		},
		ChangeMaterialGroupTag: (groupTag) => {
			dispatch(MyStoreActions.ChangeMaterialGroupTag(groupTag));
		},

		SaveMaterial: () => {
			dispatch(MyStoreActions.SaveMaterial());
		},

		DeleteMaterialById: (mId) => {
			dispatch(MyStoreActions.DeleteMaterialById(mId));
		}
	})
)(MyStoreMaterialsAdding);
