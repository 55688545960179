import React, {Component} from 'react';

import {
  CardContent,
} from '@material-ui/core';

import ProjectAttachments from '../ProjectAttachments';

import RiggingPreparingDescription from './RiggingPreparingDescription';

class RiggingPreparing extends Component{
  render(){
    const {     
      stage
    } = this.props;
    return(
        <CardContent>
          <RiggingPreparingDescription
            textTemplate={stage.textTemplate}
            additionalDescription={stage.additionalDescription}
            data={stage.data}
          />
          <ProjectAttachments
            attachmentsList={stage.attachmentsList}
          />
        </CardContent>
    );
  }
}

export default RiggingPreparing;
