import {
  TechDocMaterialsAmountConstants,
	InterfaceConstants,
	TechDocsConstants,
} from '../Constants'

import _ from 'lodash';

import api from '../../Utils/API';

const SetOblationalNotCutMaterial = (materialId, amount) => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const form = new FormData();
		form.append('amount', amount);
		return api.put(`techDocs/${techDocId}/materialsAmount/${materialId}`, form)
			.then(() => {
				dispatch(SetMaterialAmounts())
			})
	}
}

const SetMaterialAmounts = () => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		return api.get(`techDocs/${techDocId}`)
			.then((techdoc) => {
				dispatch({
					type: TechDocMaterialsAmountConstants.SET_MATERIALS_AMOUNT,
					data: techdoc.materialsAmounts
				});
				return api.get(`techDocs/${techDocId}/prices`)
			})
			.then((price) => {
				dispatch({
					type: TechDocsConstants.SET_TECHDOC_PRICE,
					data: price,
				})
			})
	};
};

const DeleteMaterial = (materialId) => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const form = new FormData();
		form.append('amount', 0);
		return api.put(`techDocs/${techDocId}/materialsAmount/${materialId}`, form)
			.then(() => {
				dispatch(SetMaterialAmounts());
			})
			.then(() => {
				dispatch(SetSelectedResin({}))
			})
	}
}

const SetSelectedOblationalMaterial = (oblationalMaterial) => {
	return (dispatch) => {
		dispatch({
			type: TechDocMaterialsAmountConstants.SET_SELECTED_OBLATIONAL_MATERIAL,
			data: oblationalMaterial,
		})
	}
};

const SaveOblationalMaterial = () => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const selectedOblationalMaterial = getState().TechDocMaterialsAmount.selectedOblationalMaterial;
		const form = new FormData();
		form.append('amount', selectedOblationalMaterial.amount);
		return api.put(`techDocs/${techDocId}/materialsAmount/${selectedOblationalMaterial.material}`, form)
			.then(() => {
				dispatch(SetMaterialAmounts());
				dispatch({
					type: InterfaceConstants.CLOSE_OBLATIONAL_MATERIAL_ADDING_DIALOG,
				})
			})
	}
}

const SetSelectedResin = (resin) => {
  return (dispatch) => {
    dispatch({
      type: TechDocMaterialsAmountConstants.SET_SELECTED_RESIN,
	    data: resin,
    });
  };
};

const SaveResin = () => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const selectedResin = getState().TechDocMaterialsAmount.selectedResin;
		const form = new FormData();
		form.append('amount', selectedResin.amount);
		const parsedMaterial = _.split(selectedResin.material, '/');
		if (_.size(parsedMaterial) > 1) {
			form.append('materialToMixWith', parsedMaterial[1]);
		}
		return api.put(`techDocs/${techDocId}/materialsAmount/${parsedMaterial[0]}`, form)
			.then(() => {
				dispatch(SetMaterialAmounts());
				dispatch({
					type: InterfaceConstants.CLOSE_RESIN_ADDING_DIALOG,
				});
			})
	};
}

const GetSuperCategories = () => {
  return (dispatch) => {
	  api.get('materialSuperCategories')
      .then((materialSuperCategories) => {
        dispatch({
          type: TechDocMaterialsAmountConstants.GET_MATERIALS_SUPERCATEGORIES,
          data: materialSuperCategories.results
        })
      })
  }
}

const SelectArmorMaterial = (amount) => {
  return (dispatch, getState) => {
	  const techDocId = getState().TechDocs.SelectedTechDoc._id;
	  const materialId = getState().Interface.editorSelectedMaterial;
	  const form = new FormData();
	  form.append('amount', JSON.stringify(amount));
    api.put(`techDocs/${techDocId}/materialsAmount/${materialId}`, form)
      .then(() => {
	      dispatch(SetMaterialAmounts())
      })
  }
};

const CalculateCuttingMaterials = () => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		return api.post(`setCuttingResult/${techDocId}`)
			.then(() => {
				dispatch(SetMaterialAmounts());
			})
	}
};

const SelectResin = (title) => {
  return {
    type: TechDocMaterialsAmountConstants.SELECT_RESIN,
    data: {
      title: title
    },
  };
};  

const AddConnector = () => {
  return {
    type: TechDocMaterialsAmountConstants.ADD_CONNECTOR,
  }
};

const SelectConnector = (title, index) => {
  return {
    type: TechDocMaterialsAmountConstants.SELECT_CONNECTOR,
    data: {
      title: title,
      index: index
    },
  };
};

const SelectType = (type) => {
  return {
    type: TechDocMaterialsAmountConstants.SELECT_TYPE,
    data: {
      type: type
    },
  };
};

const SetConnectorAmount = (amount, index) => {
  return {
    type: TechDocMaterialsAmountConstants.SET_CONNECTOR_AMOUNT,
    data: {
      amount: amount,
      index: index
    },
  };
};

const SetResinAmount = (amount) => {
  return {
    type: TechDocMaterialsAmountConstants.SET_RESIN_AMOUNT,
    data: {
      amount: amount
    },
  };
};

export default {
  SelectResin,
  SelectType,
  SetResinAmount,
  SelectConnector,
  SetConnectorAmount,
  AddConnector,

	GetSuperCategories,

	SelectArmorMaterial,

	SetSelectedResin,
	SaveResin,

	SetSelectedOblationalMaterial,
	SaveOblationalMaterial,

	DeleteMaterial,
	SetOblationalNotCutMaterial,

	CalculateCuttingMaterials,
  SetMaterialAmounts,
}