import React, {PureComponent} from 'react';
import {
  Typography,
  Select,
  MenuItem
} from '@material-ui/core';

import _ from 'lodash';

const styles = {
  descriptionText: {
    width: '50%',
  },
  descriptionValue: {
    width: '50%',
  },
  textFieldClass: {
    paddingTop: 0,
  }
};

class TechDocDescriptionParameterInput extends PureComponent {
  HandleChangeInputValue = (event) => {
    this.props.HandleChangeInputValue(event.target.value)
  }

  render() {
    const {
      parameterTitle,
      parameterValue,
      isBlocked,
      selectItems
    } = this.props;
    return (
      <div
        className='parameterSelectionContainer'
      >
        <Typography
          style={styles.descriptionText}
          variant='body1'
        >
          {parameterTitle}
        </Typography>
        <Select
          style={styles.descriptionValue}
          fullWidth
          value={parameterValue}
          disabled={isBlocked}
          onChange={(event) => this.props.HandleChangeInputValue(event.target.value)}
          inputProps={{style: styles.textFieldClass}}
        >
          {_.map(selectItems, item => 
            <MenuItem 
              value={item._id}
              key={item._id}
            >
              {item.title}
            </MenuItem>
          )}
        </Select>
      </div>
    )
  }
};

export default TechDocDescriptionParameterInput;
