import React from 'react';

import {
  Grid,
  GridListTile,
  GridListTileBar,
} from '@material-ui/core';

import { RemoveIcon } from '../DumbComponents/_NewDesignComponents'

import _ from 'lodash';

const styles = {
  techDocCard: {
    margin: 12,
    cursor: 'pointer',
    position: 'relative'
  },
  removeIcon: {
    position: 'absolute',
    zIndex: 1,
    top: -10,
    right: -10,
    backgroundColor: '#fff',
    borderRadius: 18,
    fontSize: '36px',
    width: 36,
    height: 36,
  },
  techDocCardTile: {
    listStyleType: 'none',
  }
};

function getTimeInFormat(minute) {
  return (Math.floor(minute / 60)).toString().concat(' ч ').concat((minute % 60).toString()).concat(' м');
}

class Doc extends React.PureComponent {
  render() {
    let {
      Title,
      WorkerPrice,
      MaterialPrice,
      Durability,
      TechDocId,
      AvatarPath,
    } = this.props;
    return (
      <div
        style={styles.techDocCard}
        onClick={() => this.props.selectTechDoc(TechDocId)}
      >
        <RemoveIcon
          style={styles.removeIcon}
          onClick={(e) => { this.props.selectTechDocForRemove(TechDocId); e.stopPropagation(); }}
        />
        <Grid>
          <GridListTile
            style={styles.techDocCardTile}
          >
            <img
              src={AvatarPath}
              alt={Title}
              style={{
                width: '100%',
                height: 'auto'
              }}
            />
            <GridListTileBar
              title={Title}
              subtitle={
                <div
                  className='gridListTileBarSubtitle'
                >
                  <div
                    style={{
                      marginBottom: 6,
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        marginRight: 6
                      }}
                    >
                      <span>
                        {
                          getTimeInFormat(Durability)
                        }
                      </span>
                    </div>
                    <div>
                      <span>
                        {
                          `Цена работы ${_.floor(WorkerPrice, 2)} ₽`
                        }
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      margin: '6px 0'
                    }}
                  >
                    <span>
                      {
                        `Цена материалов ${_.floor(MaterialPrice, 2)} ₽`
                      }
                    </span>
                  </div>
                </div>
              }
            />
          </GridListTile >
        </Grid >
      </div>
    )
  }
}

export default Doc;