import React, {Component} from 'react';

import _ from 'lodash';

import {
  BlueSwith
} from '../_NewDesignComponents'

const styles = {
    text: {
	    height: 16,
	    fontFamily: 'Roboto',
	    fontSize: 14,
	    fontWeight: 470,
	    letterSpacing: -0.2,
	    alignSelf: 'center',
	    width: 233,
	    display: 'block'
    }
}

class ControlInfoTaskTemplate extends Component{
  render(){
    const{
      taskId,
      isQCRequired,
      isICRequired
    } = this.props;
    return(
      <div 
        className='controlInfoTaskTemplate'
      >
	      {!_.isUndefined(isQCRequired) &&
            <div
	            style={{
		            display: 'flex'
	            }}
            >
              <span
                  style={styles.text}
              >
                Требуется технический контроль
              </span>
              <BlueSwith
                // color='primary'
                checked={isQCRequired}
                onChange={(event) => this.props.SetCustomTaskDescription(taskId, 'isQCRequired', event.target.checked)}
              />
            </div>
          }
        {!_.isUndefined(isICRequired) &&
          <div
	          style={{
		          display: 'flex'
	          }}
          >
            <span
                style={styles.text}
            >
              Требует промежуточного контроля
            </span>
            <BlueSwith
              // color='primary'
              checked={isICRequired}
              onChange={(event) => this.props.SetCustomTaskDescription(taskId, 'isICRequired', event.target.checked)}
            />
          </div>
        }
      </div>
    );
  }
}

export default ControlInfoTaskTemplate;