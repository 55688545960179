import React, {Component, Fragment} from 'react';

import _ from 'lodash';

import {
  Typography,
  CardContent
} from '@material-ui/core';

import PolymetrizationDiagramInfo from '../../Stage/Evacuation/PolymerizationDiagramInfo';
import EvacuationDescription from './EvacuationDescription';
import ResinLifecycleAndType from './ResinLifecycleAndType';
import ProjectAttachments from '../ProjectAttachments';

const styles = {
  cardContent:{
    overflowY:'auto',
    height:'100%'
  }
}

class PolymerizationDiagram extends Component {
  render(){
    const {
      stage
    } = this.props;
    return(
      <CardContent
        style={styles.cardContent}
      >
        {!_.isUndefined(stage.data) && !_.isUndefined(stage.data.polymerizationGraph) ? <Fragment>
          <EvacuationDescription
            data={stage.data}
            additionalDescription={stage.additionalDescription}
          />
          <ResinLifecycleAndType
            polymerizationGraph={stage.data.polymerizationGraph}
          />
          <PolymetrizationDiagramInfo
            steps={!_.isUndefined(stage.data.polymerizationGraph.steps) ? stage.data.polymerizationGraph.steps : []}
          />
        </Fragment>
        :
        <Typography
          variant='body1'
        >
          График полимеризации не выбран
        </Typography>}
        <ProjectAttachments
          attachmentsList={stage.attachmentsList}
        />
      </CardContent>
    );
  }
}

export default PolymerizationDiagram;