import React, { Component } from 'react';

import _ from 'lodash';

import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  CardActionArea
} from '@material-ui/core';

import {
  TaskState
} from '../';

import moment from 'moment';

class OverdueCard extends Component {

  translateTitle(state) {
    switch (state) {
      case 'todo':
        return 'К выполнению';

      case 'doing':
        return 'В работе';
  
      case 'blocked':
        return 'Заблокировано';
    
      case 'inReview':
        return 'Ожидает проверки'

      case 'waiting':
        return 'В ожидании';
    
      default:
        return;
    }
  }

  render(){
    const {
      overdueList
    } = this.props;
    return(
      <div
	      style={{
		      minWidth: 392,
          margin: '0 12px',
		      flexDirection: 'column',
		      position:'relative',
		      height: '100%',
		      display: 'inline-block',
		      verticalAlign: 'top'
	      }}
      >
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
	          margin: '12px 0',
	          maxHeight: 'calc(100% - 24px)',
          }}
        >
          <CardHeader
            style={{borderBottom:'1px solid #ccc', minHeight: 40, display: 'flex'}}
            title={
              <Typography
                variant='title'
              >
                <b>Просроченные</b>
              </Typography>
            }
          />
          <CardContent
            style={{
              overflowY: 'auto',
              height: 'auto',
              display:'block'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {_.isEmpty(overdueList) &&
                <Typography
                  variant='headline'
                >
                  ПУСТО
                </Typography>
              }
              {_.map(overdueList, overdue => {
                return(
                  <Card
                    key={overdue._id}
                    style={{
                      marginBottom: 6,
                      height: '100%',
                      width: '100%',
                    }}
                    onClick={() => this.props.SelectOverdueTask(overdue.project._id, overdue.realization)}
                  >
                    <CardActionArea
                      style={{width:'100%'}}
                    >
                    <CardContent
                      style={{display:'flex', flexDirection:'column', backgroundColor:'#fcfcfc', width: '100%'}}
                    >
                      <Typography
                        variant='title'
                        style={{
                          marginBottom: 8,
                        }}
                      >
                        {overdue.title}
                      </Typography>
                      <TaskState
                        state={overdue.state}
                      />
                      <Typography
                        variant='subheading'
                        style={{
                          marginTop: 8,
                        }}
                      >
                        <b>{`${_.capitalize(overdue.assignedTo.first_name)} ${_.capitalize(overdue.assignedTo.second_name)}`}</b>
                      </Typography>
                      <Typography
                        variant='subheading'
                      >
                        <b>{moment(overdue.date).format('DD.MM.YYYY')}</b>
                      </Typography>
                      <Typography
                        variant='subheading'
                      >
                        Проект <b>{overdue.project.title}</b>
                      </Typography>
                    </CardContent>
                    </CardActionArea>
                  </Card>
                )
              })}
            </div>
          </CardContent>
        </Card>
      </div>
    )
  }
}

export default OverdueCard;