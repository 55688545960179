import React, { PureComponent } from 'react';

import _ from 'lodash';

import ColorPick from '../../ColorPick';
import { COLORS } from '../../../../Styles/AppColors';
import {
  RemoveArmorSurfaceIcon
} from '../../../DumbComponents/_NewDesignComponents/';

const styles = {
  armorSurfaceHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden'
  },
  colorPicker: {
    marginLeft: 'auto',
  },
};

class ArmorSurfaceHeader extends PureComponent {
  render() {
    const {
      title,
      color,
      headerLayers,
      surfaceId
    } = this.props;
    return(
      <div
        style={styles.armorSurfaceHeaderContainer}
      >
        <RemoveArmorSurfaceIcon
          onClick={(event) => {
            this.props.OpenAreYouSureDialog();
            // event.stopPropagation();
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflow: 'hidden'
          }}
        >
          <span
            style={{
              letterSpacing: -0.3,
              lineHeight: '20px',
              fontSize: '16px',
              fontFamily: 'Roboto',
              fontWeight: 500,
              color: COLORS.TEXT_COLOR,
            }}
          >
            {_.capitalize(title)}
          </span>
          {_.map(headerLayers, (layer, layerId) => {
            return(
              <div
                key={layerId}
                style={{
                  display: 'flex',
                }}
              >
                <span
                  style={{
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                    textOverflow: 'ellipsis',
                    color: COLORS.TEXT_GRAY,
                    lineHeight: '16px',
                    marginRight: '16px',
                    whiteSpace: 'nowrap',
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                    flex: 10,
                    display: 'block'
                  }}
                >
                  {layerId}
                </span>
                <span
                  style={{
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                    color: COLORS.TEXT_GRAY,
                    lineHeight: '16px',
                    flex: 1
                  }}
                >
                  х{layer.length}
                </span>
              </div>
            )
          })
          }
        </div>
        <div
	        style={styles.colorPicker}
        >
          <ColorPick
            onClick={(event) => {
              this.props.SetActiveArmorSurface(surfaceId);
              this.props.OpenColorPicker(color);
              // event.stopPropagation();
            }}
            colorPanel={color}
          />
        </div>
      </div>
    )
  }
};

export default ArmorSurfaceHeader;
