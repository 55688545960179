import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  RealizationsList,
} from '../SmartComponents';

class Realizations extends Component {
  render(){
    return(
      <RealizationsList
        projectId={this.props.params.projectId}
      />
    )
  }
}

export default connect(
  state => ({

  }),
  dispatch => ({

  })
)(Realizations);