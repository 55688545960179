import React, { Component } from 'react';

import _ from 'lodash';

import SingleLayerRow from './SingleLayerRow';

class LayresFacingInfo extends Component {
  render(){
    const {
      layers
    } = this.props;
    return(
      <div>
        {_.map(layers, (layer, layerId) => {
          return(
            <SingleLayerRow
              key={layer._id}
              index={layerId}
              materialTitle={layer.material.title}
              rotationAngle={layer.fiberDirection}
              surfaceColor={layer.surface.color}
            />
          )})
        }
      </div>
    )
  }
}

export default LayresFacingInfo;