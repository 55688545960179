import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../../Styles/AppColors';
import {
  OutlinedTextfield,
  RemoveIcon
} from '../'

const styles = () => ({
  inputSizes: {
    marginRight: '8px',
    flex: 5,
  },
  countInput: {
    flex: 2,
  },
});

class OblationalMaterialSizes extends Component {
  render(){
    const {
      width,
      length,
      count
    } = this.props;
    return(
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          width: '100%',
          marginTop: 12,
        }}
      >
        <span
          style={{
            letterSpacing: -0.2,
            lineHeight: '16px',
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            color: COLORS.TEXT_COLOR,
            marginBottom: 20
          }}
        >
          Размеры куска
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <OutlinedTextfield
            required
            style={{
              marginRight: '8px',
              flex: 5,
            }}
            label='Ширина'
            value={width}
            type='number'
            defaultValue={0}
            onChange={this.props.onChangeWidth}
            measureUnits='м'
          />
          <OutlinedTextfield
            required
            style={{
              marginRight: '8px',
              flex: 5,
            }}
            label='Длина'
            value={length}
            type='number'
            defaultValue={0}
            onChange={this.props.onChangeLength}
            measureUnits='м'
          />
          <OutlinedTextfield
            required
            variant='outlined'
            label='Шт.'
            defaultValue={1}
            value={count}
            type='number'
            onChange={this.props.onChangeCount}
            style={{
              flex: 2,
            }}
          />
          <RemoveIcon
            onClick={this.props.onClickDelete}
          />
        </div>
      </div>
    )
  }
}

OblationalMaterialSizes.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  onChangeWidth: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  onChangeLength: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  onChangeCount: PropTypes.func.isRequired, 
  onClickDelete: PropTypes.func.isRequired,
}

export default withStyles(styles)(OblationalMaterialSizes);