import React, { Component } from 'react';

import _ from 'lodash';

import {
  Grid,
  Button,
  Snackbar,
} from '@material-ui/core';

import {
  Add
} from '@material-ui/icons';

import { connect } from 'react-redux';

import { ProjectCard } from '../DumbComponents/_NewDesignComponents/Project'

import { RemoveStructureDialog } from '../DumbComponents/_NewDesignComponents'

import CreateNewProjectDialog from '../DumbComponents/ProjectsComponents/CreateNewProjectDialog';

import {
  InterfaceActions,
  ProjectsActions,
} from '../../Redux/Actions';

const styles = {
  addButton: {
    right: 24,
    bottom: 24,
    position: 'fixed'
  }
}

class ProjectsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRemoveProjectDialogOpened: false,
      selectedProjectForRemove: null
    }
  }

  closeRemoveProjectDialog = () => {
    this.setState({ isRemoveProjectDialogOpened: false });
  }

  selectProjectForRemove = (projectId) => {
    this.setState({ selectedProjectForRemove: projectId, isRemoveProjectDialogOpened: true });
  }

  removeProjectByIdDialog = (projectId) => {
    this.props.RemoveProjectById(projectId);
    this.setState({ isRemoveProjectDialogOpened: false });
  }


  componentDidMount() {
    this.props.GetProjects();
  }

  render() {
    const {
      isCreateNewProjectDialogOpened,
      projects,
      newProject,
      techDocs,
      isMaterialsAmountEnough,
      productsList,
      product,
      notEnoughMaterials,
    } = this.props;
    return (
      <div
        className='projectContainer'
      >
        <Grid
          container
          style={{ width: '100%' }}
        >
          {_.map(projects, project => {
            return <Grid
              item
              key={project._id}
              sm={4}
              lg={3}
            >
              <ProjectCard
                selectProjectForRemove={this.selectProjectForRemove}
                project={project}
                SelectProject={this.props.SelectProject}
              />

            </Grid>
          })}
        </Grid>
        <Button
          variant='fab'
          color='primary'
          aria-label='add'
          style={styles.addButton}
          onClick={this.props.OpenCreatingNewProjectDialog}
        >
          <Add />
        </Button>
        <RemoveStructureDialog
          title='Удалить проект?'
          content='Это действие удалит проект и все его реализации и задачи без возможности отмены.'
          isRemoveStructureDialogOpened={this.state.isRemoveProjectDialogOpened}
          structureId={this.state.selectedProjectForRemove}
          RemoveStructureById={this.removeProjectByIdDialog}
          closeStructureProductDialog={this.closeRemoveProjectDialog}
        />
        <CreateNewProjectDialog
          isCreateNewProjectDialogOpened={isCreateNewProjectDialogOpened}
          ChangeNewProjectData={this.props.ChangeNewProjectData}

          newProject={newProject}
          GetTechDocsForProjects={this.props.GetTechDocsForProjects.bind(this)}
          techDocs={techDocs}
          productsList={productsList}
          product={product}

          CreateNewProject={this.props.CreateNewProject}
          CloseCreatingNewProjectDialog={this.props.CloseCreatingNewProjectDialog}
          GetProducts={this.props.GetProducts}
          SetProduct={this.props.SetProduct}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={!isMaterialsAmountEnough}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          action={[
            <Button
              onClick={this.props.SetMaterialsEnough}
              color="secondary"
            >
              Закрыть
	          </Button>
          ]}
          message={<span
            id='message-id'
          >
            {`Недостаточно материалов: ${_.join(notEnoughMaterials, ', ')}`}
          </span>}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    isCreateNewProjectDialogOpened: state.Interface.isCreateNewProjectDialogOpened,
    projects: state.Projects.projects,
    newProject: state.Projects.newProject,
    techDocs: state.Projects.techDocs,
    isMaterialsAmountEnough: state.Projects.isMaterialsAmountEnough,
    productsList: state.Projects.productsList,
    product: state.Projects.product,
    notEnoughMaterials: state.Projects.notEnoughMaterials,
  }),
  dispatch => ({
    SetProduct: (productId) => {
      dispatch(ProjectsActions.SetProduct(productId))
    },
    GetProducts: () => {
      dispatch(ProjectsActions.GetProducts())
    },
    SelectProject: (projectId) => {
      dispatch(ProjectsActions.SelectProject(projectId))
    },
    CleanNewProjectData: () => {
      dispatch(ProjectsActions.CleanNewProjectData());
    },
    GetProjects: () => {
      dispatch(ProjectsActions.GetProjects());
    },
    GetTechDocsForProjects: () => {
      dispatch(ProjectsActions.GetTechDocsForProjects())
    },
    CreateNewProject: () => {
      dispatch(ProjectsActions.CreateNewProject())
    },
    OpenCreatingNewProjectDialog: () => {
      dispatch(InterfaceActions.OpenCreatingNewProjectDialog());
    },
    CloseCreatingNewProjectDialog: () => {
      dispatch(InterfaceActions.CloseCreatingNewProjectDialog());
    },
    ChangeNewProjectData: (parameter, value) => {
      dispatch(ProjectsActions.ChangeNewProjectData(parameter, value))
    },
    SetMaterialsEnough: () => {
      dispatch(ProjectsActions.SetMaterialsEnough());
    },
    RemoveProjectById: (projectId) => {
      dispatch(ProjectsActions.RemoveProjectById(projectId));
    }
  })
)(ProjectsList);