import React, {PureComponent} from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from '@material-ui/core';

import {
  BlueButton,
  TechDocSelect
} from '../_NewDesignComponents'

import {
  COLORS
} from '../../../Styles/AppColors'

import Textarea from "react-textarea-autosize";

import _ from 'lodash';

const styles = {
  textarea: {
    width:'calc(100% - 4px)',
	  maxWidth:'calc(100% - 4px)',
    minWidth: 'calc(100% - 4px)',
	  minHeight:'72px',
    fontSize: 16,
    lineHeight: '24px',
    border: 'solid 2px rgba(0, 0, 0, 0.08)',
    fontFamily: 'Roboto',
    borderRadius: 4,
    marginTop: 8
  },
  flexbox: {
    flex: 1
  }
}

const types = ['important', 'notImportant'];

class AddingQCCriterionDialog extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      type: '',
      text: '',
      onFocused: false
    };
  };

  onFocus() {
    this.setState({
        onFocused: true
    })
  };

  onBlur() {
    this.setState({
        onFocused: false
    })
  };

  render() {
    const {
      isAddingQCCriterionDialogOpened,
      taskId
    } = this.props;
    return(
      <Dialog
        open={isAddingQCCriterionDialogOpened}
        fullWidth
      >
        <DialogTitle>
          <span
            style={{
              color: COLORS.TEXT_COLOR,
              fontSize: 26,
              lineHeight: '30px',
              fontFamily: 'Roboto',
              fontWeight: 300,
            }}
          >
            Новый критерий ОТК
          </span>
        </DialogTitle>
        <DialogContent>
          <span
            style={{
              color: COLORS.TEXT_COLOR,
              fontSize: 14,
              lineHeight: '16px',
              fontFamily: 'Roboto',
              fontWeight: 500,
            }}
          >
            Описание
          </span>
        <Textarea
          // style={styles.textarea}
          style={_.assign(
              {  },
              styles.textarea,
              this.state.onFocused ? { borderColor: COLORS.BLUE, borderWidth: 2 } :  null
          )}
          onChange={(event)=>{this.setState({text: event.target.value})}}
          onFocus={ () => this.onFocus() }
          onBlur={ () => this.onBlur() }
        />
        <div
          className='selectCriterionType'
        >
          <span
            style={{
              color: COLORS.TEXT_COLOR,
              fontSize: 14,
              lineHeight: '16px',
              fontFamily: 'Roboto',
              fontWeight: 500,
              flex: 1
            }}
          >
            Выберите тип критерия
          </span>
          <TechDocSelect
            style={styles.flexbox}
            value={this.state.type}
            onChange={(event)=>{this.setState({type: event.target.value})}}
          >
            {_.map(types, (typeItem, typeId) => {
              return(
                <MenuItem
                  key={typeId}
                  value={typeItem}
                >
                  {typeItem === 'important' ? 'Обязательный': 'Необязательный' }
                </MenuItem>
              )
            })
            }
          </TechDocSelect>
        </div>
        </DialogContent>
        <DialogActions>
          <BlueButton 
            onClick={()=>{
              this.props.CloseAddingQCCriterionDialog();
              this.setState({type: ''})
              }
            }
            label='ОТМЕНИТЬ'
            // color="primary"
          />
          <BlueButton
            disabled={this.state.text === '' || this.state.type === '' }
            // color="primary"
            onClick={() => {
              this.props.AddQCCriterion(this.state.text, this.state.type, taskId);
              this.props.CloseAddingQCCriterionDialog();
              this.setState({type: ''})
            }}
            label='СОХРАНИТЬ'
          />
        </DialogActions>
      </Dialog>
    )
  }
}

export default AddingQCCriterionDialog;