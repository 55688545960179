import {
  TestConstants
} from '../Constants';

const initState = {
  selectedFile: null,
  testFiles: [],
}

const SELECT_FILE = (newState, oldState) => {
  return {
    ...oldState,
    selectedFile: newState,
  };
};

const DOWNLOAD_TO_SERVER = (newState, oldState) => {
  return{
    ...oldState,
  }
}

const SET_FILES = (newState, oldState) => {
  return {
    ...oldState,
    testFiles: newState,
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case TestConstants.SELECT_FILE:
      return SELECT_FILE(action.data, state);
    
    case TestConstants.DOWNLOAD_TO_SERVER:
      return DOWNLOAD_TO_SERVER(action.data, state);

    case TestConstants.SET_FILES:
      return SET_FILES(action.data, state);
      
    default:
      return state;
  }
};