import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
	List,
	ListItem,
	ListSubheader
} from '@material-ui/core';

import LayerFunctions from './LayerFunctions';
import { 
	BlueButton,
	OutlinedButton,
	CutLayersListItem
 } from '../../../DumbComponents/_NewDesignComponents';

 import { COLORS } from '../../../../Styles/AppColors';

const styles = {
	editorFunctionsFlex: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	listContainer: {
		height: '100%',
		display: 'flex',
		width: '100%',
		borderTop: '1px solid grey',
		borderBottom: '1px solid grey',
	},
	layerFunctionsContainer: {
		height: 'auto',
		display: 'flex',
		justifyContent: 'center',
	},
	layersList: {
		height: '100%',
		overflowY: 'auto',
		width: '100%',
		paddingTop: 0,
		paddingBottom: 0,
		backgroundColor: '#fff',
		textAlign: 'center'
		// display: 'flex',
		// flexDirection: 'column',
		// alignItems: 'center',
		// justifyContent: 'center'

	},
	cutFunctionsContainer: {
		padding: 12,
		paddingBottom: 18,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	cutButton: {
	},
	selectedLayer: {
		background: COLORS.BLUE,
		display: 'flex', 
		alignItems: 'center', 
		justifyContent: 'center'
	}
};

class EditorFunctions extends PureComponent {
	getRectWidthByAngle = (layer) => {
		if (_.isUndefined(layer.angle)) {
			return _.toNumber(layer.width)
		}
		switch (layer.angle % 360) {
			case 0:
				return parseInt(layer.width, 10);
			case 45:
			case -315:
				return parseInt(layer.width / Math.sqrt(2), 10);
			case -45:
			case 315:
				return parseInt(layer.width / Math.sqrt(2), 10) + parseInt(layer.height / Math.sqrt(2), 10);
			case -90:
			case 270:
				return parseInt(layer.height, 10);
			case -135:
			case 225:
				return parseInt(layer.height / Math.sqrt(2), 10);
			default:
				return 0;
		}
	};

	getLength(layers) {
		const rightestLayer = _.maxBy(layers, layer => {
			return (!_.isUndefined(layer.position) ? layer.position.x / 1000 : 0) + this.getRectWidthByAngle(layer);
		});
		return (!_.isUndefined(rightestLayer.position) ? rightestLayer.position.x / 1000 : 0) + this.getRectWidthByAngle(rightestLayer);
	}

	render() {
		const {
			layers,
			isBlocked,
			editorSelectedLayer,
			selectedMaterialCut
		} = this.props;
		const groupedLayersList = _.groupBy(layers, 'surfaceTitle');
		console.log('layer: ', groupedLayersList)
		return (
			<div
				className='editorFunctionsFlex'
			>
				<div
					className='layerFunctionsContainer'
				>
					<LayerFunctions
						RotateLayer={this.props.RotateLayer}
						isBlocked={isBlocked}
					/>
				</div>
				<div
					className='listContainer'
				>
					<List
						style={styles.layersList}
					>
						{_.map(groupedLayersList, (layers, layerTitle) => {
							return layerTitle !== 'undefined' ? <li
								key={layerTitle}
								style={{
									backgroundColor: 'inherit',
									textAlign: 'center'
								}}
							>
								<ul
									style={{
										backgroundColor: 'inherit',
										padding: 0,
									}}
								>
									<ListSubheader>
									  <span
											style={{
												color: COLORS.TEXT_COLOR,
												fontFamily: 'Roboto',
												fontSize: 14,
												lineHeight: '16px',
											}}
										>
											{layerTitle}
										</span>
									</ListSubheader>
									{_.map(layers, (layer, layerId) => {
										return !_.isUndefined(layer.surfaceTitle) && <ListItem
											button
											key={layer._id}
											// style={editorSelectedLayer === layer._id ? styles.selectedLayer : {display: 'flex', alignItems: 'center', justifyContent: 'center'}}
											style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
											onClick={() => this.props.ChangeEditorSelectedLayer(layer._id)}
										>
											<CutLayersListItem
												style={
													editorSelectedLayer === layer._id ? {boxShadow: `inset 0px 0px 0px 2px ${COLORS.BLUE}`} : {}
												}
												textStyle={
													editorSelectedLayer === layer._id ? { fontSize: 14, lineHeight: '16px'}: {}
												}
												color={layer.color}
												layerNumber={layerId + 1}
												fiberDirection={layer.fiberDirection}
												isOblational={layer.isOblational}
											/>
										</ListItem>
									})}
								</ul>
							</li>
							:
							<span
								key={layerTitle}
								style={{
									color: COLORS.TEXT_COLOR,
									fontFamily: 'Roboto',
									fontSize: 14,
									lineHeight: '16px',
									textAlign: 'center'
								}}
							>
								ЖЕРТВЕННЫЙ МАТЕРИАЛ
							</span>
						})
						}
					</List>
				</div>
				<div
					className='cutFunctionsContainer'
				>
					<span
						style={{
							color: COLORS.TEXT_COLOR,
							fontFamily: 'Roboto',
							fontSize: 14,
							fontWeight: 300,
							lineHeight: '16px',
						}}
					>
						{selectedMaterialCut !== -1 ? 
							<span>Будет отрезано: <b>{_.toString(selectedMaterialCut)} м. п.</b> </span>
							: 
							<span>Будет отрезано: <b>0</b></span>
						}
					</span>
					<OutlinedButton
						style={styles.cutButton}
						// onClick={() => {this.props.SelectArmorMaterial(Math.ceil(this.getLength(layers)/10)/100)}}
						onClick={this.props.CalculateCuttingMaterials}
						label='Пересчитать'
					/>
				</div>
				<div
					style={{
						height: 61,
						width: '100%',
						borderTop: `3px solid ${COLORS.GRAY}`,
						display: 'flex',
						alignItems:'center',
						justifyContent: 'center'
					}}
				>
					<BlueButton
						label='Сохранить и выйти'
						onClick={this.props.CloseCutEditor}
					/>
				</div>
			</div>
		);
	};
};

export default EditorFunctions;
