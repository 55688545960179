import React, {Component, Fragment } from 'react';

import { Typography } from '@material-ui/core';

import TechDocDescriptionParameterInput from './TechDocDescriptionParameterInput';
import TechDocDescriptionParameterSelect from './TechDocDescriptionParameterSelect';
import ModelUploader from './ModelUploader';
import TechDocDescriptionChoosingAdditionalSteps from './StepRequirements/TechDocDescriptionChoosingAdditionalSteps';

class NewTechDocDescription extends Component {
  render(){
    const {
      newTechDocTitle,

      newTechDocType,
      techDocTypes,

      newTechDocTechnology,
      techDocTechnologies,

      newTechDocModelDeviceLink,

	    notRequiredProductionSteps,
      chosenProductionSteps,
    } = this.props;
    return(
      <div>
        <TechDocDescriptionParameterInput
          parameterTitle='Название технологической карты'
          parameterValue={newTechDocTitle}
          HandleChangeInputValue={this.props.ChangeNewTechDocTitle}
        />
        <TechDocDescriptionParameterSelect
          parameterTitle='Тип технологической карты'
          isBlocked={false}
          parameterValue={newTechDocType}
          HandleChangeInputValue={this.props.ChangeNewTechDocType}
          selectItems={techDocTypes}
        />
        {newTechDocType !== 'assembly' &&
          <TechDocDescriptionParameterSelect
            parameterTitle='Метод производства'
            isBlocked={false}
            parameterValue={newTechDocTechnology}
            HandleChangeInputValue={this.props.ChangeNewTechDocTechnology}
            selectItems={techDocTechnologies}
          />
        }
        <ModelUploader
          newTechDocModelDeviceLink={newTechDocModelDeviceLink}
          ChangeNewTechDocModelDeviceLink={this.props.ChangeNewTechDocModelDeviceLink}
        />
        {newTechDocType !== 'assembly' &&
          <Fragment>
            <Typography
              variant='subheading'
              align='center'
            >
              ДОПОЛНИТЕЛЬНЫЕ ПАРАМЕТРЫ
            </Typography>
            <TechDocDescriptionChoosingAdditionalSteps
              parameterTitle='Выбор дополнительных этапов'

              notRequiredProductionSteps={notRequiredProductionSteps}
              chosenProductionSteps={chosenProductionSteps}
              ChangeChosenProductionSteps={this.props.ChangeChosenProductionSteps}
            />
          </Fragment>
        }
      </div>
    )
  }
};

export default NewTechDocDescription;
