import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add'

class AddFormControl extends Component {
  render(){
    const {
      label,
      disabled
    } = this.props;
    return(
      <FormControlLabel
        label={label}
        disabled={disabled}
        onClick={this.props.onClick}
        {...this.props}
        control={
          <IconButton>
            <Add/>
          </IconButton>
        }
      />
    )
  }
}

AddFormControl.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default AddFormControl;