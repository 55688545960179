import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../Styles/AppColors';

const styles = {
  root: {
    color: COLORS.BLUE,
    '&:hover': {
      backgroundColor: COLORS.HOVER_BLUE
    }
  }
};

class BlueButton extends Component {
  render() {
    const {
      classes,
      label,
    } = this.props;
    return (
      <Button
        classes={{
          root: classes.color
        }}
        {...this.props}
      >
        {label}
      </Button>
    )
  }
};

BlueButton.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(BlueButton);
