import React, {PureComponent} from 'react';

import {
	Select,
	MenuItem,
	FormControl,
	FormHelperText,
} from '@material-ui/core';

import _ from 'lodash';

import ChangingMaterialsCount from './ChangingMaterialsCount';

const styles = {
	addingMaterialsContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	selectContainer: {
		// width: 256,
	}
};

class AddingMaterialsContent extends PureComponent {
	render() {
		const {
			selectedMaterialId,
			layersCount,
			selectedAngle,

			armorMaterials,

			angles,
		} = this.props;
		return(
			<div
				style={styles.addingMaterialsContent}
			>
				<FormControl
					fullWidth
				>
					<Select
						fullWidth
						value={selectedMaterialId}
						onChange={(event) => {this.props.ChangeSelectedMaterial(event.target.value)}}
					>
						{_.map(armorMaterials, armorMaterial => {
							return <MenuItem
								key={armorMaterial._id}
								value={armorMaterial._id}
							>
								{armorMaterial.title}
							</MenuItem>
						})}
					</Select>
					<FormHelperText>
						Выбор материала
					</FormHelperText>
				</FormControl>
				<ChangingMaterialsCount
					layersCount={layersCount}

					IncrementCount={this.props.IncrementCount}
					DecrementCount={this.props.DecrementCount}
				/>
				<FormControl
					style={{width: 192}}
				>
					<Select
						fullWidth
						value={selectedAngle}
						onChange={(event) => {this.props.ChangeSelectedAngle(event.target.value)}}
					>
						{_.map(angles, (angle) => {
							return <MenuItem
								value={angle.key}
								key={angle.key}
							>
								{angle.title}
							</MenuItem>
						})}
					</Select>
					<FormHelperText>
						Выбор направления волокон выкладки
					</FormHelperText>
				</FormControl>
			</div>
		)
	}
};

export default AddingMaterialsContent;
