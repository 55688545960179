import {
	MyStoreConstants,
} from '../Constants';

const initState = {
	myStoreMaterials: [],
	materialsCategories: [],
	materialsSuperCategories: [],
	technologies: [],
	selectedMaterialId: '',
	selectedSuperCategory: '',
	selectedCategory: '',
	data: {},
	complementaryRules: [],
	chosenTechnologies: [],
	isConfirmed: false,
	groupTag: '',
	groupTags: [],
	selectedMyStoreCode: '',
};

const SET_MY_STORE_COMPLEMENTARY_RULES = (newState, oldState) => {
	return {
		...oldState,
		complementaryRules: newState,
	}
}

const SET_MY_STORE_GROUPTAG = (newState, oldState) => {
	return {
		...oldState,
		groupTag: newState,
	}
}

const SET_MY_STORE_DATA = (newState, oldState) => {
	return {
		...oldState,
		data: newState,
	};
};

const SET_MY_STORE_TECHNOLOGIES = (newState, oldState) => {
	return {
		...oldState,
		chosenTechnologies: newState,
	}
};

const SET_MY_STORE_NEW_MATERIALS = (newState, oldState) => {
	return {
		...oldState,
		myStoreMaterials: newState,
	}
}

const SET_MY_STORE_MATERIALS = (newState, oldState) => {
	return {
		...oldState,
		myStoreMaterials: newState.materials,
		materialsCategories: newState.materialsCategories,
		materialsSuperCategories: newState.materialsSuperCategories,
		technologies: newState.technologies,
		groupTags: newState.groupTags,
	};
};

const SET_MY_STORE_SELECTED_CODE = (newState, oldState) => {
	return {
		...oldState,
		selectedMyStoreCode: newState,
	}
}

const SET_MY_STORE_SELECTED_CATEGORY = (newState, oldState) => {
	return {
		...oldState,
		selectedCategory: newState,
	}
}

const SET_MY_STORE_SELECTED_SUPER_CATEGORY = (newState, oldState) => {
	return {
		...oldState,
		selectedSuperCategory: newState,
	};
};

const SET_MATERIAL_IS_CONFIRMED = (newState, oldState) => {
	return {
		...oldState,
		isConfirmed: newState,
	}
}

const SET_MY_STORE_SELECTED_MATERIAL = (newState, oldState) => {
	return {
		...oldState,
		selectedMaterialId: newState,
	}
};

export default (state = initState, action) => {
	switch (action.type) {
		case MyStoreConstants.SET_MY_STORE_MATERIALS:
			return SET_MY_STORE_MATERIALS(action.data, state);

		case MyStoreConstants.SET_MY_STORE_SELECTED_MATERIAL:
			return SET_MY_STORE_SELECTED_MATERIAL(action.data, state);

		case MyStoreConstants.SET_MY_STORE_SELECTED_CATEGORY:
			return SET_MY_STORE_SELECTED_CATEGORY(action.data, state);

		case MyStoreConstants.SET_MY_STORE_SELECTED_SUPER_CATEGORY:
			return SET_MY_STORE_SELECTED_SUPER_CATEGORY(action.data, state);

		case MyStoreConstants.SET_MATERIAL_IS_CONFIRMED:
			return SET_MATERIAL_IS_CONFIRMED(action.data, state);

		case MyStoreConstants.SET_MY_STORE_DATA:
			return SET_MY_STORE_DATA(action.data, state);

		case MyStoreConstants.SET_MY_STORE_TECHNOLOGIES:
			return SET_MY_STORE_TECHNOLOGIES(action.data, state);

		case MyStoreConstants.SET_MY_STORE_NEW_MATERIALS:
			return SET_MY_STORE_NEW_MATERIALS(action.data, state);

		case MyStoreConstants.SET_MY_STORE_GROUPTAG:
			return SET_MY_STORE_GROUPTAG(action.data, state);

		case MyStoreConstants.SET_MY_STORE_COMPLEMENTARY_RULES:
			return SET_MY_STORE_COMPLEMENTARY_RULES(action.data, state);

		case MyStoreConstants.SET_MY_STORE_SELECTED_CODE:
			return SET_MY_STORE_SELECTED_CODE(action.data, state);

		default:
			return state;
	}
}