import React, { Component, Fragment } from 'react';

import {
	Table,
	TableRow,
	TableCell,
	TableBody,
	TableHead,
	Typography,
} from '@material-ui/core';

import _ from 'lodash';

class OblationalLayersInfo extends Component {
	render() {
		const {
			oblationalNotCuttingGroups,
			oblationalCuttingGroups
		} = this.props;
		return (
			<div
				style={{
					width: '100%'
				}}
			>
				{!_.isEmpty(oblationalNotCuttingGroups) &&
					<div>
						<Typography
							variant='subheading'
						>
							На этапе выкладки жертвенных слоев используются следующие трубки и жгуты:
						</Typography>
						<Table
							style={{
								display: 'block',
								overflowX: 'auto',
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>
										Материал
									</TableCell>
									<TableCell>
										Размеры
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{_.map(oblationalNotCuttingGroups, (oncg) => {
									return !_.isNil(oncg.material) ? (
										<TableRow
											key={oncg._id}
										>
											<TableCell>
												<b>{oncg.material.title}</b>
											</TableCell>
											<TableCell>
												{_.map(oncg.sizes, size => {
													return (
														<Typography
															variant='body1'
														>
															{`${size.count} шт. размера ${size.length} ${oncg.material.category.measureUnits}`}
														</Typography>
													)
												})}
											</TableCell>
										</TableRow>
									) : null
								})
								}
							</TableBody>
						</Table>
					</div>
				}
				{_.some(oblationalCuttingGroups, ocg => {
					return _.isUndefined(ocg.material)
				}) ?
					<Typography
						variant='subheading'
					>
						Не выбраны все жертвенные материалы, вернитесь на этап <b>Раскрой</b>
					</Typography>
					:
					<Fragment>
						<Typography
							variant='subheading'
							style={{ marginTop: 12, marginBottom: 12 }}
						>
							Порядок выкладки материалов
						</Typography>
						<Table
							style={{
								display: 'block',
								overflowX: 'auto',
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>
										№
									</TableCell>
									<TableCell>
										Тип слоя
									</TableCell>
									<TableCell>
										Материал
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{_.map(oblationalCuttingGroups, (ocg, ocgId) => {
									const groupTagTitle = ocg.groupTag.title + (!_.isUndefined(ocg.isBag) ? ` (${ocg.isBag ? 'мешок' : 'пакет'})` : '');
									return (!_.isUndefined(ocg.material) &&
										<TableRow
											key={ocg._id}
										>
											<TableCell>
												{ocgId + 1}
											</TableCell>
											<TableCell>
												{groupTagTitle}
											</TableCell>
											<TableCell>
												{!_.isUndefined(ocg.material) ? ocg.material.title : 'Материал не выбран, вернитесь на этап раскрой'}
											</TableCell>
										</TableRow>
									)
								})
								}
							</TableBody>
						</Table>
					</Fragment>
				}
			</div>
		);
	}
}

export default OblationalLayersInfo;