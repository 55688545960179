import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import { COLORS } from '../../../../Styles/AppColors';
import { CONTAINER_CONSTANTS } from '../../../../Styles/ContainerConstants';

const styles = () => ({
  menu: {
    width: '100%',
  },
  cssFocused: {
    height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT
  },
  cssOutlinedInput: {
    height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT,
    '&$cssFocused $notchedOutline': {
      borderColor: COLORS.BLUE,
    },
  },
  notchedOutline: {},
  select: {
    height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT,
    '&$cssFocused $notchedOutline': {
      height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT,
    }
  }
})

class SelectMaterial extends Component {
  render(){
    const {
      materialsList,
      selectedMaterial,
      classes
    } = this.props;
    return(
      <div
        style={{
          display: 'flex',
          flexDirection:'column',
          justifyContent:'flex-start',
        }}
      >
        <span
          style={{
            lineHeight: '16px',
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            color: COLORS.TEXT_COLOR,
            marginBottom: 16,
            letterSpacing: -0.2,
          }}
        >
          Материал
        </span>
        <TextField
          fullWidth
          select
          variant='outlined'
          className={classes.select}
          value={selectedMaterial}
          onChange={this.props.selecectMaterialHandler}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
          }}
        >
          {_.map(materialsList, material => {
            return(
              <MenuItem
                key={material._id}
                value={material._id}
              > 
                {material.title}
              </MenuItem>
            )})
          }
        </TextField>
      </div>
    )
  }
}

SelectMaterial.propTypes = {
  classes: PropTypes.object.isRequired,
  materialsList: PropTypes.array.isRequired,
  selectedMaterial: PropTypes.any.isRequired,
  selecectMaterialHandler: PropTypes.func.isRequired
}

export default withStyles(styles)(SelectMaterial);