import {
  UsersConstants
} from '../Constants'

import _ from 'lodash';

import api from '../../Utils/API';

import {
  InterfaceActions
} from '../Actions';

const CleanUserData = () => {
  return{
    type: UsersConstants.CLEAN_USER_DATA
  }
}

const GetAllUsers = () => {
  return(dispatch) => {
    api.get('users')
      .then((users) => {
        dispatch({
          type: UsersConstants.GET_ALL_USERS,
          data: users.results
        })
      })
  }
}

const GetAllRoles = () => {
  return(dispatch) => [
    api.get('userRoles')
      .then((roles) => {
        dispatch({
          type: UsersConstants.GET_ALL_ROLES,
          data: roles
        })
      })
  ]
}

const AddUser = () => {
  return(dispatch, getState) => {
    const newUser = getState().Users.newUser;
    const form = new FormData();
    _.each(newUser, (value, key) => {
      if (key === 'roles') {
        form.append(key, JSON.stringify(value));
      }else
        form.append(key, value);
    })
    api.post(`users`, form)
      .then((user) => {
        const nfc = new FormData();
        nfc.append('nfcId', newUser.nfc);
        nfc.append('type', 'user');
        nfc.append('objId', user._id);
        return api.post('nfc', nfc)
      })
      .then(() => {
        return dispatch(GetAllUsers());
      })
      .then(() => {
        dispatch(InterfaceActions.CloseAddingUserDialog());
      })
  };
};

const OpenEditingUserDialog = (userId) => {
  return(dispatch) => {
    api.get(`users/${userId}`)
      .then((user) => {
        dispatch({
          type: UsersConstants.OPEN_EDITING_DIALOG,
          data: [{
            first_name: user.first_name,
            second_name: user.second_name,
            roles: user.roles,
            password: user.password,
            rate: user.rate,
            nfc: user.nfc,
            username: user.username
          }, userId]
        });
        dispatch(InterfaceActions.OpenAddingUserDialog());
      })
  }
}

const DeleteUser = (userId) => {
  return(dispatch) => {
    api.del(`users/${userId}`, { id: userId })
      .then(()=>{
        dispatch(GetAllUsers());
      })
  }
}

const EditUser = (userId) => {
  return(dispatch, getState) => {
      const newUserData = getState().Users.newUser;
      const form = new FormData();
      _.each(newUserData, (value, key) => {
        if(key === 'roles') {
          form.append(key, JSON.stringify(value));
        }else
          form.append(key, value);
      })
      api.put(`users/${userId}`, form)
        .then((user) => {
          const nfc = new FormData();
          nfc.append('nfcId', newUserData.nfc);
          nfc.append('type', 'user');
          nfc.append('objId', user._id);
          return api.post('nfc', nfc)
        })
        .then(() => {
          return dispatch(GetAllUsers());
        })
        .then(() => {
          dispatch(InterfaceActions.CloseAddingUserDialog());
        })
  }
}

const ChangeNewUserData = (parameter, value) => {
  return(dispatch, getState) => {
    const newUser = getState().Users.newUser;
    dispatch({
      type: UsersConstants.CHANGE_NEW_USER_DATA,
      data: _.assign(newUser, {[parameter]: value})
    })
  };
};

export default {
  AddUser,
  CleanUserData,
  ChangeNewUserData,
  GetAllUsers,
  EditUser,
  OpenEditingUserDialog,
  DeleteUser,
  GetAllRoles
}