import React, { Component } from 'react';

import {
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Typography
} from '@material-ui/core';

import { RemoveIcon } from '../';
import _ from 'lodash';

import { noAvatar } from '../../../../Models/Avatars';

import api from '../../../../Utils/API';

const styles = {
  card: {
    cursor: 'pointer',
    margin: 12,
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.12)'
  },
  productCardMedia: {
    paddingTop: '56.25%',
  },
  projectNameText: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1.45,
    letterSpacing: 1.8,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  productTitle: {
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0.2,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginTop: 8,
    marginBottom: 14,
  },
  productDescription: {
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.3,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  productDate: {
    fontFamily: 'Roboto',
    fontSize: 12,
    opacity: 0.5,
    lineHeight: 1.33,
    letterSpacing: 0.4,
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: 18,
  },
  removeIcon: {
    position: 'absolute',
    zIndex: 1,
    top: -10,
    right: 0,
    backgroundColor: '#fff',
    borderRadius: 25,
    fontSize: '50px',
    width: 50,
    height: 50,
  },
}

class ProductCard extends Component {
  render() {
    const {
      product
    } = this.props;
    return (
      <div
        style={{ position: 'relative' }}
      >
        <RemoveIcon
          style={styles.removeIcon}
          onClick={() => { this.props.selectProductForRemove(product._id) }}
        />
        <Card
          style={styles.card}
          onClick={() => this.props.SelectProduct(product._id)}
        >
          <CardActionArea
            style={{ width: '100%', height: '100%' }}
          >
            <CardMedia
              image={!_.isUndefined(product.cover) ? api.getHost() + product.cover : noAvatar}
              style={styles.productCardMedia}
            />
            <CardContent>
              <Typography
                style={styles.projectNameText}
              >
                Проект не определен
            </Typography>
              <Typography
                style={styles.productTitle}
              >
                {product.title}
              </Typography>
              <Typography
                style={styles.productDescription}
              >
                Описание не определено
            </Typography>
              <Typography
                style={styles.productDate}
              >
                Дата изменения не определена
            </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    )
  }
};

export default ProductCard;