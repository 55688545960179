import {
	push
} from 'react-router-redux';

import _ from 'lodash';

import {
	TechDocsConstants,
	ProductionChainConstants,
	TechDocMaterialsConstants,
	TechDocCutConstants,
	InterfaceConstants,
	TechDocMaterialsAmountConstants,
	HTTPConstants,
} from '../Constants';

import {
	ProductsActions
} from '.'

import api from '../../Utils/API';

const SetTechDocPrice = (price) => {
	return (dispatch) => {
		dispatch({
			type: TechDocsConstants.SET_TECHDOC_PRICE,
			data: price,
		})
	}
};

const ClearTechDoc = () => {
	return (dispatch) => {
		dispatch({
			type: HTTPConstants.SET_TECHDOC_NOT_LOADED,
		});
		dispatch({
			type: TechDocsConstants.CLEAR_SELECT_TECHDOC,
		});
	}
};

const ResetColors = () => {
	return (dispatch, getState) => {
		const techDocs = getState().TechDocs.SelectedTechDoc._id;
		return api.post(`techDocs/${techDocs}/docker/clear`)
	}
};

const SetAvatar = () => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		return api.post(`techDocs/${techDocId}/docker/cover`)
			.then(() => {
				return api.get(`techDocs/${techDocId}`)
			})
			.then((techdoc) => {
				dispatch({
					type: TechDocsConstants.SET_SELECTED_TECHDOC,
					data: {
						title: techdoc.title,
						technology: !_.isUndefined(techdoc.technology) ? techdoc.technology.title : '',
						type: techdoc.type,
						modelUrl: techdoc.modelUrl,
						_id: techdoc._id,
						coverImageUrl: !_.isUndefined(techdoc.coverImageUrl) ? (api.getHost() + techdoc.coverImageUrl + `?t=${new Date().getTime()}`) : (process.env.NODE_ENV === 'development' ? '/NO_AVATAR.svg' : '/static/NO_AVATAR.svg'),
					},
				})
			})
	}
};


const SetColors = () => {
	return (dispatch, getState) => {
		const techDocs = getState().TechDocs.SelectedTechDoc._id;
		return api.post(`techDocs/${techDocs}/returnColors`)
	}
};

const GetArea = () => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		api.get(`techDocs/${techDocId}/docker/area`)
			.then((result) => {
				const measure = result.area !== 0 ? Math.ceil(result.area) / 1000000 + ' м2' : 0;
				dispatch({
					type: InterfaceConstants.SET_MEASURE_RESULT,
					data: measure,
				})
			});
	}
};

const GetLength = () => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		api.get(`techDocs/${techDocId}/docker/length`)
			.then((result) => {
				const measure = result.length !== 0 ? Math.ceil(result.length) / 1000 + ' м' : 0;
				dispatch({
					type: InterfaceConstants.SET_MEASURE_RESULT,
					data: measure,
				})
			});
	}
}

const GetTechDocById = (techDocId) => {
	return (dispatch) => {
		api.get(`techDocs/${techDocId}`)
			.then((techdoc) => {
				dispatch({
					type: TechDocsConstants.SET_SELECTED_TECHDOC,
					data: {
						title: techdoc.title,
						technology: !_.isUndefined(techdoc.technology) ? techdoc.technology.title : '',
						type: techdoc.type,
						modelUrl: techdoc.modelUrl,
						_id: techdoc._id,
						coverImageUrl: !_.isUndefined(techdoc.coverImageUrl) ? (api.getHost() + techdoc.coverImageUrl + `?t=${new Date().getTime()}`) : (process.env.NODE_ENV === 'development' ? '/NO_AVATAR.svg' : '/static/NO_AVATAR.svg'),
					},
				});
				dispatch({
					type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
					data: {
						productionChain: techdoc.productionChain
					}
				});
				dispatch({
					type: TechDocMaterialsAmountConstants.SET_MATERIALS_AMOUNT,
					data: techdoc.materialsAmounts
				});
				dispatch({
					type: TechDocCutConstants.SET_OBLATIONAL_LAYERS,
					data: techdoc.oblationalCuttingGroups,
				});
				dispatch({
					type: TechDocCutConstants.SET_ONCG,
					data: techdoc.oblationalNotCuttingGroups,
				})
				return api.get(`techDocs/${techDocId}/surfaces`);
			})
			.then((surfaces) => {
				dispatch({
					type: TechDocCutConstants.SET_SURFACES,
					data: surfaces,
				});
				return api.get(`techDocs/${techDocId}/materials`)
			})
			.then((materials) => {
				dispatch({
					type: TechDocMaterialsConstants.SET_MATERIALS,
					data: materials,
				});
				dispatch({
					type: HTTPConstants.SET_TECHDOC_LOADED,
				});
				return api.get(`techDocs/${techDocId}/prices`)
			})
			.then((price) => {
				dispatch(SetTechDocPrice(price));
			})
			.catch((error) => {
			})
	};
};

const RemoveTechDocById = (techDocId) => {
	return (dispatch, getState) => {
		const productId = getState().Products.currentProduct;
		api.del(`techDocs/${techDocId}`)
			.then(() => {
				dispatch(ProductsActions.GetTechDocsList(productId));
			})
			.catch(() => {
				console.log(`cant remove techdoc ${techDocId}`);
			})
	}
}

const RunDocker = (techDocId, width, height) => {
	return (dispatch) => {
		api.get(`techDocs/${techDocId}/docker`, { width: width, height: height })
			.then((dockerUrls) => {
				dispatch({
					type: TechDocsConstants.SET_DOCKER_URL,
					data: dockerUrls
				})
			});
	};
};

const SelectTechDoc = (productId, techDocId) => {
	return (dispatch) => {
		dispatch(push(`/product/${productId}/${techDocId}`));
	}
};


export default {
	GetTechDocById,
	SelectTechDoc,
	RunDocker,

	GetArea,
	GetLength,

	SetColors,
	ResetColors,

	SetAvatar,

	ClearTechDoc,

	SetTechDocPrice,
	RemoveTechDocById
}
