import React, { PureComponent } from 'react';

import {
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  ExpandMore,
} from '@material-ui/icons';

import ArmorSurfaceHeader from './ArmorSurfaceHeader';
import ArmorSurfaceContent from './ArmorSurfaceContent/ArmorSurfaceContent';

import {
  ArmorMaterialExpansionPanel
} from '../../_NewDesignComponents/ArmorMaterials'

import _ from 'lodash';

const styles = () => ({
  content: {
    width: '100%'
  }
});

class ArmorSurface extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      isOpened: false
    }
  }

  getMaterialsInSurface =  (layers) => {
    return _.groupBy(layers, 'material.title')
  }
  
  render() {
    const {
      title,
      isActive,
      surfaceId,
      width,
      height,
      color,
      layers,

      angles,

      selectedMaterialId,
      layersCount,
      selectedAngle,
      armorMaterials,
      classes
    } = this.props;

    const {
      isOpened
    } = this.state;
    return(
      <ArmorMaterialExpansionPanel
        Select={(e) => {
          this.props.SetActiveArmorSurface(surfaceId);
          e.preventDefault();
          e.stopPropagation();
        }}  
        expanded={isOpened}
        isActive={isActive}
      >
        <ExpansionPanelSummary
          classes={{content: classes.content}}
          expandIcon={<ExpandMore
            onClick={e => {
              this.setState({
                isOpened: !isOpened
              })
              e.preventDefault();
              e.stopPropagation();
            }}
          />
          }
          onClick={(e) => {
            this.props.SetActiveArmorSurface(surfaceId);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ArmorSurfaceHeader
            title={title}
            surfaceId={surfaceId}
            DeleteArmorSurface={this.props.DeleteArmorSurface}
            OpenAreYouSureDialog={this.props.OpenAreYouSureDialog}
            color={color}
            SetActiveArmorSurface={this.props.SetActiveArmorSurface}

            OpenColorPicker={this.props.OpenColorPicker}
            isActive={isActive}
            headerLayers={this.getMaterialsInSurface(layers)}
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ArmorSurfaceContent
            width={width}
            SetSurfaceWidth={this.props.SetSurfaceWidth}

            height={height}
            SetSurfaceHeight={this.props.SetSurfaceHeight}

            OpenAddingSurfaceMaterialDialog={this.props.OpenAddingSurfaceMaterialDialog}

            layers={layers}

            angles={angles}
            DeleteLayers={this.props.DeleteLayers}

            OpenChangingSurfaceMaterialDialog={this.props.OpenChangingSurfaceMaterialDialog}

            ConnectSurfaceWithEdges={this.props.ConnectSurfaceWithEdges}

            selectedMaterialId={selectedMaterialId}
            layersCount={layersCount}
            selectedAngle={selectedAngle}

            armorMaterials={armorMaterials}

            ChangeSelectedMaterial={this.props.ChangeSelectedMaterial}
            AddSurfaceLayers={this.props.AddSurfaceLayers}

            ChangeSelectedAngle={this.props.ChangeSelectedAngle}

            ChangeLayersCount={this.props.ChangeLayersCount}
            SetLayersCount={this.props.SetLayersCount}
            surfaceId={surfaceId}
          />
        </ExpansionPanelDetails>
      </ArmorMaterialExpansionPanel>
    )
  }
};

export default withStyles(styles)(ArmorSurface);
