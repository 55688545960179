import {
  ProductsConstants
} from '../Constants';

import { push } from 'react-router-redux';

import api from '../../Utils/API';

const SelectFile = (selectedFile) => {
  return {
    type: ProductsConstants.SELECT_FILE,
    data: selectedFile,
  };
};

const UploadToServer = (file, productId) => {
  return (dispatch) => {
    const form = new FormData();
    form.append('files', file);
    return api.post(`products/${productId}/files`, form)
      .then((response) => {
        dispatch(FetchFiles(productId));
      })
  }
};

const FetchFiles = (productId) => {
  return (dispatch) => {
    return api.get(`products/${productId}/files`)
      .then((response) => {
        dispatch({
          type: ProductsConstants.SET_FILES,
          data: response.results,
        })
      })
      .catch((error) => {
      })
  }
};

const RemoveFile = (name, productId) => {
  return (dispatch) => {
    return api.del(`products/${productId}/files/${name}`)
      .then((response) => {
        dispatch(FetchFiles(productId));
      })
  }
};

const GetTechDocsList = (productId) => {
  return (dispatch) => {
    api.get(`products/${productId}`)
      .then((product) => {
        dispatch({
          type: ProductsConstants.SET_TECHDOCS_LIST,
          data: product.techDocs,
        });
      })
      .catch((error) => {
      })
  }
};

const CleanTechDocsList = () => {
  return {
    type: ProductsConstants.CLEAN_TECHDOCS_LIST
  }
}

const GetProducts = () => {
  return (dispatch) => {
    api.get('products')
      .then((products) => {
        dispatch({
          type: ProductsConstants.GET_PRODUCTS,
          data: products.results,
        })
      })
  }
}

const CleanProducts = () => {
  return {
    type: ProductsConstants.CLEAN_PRODUCTS
  }
}

const CreateNewProduct = (title) => {
  return (dispatch) => {
    const form = new FormData();
    form.append('title', title);
    api.post('products', form)
      .then((product) => {
        dispatch(push(`/product/${product._id}`));
      })
  };
};

const RemoveProductById = (productId) => {
  return (dispatch) => {
    api.del(`products/${productId}`)
      .then(() => {
        dispatch(GetProducts())
      })
      .catch(() => console.log(`cant remove product ${productId}`));
  }
}

const SelectProduct = (id) => {
  return (dispatch) => {
    dispatch({
      type: ProductsConstants.SELECT_PRODUCT,
      data: id
    })
    dispatch(push(`/product/${id}`));
  };
};

export default {
  CreateNewProduct,
  GetProducts,
  SelectProduct,
  GetTechDocsList,
  UploadToServer,
  RemoveFile,
  FetchFiles,
  SelectFile,
  CleanProducts,
  CleanTechDocsList,
  RemoveProductById
};