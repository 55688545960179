import _ from 'lodash';

import {
  TechDocsConstants,
} from '../Constants';

const initState = {
  TechDocsList: [],
  SelectedTechDoc: {
	  title: '',
	  type: '',
	  technology: '',
	  modelUrl: '',
    _id: '',
    coverImageUrl: '',
  },
  dockerUrls: null,
  techDocPrice: {},
};

const SET_TECHDOC_PRICE = (newState, oldState) => {
  return {
    ...oldState,
    techDocPrice: newState,
  }
}

const SET_AVATAR = (newState, oldState) => {
  return {
    ...oldState,
    SelectedTechDoc: _.assign(oldState.SelectedTechDoc, {coverImageUrl: newState})
  }
}

const SET_DOCKER_URL = (newState, oldState) => {
  return {
    ...oldState,
    dockerUrls: newState,
  };
};

const SET_SELECTED_TECHDOC = (newState, oldState) => {
  return {
    ...oldState,
    SelectedTechDoc: newState,
  }
}

const CLEAR_SELECT_TECHDOC = (oldState) => {
  return {
    ...oldState,
	  SelectedTechDoc: {
		  title: '',
		  type: '',
		  technology: '',
		  modelUrl: '',
		  _id: '',
		  coverImageUrl: '',
	  },
    dockerUrls: null,
    techDocPrice: {},
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case TechDocsConstants.SET_SELECTED_TECHDOC:
      return SET_SELECTED_TECHDOC(action.data, state);

    case TechDocsConstants.CLEAR_SELECT_TECHDOC:
      return CLEAR_SELECT_TECHDOC(action.data, state);

    case TechDocsConstants.SET_DOCKER_URL:
      return SET_DOCKER_URL(action.data, state);

    case TechDocsConstants.SET_AVATAR:
      return SET_AVATAR(action.data, state);

    case TechDocsConstants.SET_TECHDOC_PRICE:
      return SET_TECHDOC_PRICE(action.data, state);

    default:
      return state;
  }
}
