import React, {Component} from 'react';

import {
  Table,
  Typography,
  IconButton
} from '@material-ui/core';

import {
  Add,
} from '@material-ui/icons';

import BodyOblationalMaterialsInfo from './BodyOblationalMaterialsInfo';
import HeadMaterialsInfo from '../HeadMaterialsInfo';
import AddingOblationalMaterialDialog from "./AddingOblationalMaterialDialog";

const styles = {
  addButton: {
    margin: 24, 
    width: 192,
  },
	oblationalMaterialAddingContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: 12,
	}
}

class OblationalMaterialsInfo extends Component{
  render() {
    const {
      oblationalMaterials,
	    addingOblationalMaterials,

	    isAddingOblationalMaterialDialogOpened,
	    selectedOblationalMaterial,

	    needsDeleting
    } = this.props;
    return(
      <div
        className='materialsInfo'
      >
        {oblationalMaterials.length > 0 &&
					<Table
						style={{overflowX: 'scroll', display: 'block'}}
					>
						<HeadMaterialsInfo
							needsDeleting={needsDeleting}
						/>
						<BodyOblationalMaterialsInfo
							oblationalMaterials={oblationalMaterials}
							DeleteMaterial={this.props.DeleteMaterial}
						/>
					</Table>
				}
	      <div
		      style={styles.oblationalMaterialAddingContainer}
	      >
		      <Typography
			      variant='subheading'
		      >
			      ЖЕРТВЕННЫЙ МАТЕРИАЛ
		      </Typography>
		      <IconButton
			      onClick={this.props.OpenOblationalMaterialAddingDialog}
		      >
			      <Add/>
		      </IconButton>
	      </div>
				<AddingOblationalMaterialDialog
					selectedOblationalMaterial={selectedOblationalMaterial}
					isAddingOblationalMaterialDialogOpened={isAddingOblationalMaterialDialogOpened}
					addingOblationalMaterials={addingOblationalMaterials}

					CloseOblationalMaterialAddingDialog={this.props.CloseOblationalMaterialAddingDialog}
					SetSelectedOblationalMaterial={this.props.SetSelectedOblationalMaterial}
					SaveOblationalMaterial={this.props.SaveOblationalMaterial}
				/>
      </div>
    )
  }
}

export default OblationalMaterialsInfo