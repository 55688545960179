const TechDocMaterialsAmountConstants = {
  SELECT_RESIN:'SELECT_RESIN',
  SELECT_TYPE: 'SELECT_TYPE',
  SET_RESIN_AMOUNT: 'SET_RESIN_AMOUNT',
  SELECT_CONNECTOR: 'SELECT_CONNECTOR',
  SET_CONNECTOR_AMOUNT: 'SET_CONNECTOR_AMOUNT',
  ADD_CONNECTOR: 'ADD_CONNECTOR',
  SET_MATERIALS_AMOUNT: 'SET_MATERIALS_AMOUNT',
  GET_MATERIALS_SUPERCATEGORIES: 'GET_MATERIALS_SUPERCATEGORIES',

	SET_SELECTED_RESIN: 'SET_SELECTED_RESIN',

	SET_SELECTED_OBLATIONAL_MATERIAL: 'SET_SELECTED_OBLATIONAL_MATERIAL',
};

export default TechDocMaterialsAmountConstants;