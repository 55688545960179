import React, { Component } from 'react';

import _ from 'lodash';

import {
	Typography,
} from '@material-ui/core';

import {
	Stage,
	Layer,
	Rect,
	Group,
	Text,
} from 'react-konva';

import api from '../../Utils/API';

export default class WorkerCut extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isError: false,
			materialTitle: '',
			materialWidth: 0,
			scale: 0,
			cutViewerHeight: 0,
			layers: [],
			isLoading: false,
			materialAmount: 0,
		}
	}
	componentDidMount() {
		const {
			techDocId,
			materialId
		} = this.props.params;
		const ch = this.cutViewer.clientHeight;
		this.setState({
			isLoading: true,
			cutViewerHeight: ch,
		});
		api.get(`workerinfo/${techDocId}/${materialId}`)
			.then((materialCutInfo) => {
				const scale =  ch / (materialCutInfo.materialWidth * 1000);
				this.setState({
					isLoading: false,
					materialTitle: materialCutInfo.materialTitle,
					materialWidth: materialCutInfo.materialWidth,
					layers: materialCutInfo.layers,
					scale: scale,
					materialAmount: materialCutInfo.materialAmount,
				})
			})
			.catch(() => {
				this.setState({
					isError: true,
					isLoading: false,
				})
			});
	}

	render() {
		const {
			cutViewerHeight,
			materialTitle,
			materialWidth,
			layers,
			scale,
			materialAmount
		} = this.state;
		let currentLength = 0;
		let arrayTrait = [];
		while(currentLength < (materialAmount + 0.1) * 1000 * scale){
			arrayTrait.push(currentLength);
			currentLength += (scale * (scale < 0.4 ? 200 : 100));
		}
		return(
			<div
				style={{
					width: '100%',
					height: '100%',
					flex: 1,
					position: 'absolute',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						display: 'flex',
						height: 64,
						width: '100%',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Typography
						variant='body1'
					>
						{`Материал ${materialTitle} (${materialAmount} м)`}
					</Typography>
				</div>
				<div
					style={{
						display: 'flex',
						width: '100%',
						height: '100%',
						overflowX: 'scroll',
					}}
					ref={(cutViewer) => this.cutViewer = cutViewer}
				>
					{cutViewerHeight > 0 && <Stage
						style={{
							background: '#EEE',
							border: 4,
							borderColor: '#000000',
							width: 'auto',
						}}
						height={cutViewerHeight}
						width={(materialAmount + 0.1) * 1000 * scale}
					>
						<Layer>
							{_.map(layers, layer => {
								return <Group
									key={layer._id}
									x={layer.position.x * scale}
									y={layer.position.y * scale}
									rotation={layer.angle}
								>
									<Rect
										fill={!_.isUndefined(layer.color) ? layer.color : '#000'}
										height={layer.height * scale * 1000}
										width={layer.width * scale * (layer.isBag ? 2 : 1) * 1000}
									/>
									<Text
										fill={'#FFF'}
										text={layer.width + 'м x ' + layer.height + 'м' + (!_.isUndefined(layer.fiberDirection) ? ` ${layer.fiberDirection}` : '') + (!_.isUndefined(layer.isBag) ? (layer.isBag ? ' (мешок)' : ' (пакет)') : '')}
										height={layer.height * scale * 1000}
										width={layer.width * scale * (layer.isBag ? 2 : 1) * 1000}
										align={'center'}
										verticalAlign={'middle'}
									/>
								</Group>
							})}
						</Layer>
						<Layer
							style={{zIndex: 100}}
						>
							{_.map(arrayTrait, (trait, traitId) => {
								return (
									<Group
										key={traitId}
									>
										<Rect
											width={2}
											height={15}
											y={0}
											x={trait}
											fill='#666'
											draggable={false}
										/>
										<Text
											y={20}
											x={trait - 7}
											draggable={false}
											text={(traitId / (scale < 0.4 ? 5 : 10)).toFixed(1)}
											fill={'#666'}
										/>
										<Rect
											width={2}
											height={15}
											y={cutViewerHeight - 15}
											x={trait}
											fill='#666'
											draggable={false}
										/>
										<Text
											y={cutViewerHeight - 30}
											x={trait - 7}
											draggable={false}
											text={(traitId / (scale < 0.4 ? 5 : 10)).toFixed(1)}
											fill={'#666'}
										/>
									</Group>
								)}
							)}
						</Layer>
						<Layer>
							<Text
								width={cutViewerHeight}
								height={30}
								x={40}
								y={0}
								rotation={90}
								draggable={false}
								align={'center'}
								verticalAlign={'middle'}
								fontSize={16}
								text={`${ materialWidth } м`}
								fill={'#222'}
							/>
						</Layer>
					</Stage>}
				</div>
			</div>
		)
	}
};
