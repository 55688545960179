const TechDocsConstants = {
  SET_TECHDOCS_LIST: 'SET_TECHDOCS_LIST',
  SET_SELECTED_TECHDOC: 'SET_SELECT_TECHDOC',
  SET_DOCKER_URL: 'SET_DOCKER_URL',
  SET_AVATAR: 'SET_AVATAR',

  CLEAR_SELECT_TECHDOC: 'CLEAR_SELECT_TECHDOC',
  
  SET_TECHDOC_PRICE: 'SET_TECHDOC_PRICE',
};

export default TechDocsConstants;
