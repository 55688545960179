import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
	TextField,
	Typography,
} from '@material-ui/core';

const styles = {
	textFieldArmorMaterialsData: {
		marginTop: 12,
	}
}

class ArmorMaterialsDescription extends PureComponent {
	componentDidMount() {
		const {
			data,
			materialTitle
		} = this.props;
		const parsedMaterial = _.split(_.toLower(materialTitle), ' ');
		if (_.isUndefined(data.width)) {
			const parsedWidth = _.toNumber(_.trimEnd(parsedMaterial[_.size(parsedMaterial) - 2], 'мм'));
			this.props.ChangeMaterialData(_.assign(data, { width: !_.isNaN(parsedWidth) ? parsedWidth / 1000 : 0 }));
		}
		if (_.isUndefined(data.dens)) {
			const parsedDens = _.toNumber(_.trimEnd(parsedMaterial[2], 'г/м2'));
			this.props.ChangeMaterialData(_.assign(data, { dens: !_.isNaN(parsedDens) ? parsedDens : 0 }))
		}
	};

	render() {
		const {
			data,
		} = this.props;
		return (
			<div>
				<Typography
					variant='caption'
				>
					Ширина рулона, м
				</Typography>
				<TextField
					fullWidth
					type='number'
					// label='Ширина рулона, м'
					value={data.width ? data.width : 0}
					onChange={(event) => {
						if (event.target.value >= 0) {
							this.props.ChangeMaterialData(_.assign(data, { width: event.target.value }))
						}
					}}
				/>
				<Typography
					variant='caption'
					style={styles.textFieldArmorMaterialsData}
				>
					Плотность, г/м2
				</Typography>
				<TextField
					fullWidth
					type='number'
					// label='Плотность, г/м'
					value={data.dens ? data.dens : 0}
					onChange={(event) => {
						if (event.target.value >= 0) {
							this.props.ChangeMaterialData(_.assign(data, { dens: event.target.value }))
						}
					}}
				/>
			</div>
		)
	}
};

export default ArmorMaterialsDescription;
