import React, {Component} from 'react';

import Textarea from "react-textarea-autosize";

import _ from 'lodash';

import { COLORS } from '../../../Styles/AppColors'

const styles = {
  textarea: {
    flex: 1,
    border: 'solid 2px rgba(0, 0, 0, 0.08)',
    borderRadius: 4,
    width: '100%',
    maxWidth: '100%',
    minWidth: '100%',
    minHeight: '72px',
    marginTop: 20,
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '24px',

  },
  text: {
    flex: 1,
    height: 16,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 470,
    letterSpacing: -0.2
  }
}

class AdditionalDescription extends Component {
  constructor(props){
    super(props);
    this.state = {
      additionalDescription: '',
      onFocused: false
    };
    this.changeTextTemplate = 
      _.debounce(() => this.props.SetCustomTaskDescription(this.props.taskId, 'additionalDescription', this.state.additionalDescription),
      1000)
  };

  componentDidMount() {
    this.setState({
      additionalDescription: this.props.additionalDescription,
    })
  };

  onFocus() {
    this.setState({
        onFocused: true
    })
  };

  onBlur() {
    this.setState({
        onFocused: false
    })
  };

  render(){
    const {
	    additionalDescription
    } = this.state;
    return(
      <div style={{marginTop: 32}}>
        <span
          style={styles.text}
        >
          Дополнительная информация
        </span>
        <Textarea
          onChange={(event) => this.setState({additionalDescription: event.target.value},
            () => {this.changeTextTemplate()}
          )}
          onFocus={ () => this.onFocus() }
          onBlur={ () => this.onBlur() }
          value={additionalDescription}
          style={_.assign(
              {  },
              styles.textarea,
              this.state.onFocused ? { borderColor: COLORS.BLUE, borderWidth: 2 } :  null
          )}
        />
      </div>
    );
  }
}

export default AdditionalDescription;
