import {
  ProductsConstants
} from '../Constants';

const initState = {
  currentProduct: '',
  productsList: [],
  TechDocsList: [],
  selectedFile: null,
  testFiles: []
}

const SELECT_PRODUCT = (newState, oldState) => {
  return {
    ...oldState,
    currentProduct: newState
  }
}

const SET_FILES = (newState, oldState) => {
  return {
    ...oldState,
    testFiles: newState,
  };
};

const SELECT_FILE = (newState, oldState) => {
  return {
    ...oldState,
    selectedFile: newState,
  };
};

const SET_TECHDOCS_LIST = (newState, oldState) => {
  return {
    ...oldState,
    TechDocsList: newState,
  };
};

const CLEAN_TECHDOCS_LIST = (oldState) => {
  return {
    ...oldState,
    TechDocsList: [],
    testFiles: [],
  };
};

const CREATE_NEW_PRODUCT = (newState, oldState) => {
  return {
    ...oldState,
    productsList: newState
  }; 
};

const GET_PRODUCTS = (newState, oldState) => {
  return {
    ...oldState,
    productsList: newState,
  };
};

const CLEAN_PRODUCTS = (oldState) => {
  return {
    ...oldState,
    productsList: []
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case ProductsConstants.CREATE_NEW_PRODUCT:
      return CREATE_NEW_PRODUCT(action.data, state);

    case ProductsConstants.GET_PRODUCTS:
      return GET_PRODUCTS(action.data, state);

    case ProductsConstants.SET_TECHDOCS_LIST:
      return SET_TECHDOCS_LIST(action.data, state);

    case ProductsConstants.SELECT_FILE:
      return SELECT_FILE(action.data, state);

    case ProductsConstants.SET_FILES:
      return SET_FILES(action.data, state);

    case ProductsConstants.CLEAN_PRODUCTS:
      return CLEAN_PRODUCTS(state);

    case ProductsConstants.CLEAN_TECHDOCS_LIST:
      return CLEAN_TECHDOCS_LIST(state);

    case ProductsConstants.SELECT_PRODUCT:
      return SELECT_PRODUCT(action.data, state);

    default:
      return state;
  }
}