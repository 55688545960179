import React, { PureComponent, Fragment } from 'react';

import {
  LayerItemArmorMaterial
} from "../../../_NewDesignComponents/ArmorMaterials";

import _ from 'lodash';

// const styles = {
// 	layersTableContainer: {
// 		width: '100%',
// 	},
// 	layersTable: {
// 		display: 'block',
// 		overflowX: 'auto',
// 	},
// 	layersTableRow: {
// 		cursor: 'pointer',
// 	}
// };

class LayersList extends PureComponent {
	render() {
		const {
			angles,
			generatedLayersList,
			surfaceId
		} = this.props;
		return(
			<div
				className='layersTableContainer'
			>
				{_.size(generatedLayersList) > 0 && 
					<Fragment>
						{_.map(generatedLayersList, (generatedLayer, generatedLayerId) => {
								return(
									<Fragment>
										<LayerItemArmorMaterial
											materialNumber={generatedLayerId + 1}
											disabledInput={true}
											selectedMaterial={generatedLayer.materialId}
											materialsList={[{_id: generatedLayer.materialId, title: generatedLayer.material}]}
											selectedFiberDirection={_.find(angles, {'key': generatedLayer.fiberDirection}).title}
											fiberDirections={['0-90','±45']}
											count={generatedLayer.layersCount}
											surfaceId={surfaceId}
											onClickDelete={(event) => {
												this.props.DeleteLayers(generatedLayer, surfaceId);
												// event.stopPropagation();
											}}
											ChangeLayersCount={this.props.ChangeLayersCount}
										/>
										{_.size(generatedLayersList) !== generatedLayerId + 1 &&
											<div
												style={{
													height: 1,
													marginRight: 35,
													marginTop: 16,
													opacity: 0.3,
													backgroundColor: '#979797',
												}}
											/>
										}
									</Fragment>
							)})
						}
					</Fragment>			
				}
			</div>
		)
	}
};

export default LayersList;
