import React, {Component} from 'react';

import _ from 'lodash';

import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
} from '@material-ui/core';

import moment from 'moment';

class PlanningTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: '',
      selectedUser: '',
    };
  }

  getHHMM(minutes){
    const newHours = Math.trunc(minutes / 60) < 10 ? '0' + Math.trunc(minutes / 60) : Math.trunc(minutes / 60) ;
    const newMinutes = minutes % 60 < 10 ? '0' + minutes % 60 : minutes % 60;
    return (`${newHours}:${newMinutes}`);
  };

  render(){
    const {
      plan
    } = this.props;
    const {
      selectedUser,
      selectedDate,
    } = this.state;
    const users = _.uniqBy(plan, 'userId');
    const days = _.uniqBy(plan,'date')
    return(
      <Table
        style={{overflowX: 'auto', width: '100%'}}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              
            </TableCell>
            {_.map(days, (day, dayId) => <TableCell 
                key={dayId}
              >
                {moment(day.date).format('DD.MM.YY')}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(users, (user) => {
            return(
              <TableRow
                key={user.userId}
              >
                <TableCell>
                  {_.toUpper(user.userName)}
                </TableCell>
                {_.map(days, (day) => {
                  return(
                    <TableCell
                      style={_.assign(
                        {  },
                        {cursor: 'pointer'},
                        (day.date === selectedDate && user.userId === selectedUser) ? { backgroundColor: '#BBB' } : null
                      )}
                      onClick={() => {
                        this.props.GetPlanningByUserDate(moment(day.date).format('DD-MM-YYYY'),user.userId)
                        this.setState({
                          selectedUser: user.userId,
                          selectedDate: day.date
                        })}
                      }
                      key={day.date}
                    >
                      <b>{this.getHHMM(_.find(plan, (planItem) => {
                        return (planItem.userId === user.userId && planItem.date === day.date)
                      }).estimatedTime)}</b>
                    </TableCell>
                  )})
                }
              </TableRow>
            )
          })
          }
        </TableBody>
      </Table>
    )
  }
}

export default PlanningTable;