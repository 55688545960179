import React, {Component} from 'react';

import{
  Typography
} from '@material-ui/core';

import _ from 'lodash';

const styles = {
  price: {
    marginRight:'12px',
  }
}

class HeadMaterials extends Component{
  render(){
    const {
      title,
      price
    } = this.props;
    return(
      <div 
        className='headTaskTemplate'
      >
        <Typography
          variant='subheading'
        >
          {title}
        </Typography>
        <Typography
          style={styles.price}
          variant='subheading'
        >
          ₽=<b>{_.floor(_.toNumber(price), 2)}</b>
        </Typography>
      </div>
    );
  }
}

export default HeadMaterials;
