import React, { Component, Fragment} from 'react';

import _ from 'lodash';

import {
  Typography,
  MenuItem,
} from '@material-ui/core';

import PolymerizationDiagramInfo from './PolymerizationDiagramInfo';
import { TechDocSelect } from "../../_NewDesignComponents";
import { ResinInfo } from '../../_NewDesignComponents/Resin'

const styles = {
  resinInfoOutputTextfields: {
    marginTop: 12,
  }
};

class PolymerizationDiagram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      material: {},
    }
  };

  componentDidMount() {
    const {
      selectedResin,
      selectedHardener,
    } = this.props;
	  if (!_.isUndefined(selectedResin) && !_.isUndefined(selectedHardener)) {
		  const foundCR = _.find(selectedResin.material.description.complementaryRules, cr => {
			  return cr.material === selectedHardener.material._id
		  });
		  const material = {
        resinTitle: selectedResin.material.title,
        resinAmount: selectedResin.amount,
        hardenerTitle: selectedHardener.material.title,
        hardenerAmount: selectedHardener.amount,
			  lifeCycle: foundCR.lifeCycle,
			  resinGraphs: foundCR.resinGraphs,
        proportion: foundCR.proportion,
		  };
		  this.setState({
        material
      })
	  }
  }

  ChangePolymerizationGraph = (event) => {
    const {
      taskId,
      data,
    } = this.props;
    const {
      material
    } = this.state;
    const foundPolymerizationGraph = _.find(material.resinGraphs, {id: event.target.value});
    this.props.SetCustomTaskDescription(taskId, 'data', _.set(data,'polymerizationGraph', {
        _id: foundPolymerizationGraph.id,
        typeTitle: foundPolymerizationGraph.typeTitle,
        resinTitle: material.resinTitle,
        resinAmount: material.resinAmount,
        hardenerTitle: material.hardenerTitle,
        hardenerAmount: material.hardenerAmount,
        proportion: material.proportion,
        steps: foundPolymerizationGraph.steps,
        lifeCycle: material.lifeCycle,
      }));
  };

  render() {
    const {
      polymerizationGraph,
      technology,
    } = this.props;
    const {
      material
    } = this.state;
    return(
      <Fragment>
        {technology === 'Препрег' &&
          <div
            className='polymerizationDiagram'
          >
            <Typography
              variant='body1'
              style={styles.resinInfoOutputTextfields}
            >
              <b>График полимеризации Препрег:</b>
            </Typography>
            <PolymerizationDiagramInfo
              steps={!_.isUndefined(polymerizationGraph.steps) ? polymerizationGraph.steps : []}
            />
          </div>
        }
        {technology !== 'Препрег' &&
          <div
            className='polymerizationDiagram'
          >
            {!_.isEmpty(material) ?
              <div>
                {/*<Typography*/}
                  {/*variant='body1'*/}
                  {/*style={styles.resinInfoOutputTextfields}*/}
                {/*>*/}
                  {/*Вы выбрали смесь <b>{material.resinTitle + ' + ' + material.hardenerTitle}</b>*/}
                {/*</Typography>*/}
                {/*<Typography*/}
                  {/*variant='body1'*/}
                  {/*style={styles.resinInfoOutputTextfields}*/}
                {/*>*/}
                  {/*Время жизни смолы: <b>{material.lifeCycle}</b> мин*/}
                {/*</Typography>*/}
                <ResinInfo
                  hardenerTitle={material.hardenerTitle}
                  resinTitle={material.resinTitle}
                  lifeCycle={material.lifeCycle}
                  proportion={material.proportion}
                />
                {/*<FormControl*/}
                  {/*fullWidth*/}
                  {/*style={styles.resinInfoOutputTextfields}*/}
                {/*>*/}
                  {/*<Select*/}
                    {/*value={!_.isUndefined(polymerizationGraph._id) ? polymerizationGraph._id : ''}*/}
                    {/*onChange={this.ChangePolymerizationGraph}*/}
                  {/*>*/}
                    {/*{_.map(material.resinGraphs, resinGraph => {*/}
                      {/*return <MenuItem*/}
                        {/*key={resinGraph.id}*/}
                        {/*value={resinGraph.id}*/}
                      {/*>*/}
                          {/*{resinGraph.typeTitle}*/}
                      {/*</MenuItem>*/}
                    {/*})}*/}
                  {/*</Select>*/}
                  {/*<FormHelperText>*/}
                    {/*Выбор графика полимеризации*/}
                  {/*</FormHelperText>*/}
                {/*</FormControl>*/}
                {/*<span*/}
                  {/*style={{*/}
                    {/*fontSize: 14,*/}
                    {/*lineHeight: '16px',*/}
                    {/*fontFamily: 'Roboto',*/}
                    {/*fontWeight: '500',*/}
                    {/*letterSpacing: -0.2,*/}
                    {/*color: COLORS.TEXT_COLOR,*/}
                  {/*}}*/}
                {/*>*/}
                  {/*График полимеризации*/}
                {/*</span>*/}
                <TechDocSelect
                  label='График полимеризации'
                  value={!_.isUndefined(polymerizationGraph._id) ? polymerizationGraph._id : ''}
                  fullWidth={true}
                  onChange={this.ChangePolymerizationGraph}
                >
                  {_.map(material.resinGraphs, resinGraph => {
                    return <MenuItem
                      key={resinGraph.id}
                      value={resinGraph.id}
                    >
                      {resinGraph.typeTitle}
                    </MenuItem>
                  })}
                </TechDocSelect>
                {!_.isEmpty(polymerizationGraph) ?
                  <PolymerizationDiagramInfo
                    steps={!_.isUndefined(polymerizationGraph.steps) ? polymerizationGraph.steps : []}
                  />
                  :
                  <Typography
                    variant='body1'
                    style={styles.resinInfoOutputTextfields}
                  >
                    График полимеризации не выбран
                  </Typography>
                }
              </div>
              :
              <Typography
                variant='body1'
                style={styles.resinInfoOutputTextfields}
              >
                <b>Вы не выбрали смолу</b>
              </Typography>}
          </div>
        }
      </Fragment>
    );
  }
}

export default PolymerizationDiagram;