import {
  NewSurfaceMaterialConstants,
} from '../Constants';

const initState = {
  selectedMaterial: '',
  layersCount: 1,
  selectedAngle: '',
  currentSurfaceMaterials: [],
  angles: [
    {
      key: 'straight',
      title: '0-90',
    },
    {
      key: 'notStraight',
      title: '±45',
    }
  ]
};

const CHANGE_LAYER_COUNT = (newState, oldState) => {
  return {
    ...oldState,
    layersCount: newState
  }
}

const CHANGE_SELECTED_ANGLE = (newState, oldState) => {
  return {
    ...oldState,
    selectedAngle: newState,
  };
};

const SET_NEW_SURFACE_MATERIAL_INITIAL_STATE = (newState, oldState) => {
  return {
    ...oldState,
    selectedMaterial: newState.material,
    layersCount: newState.layersCount,
    selectedAngle: newState.rotationAngle,
  }
};

const CHANGE_SELECTED_MATERIAL = (newState, oldState) => {
  return {
    ...oldState,
    selectedMaterial: newState,
  }
}

const INCREMENT_COUNT = (oldState) => {
  return {
    ...oldState,
    layersCount: oldState.layersCount + 1,
  }
}

const DECREMENT_COUNT = (oldState) => {
  if (oldState.layersCount > 1) {
    return {
      ...oldState,
      layersCount: oldState.layersCount - 1,
    }
  };
  return oldState;
}

export default (state = initState, action) => {
  switch (action.type) {
    case NewSurfaceMaterialConstants.SET_NEW_SURFACE_MATERIAL_INITIAL_STATE:
      return SET_NEW_SURFACE_MATERIAL_INITIAL_STATE(action.data, state);

    case NewSurfaceMaterialConstants.INCREMENT_COUNT:
      return INCREMENT_COUNT(state);

    case NewSurfaceMaterialConstants.DECREMENT_COUNT:
      return DECREMENT_COUNT(state);

    case NewSurfaceMaterialConstants.CHANGE_SELECTED_MATERIAL:
      return CHANGE_SELECTED_MATERIAL(action.data, state);

    case NewSurfaceMaterialConstants.CHANGE_SELECTED_ANGLE:
      return CHANGE_SELECTED_ANGLE(action.data, state);

    case NewSurfaceMaterialConstants.CHANGE_LAYER_COUNT:
      return CHANGE_LAYER_COUNT(action.data, state);

    default:
      return state;
  }
}
