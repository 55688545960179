import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import {
	Select,
	FormControl,
	InputLabel,
	OutlinedInput
} from '@material-ui/core';
import _ from 'lodash';
import { COLORS } from '../../../Styles/AppColors';
import { CONTAINER_CONSTANTS } from '../../../Styles/ContainerConstants';

const styles = (theme) => ({
	cssOutlinedInput: {
		height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT,
		'&$cssFocused $notchedOutline': {
			borderColor: COLORS.BLUE,
		},
	},
	cssFocused: {
		height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT
	},
	notchedOutline: {
		height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT,
		top: 0,
	},
	notchedOutlineRoot: {
		height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT + 2,
		'&$cssFocused $notchedOutline': {
			borderColor: COLORS.BLUE
		},
	},
	select: {
		height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT + 2,
		paddingTop: 0,
		paddingBottom: 0,
		fontSize: 14,
		lineHeight: `${CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT}px`,
		'&$cssFocused': {
			borderColor: COLORS.BLUE,
			height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT
		},
	},
	formControl: {
		flex: 1,
		margin: 0,
		minWidth: 120,
		height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT
	},
	selectEmpty: {
		marginTop: theme.spacing.unit * 2,
	},
	inputLabel: {
		transform: 'translate(14px, 14px) scale(1)',
		'&$cssFocused': {
			color: COLORS.BLUE
		}
	},
	selectIcon: {
		top: 'calc(50% - 11px)',
		'&$cssFocused': {
			top: 'calc(50% - 11px)',
		}
	}
});

class TechDocSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			labelWidth: 0,
		};
	}

	componentDidMount() {
		this.setState({
			labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
		});
	}

	render(){
		const {
			classes,
			value,
			label,
			fullWidth,
			disabled
		} = this.props;
		return(
			<FormControl
				variant="outlined"
				className={classes.formControl}
				fullWidth={fullWidth}
				disabled={disabled}
			>
				<InputLabel
					ref={ref => {
						this.InputLabelRef = ref;
					}}
					htmlFor="outlined-age-simple"
					classes={{root: classes.inputLabel, focused: classes.cssFocused}}
				>
					{label}
				</InputLabel>
				<Select
					value={value}
					variant={'outlined'}
					classes={{ select: classes.select, icon: classes.selectIcon }}
					style={_.assign({}, this.props.style)}
					input={
						<OutlinedInput
							labelWidth={this.state.labelWidth}
							name="age"
							classes={{ root: classes.notchedOutlineRoot, notchedOutline: classes.notchedOutline, focused: classes.cssFocused }}
						/>
					}
					{..._.pick(this.props, ['onChange'])}
				>
					{this.props.children}
				</Select>
			</FormControl>
		)
	}
}

TechDocSelect.propTypes = {
	classes: PropTypes.object.isRequired,
	value: PropTypes.string.isRequired,
	children: PropTypes.object,
	label: PropTypes.string,
	fullWidth: PropTypes.bool,
	style: PropTypes.object,
	disabled: PropTypes.bool,
};

TechDocSelect.defaultProps = {
	fullWidth: false,
};

export default withStyles(styles)(TechDocSelect);
