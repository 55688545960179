import _ from 'lodash';

import {
	TechDocCutConstants,
	ProductionChainConstants,
	HTTPConstants,
	TechDocMaterialsAmountConstants,
} from '../Constants';

import api from '../../Utils/API';
import {TechDocMaterialsAmountActions} from "./index";

const ChangeCuttingGroupMaterial = (cuttingGroupId, materialId) => {
	return (dispatch) => {
		const form = new FormData();
		form.append('material', materialId);
		return api.put(`oblationalCuttingGroups/${cuttingGroupId}`, form)
			.then(() => {
				dispatch(SetOblationalLayers());
			});
	}
};

const SetCuttingGroupIsBag = (cuttingGroupId, isBag) => {
	return (dispatch) => {
		const form = new FormData();
		form.append('isBag', isBag);
		return api.put(`oblationalCuttingGroups/${cuttingGroupId}`, form)
			.then(() => {
				dispatch(SetOblationalLayers());
			});
	}
};

const SaveOblationalSurfaces = (surfaces) => {
	const form = new FormData();
	form.append('surfaces', JSON.stringify(surfaces));
	return api.put(`saveOblationalSurfaces`, form);
};

const SaveCuttingGroup = (surfaces) => {
	return (dispatch) => {
		return SaveOblationalSurfaces(surfaces)
			.then(() => {
				dispatch(SetOblationalLayers())
			})
	}
};

const AddOblationalSurface = (cuttingGroupId, surfaces) => {
	return (dispatch) => {
		return SaveOblationalSurfaces(surfaces)
			.then(() => {
				return api.post(`oblationalCuttingGroups/${cuttingGroupId}/surfaces`)
			})
			.then(() => {
				dispatch(SetOblationalLayers());
			})
	};
};

const DeleteOblationalSurface = (cuttingGroupId, surfaceId, surfaces) => {
	return (dispatch) => {
		return SaveOblationalSurfaces(surfaces)
			.then(() => {
				return api.del(`oblationalCuttingGroups/${cuttingGroupId}/surfaces/${surfaceId}`)
			})
			.then(() => {
				dispatch(SetOblationalLayers());
			})
	};
};

const ChangeOblationalLayerData = (layerId, parameter, value) => {
	return (dispatch) => {
		const form = new FormData();
		form.append(_.toString(parameter), JSON.stringify(value));
		api.put(`layers/${layerId}`, form)
			.then(() => {
				dispatch(SetOblationalLayers());
			});
	}
};

const SetOblationalLayers = () => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		return api.get(`techDocs/${techDocId}`)
			.then((techdoc) => {
				dispatch({
					type: TechDocCutConstants.SET_OBLATIONAL_LAYERS,
					data: techdoc.oblationalCuttingGroups,
				});
				dispatch({
					type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
					data: {
						productionChain: techdoc.productionChain
					}
				});
			})
	}
};

const SetOblationalNotCuttingGroups = (isDemanded) => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		return api.get(`techDocs/${techDocId}`)
			.then((techdoc) => {
				dispatch({
					type: TechDocCutConstants.SET_ONCG,
					data: techdoc.oblationalNotCuttingGroups,
				});
				dispatch({
					type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
					data: {
						productionChain: techdoc.productionChain
					}
				});
				if (isDemanded) {
					dispatch({
						type: TechDocMaterialsAmountConstants.SET_MATERIALS_AMOUNT,
						data: techdoc.materialsAmounts
					});
				}
			})
	}
};

// const AddONCG = (groups) => {
// 	return (dispatch, getState) => {
// 		const techDocId = getState().TechDocs.SelectedTechDoc._id;
// 		return SaveONCG(techDocId, groups)
// 			.then(() => {
// 				return api.post(`techDocs/${techDocId}/notCuttingGroups`)
// 			})
// 			.then(() => {
// 				dispatch(SetOblationalNotCuttingGroups())
// 			})
// 	}
// };
//
// const DeleteONCG = (oncgId, groups) => {
// 	return (dispatch, getState) => {
// 		const techDocId = getState().TechDocs.SelectedTechDoc._id;
// 		return SaveONCG(techDocId, groups)
// 			.then(() => {
// 				return api.del(`techDocs/${techDocId}/notCuttingGroups/${oncgId}`)
// 			})
// 			.then(() => {
// 				dispatch(SetOblationalNotCuttingGroups())
// 			})
// 	}
// };
//
// const SaveONCG = (techDocId, groups, isDemandRequired) => {
// 	const form = new FormData();
// 	form.append('groups', JSON.stringify(groups));
// 	if (isDemandRequired) {
// 		form.append('isDemandRequired', isDemandRequired);
// 	}
// 	return api.put(`techDocs/${techDocId}/saveNotCuttingGroups`, form);
// };

const SaveONCG = (oncgId, parameterTitle, parameterValue) => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const form = new FormData();
		form.append(parameterTitle, JSON.stringify(parameterValue));
		return api.put(`techDocs/${techDocId}/notCuttingGroups/${oncgId}`, form)
			.then(() => {
				dispatch(SetOblationalNotCuttingGroups(true))
			})
			.then(() => {
				dispatch(TechDocMaterialsAmountActions.SetMaterialAmounts());
			})
	}
};

const RotateLayer = (direction) => {
	return (dispatch, getState) => {
		const layerId = getState().Interface.editorSelectedLayer;
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const {
			surfacesList,
			oblationalCuttingGroups
		} = getState().TechDocCut;
		const concattedArmorLayers = _.reduce(surfacesList, (armorLayers, surface) => {
			return _.concat(armorLayers, surface.layers)
		}, []);
		const concattedOblationalLayers = _.reduce(oblationalCuttingGroups, (oblationalLayers, cg) => {
			return _.concat(oblationalLayers, _.reduce(cg.oblationalSurfaces, (surfaceLayers, surface) => {
				return _.concat(surfaceLayers, surface.layers)
			}, []))
		}, []);
		let isOblational = false;
		let oldAngle;
		if (_.some(concattedArmorLayers, layer => {
			return layer._id === layerId
		})) {
			oldAngle = _.find(concattedArmorLayers, al => {
				return al._id === layerId
			}).angle;
		} else {
			oldAngle = _.find(concattedOblationalLayers, ol => {
				return ol._id === layerId
			}).angle;
			isOblational = true;
		}
		const endPointTitle = isOblational ? 'oblationalLayers' : 'layers';
		if (direction === 'left') {
			const form = new FormData();
			form.append('angle', oldAngle - 45);
			api.put(`${endPointTitle}/${layerId}`, form)
				.then(() => {
					return api.get(`techDocs/${techDocId}/surfaces`)
				})
				.then((surfaces) => {
					dispatch ({
						type: TechDocCutConstants.SET_SURFACES,
						data: surfaces,
					});
					dispatch(SetOblationalLayers());
				});
		} else if (direction === 'right') {
			const form = new FormData();
			form.append('angle', oldAngle + 45);
			api.put(`${endPointTitle}/${layerId}`, form)
				.then(() => {
					return api.get(`techDocs/${techDocId}/surfaces`)
				})
				.then((surfaces) => {
					dispatch ({
						type: TechDocCutConstants.SET_SURFACES,
						data: surfaces,
					})
					dispatch(SetOblationalLayers());
				});
		}
	};
};

const ChangeLayerPosition = (x, y, isOblational) => {
	return (dispatch, getState) => {
		const endPointTitle = isOblational ? 'oblationalLayers' : 'layers';
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const layerId = getState().Interface.editorSelectedLayer;
		const form = new FormData();
		const position = {
			x: x,
			y: y,
		};
		form.append('position', JSON.stringify(position));
		api.put(`${endPointTitle}/${layerId}`, form)
			.then(() => {
				return api.get(`techDocs/${techDocId}/surfaces`)
			})
			.then((surfaces) => {
				if (isOblational) {
					dispatch(SetOblationalLayers());
				} else {
					dispatch({
						type: TechDocCutConstants.SET_SURFACES,
						data: surfaces,
					});
				}
			})
	};
};

const AddArmorSurface = () => {
  return (dispatch, getState) => {
    const techDocId = getState().TechDocs.SelectedTechDoc._id;
    api.post(`techDocs/${techDocId}/surfaces`)
      .then((surface) => {
        // dispatch({
        //   type: TechDocCutConstants.SET_ACTIVE_ARMOR_SURFACE,
        //   id: surface._id
        // })
        return api.get(`techDocs/${techDocId}/surfaces`)
      })
      .then((surfaces) => {
        dispatch({
          type: TechDocCutConstants.SET_SURFACES,
          data: surfaces,
        });
      });
  };
};

const DeleteArmorSurface = () => {
  return (dispatch, getState) => {
    const techDocId = getState().TechDocs.SelectedTechDoc._id;
    const activeSurfaceId = getState().TechDocCut.activeSurfaceId;
	  api.del(`techDocs/${techDocId}/surfaces/${activeSurfaceId}`)
      .then(() => {
        dispatch({
          type: TechDocCutConstants.SET_ACTIVE_ARMOR_SURFACE,
          data: -1,
        });
        return api.get(`techDocs/${techDocId}/surfaces`)
      })
      .then((surfaces) => {
        dispatch({
          type: TechDocCutConstants.SET_SURFACES,
          data: surfaces,
        });
        return api.get(`techDocs/${techDocId}`)
      })
		  .then((techdoc) => {
			  dispatch({
				  type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
				  data: {
					  productionChain: techdoc.productionChain
				  }
			  });
		  })
  };
};

const DeleteLayers = (layersDesc, surfaceId) => {
  return (dispatch, getState) => {
	  const techDocId = getState().TechDocs.SelectedTechDoc._id;
		// const activeSurfaceId = getState().TechDocCut.activeSurfaceId;
		
	  api.del(`techDocs/${techDocId}/surfaces/${surfaceId}/layers`, {
	    layersQty: layersDesc.layersCount,
      materialId: layersDesc.materialId,
		  isAngleStraight: layersDesc.fiberDirection === 'straight',
    })
      .then(() => {
        return api.get(`techDocs/${techDocId}/surfaces`)
      })
		  .then((surfaces) => {
			  dispatch({
				  type: TechDocCutConstants.SET_SURFACES,
				  data: surfaces,
			  });
			  return api.get(`techDocs/${techDocId}`)
		  })
		  .then((techdoc) => {
			  dispatch({
				  type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
				  data: {
					  productionChain: techdoc.productionChain
				  }
			  });
		  })
  }
}

const getAngle = (angleKey) => {
  switch (angleKey) {
    case 'notStraight':
			return 'notStraight';

		default:
			return 'straight';
  }
};

const getAngleKey = (angle) => {
	switch (angle) {
		case '0-90':
			return 'straight';

		default:
			return 'notStraight';
	}
};

const AddSurfaceLayers = () => {
  return (dispatch, getState) => {
	  const techDocId = getState().TechDocs.SelectedTechDoc._id;
	  const activeSurfaceId = getState().TechDocCut.activeSurfaceId;
	  const chosenMaterial = getState().NewSurfaceMaterial;

	  const form = new FormData();
	  form.append('layersQty', chosenMaterial.layersCount);
    form.append('materialId', chosenMaterial.selectedMaterial);
	  form.append('fiberDirection', getAngle(chosenMaterial.selectedAngle));
	  api.post(`techDocs/${techDocId}/surfaces/${activeSurfaceId}/layers`, form)
      .then(() => {
        return api.get(`techDocs/${techDocId}/surfaces`)
      })
      .then((surfaces) => {
	      dispatch({
		      type: TechDocCutConstants.SET_SURFACES,
		      data: surfaces,
	      });
	      return api.get(`techDocs/${techDocId}`)
      })
		  .then((techdoc) => {
			  dispatch({
				  type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
				  data: {
					  productionChain: techdoc.productionChain
				  }
			  });
      })
  }
};

const ChangeLayersCount = (surfaceId, materialId, fiberDirection, count) => {
	return(dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const {
			surfacesList
		} = getState().TechDocCut;
		const floorCount = count > 0 ? _.floor(count) : 0;

		const oldChosenMaterialLayersCount = _.chain(surfacesList)
			.find({'_id': surfaceId})
			.get('layers')
			.filter((layer) => {
				return layer.material._id === materialId && layer.fiberDirection === fiberDirection
			})
			.size()
			.value()

			return Promise.resolve()
				.then(() => {
					if (oldChosenMaterialLayersCount < floorCount ) {
						const form = new FormData();
						form.append('layersQty', Math.abs(floorCount - oldChosenMaterialLayersCount));
						form.append('materialId', materialId);
						form.append('fiberDirection',getAngleKey(fiberDirection));
						return api.post(`techDocs/${techDocId}/surfaces/${surfaceId}/layers`, form)
					}
					else if (oldChosenMaterialLayersCount > floorCount) {
					  return api.del(`techDocs/${techDocId}/surfaces/${surfaceId}/layers`, {
							layersQty: Math.abs(floorCount - oldChosenMaterialLayersCount),
							materialId: materialId,
							isAngleStraight: fiberDirection === '0-90',
						})
					}
					return;
				})
				.then(() => {
					return api.get(`techDocs/${techDocId}/surfaces`)
				})
				.then((surfaces) => {
					dispatch({
						type: TechDocCutConstants.SET_SURFACES,
						data: surfaces,
					});
					return api.get(`techDocs/${techDocId}`)
				})
				.then((techdoc) => {
					dispatch({
						type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
						data: {
							productionChain: techdoc.productionChain
						}
					});
				})
	}
}

const SetActiveArmorSurface = (surfaceId) => {
  return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const selectedSurface = getState().TechDocCut.activeSurfaceId;
		if (surfaceId === selectedSurface || getState().Interface.isColorPickerOpened) {
			return;
		}
		else if ( surfaceId === -1 ){
			dispatch({
				type: TechDocCutConstants.SET_ACTIVE_ARMOR_SURFACE,
				data: surfaceId,
			})
		}
		else {
			const form = new FormData();
			form.append('surfaceId', surfaceId);
			return api.post(`techDocs/${techDocId}/docker/select`, form)
			  .then(() => {
				  dispatch({
					  type: TechDocCutConstants.SET_ACTIVE_ARMOR_SURFACE,
					  data: surfaceId,
				  });
				})
				.catch((error) => {
					dispatch({
						type: TechDocCutConstants.SET_ACTIVE_ARMOR_SURFACE,
						data: surfaceId,
					});
					dispatch({
						type: HTTPConstants.SET_WARNING,
						data: {
							warningText: 'В поверхности нет граней',
							warningCode: error.code,
						}
					})
				})
		}
  };
};

const SetSurfaceWidth = (newWidth) => {
  return (dispatch, getState) => {
	  const techDocId = getState().TechDocs.SelectedTechDoc._id;
	  const {
		  activeSurfaceId,
	  } = getState().TechDocCut;
	  const form = new FormData();
	  form.append('width', JSON.stringify(newWidth));
	  api.put(`techDocs/${techDocId}/surfaces/${activeSurfaceId}`, form)
		  .then(() => {
			  return api.get(`techDocs/${techDocId}/surfaces`)
		  })
		  .then((surfaces) => {
			  dispatch({
				  type: TechDocCutConstants.SET_SURFACES,
				  data: surfaces,
			  })
		  });
  }
};

const SetSurfaceHeight = (newHeight) => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const {
			activeSurfaceId,
		} = getState().TechDocCut;
		const form = new FormData();
		form.append('height', JSON.stringify(newHeight));
		api.put(`techDocs/${techDocId}/surfaces/${activeSurfaceId}`, form)
			.then(() => {
				return api.get(`techDocs/${techDocId}/surfaces`)
			})
			.then((surfaces) => {
				dispatch({
					type: TechDocCutConstants.SET_SURFACES,
					data: surfaces,
				});
			});
	}
}

const ChangeSurfaceColor = () => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const color = JSON.stringify(getState().Interface.selectedColor);
		const {
			activeSurfaceId,
		} = getState().TechDocCut;
		const form = new FormData();
		form.append('color', color);
		api.put(`techDocs/${techDocId}/surfaces/${activeSurfaceId}`, form)
			.then(() => {
				return api.get(`techDocs/${techDocId}/surfaces`)
			})
			.then((surfaces) => {
				dispatch({
					type: TechDocCutConstants.SET_SURFACES,
					data: surfaces,
				});
				return api.get(`techDocs/${techDocId}`)
			})
			.then((techdoc) => {
				dispatch({
					type: ProductionChainConstants.SET_INITIAL_PRODUCTION_CHAIN,
					data: {
						productionChain: techdoc.productionChain
					}
				});
			});
	}
};

const ConnectSurfaceWithEdges = () => {
	return (dispatch, getState) => {
		const techDocId = getState().TechDocs.SelectedTechDoc._id;
		const {
			activeSurfaceId,
		} = getState().TechDocCut;
		const form = new FormData();
		form.append('surfaceId', activeSurfaceId);
		api.post(`techDocs/${techDocId}/docker/color`, form)
			.then(() => {
				return api.get(`techDocs/${techDocId}/surfaces`)
			})
			.then((surfaces) => {
				dispatch({
					type: TechDocCutConstants.SET_SURFACES,
					data: surfaces,
				})
			});
	}
}

export default {
  AddArmorSurface,
  DeleteArmorSurface,
  SetActiveArmorSurface,

  SetSurfaceWidth,
  SetSurfaceHeight,

  AddSurfaceLayers,

  DeleteLayers,

	ChangeLayersCount,

	ChangeLayerPosition,

	ChangeSurfaceColor,

	ConnectSurfaceWithEdges,

	RotateLayer,

	ChangeOblationalLayerData,

	ChangeCuttingGroupMaterial,

	AddOblationalSurface,

	DeleteOblationalSurface,

	SaveCuttingGroup,

	SetCuttingGroupIsBag,

	SaveONCG,
};
