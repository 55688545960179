import React, { Component, Fragment } from 'react';

import {EvacuationMembrane, OblationalMaterialSizes} from "../_NewDesignComponents/OblationalMaterials";

export default class OblationalSurface extends Component {
	render() {
		const {
			surface,
			surfaceIndex,
			groupId,
			isVacuum,
			isBag,
			cutGroupId
		} = this.props;
		const {
			_id
		} = surface;
		return (
			<Fragment>
				{!isVacuum ? <OblationalMaterialSizes
					onClickDelete={() => this.props.DeleteSurfaceFromGroup(groupId, _id)}
					onChangeCount={(event) => this.props.ChangeSurfaceParam(surfaceIndex, 'layersCount', event.target.value)}
					onChangeLength={(event) => this.props.ChangeSurfaceParam(surfaceIndex, 'height', event.target.value)}
					onChangeWidth={(event) => this.props.ChangeSurfaceParam(surfaceIndex, 'width', event.target.value)}
					width={surface.width}
					length={surface.height}
					count={surface.layersCount}
				/> : <EvacuationMembrane
					onChangeLength={(event) => this.props.ChangeSurfaceParam(surfaceIndex, 'height', event.target.value)}
					onChangeWidth={(event) => this.props.ChangeSurfaceParam(surfaceIndex, 'width', event.target.value)}
					length={surface.height}
					width={surface.width}
					isBag={isBag}
					onChangeBag={(event) => this.props.SetCuttingGroupIsBag(cutGroupId, event.target.checked)}
				/>}
			</Fragment>
		)
	}
};
