import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
	Select,
	MenuItem,
	Typography,
	IconButton,
	TextField,
} from '@material-ui/core';

import {
	Remove,
} from '@material-ui/icons';

export default class OblationalNCGroup extends PureComponent {
	render() {
		const {
			ncg,
			oblationalNotCutMaterials,
			oncgIndex
		} = this.props;
		const {
			_id,
			material,
			count,
			length,
		} = ncg;
		return(
			<div
				style={_.assign({
					display: 'flex',
					flexDirection: 'column',
					padding: '24px 0',
				}, oncgIndex === 0 ? {  } : { borderTop: '1px solid gray' })}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}
				>
					<IconButton
						style={{ marginRight: 12 }}
						onClick={() => this.props.DeleteONCGById(_id)}
					>
						<Remove/>
					</IconButton>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							width: '100%'
						}}
					>
						<Typography
							variant='caption'
						>
							Выбор материала
						</Typography>
						<Select
							fullWidth
							value={!_.isUndefined(material) ? material : ''}
							onChange={(event) => this.props.ChangeONCGByIndex(oncgIndex, 'material', event.target.value)}
						>
							{_.map(oblationalNotCutMaterials, oncm => {
								return <MenuItem
									key={oncm._id}
									value={oncm._id}
								>
									{oncm.title}
								</MenuItem>
							})}
						</Select>
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginTop: 12,
						flex: 1,
					}}
				>
					<TextField
						label='Количество, шт.'
						value={count}
						type='number'
						onChange={(event) => this.props.ChangeONCGByIndex(oncgIndex, 'count', event.target.value)}
						style={{
							marginRight: 24
						}}
					/>
					<TextField
						label='Длина, м. п.'
						value={length}
						type='number'
						onChange={(event) => this.props.ChangeONCGByIndex(oncgIndex, 'length', event.target.value)}
					/>
				</div>
			</div>
		)
	}
}