import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
  Typography,
	IconButton,
} from '@material-ui/core';

import {
	Add,
} from '@material-ui/icons';

import ComplementaryRule from './ComplementaryRule';

class ResinMaterialsDescription extends PureComponent {
	ChangeRuleByIndex = (index, paramTitle, paramValue) => {
		let newCR = this.props.complementaryRules;
		newCR[index][paramTitle] = paramValue;
		this.props.SetComplementaryRules(newCR);
	};

	DeleteRuleByIndex = (index) => {
		const newCR = _.reject(this.props.complementaryRules, (cr, crId) => {
			return crId === index;
		});
		this.props.SetComplementaryRules(newCR);
	};

  render() {
    const {
		  hardenerMaterials,
	    complementaryRules,
    } = this.props;
    return(
        <div>
	        <div
		        style={{
		        	display: 'flex',
			        flexDirection: 'row',
			        alignItems: 'center'
		        }}
	        >
		        <Typography
			        variant='subheading'
		        >
							МАТЕРИАЛ ДЛЯ СМЕШИВАНИЯ
		        </Typography>
		        <IconButton
			        onClick={() => this.props.SetComplementaryRules(_.concat(complementaryRules, {
			        	material: '',
				        proportion: 50,
								lifeCycle: 0,
				        resinGraphs: [],
			        }))}
		        >
			        <Add/>
		        </IconButton>
	        </div>
	        {_.map(complementaryRules, (rule, ruleIndex) => {
		        return <ComplementaryRule
			        key={ruleIndex + rule.proportion + rule.lifeCycle + rule.material}
			        rule={rule}
			        ruleIndex={ruleIndex}
			        hardenerMaterials={_.reject(hardenerMaterials, hm => {
			        	return _.some(complementaryRules, cr => {
			        		return cr.material === hm._id && rule.material !== hm._id
				        })
			        })}
			        ChangeRuleByIndex={this.ChangeRuleByIndex.bind(this)}
			        DeleteRuleByIndex={this.DeleteRuleByIndex.bind(this)}
		        />
	        })}
	        {/*<PolymerizationDiagramAdding*/}
		        {/*data={data}*/}
		        {/*ChangeMaterialData={this.props.ChangeMaterialData}*/}
	        {/*/>*/}
        </div>
    )
  }
};

export default ResinMaterialsDescription;
