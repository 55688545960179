import {
  TechDocMaterialsConstants
} from '../Constants';

const initState = {
  // loadedMaterials: AllMaterials,
  loadedMaterials: [],
  separativeLayers:[
    {
      id: '121sad',
      title: 'Воск',
      timeBetweenLayers: 20,
    },
    {
      id:'sda2',
      title: 'Freekote 77',
      timeBetweenLayers: 5,
    }
  ],
  sprays:[
    {
      id: '123sad',
      title: 'Airtac 2'
    },
    {
      id: '12adas',
      title: 'Spray'
    }
  ],
};

const SET_MATERIALS = (newState, oldState) => {
  return {
    ...oldState,
    loadedMaterials: newState,
  }
}

export default (state = initState, action) => {
  switch(action.type){
    case TechDocMaterialsConstants.SET_MATERIALS:
      return SET_MATERIALS(action.data, state);

    default:
      return state;
  }
}