import React, { Component, Fragment } from 'react';

import _ from 'lodash';

import {
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';

// const styles = {
//   card: {
//     margin: 12,
//     backgroundColor: '#7cb342'
//   }
// }

class WorkersList extends Component {

  getHHMM(minutes){
    const newHours = Math.trunc(minutes / 60) < 10 ? '0' + Math.trunc(minutes / 60) : Math.trunc(minutes / 60) ;
    const newMinutes = minutes % 60 < 10 ? '0' + minutes % 60 : minutes % 60;
    return (newHours +":"+ newMinutes);
  };

  render(){
    const {
      workerBusyness,
      textColor,
      userPlan
    } = this.props;
    return(
      <Card
        style={{margin: 12}}
      >
        <CardHeader
          style={{borderBottom:'1px solid black',}}
          title={
            <div
              style={{paddingBottom:6, paddingTop: 6}}
            >
              <Typography
                variant='title'
                color={textColor}
              >
                <b>{workerBusyness}</b>
              </Typography>
            </div>
          }
        />
        <CardContent
          style={{
            display: 'flex',
            flexDirection:'column',
            overflowY: 'auto',
          }}
        >
          <div
            key={userPlan.user._id}
            style={{padding: 6}}
          >
            <Card>
              <CardContent>
                <div 
                  style={{display:'flex', flexDirection:'row',}}
                >
                  <div
                    style={{flex:2}}
                  >
                  <Typography
                    variant='subheading'
                  >
                    {`${_.capitalize(userPlan.user.first_name)} ${_.capitalize(userPlan.user.second_name)}` }
                  </Typography>
                  {workerBusyness === 'Назначеные' &&
                    <Fragment>
                      <Typography
                        variant='subheading'
                      >
                        Нагрузка на день <b>{this.getHHMM(_.sumBy(userPlan.tasks, (task) => {
                          return !_.isUndefined(task.stage) ? task.stage.durationMinutes : 0
                        } ))}</b>
                      </Typography>
                      <Typography
                        variant='subheading'
                      >
                        Стоимость работ <b>{!_.isNaN(userPlan.user.rate) ? _.floor(userPlan.user.rate * _.sumBy(userPlan.tasks, (task) => {
                          return !_.isUndefined(task.stage) ? task.stage.durationMinutes : 0 } ) / 60) : 0}</b> ₽
                      </Typography>
                    </Fragment>
                  }
                  </div>
                  <div
                    style={{display:'flex', flexDirection:'column', flex: 3}}
                  >
                    {_.map(userPlan.tasks, (task, taskId) => {
                      return <div
                        key={taskId}
                        style={{borderBottom: '1px solid gray', marginBottom: '12px',paddingBottom: '6px'}}
                      >
                        <Typography
                          variant='subheading'
                        >
                          <b>{task.title}</b>
                        </Typography>
                        <Typography
                          variant='subheading'
                        >
                          Время выполнения <b>{task.stage ? this.getHHMM(task.stage.durationMinutes) : 'не назначено'}</b>
                        </Typography>
                        <Typography
                          variant='subheading'
                        >
                          Проект <b>{task.project.title}</b>
                        </Typography>
                      </div>
                    })
                    }
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </CardContent>
      </Card>
    )
  }

}

export default WorkersList;
