import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import {
  connect,
} from 'react-redux';

import {
  AnalyticsActions
} from '../../Redux/Actions';

import {
  OutlinedTable
} from '../DumbComponents/_NewDesignComponents';

import {
  InReviewTaskCard,
  WorkersList,
  PlanningTable,
  StatesList
} from '../DumbComponents';

import {
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import api from '../../Utils/API'

const styles = {
  title: {
    margin: 32
  }
};

const screens = ['Учет NFC', 'Планирование', 'Ожидают ОТК', 'Рабочий процесс']

class AnalyticsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedScreen: 'Учет NFC',
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.props.GetTasksInQC, 10000);
    this.props.GetTasksInQC();
    this.props.GetPlanning();
    this.props.GetNFCStatusList();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {
      TasksInRewiew,
      Workers,
      Plan,
      UserPlan,
      statesList,
      nfcStatusList
    } = this.props;
    const {
      selectedScreen
    } = this.state;
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', overflowY: 'auto', }}
      >
        <div>
          <FormControl
            style={{ margin: '20px 0px 0px 20px', width: '200px' }}
          >
            <InputLabel>Раздел</InputLabel>
            <Select
              value={selectedScreen}
              onChange={(event) => {
                this.setState({
                  selectedScreen: event.target.value
                });
              }}
            >
              {_.map(screens, (screen) => {
                return (
                  <MenuItem
                    key={screen}
                    value={screen}
                  >
                    {screen}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        {selectedScreen === 'Учет NFC' &&
          <Fragment>
            <Typography
              style={styles.title}
              variant='title'
            >
              Учет NFC
            </Typography>
            <OutlinedTable>
              <TableHead>
                <TableRow>
                  <TableCell>
                    №
                  </TableCell>
                  <TableCell>
                    NFC
                  </TableCell>
                  <TableCell>
                    Проект
                  </TableCell>
                  <TableCell>
                    Номер реализации
                  </TableCell>
                  <TableCell>
                    Статус
                  </TableCell>
                  <TableCell>
                    Ссылка
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(nfcStatusList, (nfcStatus, index) => {
                  return (
                    <TableRow
                      key={nfcStatus._id}
                    >
                      <TableCell>
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        {nfcStatus.nfc}
                      </TableCell>
                      <TableCell>
                        {nfcStatus.projectTitle}
                      </TableCell>
                      <TableCell>
                        {nfcStatus.order}
                      </TableCell>
                      <TableCell>
                        {!nfcStatus.isFailed ? `${nfcStatus.status} %` : 'БРАК'}
                      </TableCell>
                      <TableCell>
                        <a
                          href={`${api.getHost()}/project/${nfcStatus.projectId}/${nfcStatus._id}`}
                        >
                          Перейти
                        </a>
                      </TableCell>
                    </TableRow>
                  )
                })

                }
              </TableBody>
            </OutlinedTable>
          </Fragment>
        }
        {selectedScreen === 'Планирование' &&
          <Fragment>
            <Typography
              style={styles.title}
              variant='title'
            >
              Занятость работников
            </Typography>
            <PlanningTable
              plan={Plan}
              GetPlanningByUserDate={this.props.GetPlanningByUserDate.bind(this)}
            />
            {!_.isEmpty(UserPlan) &&
              <div
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Grid
                  container
                  style={{ width: '100%' }}
                >
                  <Grid
                    item
                    sm={6}
                  >
                    <WorkersList
                      workers={Workers.assignedWorkers}
                      workerBusyness='Назначеные'
                      textColor={'primary'}
                      userPlan={UserPlan}
                    />
                  </Grid>
                </Grid>
              </div>
            }
          </Fragment>
        }
        {selectedScreen === 'Ожидают ОТК' &&
          <div
            style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}
          >
            <Typography
              style={styles.title}
              variant='title'
            >
              Ожидают проведения ОТК
            </Typography>
            <Grid
              container
              style={{ width: '100%' }}
            >
              {_.map(TasksInRewiew, task => {
                return (
                  <Grid
                    item
                    sm={3}
                    key={task._id}
                  >
                    <InReviewTaskCard
                      task={task}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        }
        {selectedScreen === 'Рабочий процесс' &&
          <StatesList
            statesList={statesList}
            GetProductionActualInfo={this.props.GetProductionActualInfo.bind(this)}
            SelectOverdueTask={this.props.SelectOverdueTask.bind(this)}
          />
        }
      </div>
    );
  }
}

export default connect(
  state => ({
    TasksInRewiew: state.Analytics.tasksInQC,
    Workers: state.Analytics.workers,
    Plan: state.Analytics.plan,
    UserPlan: state.Analytics.userPlan,
    statesList: state.Analytics.statesList,
    nfcStatusList: state.Analytics.nfcStatusList
  }),
  dispatch => ({
    GetNFCStatusList: () => {
      dispatch(AnalyticsActions.GetNFCStatusList());
    },
    SelectOverdueTask: (projectId, realizationId) => {
      dispatch(AnalyticsActions.SelectOverdueTask(projectId, realizationId))
    },
    GetProductionActualInfo: () => {
      dispatch(AnalyticsActions.GetProductionActualInfo());
    },
    GetPlanningByUserDate: (date, user) => {
      dispatch(AnalyticsActions.GetPlanningByUserDate(date, user));
    },
    GetPlanning: () => {
      dispatch(AnalyticsActions.GetPlanning());
    },
    GetTasksInQC: () => {
      dispatch(AnalyticsActions.GetTasksInQC());
    },
    GetBusyWorkers: () => {
      dispatch(AnalyticsActions.GetBusyWorkers());
    }
  })
)(AnalyticsScreen);