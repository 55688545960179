import React, {Component} from 'react';

import _ from 'lodash';

import LayersFacingInfo from './LayersFacingInfo';
import LayersFacingDescription from './LayersFacingDescription';
import {
  CardContent
} from '@material-ui/core';

import ProjectAttachments from '../ProjectAttachments';

class LayersFacing extends Component {
  render(){
    const {
      stage
    } = this.props;
    return(
      <CardContent
      >
        <LayersFacingInfo
          layers={stage.layers}
        />
        <LayersFacingDescription
          data={!_.isUndefined(stage.data) ? stage.data : {}}
          textTemplate={stage.textTemplate}
          additionalDescription={stage.additionalDescription}

        />
        <ProjectAttachments
          attachmentsList={stage.attachmentsList}
        />
      </CardContent>
    );
  }
}

export default LayersFacing;
