import React, { Component } from 'react';

import {
  COLORS
} from '../../../Styles/AppColors'

// const styles = {
//   timeDurationAndPrice: {
//     marginRight:'12px',
//   }
// }

import {
  RemoveIcon
} from '../_NewDesignComponents'

class TaskTemplateHead extends Component {
  render() {
    const {
      title,
      timeDuration,
      isOpen,
      techDocType,

      stageId
    } = this.props;
    return (
      <div
        className='headTaskTemplate'
      >
        {techDocType === 'assembly' &&
          <RemoveIcon
            onClick={(e) => {
              this.props.selectTaskForRemove(stageId);
              e.stopPropagation();
            }}
          />
        }
        <span
          style={isOpen ?
            {
              fontFamily: 'Roboto',
              fontSize: 16,
              lineHeight: '20px',
              color: COLORS.TEXT_COLOR,
              fontWeight: 500,
              letterSpacing: -0.3,
              flex: 5
            }
            :
            {
              fontFamily: 'Roboto',
              fontSize: 14,
              lineHeight: '16px',
              color: COLORS.TEXT_COLOR,
              letterSpacing: -0.2,
              flex: 5
            }
          }
        >
          {title}
        </span>
        <div
          className='timeDurationAndPrice'
        >
          <span
            style={{
              fontFamily: 'Roboto',
              fontSize: 14,
              lineHeight: '16px',
              color: COLORS.TEXT_COLOR,
              letterSpacing: -0.2,
              fontWeight: 300,
            }}
          >
            t = {timeDuration !== 0 ? `${Math.floor(timeDuration / 60).toString()} ч ${(timeDuration % 60).toString()} мин` : `0 ч`}
          </span>

          {/* <Typography
              variant='subheading'
            >
              ₽=<b>{isNaN(price) ? 0 : price}</b>
            </Typography> */}
        </div>
      </div>
    );
  }
}

export default TaskTemplateHead;
