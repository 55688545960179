import {
	InterfaceConstants,
  ProjectsConstants,
  UsersConstants,
} from '../Constants';

const OpenMaterialDialog = () => {
  return {
    type: InterfaceConstants.OPEN_MATERIAL_DIALOG
  }
}

const CloseMaterialDialog = () => {
  return {
    type: InterfaceConstants.CLOSE_MATERIAL_DIALOG
  }
}

const OpenSetNFCRealizationDialog = () => {
  return {
    type: InterfaceConstants.OPEN_SET_NFC_REALIZATION_DIALOG
  }
}

const CloseSetNFCRealizationDialog = () => {
  return {
    type: InterfaceConstants.CLOSE_SET_NFC_REALIZATION_DIALOG

  }
}

const OpenAreYouSureDialog = () => {
  return {
    type: InterfaceConstants.OPEN_ARE_YOU_SURE_DIALOG
  }
}

const CloseAreYouSureDialog = () => {
  return {
    type: InterfaceConstants.CLOSE_ARE_YOU_SURE_DIALOG
  }
};

const OpenAddingUserDialog = () => {
  return {
    type: InterfaceConstants.OPEN_ADDING_USER_DIALOG
  };
};

const CloseAddingUserDialog = () => {
  return (dispatch) => {
    return Promise.resolve()
    .then(() => {
      dispatch({
        type: InterfaceConstants.CLOSE_ADDING_USER_DIALOG,
      })
    })
    .then(() => {
      dispatch({
        type: UsersConstants.CLEAN_USER_DATA,
      })
    })
  };
};

const OpenOblationalMaterialAddingDialog = () => {
  return {
    type: InterfaceConstants.OPEN_OBLATIONAL_MATERIAL_ADDING_DIALOG,
  };
};

const CloseOblationalMaterialAddingDialog = () => {
  return {
    type: InterfaceConstants.CLOSE_OBLATIONAL_MATERIAL_ADDING_DIALOG,
  }
}

const OpenResinAddingDialog = () => {
  return {
    type: InterfaceConstants.OPEN_RESIN_ADDING_DIALOG,
  };
};

const CloseResinAddingDialog = () => {
  return {
    type: InterfaceConstants.CLOSE_RESIN_ADDING_DIALOG,
  };
};

const OpenCreatingNewProjectDialog = () => {
  return {
    type: InterfaceConstants.OPEN_CREATING_NEW_PROJECT_DIALOG,
  };
};

const CloseCreatingNewProjectDialog = () => {
  return dispatch => {
    dispatch({
	    type: InterfaceConstants.CLOSE_CREATING_NEW_PROJECT_DIALOG,
    });
    dispatch({
      type: ProjectsConstants.CLEAN_NEW_PROJECT_DATA,
    })
  };
};

const OpenCutEditor = () => {
  return {
    type: InterfaceConstants.OPEN_CUT_EDITOR,
  };
};

const CloseCutEditor = () => {
  return {
    type: InterfaceConstants.CLOSE_CUT_EDITOR,
  };
};

const OpenCreatingProductDialog = () => {
  return {
    type: InterfaceConstants.OPEN_CREATING_NEW_PRODUCT_DIALOG,
  }
};

const CloseCreatingProductDialog = () => {
  return {
    type: InterfaceConstants.CLOSE_CREATING_NEW_PRODUCT_DIALOG,
  }
}

const CloseEditQCCriterionDialog = () => {
  return {
    type: InterfaceConstants.CLOSE_EDIT_QC_CRITERION_DIALOG,
  };
};

const OpenEditQCCriterionDialog = () => {
  return {
    type: InterfaceConstants.OPEN_EDIT_QC_CRITERION_DIALOG,
  };
};

const CloseAddingQCCriterionDialog = () => {
  return {
    type: InterfaceConstants.CLOSE_ADDING_QC_CRITERION_DIALOG,
  };
};

const OpenAddingQCCriterionDialog = () => {
  return {
    type: InterfaceConstants.OPEN_ADDING_QC_CRITERION_DIALOG,
  };
};

const CloseGraphicsEditor = () => {
  return {
    type: InterfaceConstants.CLOSE_GRAPHICS_EDITOR
  }
};

const OpenGraphicsEditor = () => {
  return {
    type: InterfaceConstants.OPEN_GRAPHICS_EDITOR
  }
};

const ChangeStepperOpen = (isOpen) => {
  return {
    type: InterfaceConstants.CHANGE_STEPPER_OPEN,
    data: isOpen,
  };
};

const SetActiveStep = (newActiveStep) => {
  return {
    type: InterfaceConstants.SET_ACTIVE_STEP,
    data: newActiveStep,
  };
};

const OpenCreatingTechDocDialog = () =>  {
  return {
    type: InterfaceConstants.OPEN_CREATING_TECHDOC_DIALOG,
  };
};

const CloseCreatingTechDocDialog = () =>  {
  return {
    type: InterfaceConstants.CLOSE_CREATING_TECHDOC_DIALOG,
  };
};

const OpenAddingSurfaceMaterialDialog = () => {
  return {
    type: InterfaceConstants.OPEN_ADDING_SURFACE_MATERIAL_DIALOG,
  };
};

const CloseAddingSurfaceMaterialDialog = () => {
  return {
    type: InterfaceConstants.CLOSE_ADDING_SURFACE_MATERIAL_DIALOG,
  };
};

const OpenColorPicker = () => {
  return {
    type: InterfaceConstants.OPEN_COLOR_PICKER,
  }
};

const CloseColorPicker = () => {
  return {
    type: InterfaceConstants.CLOSE_COLOR_PICKER,
  };
};

const ChangeSelectedColor = (newColor) => {
  return {
    type: InterfaceConstants.CHANGE_SELECTED_COLOR,
    data: newColor,
  }
};

const OpenChangingSurfaceMaterialDialog = () => {
  return {
    type: InterfaceConstants.OPEN_CHANGING_SURFACE_MATERIAL_DIALOG,
  };
};

const CloseChangingSurfaceMaterialDialog = () => {
  return {
    type: InterfaceConstants.CLOSE_CHANGING_SURFACE_MATERIAL_DIALOG,
  };
};

const ChangeEditorSelectedMaterial = (newMaterial) => {
  return {
    type: InterfaceConstants.CHANGE_EDITOR_SELECTED_MATERIAL,
    data: newMaterial,
  }
};

const ChangeEditorSelectedLayer = (newLayer) => {
  return {
    type: InterfaceConstants.CHANGE_EDITOR_SELECTED_LAYER,
    data: newLayer,
  }
};

const SetCurrentImage = (newImage) => {
  return {
    type: InterfaceConstants.SET_CURRENT_IMAGE,
    data: newImage,
  }
};

const OpenCreatingNewSubProjectDialog = () => {
  return {
    type: InterfaceConstants.OPEN_CREATING_NEW_SUB_PROJECT_DIALOG,
  }
};

const CloseCreatingNewSubProjectDialog = () => {
  return {
    type: InterfaceConstants.CLOSE_CREATING_NEW_SUB_PROJECT_DIALOG,
  }
};

export default {
  ChangeStepperOpen,
  SetActiveStep,

  OpenCreatingTechDocDialog,
  CloseCreatingTechDocDialog,

  CloseGraphicsEditor,
  OpenGraphicsEditor,

  OpenAddingSurfaceMaterialDialog,
  CloseAddingSurfaceMaterialDialog,

  OpenAddingQCCriterionDialog,
  CloseAddingQCCriterionDialog,

  OpenEditQCCriterionDialog,
  CloseEditQCCriterionDialog,

  OpenCreatingProductDialog,
  CloseCreatingProductDialog,

  OpenColorPicker,
  CloseColorPicker,
  ChangeSelectedColor,

  OpenCutEditor,
  CloseCutEditor,

  OpenChangingSurfaceMaterialDialog,
  CloseChangingSurfaceMaterialDialog,

	ChangeEditorSelectedMaterial,
  ChangeEditorSelectedLayer,

  SetCurrentImage,
  
  CloseCreatingNewProjectDialog,
  OpenCreatingNewProjectDialog,

  OpenResinAddingDialog,
  CloseResinAddingDialog,

  OpenCreatingNewSubProjectDialog,
  CloseCreatingNewSubProjectDialog,

	OpenOblationalMaterialAddingDialog,
  CloseOblationalMaterialAddingDialog,
  
  OpenAddingUserDialog,
  CloseAddingUserDialog,

  OpenAreYouSureDialog,
  CloseAreYouSureDialog,

  OpenMaterialDialog,
  CloseMaterialDialog,

  OpenSetNFCRealizationDialog,
  CloseSetNFCRealizationDialog
};
