import React, {Component} from 'react';

import {
  TableCell,
  TableBody,
  TableRow,
  Typography,
  IconButton,
} from '@material-ui/core';

import {
  Remove,
} from '@material-ui/icons'

import _ from 'lodash';

class BodyOblationalMaterialsInfo extends Component{
  render(){
    const {
      oblationalMaterials,
    } = this.props;
    return(
      <TableBody>
        {_.map(oblationalMaterials, (oblationalMaterial, materialId) => {
          return(
	          <TableRow
		          key={oblationalMaterial.material._id}
	          >
		          <TableCell>
			          {materialId + 1}
		          </TableCell>
		          <TableCell>
			          <Typography
				          variant='body1'
			          >
				          {oblationalMaterial.material.title}
			          </Typography>
		          </TableCell>
		          <TableCell>
			          <Typography
				          variant='body1'
			          >
				          { `${_.floor(oblationalMaterial.amount * 100) / 100} ${oblationalMaterial.material.category.measureUnits}`}
			          </Typography>
		          </TableCell>
		          <TableCell>
			          <Typography
				          variant='body1'
			          >
				          {_.floor(oblationalMaterial.material.price * oblationalMaterial.amount, 2)} ₽
			          </Typography>
		          </TableCell>
              <TableCell>
                <IconButton
                  disabled={_.some(oblationalMaterial.material.categories, category => {
                    return category.title === 'Жертвенные материалы требующие раскроя' || category.title === 'Жертвенные материалы не требующие раскроя'
                  })}
                  onClick={() => this.props.DeleteMaterial(oblationalMaterial.material._id)}
                >
                  <Remove/>
                </IconButton>
              </TableCell>
	          </TableRow>
          )
        })
        }
      </TableBody>
    );
  }
}

export default BodyOblationalMaterialsInfo;
