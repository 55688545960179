import React, { PureComponent } from 'react';

import _ from 'lodash';

import OblationalMaterialCutInfo from './OblationalMaterialCutInfo';
import {MaterialPreparingHeader} from "../_NewDesignComponents";

// const styles = {
// 	oblationalCutTitleHeader: {
// 		marginLeft: 12,
// 		marginTop: 24,
// 		marginBottom: 24,
// 	}
// };

class OblationalMaterialsCut extends PureComponent {
	render() {
		const {
			oblationalCuttingGroups,
			oblationalCutMaterials
		} = this.props;
		return(
			<div>
				<MaterialPreparingHeader
					materialTypeTitle={'Жертвенные слои'}
					materialTypeDescription={'Задайте размеры и материалы жертвенных слоёв'}
				/>
				<div>
					{_.map(_.sortBy(oblationalCuttingGroups, 'cg.groupTag.needsCutting', 'cg.groupTag.order'), (cutGroup) => {
						return <OblationalMaterialCutInfo
								key={cutGroup._id}
								cutGroup={cutGroup}
								filteredOblationalCutMaterials={_.filter(oblationalCutMaterials, material => {
									return material.groupTag._id === cutGroup.groupTag._id
								})}
								ChangeOblationalLayerData={this.props.ChangeOblationalLayerData}

								ChangeCuttingGroupMaterial={this.props.ChangeCuttingGroupMaterial}
								AddOblationalSurface={this.props.AddOblationalSurface}
								DeleteOblationalSurface={this.props.DeleteOblationalSurface}
								SaveCuttingGroup={this.props.SaveCuttingGroup}
								SetCuttingGroupIsBag={this.props.SetCuttingGroupIsBag}

								SaveONCG={this.props.SaveONCG}
							/>
					})}
				</div>
			</div>
		)
	}
};

export default OblationalMaterialsCut;