const ProjectsConstants = {
  CREATE_NEW_PROJECT: 'CREATE_NEW_PROJECT',
  CREATE_NEW_SUB_PROJECT: 'CREATE_NEW_SUB_PROJECT',

  CHANGE_NEW_PROJECT_DATA: 'CHANGE_NEW_PROJECT_DATA',
  CLEAN_NEW_PROJECT_DATA:'CLEAN_NEW_PROJECT_DATA',

	SET_PROJECTS: 'SET_PROJECTS',

	SET_TECHDOCS: 'SET_TECHDOCS',

	SET_REALIZATIONS: 'SET_REALIZATIONS',
	SET_CURRENT_PROJECT: 'SET_CURRENT_PROJECT',
	CLEAN_CURRENT_PROJECT: 'CLEAN_CURRENT_PROJECT',
	CLEAN_CURRENT_REALIZATION:'CLEAN_CURRENT_REALIZATION',

	SET_CURRENT_REALIZATION: 'SET_CURRENT_REALIZATION',
	SET_PS_TEMPLATES: 'SET_PS_TEMPLATES',

	SET_TASK: 'SET_TASK',
	SET_TASK_USERS: 'SET_TASK_USERS',

	CLEAN_PROJECTS: 'CLEAN_PROJECTS',

	SET_MATERIALS_ENOUGH: 'SET_MATERIALS_ENOUGH',
	SET_MATERIALS_NOT_ENOUGH: 'SET_MATERIALS_NOT_ENOUGH',
	GET_PRODUCTS: 'GET_PRODUCTS',
	SET_PRODUCT: 'SET_PRODUCT',
	GET_TASK_EXECUTORS_PALNE: 'GET_TASK_EXECUTORS_PALNE',
	NFC_PROBLEM: 'NFC_PROBLEM',
	CLOSE_SNACKBAR_NFC_PROBLEM: 'CLOSE_SNACKBAR_NFC_PROBLEM'
};

export default ProjectsConstants;