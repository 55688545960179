import React, { Component } from 'react';
import _ from "lodash";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../../Styles/AppColors';

import {
  OutlinedTextfield,
  RemoveIcon
} from '../'

const styles = () => ({
  inputSizes: {
    marginRight: '8px',
    flex: 10,
  },
  countInput: {
    flex: 2,
  },
})

class NotCutMaterialSize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      length: 0,
      count: 0
    };
    this.changeCount = _.debounce(() => this.props.onChangeCount(this.props.sizeIndex, this.state.count), 2000);
	  this.changeLength = _.debounce(() => this.props.onChangeLength(this.props.sizeIndex, this.state.length), 2000);
  };

  componentDidMount() {
    const { length, count } = this.props;
    this.setState({ length, count });
  }

  render(){
    return(
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          width: '100%',
          marginTop: 24
        }}
      >
        <span
          style={{
            letterSpacing: -0.2,
            lineHeight: '16px',
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            color: COLORS.TEXT_COLOR,
            marginBottom: 20
          }}
        >
          Размеры куска
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <OutlinedTextfield
            required
            style={{
              marginRight: '8px',
              flex: 10,
            }}
            measureUnits='м.п.'
            label='Длина'
            value={this.state.length}
            type='number'
            defaultValue={0}
            onChange={(event) => {
              this.setState({
                length: event.target.value
              }, () => this.changeLength())
            }}
          />
          <OutlinedTextfield
            required
            label='Шт.'
            defaultValue={1}
            value={this.state.count}
            type='number'
            // onChange={this.props.onChangeCount}
            onChange={(event) => this.setState({
              count: event.target.value
            }, () => this.changeCount())}
            style={{
              flex: 2,
            }}
          />
          <RemoveIcon
            onClick={this.props.onClickDelete}
          />
        </div>
      </div>  
    )
  }

}

NotCutMaterialSize.propTypes = {
  classes: PropTypes.object.isRequired,
  length: PropTypes.number.isRequired,
  onChangeLength: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  onChangeCount: PropTypes.func.isRequired, 
  onClickDelete: PropTypes.func.isRequired,
}

export default withStyles(styles)(NotCutMaterialSize);
