import React, { Component } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import {
  BlueButton
} from '../../_NewDesignComponents'

class AreYouSureDialog extends Component {
  onCloseDialogHandler = (event) => {
    this.props.CloseAreYouSureDialog();
    event.stopPropagation();
  }

  onRemoveLayerHandler = (event) => {
    this.props.DeleteArmorSurface();
    this.props.CloseAreYouSureDialog();
    event.stopPropagation();
  }

  render(){
    const {
      isAreYouSureDialogOpened
    } = this.props;
    return(
      <Dialog
        open={isAreYouSureDialogOpened}
      >
        <DialogTitle>
          Удалить поверхность?
        </DialogTitle>
        <DialogContent>
          Это действие удалит поверхность без возможности отмены.
        </DialogContent>
        <DialogActions>
          <BlueButton
            color='primary'
            onClick={this.onCloseDialogHandler}
            label='Отмена'
          />
          <BlueButton
            color='primary'
            onClick={this.onRemoveLayerHandler}
            label='Удалить'
          />
        </DialogActions>
      </Dialog>
    )
  }
}

export default AreYouSureDialog;