import React, { Component, Fragment } from 'react';

import _ from 'lodash';

import CustomTaskTemplates from './CustomTask/CustomTaskTemplates';
import HeadTaskTemplates from './HeadTaskTemplates';
import ControlInfoTaskTemplates from './ControlInfoTaskTemplates';
import SetTimeDurationTaskTemplates from './SetTimeDurationTaskTemplates';
import QCCriteriaTable from './QCCriteriaTable';

import PolymerizationDiagram from './Evacuation/PolymerizationDiagram';

import LayersFacing from './LayersFacing/LayersFacing';
import RiggingPreparing from './RiggingPreparing/RiggingPreparing';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddingQCCriterionDialog from './AddingQCCriterionDialog';

import AdditionalDescription from './AdditionalDescription';
import StageParamField from './StageParamField';
import {
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';

import AttachmentsTaskTemplates from './AttachmentsTaskTemplates';

import Lightbox from 'react-images';
import api from '../../../Utils/API';

import {
  OutlinedExpansionPanel
} from '../../DumbComponents/_NewDesignComponents'

import {
  OblationalMaterialsTable
} from '../../DumbComponents/_NewDesignComponents/OblationalLayersFacing'

// const styles = {
// 	taskDescriptionText:{
// 		marginBottom: '12px',
// 		whiteSpace: 'pre-wrap'
// 	},
// }

class Stage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lightboxIsOpened: false,
      currentAttachment: -1,
      isAddingQCCriterionDialogOpened: false,
      isOpen: false,
    }
  }

  OpenAddingQCCriterionDialog() {
    this.setState({
      isAddingQCCriterionDialogOpened: true
    })
  }

  CloseAddingQCCriterionDialog() {
    this.setState({
      isAddingQCCriterionDialogOpened: false
    })
  }

  OpenAttachments() {
    this.setState({
      lightboxIsOpened: true
    })
  }

  CloseAttachments() {
    this.setState({
      lightboxIsOpened: false
    })
  }

  NextAttachment() {
    this.setState({
      currentAttachment: this.state.currentAttachment + 1
    })
  }

  PreviousAttachment() {
    this.setState({
      currentAttachment: this.state.currentAttachment - 1
    })
  }

  SetCurrentAttachment(attachment) {
    this.setState({
      currentAttachment: attachment
    })
  }

  openExpansionHandler = () => {
    if (this.props.stepType === 'molding' || this.props.stepType === 'assembling') {
      this.setState({
        isOpen: !this.state.isOpen
      })
    }
  }

  componentDidMount() {
    if (this.props.stepType !== 'molding' && this.props.stepType !== 'assembling') {
      this.setState({
        isOpen: true
      })
    }
  }

  render() {
    const {
      separativeLayers,
      sprays,
      stage,
      selectedResin,
      isEditQCCriterionDialogOpened,
      technology,
      selectedHardener,
      techDocType
    } = this.props;
    const {
      durationMinutes,
      layers,
      isICRequired,
      _id,
      textTemplate,
      attachmentsList,
      template,
      additionalDescription,
      data,
      QCCriteria,
      estimatedWorkerPrice
    } = stage;
    const {
      lightboxIsOpened,
      currentAttachment,
      isAddingQCCriterionDialogOpened,
      isOpen
    } = this.state;

    const isQCRequired = !_.isUndefined(stage.isQCRequired) ? stage.isQCRequired : template.isQCRequired;
    return (
      <OutlinedExpansionPanel
        expanded={isOpen}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={this.openExpansionHandler}
        >
          <HeadTaskTemplates
            techDocType={techDocType}
            isOpen={isOpen}
            title={template.title}
            timeDuration={!_.isUndefined(durationMinutes) ? durationMinutes : template.durationMinutes}
            price={!_.isUndefined(estimatedWorkerPrice) ? _.ceil(estimatedWorkerPrice) : 0}
            selectTaskForRemove={this.props.selectTaskForRemove}
            stageId={_id}
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div
            className='contentTaskTemplates'
          >
            <SetTimeDurationTaskTemplates
              timeDuration={!_.isUndefined(durationMinutes) ? durationMinutes : template.durationMinutes}
              stageId={_id}
              SetTimeDurationHours={this.props.SetTimeDurationHours}
              SetTimeDurationMinutes={this.props.SetTimeDurationMinutes}
              SetCustomTaskDescription={this.props.SetCustomTaskDescription}
            />
            {template.type === 'riggingPreparing' &&
              <RiggingPreparing
                data={data}
                separativeLayers={separativeLayers}
                taskId={_id}
                SetCustomTaskDescription={this.props.SetCustomTaskDescription}
                separativeLayer={!_.isUndefined(data) ? data.separativeLayer : template.data.separativeLayer}
                timeBetweenLayers={!_.isUndefined(data) ? data.timeBetweenLayers : template.data.timeBetweenLayers}
              />
            }
            {template.type === 'layersFacing' &&
              <LayersFacing
                layers={!_.isUndefined(layers) ? layers : template.layers}
                taskId={_id}
                sprays={sprays}
                SetCustomTaskDescription={this.props.SetCustomTaskDescription}
                spray={!_.isUndefined(data) ? data.spray : template.data.spray}
              />
            }
            {(template.type === 'custom' || template.type === 'assemblyCustom') &&
              <CustomTaskTemplates
                taskId={_id}
                textTemplate={!_.isUndefined(textTemplate) ? textTemplate : template.textTemplate}
                SetCustomTaskDescription={this.props.SetCustomTaskDescription}
              />
            }
            {template.type === 'oblationalLayersFacing' &&
              <OblationalMaterialsTable
                stage={stage}
                technology={technology}
              />
            }
            {template.type === 'evacuation' &&
              <Fragment>
                <StageParamField
                  technology={technology}
                  taskId={_id}
                  text={technology === 'Инфузия' ? 'Время вакуумирования до пропитки' : 'Время вакуумирования до термоциклирования'}
                  measureUnits='мин'
                  value={!_.isUndefined(data) && !_.isUndefined(data.timeBeforeImpregnation) ? data.timeBeforeImpregnation : 0}
                  SetCustomTaskDescription={this.props.SetCustomTaskDescription}
                  updateKey='timeBeforeImpregnation'
                  data={data}
                />
                <StageParamField
                  technology={technology}
                  taskId={_id}
                  text='Температура оснастки'
                  measureUnits='°С'
                  value={!_.isUndefined(data) && !_.isUndefined(data.riggingTemperature) ? data.riggingTemperature : 0}
                  SetCustomTaskDescription={this.props.SetCustomTaskDescription}
                  updateKey='riggingTemperature'
                  data={data}
                />
                <StageParamField
                  technology={technology}
                  taskId={_id}
                  text='Контроль вакуума'
                  measureUnits='МПа'
                  value={!_.isUndefined(data) && !_.isUndefined(data.vacuumControl) ? data.vacuumControl : 0}
                  SetCustomTaskDescription={this.props.SetCustomTaskDescription}
                  updateKey='vacuumControl'
                  data={data}
                />
                <PolymerizationDiagram
                  selectedResin={selectedResin}
                  technology={technology}
                  taskId={_id}
                  data={data}
                  riggingTemperature={!_.isUndefined(data) && !_.isUndefined(data.riggingTemperature) ? data.riggingTemperature : 0}
                  timeBeforeImpregnation={!_.isUndefined(data) && !_.isUndefined(data.timeBeforeImpregnation) ? data.timeBeforeImpregnation : 0}
                  vacuumControl={!_.isUndefined(data) && !_.isUndefined(data.vacuumControl) ? data.vacuumControl : 0}
                  polymerizationGraph={!_.isUndefined(data) && !_.isUndefined(data.polymerizationGraph) ? data.polymerizationGraph : {}}
                  SetCustomTaskDescription={this.props.SetCustomTaskDescription}
                  selectedHardener={selectedHardener}
                />
              </Fragment>
            }
            {(template.type !== 'custom' && template.type !== 'assemblyCustom') &&
              <AdditionalDescription
                taskId={_id}
                additionalDescription={!_.isUndefined(additionalDescription) ? additionalDescription : template.additionalDescription}
                SetCustomTaskDescription={this.props.SetCustomTaskDescription}
              />
            }
            <ControlInfoTaskTemplates
              taskId={_id}
              isQCRequired={isQCRequired}
              isICRequired={!_.isUndefined(isICRequired) ? isICRequired : template.isICRequired}
              SetCustomTaskDescription={this.props.SetCustomTaskDescription}
            />
            {isQCRequired &&
              <QCCriteriaTable
                taskId={_id}
                criteriaQC={!_.isUndefined(QCCriteria) ? QCCriteria : template.QCCriteria}
                isAddingQCCriterionDialogOpened={isAddingQCCriterionDialogOpened}
                isEditQCCriterionDialogOpened={isEditQCCriterionDialogOpened}

                OpenEditQCCriterionDialog={this.props.OpenEditQCCriterionDialog}
                CloseEditQCCriterionDialog={this.props.CloseEditQCCriterionDialog}
                EditQCCriterion={this.props.EditQCCriterion}
                RemoveQCCriterion={this.props.RemoveQCCriterion}
                OpenAddingQCCriterionDialog={this.OpenAddingQCCriterionDialog.bind(this)}

                CheckQCCriterion={this.props.CheckQCCriterion}
              />
            }
            <AttachmentsTaskTemplates
              attachmentsList={attachmentsList}
              id={_id}
              lightboxIsOpened={this.state.lightboxIsOpened}
              images={this.state.attachmentsList}

              OpenAttachments={this.OpenAttachments.bind(this)}
              SetCurrentAttachment={this.SetCurrentAttachment.bind(this)}

              AddScreenshot={this.props.AddScreenshot}
              DeleteScreenshot={this.props.DeleteScreenshot}
              OpenGraphicsEditor={this.props.OpenGraphicsEditor}
              CloseGraphicsEditor={this.props.CloseGraphicsEditor}
            />
          </div>
        </ExpansionPanelDetails>
        <Lightbox
          images={_.map(attachmentsList, attachment => {
            return { src: api.getHost() + attachment.url }
          })}
          isOpen={lightboxIsOpened}
          currentImage={currentAttachment}

          onClose={() => this.CloseAttachments()}
          onClickPrev={() => this.PreviousAttachment()}
          onClickNext={() => this.NextAttachment()}
        />
        <AddingQCCriterionDialog
          taskId={_id}
          isAddingQCCriterionDialogOpened={isAddingQCCriterionDialogOpened}

          CloseAddingQCCriterionDialog={this.CloseAddingQCCriterionDialog.bind(this)}
          AddQCCriterion={this.props.AddQCCriterion}
        />
      </OutlinedExpansionPanel>
    );
  }
}

export default Stage;
