import React, { Component } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton
} from '@material-ui/core';

import {
  Remove
} from '@material-ui/icons';

import _ from 'lodash';

import { UserRoleTranslate } from './AddUserDialog';

const styles = {
  tableHeaderItem: {
		position: 'sticky',
		top: 0,
    background: '#EEEEEE',
    zIndex: 100
  },
  userRow: {
    cursor: 'pointer'
  }
}

const usersHeader = ['№', 'ФИО', 'Роли', 'Ставка', 'NFC', 'Удаление'];

class UsersTable extends Component{
  render(){
    const {
      users
    } = this.props;

    return(
      <Table>
        <TableHead>
          <TableRow>
            {_.map(usersHeader, (item, itemId) => {
              return (
                <TableCell
                  key={itemId}
                  style={styles.tableHeaderItem}
                >
                  {item}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(users, (user, userId) => {
            return (
              <TableRow
                style={styles.userRow}
                key={user._id}
                onClick={() => { this.props.OpenEditingUserDialog(user._id) }}
              >
                <TableCell>
                  {userId + 1}
                </TableCell>
                <TableCell>
                  {_.capitalize(user.first_name) + ' ' + _.capitalize(user.second_name)}
                </TableCell>
                <TableCell
                >
                  {_.map(user.roles, (role, roleId) => {
                    return (
                      <Typography
                        variant='body1'
                        key={roleId}
                      >
                        {UserRoleTranslate(role.type)}
                      </Typography>
                    )
                  })
                  }
                </TableCell>
                <TableCell>
                  {user.rate}
                </TableCell>
                <TableCell>
                  {!_.isUndefined(user.nfc) ? 'Есть' : 'Нет'}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      this.props.DeleteUser(user._id)
                    }}
                  >
                    <Remove />
                  </IconButton>
                </TableCell>
              </TableRow>
            )})
          }
        </TableBody>
      </Table>
    );
  }
}

export default UsersTable;
