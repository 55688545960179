import React, { Component } from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogActions,
} from '@material-ui/core';

import GraphicsEditor from './GraphicsEditor';

const styles = {
  dialogContent:{
    height: '100%',
    padding: 0
  }
 }

class DialogGraphicsEditor extends Component {
  constructor(props) {
		super(props);
		this.state = {
			newImageSrc: '',
		}
  }
  
	onChangeScreen = (newImageSrc) => {
		this.setState({
			newImageSrc: newImageSrc,
		})
	};

  render(){
    const {
      isGraphicsEditorOpened,
      CurrentImageInEditing,
    } = this.props;
    return(
      <Dialog
        fullScreen={true}
        open={isGraphicsEditorOpened}
        onClose={this.props.CloseGraphicsEditor}
      >
        <DialogContent
          style={styles.dialogContent}
        >
          <GraphicsEditor
            editingImage={CurrentImageInEditing}
            onChangeScreen={this.onChangeScreen.bind(this)}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={()=>{
              this.props.CloseGraphicsEditor();
            }} 
            variant='raised' 
            color='primary'
          >
            Отмена
          </Button>
          <Button
            onClick={() => {
              this.props.EditScreenshot(CurrentImageInEditing.url, this.state.newImageSrc);
            }} 
            color='primary'
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DialogGraphicsEditor;