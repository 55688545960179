import React from 'react';
import _ from 'lodash';

import { VncDisplay } from 'react-vnc-display';

import {
  CircularProgress
} from '@material-ui/core';

class VNCDisplay extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }
  componentDidMount() {
    if (this.props.isTechDocLoaded) {
      this.props.RunDocker(this.props.techDocId, this.freeCADEditor.clientWidth, this.freeCADEditor.clientHeight);
    }
  };

  render() {
    return (
      <div
        ref={(freeCADEditor) => { this.freeCADEditor = freeCADEditor }}
        className='vncDisplayContainder'
      >
        {(!_.isEmpty(this.props.VNCHostURL) && !this.state.isLoading) ?
          <VncDisplay
            url={this.props.VNCHostURL}
            onUpdateState={(value) => {
              if (value._rfb_state === 'failed' && !this.state.isLoading && !_.isEmpty(this.props.VNCHostURL)) {
                this.setState({ isLoading: true });
                setTimeout(() => {
                  this.setState({ isLoading: false })
                }, 1500)
              }
            }}
          />
          :
          <CircularProgress
            size={150}
          />
        }
      </div>
    )
  }
}

export default VNCDisplay;