import React, { Component, Fragment } from "react";

import {
  Typography
} from '@material-ui/core';

const styles = {
  taskDescriptionText:{
    marginBottom:'12px',
    whiteSpace:'pre-wrap',
    marginTop: 12,
  },
  taskDescription:{
    display:'flex',
    flexDirection:'column', 
    alignContent:'space-around' 
  }
}

class EvacuatingDescription extends Component{
  render(){
    const{
      additionalDescription,
    } = this.props;

    const {
      vacuumControl,
      riggingTemperature,
      timeBeforeImpregnation
    } = this.props.data;
    return(
      <Fragment>
        <Typography
          headlineMapping={{body1:'p'}}
          variant='body1'
          align='left'
          style={styles.taskDescriptionText}
        >
          {`Время вакуумирования до пропитки: ${timeBeforeImpregnation} мин.\nТемпература оснастки: ${riggingTemperature}°\nКонтроль вакуума: ${vacuumControl}`}
        </Typography>
	      <Typography
		      headlineMapping={{body1:'p'}}
		      variant='body1'
		      align='left'
		      style={styles.taskDescriptionText}
	      >
		      <b>Дополнительная информация</b>: {additionalDescription.length > 0 ? additionalDescription: 'нет информации'}
	      </Typography>
      </Fragment>
    );
  }
}

export default EvacuatingDescription;