import React, { Component, Fragment } from 'react'

import _ from 'lodash';
import appStyles from '../../Styles/AppThemStyles';

import {
  connect,
} from 'react-redux';

import {
  ProductionChainActions,
  NavigationActions
} from '../../Redux/Actions/';

import {
  FreeCadCommands,
  NavigationButtons
} from '../DumbComponents';

import {
  VNCDisplay,
  StagesList
} from './';
import { CONTAINER_CONSTANTS } from "../../Styles/ContainerConstants";

const styles = {
  freeCad: {
    height: 'calc(100% - 64px)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
}

class AssemblyTechDoc extends Component {
  render() {
    const {
      dockerUrls,
      currentMeasureResult,
      isTechDocLoaded,
    } = this.props;
    return (
      <div
        className='techDocCreatingPageRoot'
      >
        {isTechDocLoaded &&
          <Fragment>
            <div
              className='mainWindow'
            >
              <div
                className='techDocCreatingPageVNCDisplay'
              >
                <div
                  style={styles.freeCad}
                >
                  <VNCDisplay
                    VNCHostURL={dockerUrls ? dockerUrls.VNCAddress : ''}
                    OnVNCConnectionLose={() => { }}
                    RunDocker={this.props.RunDocker}
                    techDocId={this.props.techDocId}
                    isTechDocLoaded={isTechDocLoaded}
                  />
                </div>
                <FreeCadCommands
                  currentMeasureResult={currentMeasureResult}

                  SetColors={this.props.SetColors}
                  ResetColors={this.props.ResetColors}

                  GetArea={this.props.GetArea}
                  GetLength={this.props.GetLength}
                />
              </div>
              <div
                style={{
                  width: '40%',
                  height: 'calc(100% - 64px)',
                  boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.5)',
                  marginBottom: 64,
                }}
              >
                <div
                  style={{
                    padding: `0 ${CONTAINER_CONSTANTS.RIGHT_PADDING}px 0 ${CONTAINER_CONSTANTS.LEFT_PADDING}px`,
                    height: '100%',
                    width: `calc(100% - ${CONTAINER_CONSTANTS.LEFT_PADDING + CONTAINER_CONSTANTS.RIGHT_PADDING}px`,
                    display: 'flex',
                    overflowY: 'scroll',
                    overflowX: 'hidden'
                  }}
                >
                  <StagesList
                    techDocType='assembly'
                    productionChain={this.props.ProductionChain[0]}
                    stepDescription={{
                      title: 'Этапы сборки изделия',
                      description: 'Заполните описание этапов сборки изделия',
                      type: 'assembling',
                    }}
                    DeleteStage={this.props.DeleteStage}
                  />
                </div>
                <NavigationButtons
                  techDocType='assembly'
                  addStage={this.props.AddStage.bind(this)}
                  OnClickSave={this.props.SaveTechDoc.bind(this)}
                  PushToPDF={this.props.PushToPDF.bind(this)}
                  needCutting={false}
                />
              </div>
            </div>
          </Fragment>
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    Interface: state.Interface,
    isTechDocLoaded: state.HTTP.isTechDocLoaded,
    warning: state.HTTP.warning,
  }),
  dispatch => ({
    SaveTechDoc: () => {
      dispatch(NavigationActions.SaveTechDoc());
    },
    PushToPDF: () => {
      dispatch(NavigationActions.PushToPDF());
    },
    AddStage: () => {
      dispatch(ProductionChainActions.AddStage());
    },
    DeleteStage: (stageId) => {
      dispatch(ProductionChainActions.DeleteStage(stageId));
    }
  }),
)(AssemblyTechDoc);
