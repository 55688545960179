import React, { Component } from 'react';

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';

import {
  ExpandMore,
  Add,
} from '@material-ui/icons';

import _ from 'lodash';

import OblationalNCGroup from './OblationalNCGroup';

const styles = {
  groupTagTitle: {
    display:'flex', 
    flexDirection:'column'
  }
}

class OblationalNotCutMaterialInfo extends Component {
  constructor(props){
    super(props);
    this.state = {
      oblationalNotCuttingGroups: [],
    }
  }

	static getDerivedStateFromProps(props, state) {
		const propsos = props.oblationalNotCuttingGroups;
		const stateos = state.oblationalNotCuttingGroups;
		if (_.size(stateos) !== _.size(propsos)) {
			return {
				oblationalNotCuttingGroups: propsos
			}
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true
	}

	ChangeONCGByIndex(index, paramTitle, paramValue) {
		const newONCG = this.state.oblationalNotCuttingGroups;
		newONCG[index][paramTitle] = paramValue;
		this.setState({
			oblationalNotCuttingGroups: newONCG,
		});
	};

  DeleteONCGById(oncgId) {
  	this.props.DeleteONCG(oncgId, this.state.oblationalNotCuttingGroups);
  }

  render(){
    const {
	    oblationalNotCutMaterials
    } = this.props;
    const {
    	oblationalNotCuttingGroups
    } = this.state;
    return(
      <ExpansionPanel
	      style={{ margin: '24px 0' }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMore/>}
        >
          {'ЖЕРТВЕННЫЕ МАТЕРИАЛЫ НЕ ТРЕБУЮЩИЕ РАСКРОЯ'}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={styles.groupTagTitle}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography
              variant='subheading'
            >
              МАТЕРИАЛ
            </Typography>
            <IconButton
              onClick={() => this.props.AddONCG(oblationalNotCuttingGroups)}
            >
              <Add/>
            </IconButton>
          </div>
          {_.map(oblationalNotCuttingGroups, (ncg, ncgId) => {
            return <OblationalNCGroup
              key={ncg._id}
              ncg={ncg}
              oncgIndex={ncgId}

              ChangeONCGByIndex={this.ChangeONCGByIndex.bind(this)}

              oblationalNotCutMaterials={oblationalNotCutMaterials}
              DeleteONCGById={this.DeleteONCGById.bind(this)}
            />
          })}
	        <Button
		        variant="contained"
		        color="primary"
		        fullWidth
		        onClick={() => this.props.SaveONCGWithDemand(oblationalNotCuttingGroups)}
		        disabled={_.some(oblationalNotCuttingGroups, oncg => {
		        	return _.isUndefined(oncg.material)
		        })}
	        >
		        Сохранить и отрезать
	        </Button>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

export default OblationalNotCutMaterialInfo;