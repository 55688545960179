import React, { Component } from 'react';
import _ from 'lodash';
import {OutlinedTextfield} from "..";
import {CONTAINER_CONSTANTS} from "../../../../Styles/ContainerConstants";
import {COLORS} from "../../../../Styles/AppColors";

export default class TowMaterialSize extends Component {
	constructor(props) {
		super(props);
		this.state = {
			length: 0,
		};
		this.changeLength = _.debounce(() => this.props.changeTowMaterialLength(this.state.length), 2000)
	};

	componentDidMount() {
		const { length } = this.props;
		this.setState({ length: length })
	}

	render() {
		return (
			<div
				style={{width: `calc(100% - ${CONTAINER_CONSTANTS.ICON_CONTAINER_SIZE}px`, position: 'relative', marginTop: 24}}
			>
				 <span
					 style={{
						 letterSpacing: -0.2,
						 lineHeight: '16px',
						 fontSize: '14px',
						 fontFamily: 'Roboto',
						 fontWeight: 500,
						 color: COLORS.TEXT_COLOR,
					 }}
				 >
          Размеры куска
        </span>
				<OutlinedTextfield
					required
					measureUnits='м.п.'
					label='Длина'
					style={{
						width: '100%',
						marginTop: 20
					}}
					value={this.state.length}
					type='number'
					defaultValue={0}
					onChange={(event) => {
						this.setState({
							length: event.target.value
						}, () => this.changeLength())
					}}
				/>
			</div>
		)
	}
}