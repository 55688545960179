import React, {Component} from 'react';

import _ from 'lodash';

import {
  Table,
  Typography,
  IconButton,
} from '@material-ui/core';

import {
  Add,
} from '@material-ui/icons';

import BodyResinMaterialsInfo from './BodyResinMaterialsInfo';
import HeadMaterialsInfo from '../HeadMaterialsInfo';
import AddingResinDialog from './AddingResinDialog';


const styles = {
  resinAddingContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
  }
}

class ResinMaterialsInfo extends Component {
  render(){
    const {
      resinMaterials,
      isAddingResinDialogOpened,
      resinsList,
	    selectedResin,

      needsDeleting,
      newResinAmount,
    } = this.props;
    return(
      <div
        className='materialsInfo'
      >
        {!_.isEmpty(resinMaterials) &&
          <Table
            style={{overflowX: 'scroll', display: 'block'}}
          >
            <HeadMaterialsInfo
              needsDeleting={needsDeleting}
            />
            <BodyResinMaterialsInfo
              resinMaterials={resinMaterials}
              DeleteMaterial={this.props.DeleteMaterial}
            />
          </Table>
        }
        {_.isEmpty(resinMaterials) &&
          <div
            style={styles.resinAddingContainer}
          >
            <Typography
              variant='subheading'
            >
              Смола
            </Typography>
            <IconButton
              onClick={this.props.OpenResinAddingDialog}
            >
              <Add/>
            </IconButton>
          </div>}
          {isAddingResinDialogOpened &&
            <AddingResinDialog
              isAddingResinDialogOpened={isAddingResinDialogOpened}
              CloseResinAddingDialog={this.props.CloseResinAddingDialog}
              resinsList={resinsList}

              newResinAmount={newResinAmount}

              SetSelectedResin={this.props.SetSelectedResin}
              selectedResin={selectedResin}
              SaveResin={this.props.SaveResin}
            />
          }
      </div>
    )
  }
}

export default ResinMaterialsInfo;
