import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { COLORS } from '../../../Styles/AppColors';

class TotalTimeDurationAndPrice extends Component {
  render(){
    const {
      totalTime,
      totalPrice
    } = this.props;
    return(
      <div
        style={{
          border: `3px solid ${COLORS.BORDER_GREY}`,
          borderRadius: '4px',
          height: '60px',
          width: '124px',
          position: 'relative',
          marginRight: 12
        }}
      > 
        <div
          style={{
            position: 'absolute',
            top: -12,
            left: 10,
            backgroundColor: COLORS.GRAY,
            paddingLeft: 6,
            paddingRight: 8
          }}
        >
          <span
            style={{
              fontFamily: 'Roboto',
              fontSize: 12,
              lineHeight: '14px',
              letterSpacing: -0.2,
              color: COLORS.TEXT_COLOR,
            }}
          >
            Затраты
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
          }}
        > 
          <span
            style={{
              color: COLORS.TEXT_COLOR,
              fontSize: 14,
              lineHeight: '16px',
              letterSpacing: -0.2,
              fontWeight: 500,
              fontFamily: 'Roboto'
            }}
          >
            {!_.isNaN(totalPrice.mp + totalPrice.wp) ? _.ceil((totalPrice.mp + totalPrice.wp), 0) : 0} руб.
          </span>
          <span
            style={{
              color: COLORS.TEXT_COLOR,
              fontSize: 14,
              lineHeight: '16px',
              letterSpacing: -0.2,
              fontWeight: 500,
              fontFamily: 'Roboto'
            }}
          >
            {(totalTime / 60).toFixed(0)} ч {totalTime % 60} мин
          </span>
        </div>
      </div>
    )
  }
}

TotalTimeDurationAndPrice.propTypes = {
  totalTime: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired
}

export default TotalTimeDurationAndPrice;