const ProductsConstants = {
  CREATE_NEW_PRODUCT: 'CREATE_NEW_PRODUCT',
  GET_PRODUCTS: 'GET_PRODUCTS',
  SET_TECHDOCS_LIST: 'SET_TECHDOCS_LIST',
  SET_FILES: 'SET_FILES',
  SELECT_FILE: 'SELECT_FILE',
  CLEAN_PRODUCTS: 'CLEAN_PRODUCTS',
  CLEAN_TECHDOCS_LIST: 'CLEAN_TECHDOCS_LIST',
  SELECT_PRODUCT: 'SELECT_PRODUCT'
}

export default ProductsConstants;