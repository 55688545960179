import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {COLORS} from "../../../Styles/AppColors";

export default class MaterialPreparingHeader extends PureComponent {
	render() {
		const {
			materialTypeTitle,
			materialTypeDescription,
		} = this.props;
		return (
			<div
				style={{
					marginTop: 32,
					marginBottom: 16,
					display: 'flex',
					flexDirection: 'column'
				}}
			>
				<span
					style={{
						fontFamily: 'Roboto',
						fontSize: 18,
						letterSpacing: -0.3,
						lineHeight: '21px',
						fontWeight: '500',
						color: COLORS.TEXT_COLOR
					}}
				>
					{materialTypeTitle}
				</span>
				<span
					style={{
						marginTop: 8,
						fontFamily: 'Roboto',
						fontSize: 14,
						letterSpacing: -0.2,
						lineHeight: '16px',
						fontWeight: '300',
						color: COLORS.TEXT_COLOR
					}}
				>
					{materialTypeDescription}
				</span>
			</div>)
	}
};

MaterialPreparingHeader.propTypes = {
	materialTypeTitle: PropTypes.string.isRequired,
	materialTypeDescription: PropTypes.string.isRequired,
};
