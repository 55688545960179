import _ from 'lodash';
import {
  AnalyticsConstants
} from '../Constants';

import api from '../../Utils/API';
import {
  push
} from 'react-router-redux';

import moment from 'moment';

const getHHMM = (minutes) => {
  const newHours = Math.trunc(minutes / 60) < 10 ? '0' + Math.trunc(minutes / 60) : Math.trunc(minutes / 60);
  const newMinutes = minutes % 60 < 10 ? '0' + minutes % 60 : minutes % 60;
  return (newHours + ":" + newMinutes);
};

const getTaskSpentTime = (reversedHistory) => {
  const history = _.sortBy(reversedHistory, 'date');
  if (_.isUndefined(history)) {
    return getHHMM(0);
  };
  if (_.findIndex(history, historyItem => {
    return historyItem.state === 'doing'
  }) === -1) {
    return getHHMM(0);
  };
  let attemptsCounter = 0;
  const taskAttempts = _.reduce(history, (historyAttempts, historyItem) => {
    if (_.isEmpty(_.last(historyAttempts)) && historyItem.state === 'doing') {
      const attempt = historyAttempts[attemptsCounter]
      historyAttempts[attemptsCounter] = _.assign(attempt, { doing: historyItem.date });
      return historyAttempts;
    };
    if (!_.isEmpty(_.last(historyAttempts)) && (historyItem.state === 'inReview' || historyItem.state === 'done')) {
      const attempt = historyAttempts[attemptsCounter]
      historyAttempts[attemptsCounter] = _.assign(attempt, { done: historyItem.date });
      attemptsCounter = attemptsCounter + 1;
      return _.concat(historyAttempts, {});
    };
    return historyAttempts;
  }, [{}]);
  const updatedAttempts = !_.isEmpty(_.last(taskAttempts)) ? taskAttempts : _.dropRight(taskAttempts);
  return getHHMM(_.sumBy(updatedAttempts, attempt => {
    return !_.isUndefined(attempt.done) ? moment(attempt.done).diff(moment(attempt.doing), "minutes") : moment().diff(moment(attempt.doing), "minutes");
  }))
};

const GetNFCStatusList = () => {
  return (dispatch) => {
    api.get(`realizationsNfcs`)
      .then((statusList) => {
        dispatch({
          type: AnalyticsConstants.GET_NFC_STATUS_LIST,
          data: statusList
        })
      })
      .catch((err) => console.log(err))
  }
}

const GetTasksInQC = () => {
  return (dispatch) => {
    api.get('tasks')
      .then((tasks) => {
        let InRewiew = _.filter(tasks, (task) => {
          return _.toLower(task.state) === 'inreview'
        })
        dispatch({
          type: AnalyticsConstants.GET_TASKS_IN_QC,
          data: InRewiew
        })
      })
      .catch((err) => {
        console.log(err);
      })
  }
}

const GetPlanningByUserDate = (date, user) => {
  return (dispatch) => {
    const params = {
      assignedTo: user,
      date: date
    }
    api.get('getPlanningByUserDate', params)
      .then(userPlan => {
        dispatch({
          type: AnalyticsConstants.GET_PLANNING_BY_USER_DATE,
          data: userPlan
        })
      })
  }
}

const GetBusyWorkers = () => {
  return (dispatch) => {
    api.get('tasks')
      .then((tasks) => {
        let inDoing = _.chain(tasks).filter((task) => {
          return _.toLower(task.state) === 'doing' && !_.isUndefined(task.assignedTo) &&
            (moment().format('DD-MM-YYYY') === moment(task.date).format('DD-MM-YYYY'))
        }).map((task) => {
          return {
            assignedTo: task.assignedTo,
            taskTitle: task.title,
            projecTitle: task.project.title,
            durationMinutes: `${getTaskSpentTime(task.history)}/${(task.type !== 'materialPreparing') ? getHHMM(task.stage.durationMinutes) : 'Не определено'}`
          }
        }).groupBy((description) => {
          return _.upperFirst(description.assignedTo.first_name) + ' ' + _.upperFirst(description.assignedTo.second_name)
        }).value();

        let assigned = _.chain(tasks).filter((task) => {
          return !_.isUndefined(task.assignedTo) && (moment().format('DD-MM-YYYY') === moment(task.date).format('DD-MM-YYYY')) // && _.toLower(task.state) !== 'doing'
        }).map((task) => {
          return {
            assignedTo: task.assignedTo,
            taskTitle: task.title,
            projecTitle: task.project.title,
            durationMinutes: task.type !== 'materialPreparing' ? getHHMM(task.stage.durationMinutes) : 'Не определено',
            rawDurationMinutes: task.type !== 'materialPreparing' ? task.stage.durationMinutes : 0
          }
        }).groupBy((description) => {
          return _.upperFirst(description.assignedTo.first_name) + ' ' + _.upperFirst(description.assignedTo.second_name)
        }).value();

        dispatch({
          type: AnalyticsConstants.GET_BUSY_WORKERS,
          data: {
            busyWorkers: inDoing,
            assignedWorkers: assigned
          }
        })
      })
      .catch((err) => {
        console.log(err);
      })
  }
}

const SelectOverdueTask = (projectId, realizationId) => {
  return (dispatch) => {
    dispatch(push(`project/${projectId}/${realizationId}`))
  }
}

const GetPlanning = () => {
  return (dispatch) => {
    api.get('getPlanning')
      .then((plan) => {
        dispatch({
          type: AnalyticsConstants.GET_PLANNING,
          data: plan
        })
      })
      .catch((err) => {
        console.log(err);
      })
  }
}

const GetProductionActualInfo = () => {
  return (dispatch) => {
    api.get('getProductionActualInfo')
      .then(statesList => {
        dispatch({
          type: AnalyticsConstants.GET_PRODUCTION_ACTUAL_INFO,
          data: statesList
        })
      })
  }
}

export default {
  GetTasksInQC,
  GetBusyWorkers,
  GetPlanning,
  GetPlanningByUserDate,
  GetProductionActualInfo,
  SelectOverdueTask,
  GetNFCStatusList
};