import React, { Component } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core';

import CreateNewProjectContent from './CreateNewProjectContent';

class CreateNewProjectDialog extends Component{
  render(){
    const {
      isCreateNewProjectDialogOpened,
      newProject,
      techDocs,
      productsList,
      product
    } = this.props;
    return(
      <Dialog
        open={isCreateNewProjectDialogOpened}
      >
        <DialogTitle>
          ИНФОРМАЦИЯ О НОВОМ ПРОЕКТЕ
        </DialogTitle>
        <DialogContent>
          <CreateNewProjectContent
            productsList={productsList}
            newProject={newProject}
            techDocs={techDocs}
            product={product}
            ChangeNewProjectData={this.props.ChangeNewProjectData}
            GetTechDocsForProjects={this.props.GetTechDocsForProjects}
            GetProducts={this.props.GetProducts}
            SetProduct={this.props.SetProduct}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              this.props.CloseCreatingNewProjectDialog();
            }}
            color="primary"
          >
            ОТМЕНИТЬ
          </Button>
          <Button 
            color="primary"
            onClick={()=>{
              this.props.CreateNewProject();
            }}
          >
            СОХРАНИТЬ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreateNewProjectDialog;