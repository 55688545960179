import React, { Component } from 'react';

import { connect } from 'react-redux';

import {
  InterfaceActions,
  TechDocMaterialsAmountActions
} from '../../Redux/Actions'

import {
  Materials
} from '../DumbComponents';

import _ from 'lodash';

class MaterialList extends Component {
  componentDidMount() {
    this.props.GetSuperCategories();
  };

  render() {
    const {
      materialsSuperCategories,
      materialsAmount,
      isAddingResinDialogOpened,
      resinsList,
      technology,
      selectedResin,
      addingOblationalMaterials,
      isAddingOblationalMaterialDialogOpened,
      selectedOblationalMaterial,
      surfacesList,
    } = this.props;
    const newResinAmount = 1.3 * _.sumBy(surfacesList, surface => {
      return surface.width / 1000 * surface.height / 1000 * _.sumBy(surface.layers, layer => {
        return !_.isUndefined(layer.material.description.data.dens) ? layer.material.description.data.dens / 1000 : 0
      })
    });

    return (
      <div
        className='materialsList'
      >
        {_.map(materialsSuperCategories, (superCat) => {
          const filteredMaterialsAmount = _.filter(materialsAmount, (materialAmount) => {
            return materialAmount.material.category.superCategory === superCat._id
          });
          return (
            <Materials
              key={superCat._id}
              superCategory={superCat}
              filteredMaterialsAmount={filteredMaterialsAmount}

              price={_.sumBy(filteredMaterialsAmount, ma => {
                return ma.amount * ma.material.price;
              })}

              newResinAmount={newResinAmount}

              technology={technology}

              OpenResinAddingDialog={this.props.OpenResinAddingDialog.bind(this)}
              CloseResinAddingDialog={this.props.CloseResinAddingDialog.bind(this)}
              isAddingResinDialogOpened={isAddingResinDialogOpened}

              resinsList={resinsList}
              SetSelectedResin={this.props.SetSelectedResin.bind(this)}
              selectedResin={selectedResin}
              SaveResin={this.props.SaveResin.bind(this)}

              addingOblationalMaterials={addingOblationalMaterials}

              isAddingOblationalMaterialDialogOpened={isAddingOblationalMaterialDialogOpened}
              OpenOblationalMaterialAddingDialog={this.props.OpenOblationalMaterialAddingDialog.bind(this)}
              CloseOblationalMaterialAddingDialog={this.props.CloseOblationalMaterialAddingDialog.bind(this)}
              selectedOblationalMaterial={selectedOblationalMaterial}
              SetSelectedOblationalMaterial={this.props.SetSelectedOblationalMaterial.bind(this)}
              SaveOblationalMaterial={this.props.SaveOblationalMaterial.bind(this)}

              DeleteMaterial={this.props.DeleteMaterial.bind(this)}
            />
          )
        })}
      </div>
    )
  }
}

export default connect(
  state => ({
    materialsSuperCategories: state.TechDocMaterialsAmount.materialsSuperCategories,
    materialsAmount: state.TechDocMaterialsAmount.materialsAmount,
    isAddingResinDialogOpened: state.Interface.isAddingResinDialogOpened,
    resinsList: _.filter(state.TechDocMaterials.loadedMaterials, material => {
      return material.category.key === 'rr'
    }),
    //to do another logic of filtering
    addingOblationalMaterials: _.filter(state.TechDocMaterials.loadedMaterials, material => {
      return material.category.key === 'omo'
    }),
    selectedResin: state.TechDocMaterialsAmount.selectedResin,

    isAddingOblationalMaterialDialogOpened: state.Interface.isAddingOblationalMaterialDialogOpened,
    selectedOblationalMaterial: state.TechDocMaterialsAmount.selectedOblationalMaterial,

    surfacesList: state.TechDocCut.surfacesList,
    technology: state.TechDocs.SelectedTechDoc.technology
  }),
  dispatch => ({
    AddConnector: () => {
      dispatch(TechDocMaterialsAmountActions.AddConnector());
    },
    SetConnectorAmount: (amount, index) => {
      dispatch(TechDocMaterialsAmountActions.SetConnectorAmount(amount, index));
    },
    SelectConnector: (title, index) => {
      dispatch(TechDocMaterialsAmountActions.SelectConnector(title, index));
    },
    SetResinAmount: (amount) => {
      dispatch(TechDocMaterialsAmountActions.SetResinAmount(amount));
    },
    SelectResin: (resinTitle) => {
      dispatch(TechDocMaterialsAmountActions.SelectResin(resinTitle));
    },
    GetSuperCategories: () => {
      dispatch(TechDocMaterialsAmountActions.GetSuperCategories());
    },
    OpenResinAddingDialog: () => {
      dispatch(InterfaceActions.OpenResinAddingDialog());
    },
    CloseResinAddingDialog: () => {
      dispatch(InterfaceActions.CloseResinAddingDialog());
    },
    SetSelectedResin: (resin) => {
      dispatch(TechDocMaterialsAmountActions.SetSelectedResin(resin));
    },
    SaveResin: () => {
      dispatch(TechDocMaterialsAmountActions.SaveResin())
    },
    SetSelectedOblationalMaterial: (oblationalMaterial) => {
      dispatch(TechDocMaterialsAmountActions.SetSelectedOblationalMaterial(oblationalMaterial))
    },
    OpenOblationalMaterialAddingDialog: () => {
      dispatch(InterfaceActions.OpenOblationalMaterialAddingDialog());
    },
    CloseOblationalMaterialAddingDialog: () => {
      dispatch(InterfaceActions.CloseOblationalMaterialAddingDialog());
    },
    SaveOblationalMaterial: () => {
      dispatch(TechDocMaterialsAmountActions.SaveOblationalMaterial())
    },
    DeleteMaterial: (materialId) => {
      dispatch(TechDocMaterialsAmountActions.DeleteMaterial(materialId))
    }
  })
)(MaterialList);
