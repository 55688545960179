import _ from 'lodash';

import {
  ProjectsConstants
} from '../Constants';

import api from '../../Utils/API';
import {
  push
} from "react-router-redux";

import {
  InterfaceActions
} from './index';

const RemoveRealizationById = (realizationId) => {
  return (dispatch, getState) => {
    const projectId = getState().Projects.currentProject._id
    api.del(`realizations/${realizationId}`)
      .then(() => {
        dispatch(GetProjectById(projectId));
      })
      .catch(() => {
        console.log(`Cant remove project ${realizationId}`);
      })
  }
}

const RemoveProjectById = (projectId) => {
  return (dispatch) => {
    api.del(`projects/${projectId}`)
      .then(() => {
        dispatch(GetProjects());
      })
      .catch(() => {
        console.log(`Cant remove project ${projectId}`);
      })
  }
}

const SetRealizationNFCTag = (nfcId) => {
  return (dispatch, getState) => {
    const realizationId = getState().Projects.currentRealization._id;
    const form = new FormData();
    form.append('nfcId', nfcId);
    form.append('type', 'realization');
    form.append('objId', realizationId);
    api.post('nfc', form)
      .then(() => {
        dispatch(InterfaceActions.CloseSetNFCRealizationDialog())
        dispatch(CloseSnackbarNFCProblem())
      })
      .catch(() => {
        dispatch({
          type: ProjectsConstants.NFC_PROBLEM,
        })
      })
  }
}

const CloseSnackbarNFCProblem = () => {
  return (dispatch) => {
    dispatch({
      type: ProjectsConstants.CLOSE_SNACKBAR_NFC_PROBLEM,
    })
  }
}

const GetTaskExecutorsPlan = (taskId) => {
  return (dispatch) => {
    api.get(`getUsersByTask/${taskId}`)
      .then((tep) => {
        dispatch({
          type: ProjectsConstants.GET_TASK_EXECUTORS_PALNE,
          data: tep
        })
      })
  }
}

const GetUsersByRoles = (roles) => {
  return (dispatch) => {
    api.get(`userRoles/users`, { roles: JSON.stringify(roles) })
      .then((users) => {
        dispatch({
          type: ProjectsConstants.SET_TASK_USERS,
          data: users,
        })
      })
  }
}

const GetProducts = () => {
  return (dispatch) => {
    api.get(`products`)
      .then((products) => {
        dispatch({
          type: ProjectsConstants.GET_PRODUCTS,
          data: products.results
        })
      })
  }
}

const SetProduct = (productId) => {
  return (dispatch) => {
    api.get(`products/${productId}`)
      .then((product) => {
        dispatch({
          type: ProjectsConstants.SET_PRODUCT,
          data: product
        })
      })
  }
}

const SetTaskParam = (assign) => {
  return (dispatch, getState) => {
    const taskId = getState().Projects.currentTask._id;
    const form = new FormData();
    _.each(assign, (v, k) => {
      form.append(_.toString(k), JSON.stringify(v))
    })
    api.put(`tasks/${taskId}`, form)
      .then(() => {
        dispatch(GetTask(taskId));
      })
      .then(() => {
        dispatch(GetTaskExecutorsPlan(taskId))
      })
  }
}

const BeginTask = () => {
  return (dispatch, getState) => {
    const taskId = getState().Projects.currentTask._id;
    const form = new FormData();
    form.append('state', 'doing');
    api.put(`tasks/${taskId}/setState`, form)
      .then(() => {
        dispatch(GetTask(taskId));
      })
  }
}

const WaitingTask = () => {
  return (dispatch, getState) => {
    const taskId = getState().Projects.currentTask._id;
    const form = new FormData();
    form.append('state', 'waiting');
    api.put(`tasks/${taskId}/setState`, form)
      .then(() => {
        dispatch(GetTask(taskId));
      })
  }
}

const EndTask = () => {
  return (dispatch, getState) => {
    const taskId = getState().Projects.currentTask._id;
    const form = new FormData();
    form.append('state', 'done');
    api.put(`tasks/${taskId}/setState`, form)
      .then(() => {
        dispatch(GetTask(taskId));
      })
  }
}

const GetTask = (taskId) => {
  return (dispatch, getState) => {
    api.get(`tasks/${taskId}`)
      .then((task) => {
        dispatch({
          type: ProjectsConstants.SET_TASK,
          data: task,
        });
        dispatch(GetRealization(getState().Projects.currentRealization._id))
      })
  };
}

const GetRealization = (realizationId) => {
  return (dispatch) => {
    return api.get(`realizations/${realizationId}`)
      .then((realization) => {
        dispatch({
          type: ProjectsConstants.SET_CURRENT_REALIZATION,
          data: realization,
        });
      })
  };
};

const CleanCurrentRealization = () => {
  return {
    type: ProjectsConstants.CLEAN_CURRENT_REALIZATION
  }
}

const SelectRealization = (realizationId) => {
  return (dispatch, getState) => {
    const currentProjectId = getState().Projects.currentProject._id;
    dispatch(push(`/project/${currentProjectId}/${realizationId}`));
  }
}

const SelectProject = (projectId) => {
  return dispatch => {
    dispatch(push(`/project/${projectId}`));
  }
};

const CleanNewProjectData = () => {
  return {
    type: ProjectsConstants.CLEAN_NEW_PROJECT_DATA
  }
}

const ChangeNewProjectData = (parameter, value) => {
  return (dispatch, getState) => {
    const newProject = getState().Projects.newProject;
    dispatch({
      type: ProjectsConstants.CHANGE_NEW_PROJECT_DATA,
      data: _.assign(newProject, { [parameter]: value })
    })
  }
};

const CreateNewProject = () => {
  return (dispatch, getState) => {
    const newProject = getState().Projects.newProject;
    const form = new FormData();
    _.each(newProject, (value, key) => {
      form.append(key, value);
    });
    return api.post(`projects`, form)
      .then((project) => {
        dispatch(push(`/project/${project._id}`));
        dispatch(InterfaceActions.CloseCreatingNewProjectDialog())
      })
      .catch((err) => {
        dispatch({
          type: ProjectsConstants.SET_MATERIALS_NOT_ENOUGH,
          data: JSON.parse(err.message)
        });
        // setTimeout(() => {
        //   dispatch({
        //     type: ProjectsConstants.SET_MATERIALS_ENOUGH
        //   })
        // }, 5000)
      });
  }
};

const SetMaterialsEnough = () => {
  return {
    type: ProjectsConstants.SET_MATERIALS_ENOUGH,
  }
}

const GetProjectById = (projectId) => {
  return (dispatch) => {
    return api.get(`projects/${projectId}`)
      .then((project) => {
        dispatch({
          type: ProjectsConstants.SET_CURRENT_PROJECT,
          data: project,
        })
      })
  }
}

const CleanCurrentProject = () => {
  return {
    type: ProjectsConstants.CLEAN_CURRENT_PROJECT
  }
}

const GetProjects = () => {
  return (dispatch) => {
    return api.get(`projects`)
      .then((projects) => {
        dispatch({
          type: ProjectsConstants.SET_PROJECTS,
          data: projects.results,
        })
      })
  }
};

const CleanProjects = () => {
  return {
    type: ProjectsConstants.CLEAN_PROJECTS
  }
}

const GetTechDocsForProjects = () => {
  return (dispatch) => {
    return api.get(`techDocs`)
      .then((techDocs) => {
        dispatch({
          type: ProjectsConstants.SET_TECHDOCS,
          data: techDocs.results
        })
      })
  }
}

const CreateNewSubProject = (techDoc) => {
  //let production = techDoc.productionChain;

  return {
    type: ProjectsConstants.CREATE_NEW_SUB_PROJECT,
  }
}

export default {
  CreateNewProject,
  CreateNewSubProject,
  CleanNewProjectData,
  ChangeNewProjectData,

  GetTechDocsForProjects,

  GetProjects,

  GetProjectById,

  SelectProject,

  SelectRealization,

  GetRealization,

  GetTask,

  BeginTask,
  EndTask,
  WaitingTask,

  SetTaskParam,
  GetUsersByRoles,
  CleanProjects,
  CleanCurrentProject,
  CleanCurrentRealization,
  GetProducts,
  SetProduct,
  SetMaterialsEnough,
  GetTaskExecutorsPlan,
  SetRealizationNFCTag,
  CloseSnackbarNFCProblem,
  RemoveRealizationById,
  RemoveProjectById
}