import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../Styles/AppColors';
import _ from 'lodash';

class CutLayersListItem extends PureComponent {
  render(){
    const {
      color,
      layerNumber,
      fiberDirection,
      isOblational
    } = this.props;
    return(
      <div
        style={_.assign({},{
          padding: 10,
          backgroundColor: isOblational ? COLORS.OBLATIONAL_LAYER_COLOR : color,
          borderRadius: '3px',
          minWidth: 150,
        }, this.props.style) }
      >
        <span
          style={_.assign({}, {
            fontFamily: 'Roboto',
            fontSize: 14,
            color: COLORS.TEXT_COLOR
          }, this.props.textStyle)}
        >
          {isOblational ? `Кусок ${layerNumber}` : `Слой ${layerNumber}. Направление ${fiberDirection}`}
        </span>
      </div>
    )
  }
}

CutLayersListItem.propTypes = {
  color: PropTypes.string.isRequired,
  layerNumber: PropTypes.number.isRequired,
  fiberDirection: PropTypes.string.isRequired,
  isOblational: PropTypes.bool,
}

export default CutLayersListItem;