import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
	withStyles,
	Button
} from '@material-ui/core';

import {COLORS} from '../../../Styles/AppColors';

const styles = () => ({
	root: {
		color: COLORS.BLUE,
		marginTop: 12,
		letterSpacing: 1.3,
		lineHeight: '16px',
		'&:hover': {
			backgroundColor: COLORS.HOVER_BLUE,
		},
	}
});

class OutlinedButton extends PureComponent {
	render() {
		const {
			classes,
			label
		} = this.props;
		return (
			<Button
				variant='outlined'
				classes={{
					root: classes.root
				}}
				{...this.props}
			>
				{label}
			</Button>
		)
	}
}

OutlinedButton.propTypes = {
	classes: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
};

export default withStyles(styles)(OutlinedButton);
