import React, { PureComponent } from 'react';

import _ from "lodash";

import {
	TextField,
	Typography,
	Select,
	MenuItem,
} from '@material-ui/core';

class OblationalMaterialsWithCutDescription extends PureComponent {
	componentDidMount() {
		const {
			data,
			materialTitle,
			oblationalGroupTags,
			groupTag,
		} = this.props;
		const parsedMaterial = _.split(_.toLower(materialTitle), ' ');
		if (_.isUndefined(data.width)) {
			const parsedWidth = _.toNumber(_.trimEnd(_.last(parsedMaterial), 'мм'));
			this.props.ChangeMaterialData(_.assign(data, {width: !_.isNaN(parsedWidth) ? parsedWidth / 1000 : 0}))
		}
		if (_.isEmpty(groupTag)) {
			const materialWords = _.words(_.toLower(materialTitle));
			const foundGroupTag = _.find(oblationalGroupTags, ogt => {
				return _.startsWith(_.toLower(ogt.title), (materialWords[0] + ' ' + materialWords[1]))
			});
			this.props.ChangeMaterialGroupTag((!_.isUndefined(foundGroupTag) && !_.isUndefined(foundGroupTag._id)) ? foundGroupTag._id : '')
		}
	};

	render() {
		const {
			data,
			groupTag,
			oblationalGroupTags
		} = this.props;
		return(
			<div>
				<Typography
					variant='caption'
				>
					Выбор тега
				</Typography>
				<Select
					value={groupTag}
					onChange={(event) => this.props.ChangeMaterialGroupTag(event.target.value)}
					fullWidth
				>
					{_.map(oblationalGroupTags, ogt => {
						return <MenuItem
							key={ogt._id}
							value={ogt._id}
						>
							{ogt.title}
						</MenuItem>
					})}
				</Select>
				<Typography
					variant='caption'
					style={{ marginTop: 18 }}
				>
					Ширина рулона, м
				</Typography>
				<TextField
					fullWidth
					type='number'
					value={data.width}
					onChange={(event) => {
						if (event.target.value >= 0) {
							this.props.ChangeMaterialData(_.assign(data, {width: event.target.value}))
						}
					}}
				/>
			</div>
		)
	}
}

export default OblationalMaterialsWithCutDescription;
