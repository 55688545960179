import React, { PureComponent } from 'react';

import {
	IconButton
} from '@material-ui/core';

import {
	RotateRight,
	RotateLeft,
} from '@material-ui/icons';
import { COLORS } from '../../../../Styles/AppColors';


class LayerFunctions extends PureComponent {
	render() {
		const {
			isBlocked
		} = this.props;
		return (
			<div
				className='layerFunctions'
			>
				<IconButton
					style={{
						opacity: 0.4,
						backgroundClip: 'red'
					}}
					onClick={() => this.props.RotateLayer('left')}
					disabled={isBlocked}
				>
					<RotateLeft/>
				</IconButton>
				<IconButton
					onClick={() => this.props.RotateLayer('right')}
					disabled={isBlocked}
					style={{
						opacity: 0.4
					}}
				>
					<RotateRight/>
				</IconButton>
				<span
					style={{
						color: COLORS.TEXT_COLOR,
						opacity: 0.6,
						fontSize: 12,
						fontFamily: 'Roboto',
						fontWeight: 500,
						letterSpacing: 1.1,
						lineHeight: '14px'
					}}
				>
					ПОВОРОТ НА 45°
				</span>
			</div>
		)
	}
};

export default LayerFunctions;

