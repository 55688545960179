import React, {Component} from 'react';

import { 
  Typography,
} from '@material-ui/core';

import OutlinedTextfield from "../_NewDesignComponents/OutlinedTextfield.js";

import _ from 'lodash';

const styles = {
  inputProps: {
    paddingTop: 0
  },
	text:{
		flex: 1,
		alignSelf: 'center',
		fontFamily: 'Roboto',
		fontSize: 14,
		fontWeight: 470,
		letterSpacing: -0.2
	},
};

class StageParamField extends Component {
  constructor(props){
    super(props);
    this.state = {
      stageParam: 0
    }

    this.changeValue = _.debounce(() => this.props.SetCustomTaskDescription(this.props.taskId, 'data', _.set(this.props.data, this.props.updateKey, this.state.stageParam)), 1000)
  }

  componentDidMount(){
    this.setState({
      stageParam: this.props.value
    })
  }
  
  render(){
    const {
      text,
      technology,
	    measureUnits
    } = this.props;
    return(
      <div
        className='paramField'
      >
        <Typography
          variant='body1'
          style={styles.text}
        >
          {text}
        </Typography>
	      <OutlinedTextfield
		      disabled={technology === 'Препрег'}
		      value={this.state.stageParam}
		      onChange={(event) => {
			      // this.props.SetCustomTaskDescription(taskId, 'data', _.set(data,updateKey, event.target.value))
			      this.setState({
				      stageParam: event.target.value
			      }, () => this.changeValue())
		      }}
		      type='number'
		      measureUnits={measureUnits}
		      style={{
			      flex: 1
		      }}
	      />
      </div>
    )
  }

}

export default StageParamField;