import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { COLORS } from '../../../Styles/AppColors';
import { CONTAINER_CONSTANTS } from '../../../Styles/ContainerConstants';
import _ from 'lodash';

const styles = () => ({
  cssLabel: {
    '&$cssFocused': {
      color: COLORS.BLUE
    },
    transform: 'translate(14px, 14px) scale(1)',
    top: 0,
	  height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT,
  },
  cssFocused: {
    height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT
  },
  cssOutlinedInput: {
    height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT,
    '&$cssFocused $notchedOutline': {
      borderColor: COLORS.BLUE,
    },
  },
  // inputSizes: {
  //   height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT,
  // },
  notchedOutline: {
    height: CONTAINER_CONSTANTS.TEXT_FIELD_HEIGHT,
    top: 0,
  },
});

class OutlinedTextfield extends Component {
  render(){
    const {
      classes,
      measureUnits
    } = this.props;

    return(
      <TextField
        variant='outlined'
        // className={classes.inputSizes}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          }
        }}
        InputProps={{
          endAdornment: measureUnits && (
            <InputAdornment 
              variant="filled" 
              position="end"
            >
              {measureUnits}
            </InputAdornment>
          ),
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline
          },
        }}
        style={_.assign({}, this.props.style)}
        {...this.props}
      />
    )
  }
}

OutlinedTextfield.propTypes = {
  classes: PropTypes.object.isRequired,
  measureUnits: PropTypes.string,
};

export default withStyles(styles)(OutlinedTextfield);