import React, {PureComponent} from 'react';

import MaterialsAdding from './MaterialsAdding/MaterialsAdding';
import LayersList from './LayersList';
import {OutlinedButton} from "../../../_NewDesignComponents/";
import {
  ArmorMaterialSizes
} from '../../../_NewDesignComponents/ArmorMaterials'
import _ from 'lodash';
import { COLORS } from '../../../../../Styles/AppColors';

// const styles = {
//   surfaceContainer: {
//     width: '100%',
//   },
//   connectSurfaceButton: {
//     marginTop: 12,
//   }
// };

class ArmorSurfaceContent extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      width: 0,
      length: 0
    };

    this.changeWidth = 
      _.debounce(() => this.props.SetSurfaceWidth(this.state.width), 1000)
    
    this.changeLength = 
      _.debounce(() => this.props.SetSurfaceHeight(this.state.length), 1000)
    }

  generateSortedLayersList = (layers) => {
		const layersListSortedByStraightAngle = _.partition(layers, layer => {
			return layer.fiberDirection === '0-90'
		});
		const straightAngleLayersList = layersListSortedByStraightAngle[0];
		const nonStraightAngleLayersList = layersListSortedByStraightAngle[1];
		const uniqLayers = _.map(_.uniqBy(layers, (layer) => {
			return layer.material._id
		}), uniqMaterial => {
			return {
				_id: uniqMaterial.material._id,
				title: uniqMaterial.material.title,
			};
		});
		let generatedLayersList = [];
		_.each(uniqLayers, uniqLayer => {
			const straightAngleLayersCount = _.size(_.filter(straightAngleLayersList, (layer) => {
				return layer.material._id === uniqLayer._id;
			}));
			const nonStraightAngleLayersCount = _.size(_.filter(nonStraightAngleLayersList, (layer) => {
				return layer.material._id === uniqLayer._id;
			}));
			if (straightAngleLayersCount > 0) {
				generatedLayersList = _.concat(generatedLayersList, [{
					materialId: uniqLayer._id,
					material: uniqLayer.title,
					fiberDirection: 'straight',
					layersCount: straightAngleLayersCount,
				}]);
			}

			if (nonStraightAngleLayersCount > 0) {
				generatedLayersList = _.concat(generatedLayersList, [{
					materialId: uniqLayer._id,
					material: uniqLayer.title,
					fiberDirection: 'notStraight',
					layersCount: nonStraightAngleLayersCount,
				}])
			}
		});
		return _.sortBy(generatedLayersList, ['material', 'angle']);
	};

  componentDidMount(){
    this.setState({
      width: this.props.width,
      length: this.props.height
    })
  }

  render() {
    const {
      layers,
      surfaceId,
      angles,

      selectedMaterialId,
      layersCount,
      selectedAngle,
      armorMaterials
    } = this.props;
    return(
      <div
        className='surfaceContainer'
      >
        <ArmorMaterialSizes
          width={this.state.width}
          onChangeWidth={e => {
            this.setState({width: e.target.value},
              () => this.changeWidth()  
            )
          }}
          length={this.state.length}
          onChangeLength={e => {
            this.setState({length: e.target.value},
              () => this.changeLength()  
            )
          }}
        />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              marginTop: 16
            }}
          >
            <span
              style={{
                letterSpacing: -0.2,
                lineHeight: '16px',
                fontSize: '14px',
                fontFamily: 'Roboto',
                fontWeight: 500,
                color: COLORS.TEXT_COLOR,
                marginBottom: 4
              }}
            >
              Материалы
            </span>
            <LayersList
              generatedLayersList={this.generateSortedLayersList(layers)}
              surfaceId={surfaceId}
              angles={angles}
              DeleteLayers={this.props.DeleteLayers}

              OpenChangingSurfaceMaterialDialog={this.props.OpenChangingSurfaceMaterialDialog}

              ChangeLayersCount={this.props.ChangeLayersCount}
            />
          </div>
        
        <MaterialsAdding
          materialNumber={this.generateSortedLayersList(layers).length + 1}
          OpenAddingSurfaceMaterialDialog={this.props.OpenAddingSurfaceMaterialDialog}

          selectedMaterialId={selectedMaterialId}
          layersCount={layersCount}
          selectedAngle={selectedAngle}

          armorMaterials={armorMaterials}

          ChangeSelectedMaterial={this.props.ChangeSelectedMaterial}
          AddSurfaceLayers={this.props.AddSurfaceLayers}

          ChangeSelectedAngle={this.props.ChangeSelectedAngle}

          ChangeLayersCount={this.props.ChangeLayersCount}
          SetLayersCount={this.props.SetLayersCount}
        />
        <OutlinedButton
          onClick={this.props.ConnectSurfaceWithEdges}
          label='Привязать грани к поверхности'
        />
      </div>
    )
  }
}

export default ArmorSurfaceContent;
