import React, { Component } from 'react';
import {
	Typography
} from '@material-ui/core';

import api from '../../Utils/API';
import MaterialsAmounts from "../DumbComponents/TechDocReadOnly/MaterialsAmounts";
import ArmorCut from "../DumbComponents/TechDocReadOnly/ArmorCut";
import OblationalCut from "../DumbComponents/TechDocReadOnly/OblationalCut";
import ProductionChain from "../DumbComponents/TechDocReadOnly/ProductionChain";

export default class TechDocReadOnly extends Component {
	constructor(props) {
		super(props);
		this.state = {
			td: {},
			msc: [],
			surfaces: [],
			materials: [],
			isLoaded: false,
		};
	};

	componentDidMount() {
		Promise.all([
			api.get(`techDocDescription/${this.props.params.techDocId}`),
			api.get('materialSuperCategories')
				.then((msc) => {
					return msc.results
				}),
			api.get(`techDocs/${this.props.params.techDocId}/surfaces`),
			api.get(`techDocs/${this.props.params.techDocId}/materials`),
		])
			.then(([td, msc, surfaces, materials]) => {
				this.setState({ td, msc, surfaces, materials, isLoaded: true })
			});
	};

	render() {
		const {
			td,
			msc,
			isLoaded,
			surfaces,
			materials,
		} = this.state;
		const {
			title,
			technology,
			type,
			productionChain,
			oblationalCuttingGroups,
			oblationalNotCuttingGroups,
			materialsAmounts
		} = td;
		const isDefault = type === 'default';
		return (
			<div
				style={{
					padding: 24,
					overflowY: 'auto',
					height: '100%',
					display: 'flex',
					flex: 1,
				}}
			>
				{isLoaded && <div
					style={{
						width: '100%',
					}}
				>
					<Typography
						variant='headline'
					>
						Название технологической карты <b>{title}</b>
					</Typography>
					<Typography
						variant='subtitle1'
					>
						Тип технологической карты <b>{isDefault ? 'Стандартная' : 'Сборочная'}</b>
					</Typography>
					{isDefault && <Typography
						variant='subtitle1'
					>
						Технология <b>{technology.title}</b>
					</Typography>}

					{isDefault && <MaterialsAmounts
						materialsAmounts={materialsAmounts}
						msc={msc}
					/>}
					{isDefault && <ArmorCut
						surfaces={surfaces}
					/>}
					{isDefault && <OblationalCut
						oblationalCuttingGroups={oblationalCuttingGroups}
						oblationalNotCuttingGroups={oblationalNotCuttingGroups}
						materials={materials}
					/>}
					<ProductionChain
						productionChain={productionChain}
					/>
				</div>}
			</div>
		)
	}
};
