import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  OutlinedTable
} from '../'

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

import {
  COLORS
} from '../../../../Styles/AppColors'

import _ from 'lodash'

class OblationalMaterialsTable extends Component {
  render(){
    const {
      oblationalCuttingGroups,
      oblationalNotCuttingGroups
    } = this.props.stage;
    const spiralTube = _.find(oblationalNotCuttingGroups, oncg => {
      return oncg.groupTag.key === 'spiral_tube';
    });
    return(
      <div
        style={{
          marginTop: 24
        }}
      >
        {(_.some(oblationalCuttingGroups, ocg => {
	        return _.isUndefined(ocg.material)
        }) || _.some(oblationalNotCuttingGroups, oncg => {
	        return _.isUndefined(oncg.material)
        })) ?
            <span
              style={{
                fontSize: '14px',
                fontFamily: 'Roboto',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: -0.2,
                color: COLORS.TEXT_COLOR
              }}
            >
              Не выбраны все жертвенные материалы, вернитесь на этап <b>Подготовка материалов</b>
            </span>
          :
            <Fragment>
              <span
                style={{
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: -0.2,
                  color: COLORS.TEXT_COLOR,
                }}
              >
                Порядок выкладки материалов:
              </span>
              <OutlinedTable
                style={{
                  marginTop: 8,
                  display: 'block',
                  overflowX: 'auto'
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      padding='dense'
                    >
                      #
                    </TableCell>
                    <TableCell
                      component="th" 
                      scope="row"
                    >
                      Материал
                    </TableCell>
                    <TableCell>
                      Тип 
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {_.map(oblationalCuttingGroups, (ocg, ocgId) => {
									const groupTagTitle = ocg.groupTag.title + (!_.isUndefined(ocg.isBag) ? ` (${ocg.isBag ? 'мешок' : 'пакет'})` : '');
                  if (ocgId === 2)
                    return(
                      <Fragment
                        key={ocg._id}
                      >
                        {!_.isUndefined(spiralTube) && !_.isUndefined(spiralTube.material) &&
                          <TableRow>
                            <TableCell
                              padding='dense'
                            >
                              {ocgId + 1}
                            </TableCell>
                            <TableCell
                              component="th" 
                              scope="row"
                            >
                              {!_.isUndefined(spiralTube) && !_.isUndefined(spiralTube.material) ? spiralTube.material.title : 'Материал не выбран, вернитесь на этап раскрой'}
                            </TableCell>
                            <TableCell>
                              {spiralTube.groupTag.title}
                            </TableCell>
                          </TableRow>
                        }
                        <TableRow>
                          <TableCell
                            padding='dense'
                          >
                            {!_.isUndefined(spiralTube) &&!_.isUndefined(spiralTube.material) ? ocgId + 2 : ocgId +1}
                          </TableCell>
                          <TableCell
                            component="th" 
                            scope="row"
                          >
                            {!_.isUndefined(ocg.material) ? ocg.material.title : 'Материал не выбран, вернитесь на этап раскрой'}
                          </TableCell>
                          <TableCell>
                            {groupTagTitle}
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    )
                    else return (!_.isUndefined(ocg.material) &&
										<TableRow
											key={ocg._id}
										>
											<TableCell
                        padding='dense'
                      >
												{ocgId + 1}
											</TableCell>
											<TableCell
                        component="th" 
                        scope="row"
                      >
												{!_.isUndefined(ocg.material) ? ocg.material.title : 'Материал не выбран, вернитесь на этап раскрой'}
											</TableCell>
                      <TableCell>
												{groupTagTitle}
											</TableCell>
										</TableRow>
									)})
								}
                </TableBody>
              </OutlinedTable>
            </Fragment>
          }
      </div>
    )
  }
}

OblationalMaterialsTable.propTypes = {
  stage: PropTypes.object
}

export default OblationalMaterialsTable;