import React, { PureComponent } from 'react';

import {
	IconButton,
	TextField,
} from '@material-ui/core';

import {
	Remove,
} from '@material-ui/icons';

const styles = {
	diagramStepContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '12px 0'
	},
	stepTextField: {
		marginLeft: 24,
	}
};

class PolymerizationDiagramStep extends PureComponent {
	render() {
		const {
			step,
			stepId
		} = this.props;
		const {
			time,
			temperature
		} = step;
		return(
			<div
				style={styles.diagramStepContainer}
			>
				<IconButton
					onClick={() => this.props.RemoveStepById(stepId)}
				>
					<Remove/>
				</IconButton>
				<TextField
					label='Время, ч'
					value={time}
					type='number'
					onChange={(event) => this.props.ChangeStepById(stepId, 'time', event.target.value)}
				/>
				<TextField
					label='Температура, ℃'
					value={temperature}
					style={styles.stepTextField}
					type='number'
					onChange={(event) => this.props.ChangeStepById(stepId, 'temperature', event.target.value)}
				/>
			</div>
		)
	}
};

export default PolymerizationDiagramStep;
