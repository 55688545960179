import React, {Component} from 'react';

import {
  TableCell,
  TableBody,
  TableRow,
  IconButton,
} from '@material-ui/core';

import {
  Remove
} from '@material-ui/icons';

import _ from 'lodash';

class BodyResinMaterialsInfo extends Component{
  render(){
    const {
      resinMaterials,
    } = this.props;
    return(
      <TableBody>
        {_.map(resinMaterials, (resinMaterial, resinMaterialId) => {
          return <TableRow
            key={resinMaterial.material._id}
          >
            <TableCell>{resinMaterialId + 1}</TableCell>
            <TableCell>{resinMaterial.material.title}</TableCell>
            <TableCell>{`${_.floor(resinMaterial.amount * 100) / 100} ${resinMaterial.material.category.measureUnits}`}</TableCell>
            <TableCell>{_.floor(resinMaterial.material.price * resinMaterial.amount, 2) } ₽ </TableCell>
            <TableCell>
              <IconButton
                onClick={() => this.props.DeleteMaterial(resinMaterial.material._id)}
              >
                <Remove/>
              </IconButton>
            </TableCell>
          </TableRow>
        })}
      </TableBody>
    );
  }
}

export default BodyResinMaterialsInfo;