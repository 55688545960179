import {
  HTTPConstants
} from '../Constants';

const RequestError = (code) => {
  return {
    type: HTTPConstants.REQUEST_ERROR,
    data: code
  }
};

const CloseWarning = () => {
  return {
    type: HTTPConstants.CLOSE_WARNING,
  }
}

export default {
  RequestError,
	CloseWarning,
}