import {
  TechDocMaterialsAmountConstants
} from '../Constants'

import _ from 'lodash';

const initState = { 
  materialsAmount: [],
  materialsSuperCategories: [],
  selectedResin: {},
  selectedOblationalMaterial: {}
};

const SET_SELECTED_OBLATIONAL_MATERIAL = (newState, oldState) => {
  return {
    ...oldState,
    selectedOblationalMaterial: _.assign(oldState.selectedOblationalMaterial, newState),
  };
};

const SET_MATERIALS_AMOUNT = (newState, oldState) => {
  return {
    ...oldState,
    materialsAmount: newState
  };
};

const SET_SELECTED_RESIN = (newState, oldState) => {
  return {
    ...oldState,
    selectedResin: _.assign(oldState.selectedResin, newState),
  }
}

const GET_MATERIALS_SUPERCATEGORIES = (newState, oldState) => {
  return{
    ...oldState,
    materialsSuperCategories: newState
  }
}

const ADD_CONNECTOR = (oldState) => {
  let oblationalMaterialsList = 
    JSON.parse(JSON.stringify(oldState.oblationalMaterials));
  return{
    ...oldState,
    oblationalMaterials: _.concat(oblationalMaterialsList, {
      title: '',
      amount: 0,
      price: 0,
    })
  }
}

const SET_CONNECTOR_AMOUNT = (newState, oldState) => {
  let newConnector = 
    JSON.parse(JSON.stringify(oldState.oblationalMaterials)); 
    newConnector[newState.index].amount = newState.amount;
  return{
    ...oldState,
    oblationalMaterials: newConnector
  }
};

const SET_RESIN_AMOUNT = (newState, oldState) => {
  let newResin = 
    JSON.parse(JSON.stringify(oldState.resin)); 
  newResin.amount = newState.amount;
  return{
    ...oldState,
    resin: newResin
  }
};

const SELECT_CONNECTOR = (newState, oldState) => {
  let newConnector = 
    JSON.parse(JSON.stringify(oldState.oblationalMaterials));
  newConnector[newState.index].title = newState.title;
  newConnector[newState.index].amount = 0
  return {
    ...oldState,
    oblationalMaterials: newConnector,
  }
};

const SELECT_RESIN = (newState, oldState) => {
  let newResin = 
    JSON.parse(JSON.stringify(oldState.resin));
  newResin.title = newState.title;
  newResin.type = '';
  return {
    ...oldState,
    resin: newResin,
  }
};

const SELECT_TYPE = (newState, oldState) => {
  let newType = 
    JSON.parse(JSON.stringify(oldState.resin));
  newType.type = newState.type;
  return {
    ...oldState,
    resin: newType
  }
};

export default (state = initState, action) => {
  switch(action.type){
    case TechDocMaterialsAmountConstants.SELECT_RESIN:
      return SELECT_RESIN(action.data, state);

    case TechDocMaterialsAmountConstants.SELECT_TYPE:
      return SELECT_TYPE(action.data, state);
 
    case TechDocMaterialsAmountConstants.SET_RESIN_AMOUNT:
      return SET_RESIN_AMOUNT(action.data, state);

    case TechDocMaterialsAmountConstants.SELECT_CONNECTOR:
      return SELECT_CONNECTOR(action.data, state);

    case TechDocMaterialsAmountConstants.SET_CONNECTOR_AMOUNT:
      return SET_CONNECTOR_AMOUNT(action.data, state);

    case TechDocMaterialsAmountConstants.ADD_CONNECTOR:
      return ADD_CONNECTOR(state);

    case TechDocMaterialsAmountConstants.SET_MATERIALS_AMOUNT:
      return SET_MATERIALS_AMOUNT(action.data, state);

    case TechDocMaterialsAmountConstants.GET_MATERIALS_SUPERCATEGORIES:
      return GET_MATERIALS_SUPERCATEGORIES(action.data, state);

    case TechDocMaterialsAmountConstants.SET_SELECTED_RESIN:
      return SET_SELECTED_RESIN(action.data, state);

	  case TechDocMaterialsAmountConstants.SET_SELECTED_OBLATIONAL_MATERIAL:
		  return SET_SELECTED_OBLATIONAL_MATERIAL(action.data, state);

    default:
      return state;
  }
};