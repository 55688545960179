import {
} from '../Constants';

const initState = {
  routes: [
    {
      title: 'Проекты',
      key: 'projects',
      path: '/project',
      routeKeys: ['projects', 'realizations', 'realization']
    },
    {
      title: 'Изделия',
      key: 'techdocs',
      path: '/product',
      routeKeys: ['products', 'techdocs', 'techdoc']
    },
    {
      title: 'Материалы',
      key: 'materials',
      path: '/materials',
      routeKeys: ['materials']
    },
    {
      title: 'Пользователи',
      key: 'users',
      path: '/users',
      routeKeys: ['users'],
    },
    {
      title: 'Аналитика',
      key: 'analytics',
      path: '/analytics',
      routeKeys: ['analytics'],
    }
  ]
};

export default (state = initState, action) => {
  switch (action.type) {
    default:
      return state;
  }
}