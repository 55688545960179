import React, {Component} from 'react';

import QCCriteriaList from './QCCriteriaList';
import {CONTAINER_CONSTANTS} from "../../../Styles/ContainerConstants";
import OutlinedButton from "../_NewDesignComponents/OutlinedButton";

const styles = {
  addButton:{
    marginLeft: CONTAINER_CONSTANTS.QC_CRITERION_MARGIN,
  },
  listItemText:{
    whiteSpace:'pre-wrap',
  },
  typograpy:{
    overflow: 'hidden', 
    textOverflow: 'ellipsis'
  },
  criteriaList:{
    display:'block',
    margin: CONTAINER_CONSTANTS.QC_CRITERION_MARGIN
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: -0.2,
    marginBottom: 12,
    marginLeft: CONTAINER_CONSTANTS.QC_CRITERION_MARGIN,
  }
};

class QCCriteriaTable extends Component{
  render(){
    const{
      criteriaQC,
      taskId,
      isEditQCCriterionDialogOpened
    } = this.props;
    return(
      <div
        className='containerQCCriteria'
      >
        <span
          style={styles.text}
        >
          Критерии контроля
        </span>
        <QCCriteriaList
          type='important'
          title='Обязательные:'
          isEditQCCriterionDialogOpened={isEditQCCriterionDialogOpened}
          taskId={taskId}
          criteriaQC={criteriaQC}

          CheckQCCriterion={this.props.CheckQCCriterion}
        />
        <QCCriteriaList
          type='notImportant'
          title='Необязательные:'
          isEditQCCriterionDialogOpened={isEditQCCriterionDialogOpened}
          taskId={taskId}
          criteriaQC={criteriaQC}

          CheckQCCriterion={this.props.CheckQCCriterion}
        />
        {/*<div*/}
          {/*className='addCriterionButton'*/}
        {/*>*/}
	        {/*<IconButton*/}
		        {/*style={styles.addButton}*/}
		        {/*onClick={this.props.OpenAddingQCCriterionDialog}*/}
	        {/*>*/}
		        {/*<Add />*/}
	        {/*</IconButton>*/}
          {/*<Typography*/}
            {/*variant='subheading'*/}
          {/*>*/}
            {/*КРИТЕРИЙ*/}
          {/*</Typography>*/}
        {/*</div>*/}
        <OutlinedButton
	        onClick={this.props.OpenAddingQCCriterionDialog}
          style={styles.addButton}
          label={'Добавить критерий'}
        />
      </div>
    );
  }
}

export default QCCriteriaTable;