import React, { Component } from 'react';

import _ from 'lodash';

import { 
  DragDropContext, 
  Droppable, 
  Draggable 
} from 'react-beautiful-dnd';

import SingleLayerRowTaskTemplates from './SingleLayerRowTaskTemplates';

class LayersFacingInfoTaskTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layers: [],
    }
  };

  componentDidMount() {
    this.setState({
      layers: this.props.layers,
    });
  };

  render(){
    const {
      taskId
    } = this.props;
    const {
      layers,
    } = this.state;
    return(
      <div 
        className='layersFacingInfoTaskTemplates'
      >
        <DragDropContext 
          onDragEnd={(result) => {
            if (!result || !result.destination || result.destination.index === result.source.index) {
              return
            };
            let objectToDrag;
            let newOrderLayers = [];
            if (result.source.index < result.destination.index) {
              newOrderLayers = _.reduce(layers, (newOrderLayers, layer, layerId) => {
                if (layerId === result.source.index) {
                  objectToDrag = layer;
                  return newOrderLayers;
                } else if (layerId === result.destination.index) {
                  return _.concat(newOrderLayers, [layer, objectToDrag]);
                } else {
                  return _.concat(newOrderLayers, layer);
                }
              }, []);
            } else {
              newOrderLayers = _.reverse(_.reduceRight(layers, (newOrderLayers, layer, layerId) => {
                if (layerId === result.source.index) {
                  objectToDrag = layer;
                  return newOrderLayers;
                } else if (layerId === result.destination.index) {
                  return _.concat(newOrderLayers, [layer, objectToDrag]);
                } else {
                  return _.concat(newOrderLayers, layer);
                }
              }, []))
            };
            this.props.SetCustomTaskDescription(taskId, 'layers', newOrderLayers);
            this.setState({
              layers: newOrderLayers,
            })
            return result;
          }}
        >
          <Droppable 
            droppableId='droppable'
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
              >
                {_.map(layers, (layer, layerId) => (
                  <Draggable 
                    draggableId={layerId + 1}
                    index={layerId}
                    key={layerId} 
                  >
                    {(provided) => (
                      <div>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className='layersFacingRowContainer'
                        >
                          <SingleLayerRowTaskTemplates
                            index={layerId}
                            materialTitle={layer.material.title}
                            surfaceTitle='Поверхность'
                            rotationAngle={layer.fiberDirection}
                            surfaceColor={layer.surface.color}
                          />
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

export default LayersFacingInfoTaskTemplates;
