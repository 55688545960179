import React, { PureComponent } from 'react';
import {
  OutlinedButton
} from '../../../DumbComponents/_NewDesignComponents/';

class SurfacesAdding extends PureComponent {
  render() {
    return(
      <OutlinedButton
        label={'+ Добавить поверхность'}
        onClick={this.props.AddArmorSurface}
      />
    )
  }
};

export default SurfacesAdding;
