import React, {Component} from 'react';

import _ from 'lodash';

import {
  Typography,
} from '@material-ui/core';

import moment from 'moment';

const styles = {
  flexBox: {
    flex: 1
  }
}

class TaskDescriptionTopPanel extends Component {
  render(){
    const {
      assignedTo,
      durationMinutes,
      isQCRequired,
      date,
    } = this.props;
    return(     
      <div
        className='taskDescriptionTopPanelContainer'
      >
        <div 
          className='taskDescriptionTopPanelRow'
        >
          <Typography
            variant='body1'
            style={styles.flexBox}
          >
            Исполнитель
          </Typography>
          <Typography
            variant='body1'
            style={styles.flexBox}
          >
            <b>{assignedTo}</b>
          </Typography>
        </div>
        <div 
          className='taskDescriptionTopPanelRow'
        >
          <Typography
          variant='body1'
          style={styles.flexBox}
          >
            Срок выполнения
          </Typography>
          <Typography
          variant='body1'
          style={styles.flexBox}
          >
            <b>{!_.isUndefined(date) ? moment(date).format('DD.MM.YYYY').toString() : 'Нe задан'}</b>
          </Typography>
        </div>
        <div 
          className='taskDescriptionTopPanelRow'
        >
          <Typography
            variant='body1'
            style={styles.flexBox}
          >
            Длительность выполнения
          </Typography>
          <Typography
            variant='body1'
            style={styles.flexBox}
          >
            <b>{durationMinutes}</b>
          </Typography>
        </div>
        <div 
          className='taskDescriptionTopPanelRow'
        >
          <Typography
            style={styles.flexBox}
            variant='body1'
          >
            Требует ОТК
          </Typography>

          <Typography
            variant='body1'
            style={styles.flexBox}
          >
            <b>{isQCRequired ? 'Да' : 'Нет'}</b>
          </Typography>
        </div>
      </div>
    )
  }
}

export default TaskDescriptionTopPanel;
