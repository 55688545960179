import React, { PureComponent } from 'react';

import _ from "lodash";

import {
	TextField,
	IconButton,
	Typography,
} from '@material-ui/core';

import {
	Remove,
	Add,
} from '@material-ui/icons';

import PolymerizationDiagramStep from './PolymerizationDiagramStep';

const styles = {
	diagramCreatingContainer: {
		marginTop: 12,
	},
	diagramCreatingHeader: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		alignItems: 'center',
		marginTop: 12,
	}
};

class PolymerizationDiagram extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			typeTitle: '',
			steps: [],
		};
		this.ChangeTypeTitle = _.debounce(() => this.props.ChangePGParam(this.props.rgId, this.state.typeTitle), 1000)
	}

	componentDidMount() {
		const {
			rg
		} = this.props;
		const {
			typeTitle,
			steps,
		} = rg;
		this.setState({
			typeTitle,
			steps,
		})
	};

	ChangeSteps = (newSteps) => {
		this.props.ChangePGParam(this.props.rgId, 'steps', newSteps)
	};

	RemoveStepById = (stepToRemoveId) => {
		const steps = this.props.rg.steps;
		this.ChangeSteps(_.reject(steps, (step, stepId) => {
			return stepId === stepToRemoveId;
		}));
	};

	ChangeStepById = (stepId, paramTitle, value) => {
		let steps = this.props.rg.steps;
		steps[stepId][paramTitle] = value;
		this.ChangeSteps(steps);
	};

	render() {
		const {
			rg,
			rgId
		} = this.props;
		const {
			typeTitle,
			steps,
		} = rg;
		return(
			<div
				style={styles.diagramCreatingContainer}
			>
				<div
					style={styles.diagramCreatingHeader}
				>
					<IconButton
						onClick={() => this.props.RemoveGraphById(rgId)}
					>
						<Remove/>
					</IconButton>
					<TextField
						fullWidth
						value={typeTitle}
						helperText={'Название графика'}
						onChange={(event) => this.props.ChangePGParam(rgId, 'typeTitle', event.target.value)}
					/>
				</div>
				<div
					style={styles.diagramCreatingHeader}
				>
					<Typography
						variant='body1'
					>
						ЦИКЛ ПОЛИМЕРИЗАЦИИ
					</Typography>
					<IconButton
						onClick={() => this.ChangeSteps(_.concat(steps, {
							time: 0,
							temperature: 0,
						}))}
					>
						<Add/>
					</IconButton>
				</div>
				<div>
					{_.map(steps, (step, stepId) => {
						return <PolymerizationDiagramStep
							key={stepId}
							stepId={stepId}
							step={step}

							ChangeStepById={this.ChangeStepById.bind(this)}
							RemoveStepById={this.RemoveStepById.bind(this)}
						/>
					})}
				</div>
			</div>
		)
	}
};

export default PolymerizationDiagram;
