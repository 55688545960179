import React, { Component } from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';

const styles = {
  taskCardHeader: {
    borderBottom: '1px solid grey'
  },
  taskCard: {
    margin: 12,
  }
}

class QCMiniDescription extends Component {
  render(){
    const {
      title,
    } = this.props.task
    return(
      <Card
        style={styles.taskCard}
      >
        <CardHeader 
          title={
            <Typography>
              <b>{title}</b>
            </Typography>
          }
          style={{backgroundColor: '#cfcfcf'}}
        />
        <CardContent
          style={{display: 'flex', flexDirection:'column'}}
        >
          <Typography
            variant='body1'
          >
            Производится <b>{title}</b> по завершению проверяемой задачи
          </Typography>
        </CardContent>
      </Card>
    )
  }
}

export default QCMiniDescription;