import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { COLORS } from '../../../Styles/AppColors';

const styles = () => ({
  removeIcon: {
    position: 'absolute',
    zIndex: 1,
    top: -10,
    right: -10,
    backgroundColor: '#fff',
    borderRadius: 12,
    '&:hover': {
      color: COLORS.ICON_GRAY,
      cursor: 'pointer',
    },
  },
  editIcon: {
    fontSize: '16px',
    zIndex: 1,
    '&:hover': {
      color: COLORS.ICON_GRAY,
      cursor: 'pointer',
    },
  },
  font: {
    fontSize: '16px',
  }
});

class Attachment extends PureComponent {
  render(){
    const {
      classes,
      uri
    } = this.props;
    return(
      <div
        style={{
          height: 134,
        }}
      >
      <div
        style={{
          position: 'relative',
          // backgroundImage: `url(${uri}` + `?t=${new Date().getTime()})`,
          // backgroundRepeat: 'no-repeat',
          // backgroundSize: '100% 100%',
          height: 90,
          cursor: 'zoom-in'
        }}
        onClick={this.props.onClickImage}
      >
        <img
          src={uri + `?t=${new Date().getTime()})`}
          style={{maxWidth: '100%', height: '100%', width: '100%'}}
          alt={'stage attachment'}
        />
        <Icon
          color='disabled'
          classes={{
            root: classes.removeIcon
          }}
          onClick={this.props.onClickRemove}
        >
          cancel
        </Icon>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 10,
            cursor: 'pointer'
          }}
          onClick={this.props.onClickEdit}
        >
          <Icon
            color='disabled'
            classes={{
              root: classes.editIcon,
              fontSizeSmall: classes.font
            }}
            fontSize='small'
          >
            edit
          </Icon>
          <span
            style={{
              fontFamily: 'Roboto',
              opacity: 0.6,
              color: COLORS.TEXT_COLOR,
              fontSize: 12,
              lineHeight: '14px',
              fontWeight: 500,
              letterSpacing: 1.1,
            }}
          >
            ИЗМЕНИТЬ
          </span>
        </div>
      </div>
    )
  }
}

Attachment.propTypes = {
  classes: PropTypes.object.isRequired,
  onClickImage: PropTypes.func.isRequired,
  onClickRemove: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  uri: PropTypes.string.isRequired

}

export default withStyles(styles)(Attachment)
