const MyStoreConstants = {
	SET_MY_STORE_MATERIALS: 'SET_MY_STORE_MATERIALS',
	SET_MY_STORE_NEW_MATERIALS: 'SET_MY_STORE_NEW_MATERIALS',
	SET_MY_STORE_SELECTED_MATERIAL: 'SET_MY_STORE_SELECTED_MATERIAL',

	SET_MY_STORE_SELECTED_CATEGORY: 'SET_MY_STORE_SELECTED_CATEGORY',
	SET_MY_STORE_SELECTED_SUPER_CATEGORY: 'SET_MY_STORE_SELECTED_SUPER_CATEGORY',
	SET_MATERIAL_IS_CONFIRMED: 'SET_MATERIAL_IS_CONFIRMED',

	SET_MY_STORE_DATA: 'SET_MY_STORE_DATA',

	SET_MY_STORE_TECHNOLOGIES: 'SET_MY_STORE_TECHNOLOGIES',

	SET_MY_STORE_GROUPTAG: 'SET_MY_STORE_GROUPTAG',

	SET_MY_STORE_COMPLEMENTARY_RULES: 'SET_MY_STORE_COMPLEMENTARY_RULES',

	SET_MY_STORE_SELECTED_CODE: 'SET_MY_STORE_SELECTED_CODE',
};

export default MyStoreConstants;
