import {
  TechnologiesConstants,
} from '../Constants';

const initState = {
  availableTechnologiesList: [
    {
      id: 1,
      title: 'Инфузия',
      type: 'infusion',
    },
    {
      id: 2,
      title: 'Препрег',
      type: 'prepreg',
    },
  ],
  selectedTechnology: '',
};

const SET_TECHNOLOGY = (newState, oldState) => {
  return {
    ...oldState,
    selectedTechnology: newState,
  }
}


export default (state = initState, action) => {
  switch (action.type) {
    case TechnologiesConstants.SET_TECHNOLOGY:
      return SET_TECHNOLOGY(action.data, state);
      
    default:
      return state;
  }
};

