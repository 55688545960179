import React, { Component, Fragment } from 'react';

import {
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Snackbar
} from '@material-ui/core'

import {
  Nfc
} from '@material-ui/icons';

import _ from 'lodash';

class SetNFCToRealization extends Component {
  constructor(props){
    super(props);
    this.state = {
      nfcText: ''
    }
  }

  render(){
    const {
      nfcText
    } = this.state;

    const {
      realizationNFCTag,
      isSetNFCToRealisationOpened,
      nfcProblemSnackbarOpened
    } = this.props;

    return(
      <Fragment>
        <div
          style={{
            display: 'flex',
            margin: 12,
            alignItems: 'center'
          }}
        >
          <Typography
            variant='headline'
          >
            Изменить тег метки {!_.isNil(realizationNFCTag) ? `(${realizationNFCTag})` : null }
          </Typography>
          <IconButton
            onClick={() => {
              this.props.OpenSetNFCRealizationDialog()
              this.setState({
                nfcText: _.isNil(realizationNFCTag) ? '' : realizationNFCTag
              })
            }}
          >
            <Nfc/>
          </IconButton>
        </div>
        <Dialog
          open={isSetNFCToRealisationOpened}
          onClose={()=> {
            this.props.CloseSetNFCRealizationDialog()
            this.setState({
              nfcText: '',
            })
          }}
        >
          <DialogTitle>
            ВВЕДИТЕ ТЕГ МЕТКИ
          </DialogTitle>
          <DialogContent>
            <TextField
              value={nfcText}
              onChange={(event) => {
                this.setState({
                  nfcText: event.target.value
                })
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.props.CloseSetNFCRealizationDialog()
                this.setState({
                  nfcText: '',
                })
              }}
            >
              ОТМЕНИТЬ
            </Button>
            <Button
              onClick={() => {
                this.props.SetRealizationNFCTag(nfcText);
                this.setState({
                  nfcText: '',
                })
              }}
              disabled={_.isEmpty(nfcText)}
            >
              СОХРАНИТЬ
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={nfcProblemSnackbarOpened}
          onClose={()=> {
            this.props.CloseSnackbarNFCProblem()
          }}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          message={<span
            id='message-id'
          >
            {'Не подходящий тег'}
          </span>}
        />
      </Fragment>
    )
  }
}

export default SetNFCToRealization;