import React, { Component } from 'react';

import _ from 'lodash';

import {
	GridListTile,
	GridList,
} from '@material-ui/core';

import api from '../../../Utils/API';

const styles = {
  gridListTile: {
    width: 192,
    height: 144, 
    margin: 6
  },
  editColor:{
    color:'rgba(255, 255, 255, 0.54)'
  },
  addAttachmentButton:{
    marginTop: '12px',
    marginLeft: '12px',
    width: '192px'
  },
  gridList: {
    marginTop: 12
  }
}

class ProjectAttachments extends Component {
  render(){
    const {
      attachmentsList,
    } = this.props;
    return(  
      <GridList
        style={styles.gridList}
      >
        {_.map(attachmentsList, (attachment) => {
          return(
            <GridListTile
              style={styles.gridListTile}
              key={attachment.id}
            >
              <img
                src={api.getHost() + attachment.url}
                alt='Error'
              />
            </GridListTile>             
          )})
        }
      </GridList>
    )
  }
}

export default ProjectAttachments;
