import React, {Component, Fragment} from 'react';

import{
  Stepper,
  Step,
  Typography,
  StepButton,
} from '@material-ui/core';

import _ from 'lodash';

const styles = {
  stepper: {
    padding: '10px',
    paddingTop: '24px',
    paddingBottom: '24px'
  },
  stepButton:{
    flexDirection: 'column',
  }
}

class TechDocStepperStepsOpen extends Component{
  render(){
    const{
      stepList,
      activeStep,
    } = this.props;
    return(
      <div 
        className='centerStepper'
      >
        <Stepper
          activeStep={activeStep}
          style={styles.stepper}
          alternativeLabel
          nonLinear
        >
          {
            stepList.map((step, stepId) => {
              return (
                <Step key={stepId}>
                  <StepButton
                    onClick={() => this.props.OnClickStep(stepId)}
                    completed={false}
                    style={styles.stepButton}
                  >
                    <Fragment>
                      {activeStep === stepId &&
                        <Typography 
                          variant='subheading'
                        >
                          <b>{step.title}</b>
                        </Typography>
                      }
                      {activeStep !== stepId && 
                        <Typography 
                          variant='subheading'
                        >
                          {step.title}
                        </Typography>
                      }
                      <Fragment>
                        {!_.isUndefined(step.materialsCount) &&
                          <Typography>
                            {`Матер.: ${step.materialsCount}`}
                          </Typography>                                         
                        }
                        {!_.isUndefined(step.timeDuration) &&
                          <Typography>
                            t=<b>{`${Math.floor(step.timeDuration / 60).toString().padStart(2,0)}:${(step.timeDuration % 60).toString().padStart(2,0)}`}</b>
                          </Typography>
                        }
                        {!_.isUndefined(step.price) &&
                          <Typography>
                            ₽=<b>{`${isNaN(step.price) ? 0 : _.floor(step.price, 2)}`}</b>
                          </Typography>
                        }
                      </Fragment>
                    </Fragment>
                  </StepButton>
                </Step>
              );
            })}
        </Stepper>
      </div>
    );  
  }
}

export default TechDocStepperStepsOpen;
