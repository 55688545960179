import React, { Component } from 'react';

import _ from 'lodash';

import {
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';

import moment from 'moment';

const styles = {
  card: {
    margin: 12,
    backgroundColor: '#FFF'
  }
};

class InReviewTaskCard extends Component {
  render() {
    const {
      title,
      assignedTo,
      project,
      date
    } = this.props.task;
    return(
      <Card
        style={styles.card}
      >
        <CardHeader
          title={
            <Typography
              variant='headline'
            >
              <b>{title}</b>
            </Typography>
          }
        />
        <CardContent
          style={{    
            display:'flex',
            flexDirection:'column',
          }}
        > 
          <Typography
            variant='body1'
          >
            Дата выполнения: <b>{!_.isUndefined(date) ? moment(date).format('DD.MM.YY') : 'Не выбрана'}</b>
          </Typography>
          <Typography
            variant='body1'
          >
            Исполнитель: <b>{!_.isUndefined(assignedTo) ? _.capitalize(assignedTo.second_name) + ' ' + _.capitalize(assignedTo.first_name) : 'Не выбран' }</b>
          </Typography>
          <Typography
            variant='body1'
          >
            Проект: <b>{!_.isUndefined(project) ? project.title : '' }</b>
          </Typography>
        </CardContent>
      </Card>
    )
  }
}

export default InReviewTaskCard;