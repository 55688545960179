import React, {Component} from 'react';

import Textarea from "react-textarea-autosize";

import { 
  Typography,
} from '@material-ui/core';

import _ from 'lodash';
import {COLORS} from "../../../../Styles/AppColors";

const styles = {
  textarea: {
	  flex: 1,
	  border: 'solid 2px rgba(0, 0, 0, 0.08)',
	  borderRadius:4,
	  width: '100%',
	  maxWidth: '100%',
	  minWidth: '100%',
	  minHeight: '72px',
	  fontFamily: 'Roboto',
	  fontSize: 16,
    lineHeight:'24px',
  },
  text:{
	  flex: 1,
	  height: 16,
	  fontFamily: 'Roboto',
	  fontSize: 14,
	  fontWeight: 470,
	  letterSpacing: -0.2,
    marginBottom:'12px',
    marginTop: '12px'
  }
}

class CustomTaskTemplates extends Component {
  constructor(props){
    super(props);
    this.state = {
      textTemplate: '',
	    onFocused: false
    };
    this.changeTextTemplate = 
      _.debounce(() => this.props.SetCustomTaskDescription(this.props.taskId, 'textTemplate', this.state.textTemplate),
      1000)
  };

  componentDidMount() {
    this.setState({
      textTemplate: this.props.textTemplate,
    })
  };

	onFocus() {
		this.setState({
			onFocused: true
		})
	};

	onBlur() {
		this.setState({
			onFocused: false
		})
	};

  render(){
    const {
	    textTemplate
    } = this.state;
    return(
      <div>
        <Typography
          variant='body1'
          style={styles.text}
        >
          Введите описание задания
        </Typography>
	      <Textarea
		      onChange={(event) => this.setState({textTemplate: event.target.value},
			      () => {this.changeTextTemplate()}
		      )}
		      onFocus={ () => this.onFocus() }
		      onBlur={ () => this.onBlur() }
		      value={textTemplate}
		      style={_.assign(
			      {  },
			      styles.textarea,
			      this.state.onFocused ? { borderColor: COLORS.BLUE, borderWidth: 2 } :  null
		      )}
	      />
      </div>
    );
  }
}

export default CustomTaskTemplates;
