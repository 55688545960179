import React, { Component } from 'react';

import {
  connect,
} from 'react-redux';

import _ from 'lodash';

import {
  TextField,
  Button,
  Snackbar,
  InputAdornment,
  FormControl,
  InputLabel,
  Input,
  IconButton
} from '@material-ui/core';

import {
  Visibility,
  VisibilityOff
} from '@material-ui/icons';

import {
  AuthActions,
} from '../../Redux/Actions'

const styles = {
  textField: {
    width: 300,
    justifyContent: 'flex-end'
  },
  panelAuth: {
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    display:'flex'
  }
}

class AuthorizationPage extends Component {
  constructor(props){
    super(props)
    this.state = {
      showPassword: false
    }
  }
  
  render() {
    const { 
      authInfo
    } = this.props;
    const {
      username,
      password,
      authFailed,
    } = authInfo;
    if (_.isEmpty(localStorage.getItem('token'))) return (
      <div 
        className='panelAuth'
      >
        <div
          className='authPageContainer'
        >
          <div
            className='authPageInputContainer'
          >
            <div>
              <TextField
                id='login-input'
                label='Логин'
                value={username}
                style={styles.textField}
                onChange={(event) => this.props.ChangeUsername(event.target.value)}
                type='login'
                autoComplete='current-login'
                margin='normal'
              />
            </div>
            <div>
              <FormControl
                style={styles.textField}
                margin='normal'
              >
                <InputLabel
                  htmlFor='password-input'
                >
                  Пароль
                </InputLabel>
                <Input
                  autoComplete='current-password'
                  id='password-input'
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(event) => this.props.ChangePassword(event.target.value)}
                  endAdornment={
                    <InputAdornment 
                      position="end"
                    >
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() =>  this.setState(state => ({ showPassword: !state.showPassword }))}
                      >
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div 
              className='authorizationTextField'
            >
              <Button 
                variant='raised' 
                color='primary'
                fullWidth
                onClick={this.props.AuthLogin}
              >
                Войти
              </Button>
            </div>
          </div>
          <div
            className='authPageLogoContainer'
          >
            <div 
              className='authPageLogo'
            />
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={authFailed}
          autoHideDuration={6000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span
            id='message-id'
          >
            {'Вы ввели неверные данные. Повторите попытку'}
          </span>}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    authInfo: state.Auth,
  }),
  dispatch => ({
    ChangeUsername: (username) => {
      dispatch(AuthActions.ChangeUsername(username));
    },
    ChangePassword: (password) => {
      dispatch(AuthActions.ChangePassword(password));
    },
    AuthLogin: () => {
      dispatch(AuthActions.AuthLogin());
    }
  })
)(AuthorizationPage);
