import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../Styles/AppColors';

const styles = {
  checked: {},
  bar:{},
  colorSwitchBase: {
    '&$checked': {
      color: COLORS.BLUE,
      '& + $bar': {
        backgroundColor: COLORS.SWITCH_BLUE,
      },
    },
  },
}

class BlueSwitch extends Component {
  render(){
    const {
      classes
    } = this.props;

    return(
      <Switch
        {...this.props}
        classes={{
          switchBase: classes.colorSwitchBase,
          bar: classes.bar,
          checked: classes.checked
        }}
      />
    )
  }

}

BlueSwitch.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(BlueSwitch);