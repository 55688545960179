import React, {Component} from 'react';

import PreparingLayersInfoTaskTemplates from './PreparingLayersInfoTaskTemplates';

class RiggingPreparing extends Component{
  render(){
    const {     
      separativeLayers,
      taskId,
      separativeLayer,
      data,
      timeBetweenLayers
    } = this.props;
    return(
      <PreparingLayersInfoTaskTemplates
        data={data}
        timeBetweenLayers={timeBetweenLayers}
        separativeLayers={separativeLayers}
        taskId={taskId}
        SetCustomTaskDescription={this.props.SetCustomTaskDescription}
        separativeLayer={separativeLayer}
      />
    );
  }
}

export default RiggingPreparing;
