import {
	MyStoreConstants,
	InterfaceConstants,
} from '../Constants';

import _ from 'lodash';

import api from '../../Utils/API';

const OpenChangingMyStoreMaterialDialog = (materialId) => {
	return (dispatch, getState) => {
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_SELECTED_MATERIAL,
			data: materialId,
		});
		const foundMaterial = _.find(getState().MyStore.myStoreMaterials, { _id: materialId });
		dispatch({
			type: MyStoreConstants.SET_MATERIAL_IS_CONFIRMED,
			data: foundMaterial.isConfirmed,
		});
		const selectedCategory = foundMaterial.category;
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_SELECTED_CATEGORY,
			data: selectedCategory._id
		});
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_SELECTED_SUPER_CATEGORY,
			data: selectedCategory.superCategory._id,
		});
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_SELECTED_CODE,
			data: !_.isUndefined(foundMaterial.moySkladCode) ? foundMaterial.moySkladCode : '',
		});
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_DATA,
			data: !_.isUndefined(foundMaterial.description) &&
				!_.isUndefined(foundMaterial.description.data) ?
				foundMaterial.description.data :
				{},
		});
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_GROUPTAG,
			data: !_.isUndefined(foundMaterial.groupTag) ?
				foundMaterial.groupTag :
				'',
		});
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_COMPLEMENTARY_RULES,
			data: !_.isUndefined(foundMaterial.description) &&
				!_.isEmpty(foundMaterial.description.complementaryRules) ?
				foundMaterial.description.complementaryRules :
				[]
		});
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_TECHNOLOGIES,
			data: foundMaterial.technologies,
		});
		dispatch({
			type: InterfaceConstants.OPEN_CHANGING_MY_STORE_MATERIAL_DIALOG,
		});
	}
};

const CloseChangingMyStoreMaterialDialog = () => {
	return (dispatch) => {
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_SELECTED_MATERIAL,
			data: '',
		});
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_SELECTED_CODE,
			data: '',
		})
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_SELECTED_SUPER_CATEGORY,
			data: '',
		});
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_SELECTED_CATEGORY,
			data: '',
		});
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_DATA,
			data: {},
		})
		dispatch({
			type: InterfaceConstants.CLOSE_CHANGING_MY_STORE_MATERIAL_DIALOG,
		});
	}
};

const GetAllMyStoreMaterials = () => {
	return (dispatch) => {
		return Promise.all([
			api.get('materials'),
			api.get('materialCategories'),
			api.get('materialSuperCategories'),
			api.get('technologies'),
			api.get('groupTags')
		])
			.then(([materials, materialsCategories, materialsSuperCategories, technologies, groupTags]) => {
				dispatch({
					type: MyStoreConstants.SET_MY_STORE_MATERIALS,
					data: {
						materials: materials,
						materialsCategories: materialsCategories.results,
						materialsSuperCategories: materialsSuperCategories.results,
						technologies: technologies.results,
						groupTags: groupTags.results,
					}
				})
			})
	}
};

const ChangeMaterialSuperCategory = (newSuperCategory) => {
	return (dispatch) => {
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_SELECTED_SUPER_CATEGORY,
			data: newSuperCategory,
		});
		dispatch(ChangeMaterialCategory(''))
	}
};

const ChangeMaterialCategory = (newCategory) => {
	return (dispatch) => {
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_SELECTED_CATEGORY,
			data: newCategory,
		});
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_DATA,
			data: {},
		});
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_GROUPTAG,
			data: '',
		});
		dispatch({
			type: MyStoreConstants.SET_MATERIAL_IS_CONFIRMED,
			data: false,
		});
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_COMPLEMENTARY_RULES,
			data: [],
		})
	}
};

const ChangeMaterialConfirmed = (isConfirmed) => {
	return {
		type: MyStoreConstants.SET_MATERIAL_IS_CONFIRMED,
		data: isConfirmed,
	}
};

const ChangeMaterialData = (newData) => {
	return {
		type: MyStoreConstants.SET_MY_STORE_DATA,
		data: newData,
	}
};

const ChangeMaterialGroupTag = (newGroupTag) => {
	return {
		type: MyStoreConstants.SET_MY_STORE_GROUPTAG,
		data: newGroupTag,
	}
}

const ChangeMaterialMyStoreCode = (newCode) => {
	return {
		type: MyStoreConstants.SET_MY_STORE_SELECTED_CODE,
		data: newCode,
	}
}

const SaveMaterial = () => {
	return (dispatch, getState) => {
		const {
			selectedMaterialId,
			selectedCategory,
			data,
			chosenTechnologies,
			isConfirmed,
			groupTag,
			complementaryRules,
			selectedMyStoreCode
		} = getState().MyStore;
		const formdata = new FormData();
		if (!_.isEmpty(data)) {
			formdata.append('data', JSON.stringify(data));
		}
		if (!_.isEmpty(complementaryRules)) {
			formdata.append('complementaryRules', JSON.stringify(complementaryRules));
		}
		const form = new FormData();
		form.append('categories', JSON.stringify([selectedCategory]));
		form.append('technologies', JSON.stringify(chosenTechnologies));
		form.append('isConfirmed', JSON.stringify(isConfirmed));
		if (groupTag !== '') {
			form.append('groupTag', JSON.stringify(groupTag));
		}
		form.append('moySkladCode', JSON.stringify(selectedMyStoreCode))
		return Promise.resolve()
			.then(() => {
				if (formdata.has('data') || formdata.has('complementaryRules')) {
					return api.post('materialDescriptions', formdata)
				}
			})
			.then((md) => {
				if (!_.isUndefined(md)) {
					form.append('description', JSON.stringify(md._id));
				}
				return api.put(`materials/${selectedMaterialId}`, form)
			})
			.then(() => {
				return api.get('materials')
			})
			.then((materials) => {
				dispatch({
					type: MyStoreConstants.SET_MY_STORE_NEW_MATERIALS,
					data: materials,
				});
				dispatch({
					type: InterfaceConstants.CLOSE_CHANGING_MY_STORE_MATERIAL_DIALOG,
				})
			})
	}
}

const SetTechnology = (technologyId, checked) => {
	return (dispatch, getState) => {
		const chosenTechnologies = getState().MyStore.chosenTechnologies;
		let newChosenTechnologies = [];
		checked ?
			newChosenTechnologies = _.concat(chosenTechnologies, technologyId) :
			newChosenTechnologies = _.without(chosenTechnologies, technologyId);
		dispatch({
			type: MyStoreConstants.SET_MY_STORE_TECHNOLOGIES,
			data: newChosenTechnologies,
		})
	}
};

const ChangeComplementaryRules = (newRules) => {
	return {
		type: MyStoreConstants.SET_MY_STORE_COMPLEMENTARY_RULES,
		data: newRules
	}
};

const DeleteMaterialById = (mId) => {
	return (dispatch) => {
		return api.del(`materials/${mId}`)
			.then(() => api.get('materials'))
			.then((materials) => {
				dispatch({
					type: MyStoreConstants.SET_MY_STORE_NEW_MATERIALS,
					data: materials,
				});
			})
	}
};

export default {
	GetAllMyStoreMaterials,
	ChangeMaterialSuperCategory,
	ChangeMaterialCategory,
	ChangeMaterialConfirmed,
	ChangeMaterialData,

	OpenChangingMyStoreMaterialDialog,
	CloseChangingMyStoreMaterialDialog,

	SetTechnology,
	SaveMaterial,

	ChangeMaterialGroupTag,
	ChangeComplementaryRules,

	ChangeMaterialMyStoreCode,

	DeleteMaterialById
};
