import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import {CONTAINER_CONSTANTS} from '../../../Styles/ContainerConstants'
import {COLORS} from '../../../Styles/AppColors';

import {
	TotalTimeDurationAndPrice
} from '../../DumbComponents/_NewDesignComponents'

export default class TechDocInfo extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			menuAnchorEl: null,
		};
	}

	render() {
		const {
			techDocTitle,
			techDocTechnology,
			techDocCover,
			timeDuration,
			techDocPrice,
		} = this.props;
		const { menuAnchorEl } = this.state;
		return (
			<div
				style={{
					height: 90,
					backgroundColor: COLORS.GRAY,
					display: 'flex',
					paddingLeft: CONTAINER_CONSTANTS.LEFT_PADDING,
					paddingRight: CONTAINER_CONSTANTS.RIGHT_PADDING - 24,
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginTop: 20,
					}}
				>
					<span
						style={{
							fontSize: 26,
							lineHeight: '30px',
							fontFamily: 'Roboto',
							letterSpacing: -0.4,
							fontWeight: '300',
							marginBottom: 4,
							color: COLORS.TEXT_COLOR
						}}
					>
						{techDocTitle}
					</span>
					<span
						style={{
							fontSize: 14,
							lineHeight: '16px',
							fontFamily: 'Roboto',
							letterSpacing: -0.2,
							fontWeight: '300',
							color: COLORS.TEXT_COLOR
						}}
					>
						Технология: {techDocTechnology}
					</span>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginTop: 20,
						alignItems: 'flex-start',
					}}
				>
					<TotalTimeDurationAndPrice
						totalTime={timeDuration}
						totalPrice={techDocPrice}
					/>
					<img
						src={techDocCover}
						style={{
							width: 60,
							height: 45,
							borderRadius: '4px'
						}}
						alt={'techdoc_avatar'}
					/>
					<Icon
						style={{
							cursor: 'pointer',
							color: COLORS.ICON_GRAY
						}}
						onClick={(event) => this.setState({
							menuAnchorEl: event.currentTarget,
						})}
					>
						<MoreVert/>
					</Icon>
					<Menu
						anchorEl={menuAnchorEl}
						open={Boolean(menuAnchorEl)}
						onClose={() => this.setState({
							menuAnchorEl: null
						})}
					>
						<MenuItem
							onClick={() => {
								this.props.SetAvatar();
								this.setState({
									menuAnchorEl: null,
								})
							}}
						>
							Сделать обложку ТК
						</MenuItem>
					</Menu>
				</div>
			</div>
		)
	}
};

TechDocInfo.propTypes = {
	techDocTitle: PropTypes.string.isRequired,
	techDocTechnology: PropTypes.string.isRequired,
	techDocCover: PropTypes.string,
};
