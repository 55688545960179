import {
  ProjectsConstants
} from '../Constants';

const initState = {
  projects: [],
  realizations: [],
  newProject: {
    title: '',
    techDocId: '',
    productId: '',
    productsCount: 1,
    riggingCapacity: 1,
  },
  techDocs: [],
  currentProject: {},
  currentRealization: {},

  productionStepTemplates: [],

  currentTask: {},

  taskUsers: [],

  isMaterialsAmountEnough: true,
  productsList: [],
  product: {},

  notEnoughMaterials: [],
  taskExecutorsPlan: [],
  nfcProblemSnackbarOpened: false 
};

const OPEN_SNACK_BAR_NFC_PROBLEM = (oldState) => {
  return {
    ...oldState,
    nfcProblemSnackbarOpened: true
  }
}

const CLOSE_SNACKBAR_NFC_PROBLEM = (oldState) => {
  return {
    ...oldState,
    nfcProblemSnackbarOpened: false
  }
}

const GET_TASK_EXECUTORS_PALNE = (newState, oldState) => {
  return {
    ...oldState,
    taskExecutorsPlan: newState
  }
}

const GET_PRODUCTS = (newState, oldState) => {
  const productsList = JSON.parse(JSON.stringify(newState))
  return {
    ...oldState,
    productsList: productsList
  }
}

const SET_PRODUCT = (newState, oldState) => {
  const product = JSON.parse(JSON.stringify(newState))
  return {
    ...oldState,
    product: product
  }
}

const SET_MATERIALS_ENOUGH = (oldState) => {
  return {
    ...oldState,
    isMaterialsAmountEnough: true,
    notEnoughMaterials: [],
  };
};

const SET_MATERIALS_NOT_ENOUGH = (newState, oldState) => {
  return {
    ...oldState,
    isMaterialsAmountEnough: false,
	  notEnoughMaterials: newState,
  }
}

const SET_TASK_USERS = (newState, oldState) => {
  return {
    ...oldState,
    taskUsers: newState,
  }
}

const SET_TASK = (newState, oldState) => {
  return {
    ...oldState,
    currentTask: newState,
  }
}

const SET_PS_TEMPLATES = (newState, oldState) => {
  return {
    ...oldState,
    productionStepTemplates: newState,
  }
}

const SET_CURRENT_REALIZATION = (newState, oldState) => {
  return {
    ...oldState,
    currentRealization: newState,
  }
}

const CLEAN_CURRENT_REALIZATION = (oldState) => {
  return {
    ...oldState,
    currentRealization: {},
    currentTask: {}
  }
}

const SET_CURRENT_PROJECT = (newState, oldState) => {
  return {
    ...oldState,
    currentProject: newState,
  }
}

const CLEAN_CURRENT_PROJECT = (oldState) => {
  return {
    ...oldState,
    currentProject: {}
  }
}

const SET_REALIZATIONS = (newState, oldState) => {
  return {
    ...oldState,
    realizations: newState,
  }
}

const SET_TECHDOCS = (newState, oldState) => {
  return {
    ...oldState,
    techDocs: newState,
  }
};

const CLEAN_NEW_PROJECT_DATA = (oldState) => {
  let newProject = {
    title:'',
    techDocId: '',
    productId: '',
    productsCount: 1,
    riggingCapacity: 1,
  }
  return {
    ...oldState,
    newProject: newProject
  }
}

const SET_PROJECTS = (newState, oldState) => {
  return {
    ...oldState,
    projects: newState,
  }
}

const CLEAN_PROJECTS = (oldState) => {
  return {
    ...oldState,
    projects: []
  }
}

const CHANGE_NEW_PROJECT_DATA = (newState, oldState) => {
  const newProject = JSON.parse(JSON.stringify(newState));
  return {
    ...oldState,
    newProject: newProject,
  };
}

const CREATE_NEW_PROJECT = (newState, oldState) => {
  let newProjects = JSON.parse(JSON.stringify(oldState.projects));
  let newProject = JSON.parse(JSON.stringify(oldState.newProject))
  newProjects.push(newProject);
  newProject = {
    projectTitle:'',
    techDoc: '',
    productsCount: 0,
    riggingCapacity: 0,
  }
  return {
    ...oldState,
    projects: newProjects,
    newProject: newProject
  }
};

const CREATE_NEW_SUB_PROJECT = (newState, oldState) => {
  return{
    ...oldState,
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case ProjectsConstants.CREATE_NEW_PROJECT:
      return CREATE_NEW_PROJECT(action.data, state);

    case ProjectsConstants.CREATE_NEW_SUB_PROJECT:
      return CREATE_NEW_SUB_PROJECT(action.data, state);

    case ProjectsConstants.CHANGE_NEW_PROJECT_DATA:
      return CHANGE_NEW_PROJECT_DATA(action.data, state);

    case ProjectsConstants.CLEAN_NEW_PROJECT_DATA:
      return CLEAN_NEW_PROJECT_DATA(state);

    case ProjectsConstants.SET_PROJECTS:
      return SET_PROJECTS(action.data, state);

    case ProjectsConstants.SET_TECHDOCS:
      return SET_TECHDOCS(action.data, state);

    case ProjectsConstants.SET_REALIZATIONS:
      return SET_REALIZATIONS(action.data, state);

    case ProjectsConstants.SET_CURRENT_PROJECT:
      return SET_CURRENT_PROJECT(action.data, state);

    case ProjectsConstants.SET_CURRENT_REALIZATION:
      return SET_CURRENT_REALIZATION(action.data, state);

    case ProjectsConstants.SET_PS_TEMPLATES:
      return SET_PS_TEMPLATES(action.data, state);

    case ProjectsConstants.SET_TASK:
      return SET_TASK(action.data, state);

    case ProjectsConstants.SET_TASK_USERS:
      return SET_TASK_USERS(action.data, state);

    case ProjectsConstants.CLEAN_PROJECTS:
      return CLEAN_PROJECTS(state);

    case ProjectsConstants.CLEAN_CURRENT_PROJECT:
      return CLEAN_CURRENT_PROJECT(state);

    case ProjectsConstants.CLEAN_CURRENT_REALIZATION:
      return CLEAN_CURRENT_REALIZATION(state);

    case ProjectsConstants.SET_MATERIALS_ENOUGH:
      return SET_MATERIALS_ENOUGH(state);

    case ProjectsConstants.SET_MATERIALS_NOT_ENOUGH:
      return SET_MATERIALS_NOT_ENOUGH(action.data, state);

    case ProjectsConstants.GET_PRODUCTS: 
      return GET_PRODUCTS(action.data, state);

    case ProjectsConstants.SET_PRODUCT:
      return SET_PRODUCT(action.data, state);

    case ProjectsConstants.GET_TASK_EXECUTORS_PALNE:
      return GET_TASK_EXECUTORS_PALNE(action.data, state);

    case ProjectsConstants.NFC_PROBLEM:
      return OPEN_SNACK_BAR_NFC_PROBLEM(state);

    case ProjectsConstants.CLOSE_SNACKBAR_NFC_PROBLEM:
      return CLOSE_SNACKBAR_NFC_PROBLEM(state);

    default:
      return state;
  }
}