import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
	FormControlLabel,
	Checkbox,
	Typography,
	TextField,
} from '@material-ui/core';

import ArmorMaterialsDescription from './ArmorMaterialsDescription';
import OblationalMaterialsWithCutDescription from './OblationalMaterialsWithCutDescription';
import OblationalMaterialsNoCutDescription from './OblationalMaterialsNoCutDescription';
import ResinMaterialsDescription from './ResinMaterialsDescription';
import CategoryDescription from './CategoryDescription';

// const styles = {
// 	selectMyStore: {
// 		marginTop: 12,
// 	}
// }

class MyStoreChangingMaterialsContent extends PureComponent {
	render() {
		const {
			materialsCategories,
			materialsSuperCategories,
			technologies,

			selectedSuperCategory,
			selectedCategory,
			isConfirmed,
			data,
			chosenTechnologies,
			groupTag,
			oblationalGroupTags,

			hardenerMaterials,
			complementaryRules,

			materialTitle,

			myStoreCode,
		} = this.props;
		return (
			<div>
				<Typography
					variant='caption'
				>
					Код МойСклад <b>(не рекомендуется изменять)</b>
				</Typography>
				<TextField
					value={myStoreCode}
					fullWidth
					onChange={(event) => this.props.ChangeMaterialMyStoreCode(event.target.value)}
					style={{ margin: '12px 0' }}
				/>
				<div
					style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
				>
					<Typography
						variant='body1'
						style={{ marginRight: 12 }}
					>
						Выбор технологии
					</Typography>
					{_.map(technologies, technology => {
						return <FormControlLabel
							key={technology._id}
							control={
								<Checkbox
									checked={_.indexOf(chosenTechnologies, technology._id) !== -1}
								/>
							}
							onChange={(event) => this.props.SetTechnology(technology._id, event.target.checked)}
							label={technology.title}
						/>
					})}
				</div>
				<Typography
					variant='caption'
					style={{ marginBottom: 12 }}
				>
					Примечание: если материал универсальный, выберите обе технологии
				</Typography>
				<CategoryDescription
					title={'Категория'}
					value={_.find(materialsSuperCategories, { _id: selectedSuperCategory }).title}
				/>
				<CategoryDescription
					title={'Подкатегория'}
					value={_.find(materialsCategories, { _id: selectedCategory }).title}
				/>

				{selectedCategory !== '' && _.find(materialsCategories, { _id: selectedCategory }).key === 'am' &&
					<ArmorMaterialsDescription
						data={data}
						ChangeMaterialData={this.props.ChangeMaterialData}
						materialTitle={materialTitle}
					/>
				}

				{selectedCategory !== '' && _.find(materialsCategories, { _id: selectedCategory }).key === 'omc' &&
					<OblationalMaterialsWithCutDescription
						data={data}
						ChangeMaterialData={this.props.ChangeMaterialData}
						materialTitle={materialTitle}
						oblationalGroupTags={_.filter(oblationalGroupTags, ogt => {
							return ogt.needsCutting
						})}
						groupTag={groupTag}
						ChangeMaterialGroupTag={this.props.ChangeMaterialGroupTag}
					/>
				}

				{selectedCategory !== '' && _.find(materialsCategories, { _id: selectedCategory }).key === 'omnc' &&
					<OblationalMaterialsNoCutDescription
						materialTitle={materialTitle}
						oblationalGroupTags={_.filter(oblationalGroupTags, ogt => {
							return !ogt.needsCutting
						})}
						groupTag={groupTag}
						ChangeMaterialGroupTag={this.props.ChangeMaterialGroupTag}
					/>
				}

				{selectedCategory !== '' && _.find(materialsCategories, { _id: selectedCategory }).key === 'rr' &&
					<ResinMaterialsDescription
						hardenerMaterials={hardenerMaterials}
						SetComplementaryRules={this.props.SetComplementaryRules}
						ChangeMaterialData={this.props.ChangeMaterialData}
						complementaryRules={complementaryRules}
						data={data}
					/>
				}

				{_.size(chosenTechnologies) > 0 && selectedCategory !== '' && <FormControlLabel
					control={
						<Checkbox
							checked={isConfirmed}
						/>
					}
					onChange={(event) => this.props.ChangeMaterialConfirmed(event.target.checked)}
					label="Подтверждение возможности использования"
				/>}
			</div>
		)
	}
}

export default MyStoreChangingMaterialsContent;
