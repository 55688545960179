import {
  AnalyticsConstants
} from '../Constants';

const InitState = {
  tasksInQC: [],
  workers: {},
  plan: [],
  userPlan: {},
  statesList: {},
  nfcStatusList: []
}

const GET_NFC_STATUS_LIST = (newState, oldState) => {
  return {
    ...oldState,
    nfcStatusList: newState
  }
}

const GET_PRODUCTION_ACTUAL_INFO = (newState, oldState) => {
  return {
    ...oldState,
    statesList: newState
  }
}

const GET_PLANNING_BY_USER_DATE = (newState, oldState) => {
  return {
    ...oldState,
    userPlan: newState
  }
}

const GET_PLANNING = (newState, oldState) => {
  return {
    ...oldState,
    plan: newState
  }
}

const GET_TASKS_IN_QC = (newState, oldState) => {
  let InReview = JSON.parse(JSON.stringify(newState))
  return {
    ...oldState,
    tasksInQC: InReview
  };
};

const GET_BUSY_WORKERS = (newState, oldState) => {
  let busyWrkers = JSON.parse(JSON.stringify(newState))
  return {
    ...oldState,
    workers: busyWrkers
  }
};

export default (state = InitState, action) => {
  switch (action.type) {
    case AnalyticsConstants.GET_TASKS_IN_QC:
      return GET_TASKS_IN_QC(action.data, state);

    case AnalyticsConstants.GET_BUSY_WORKERS:
      return GET_BUSY_WORKERS(action.data, state);

    case AnalyticsConstants.GET_PLANNING:
      return GET_PLANNING(action.data, state);

    case AnalyticsConstants.GET_PLANNING_BY_USER_DATE:
      return GET_PLANNING_BY_USER_DATE(action.data, state);

    case AnalyticsConstants.GET_PRODUCTION_ACTUAL_INFO:
      return GET_PRODUCTION_ACTUAL_INFO(action.data, state);

    case AnalyticsConstants.GET_NFC_STATUS_LIST:
      return GET_NFC_STATUS_LIST(action.data, state);

    default:
      return state;
  }
}