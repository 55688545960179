import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
	Tabs,
	Tab,
	withStyles,
} from '@material-ui/core';

import {
	CONTAINER_CONSTANTS
} from '../../../Styles/ContainerConstants';

import {
	COLORS,
} from '../../../Styles/AppColors'

const styles = theme => ({
	sectionsContainer: {
		marginRight: 0,
		marginLeft: 'auto',
		position: 'sticky',
		top: 0,
		backgroundColor: COLORS.GRAY,
		zIndex: 4,
		display: 'flex',
	},
	tabsRoot: {
		height: CONTAINER_CONSTANTS.STEP_CHOOSING_HEIGHT,
		alignItems: 'center',
		display: 'flex',
		width: '100%',
	},
	tabsFlexContainer: {
		// height: ContainerConstants.NAV_BAR_HEIGHT - 16,
	},
	tabsIndicator: {
		backgroundColor: COLORS.YELLOW,
	},
	tabRoot: {
		textTransform: 'initial',
		minWidth: 72,
		maxWidth: 160,
		fontSize: 14,
		lineHeight: '16px',
		letterSpacing: 1.3,
		fontWeight: theme.typography.fontWeightMedium,
		marginLeft: theme.spacing.unit * 2,
		fontFamily: [
			'Roboto',
		].join(','),
		'&:hover': {
			color: '#40a9ff',
			opacity: 1,
		},
	},
	typography: {
		padding: theme.spacing.unit * 3,
	},
	labelContainer: {
		padding: '6px 0'
	},
});

class StepChoosing extends Component {
	render() {
		const {
			steps,
			selectedStep,
			classes
		} = this.props;
		return (
			<div
				className={classes.sectionsContainer}
			>
				<Tabs
					value={selectedStep}
					classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator, flexContainer: classes.tabsFlexContainer }}
					onChange={(event, value) => this.props.OnClickStep(value)}
					// scrollable={true}
				>
					{steps.map((step, stepId) => {
						return <Tab
							key={step.id}
							disableRipple={true}
							value={stepId}
							classes={{
								root: classes.tabRoot,
								labelContainer: classes.labelContainer,
							}}
							label={(stepId + 1) + '. ' + _.toUpper(step.title)}
						/>
					})}
				</Tabs>
			</div>
		)
	}
}

StepChoosing.propTypes = {
	steps: PropTypes.arrayOf(PropTypes.object).isRequired,
	selectedStep: PropTypes.number.isRequired,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StepChoosing);
