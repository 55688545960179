import {
  TestConstants
} from '../Constants';

import api from '../../Utils/API';

const SelectFile = (selectedFile) => {
  return {
    type: TestConstants.SELECT_FILE,
    data: selectedFile,
  };
};

const UploadToServer = (file) => {
  return(dispatch) => {
	  const form = new FormData();
	  form.append('files', file);
	  return api.post(`products/5b687f2cee80fd14e6777122/files`, form)
		  .then((response) => {
			  dispatch(FetchFiles());
		  })
  }
};

const FetchFiles = () => {
  return (dispatch) => {
    return api.get(`products/5b687f2cee80fd14e6777122/files`)
      .then((response) => {
        dispatch({
          type: TestConstants.SET_FILES,
          data: response.results,
        })
      })
  }
};

const RemoveFile = (name) => {
  return (dispatch) => {
    return api.del(`products/5b687f2cee80fd14e6777122/files/${name}`)
      .then((response) => {
        dispatch(FetchFiles());
      })
  }
}

export default {
  SelectFile,
  UploadToServer,
  FetchFiles,
	RemoveFile
}