import React, { PureComponent } from 'react';

import { COLORS } from '../../../../../../Styles/AppColors';
import _ from 'lodash';
import {
  AddedArmorMaterial
} from "../../../../../DumbComponents/_NewDesignComponents/ArmorMaterials";

import {
  AddFormControl
} from "../../../../../DumbComponents/_NewDesignComponents/";

const styles = {
  surfaceAddingContainer: {
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    marginTop: 12,
    marginBottom: 8,
  },
  buttonContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.26)',
  },
}

class MaterialsAdding extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      addedMaterialIsVisible: false,
      count: 1,
      fiberDirection: '',
      selectedMaterial: ''
    }
  }

  componentDidUpdate(){
    const {
      addedMaterialIsVisible,
      fiberDirection,
      selectedMaterial,
      count
    } = this.state;
    if(!_.isEmpty(selectedMaterial) && !_.isEmpty(fiberDirection) && addedMaterialIsVisible) {
      this.props.SetLayersCount(count);
      this.props.AddSurfaceLayers();
      this.setState({addedMaterialIsVisible: false});
    }
  }
  
  render() {
    const {
      addedMaterialIsVisible,
      count,
      fiberDirection,
      selectedMaterial
    } = this.state;
    const {
      armorMaterials,
      materialNumber
    } = this.props;
    return(
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {addedMaterialIsVisible &&
          <AddedArmorMaterial
            materialNumber={materialNumber}
            selectedMaterial={selectedMaterial}
            materialsList={armorMaterials}
            selectedFiberDirection={fiberDirection}
            fiberDirections={['straight','notStraight']}
            count={count}
            selectMaterialHandler={(event) => {
              this.setState({
                selectedMaterial: event.target.value
              });
              this.props.ChangeSelectedMaterial(event.target.value)
            }}
            selectFiberDirectionHandler={(event) => {
              this.setState({
                fiberDirection: event.target.value
              });
              this.props.ChangeSelectedAngle(event.target.value)
            }}
            onClickDelete={() => {
              this.setState({
                count: 1,
                selectedMaterial: '',
                fiberDirection: '',
                addedMaterialIsVisible: false
              })
            }}
            onChangeCount={(event) => {
              this.setState({
                count: event.target.value
              })
            }}
          />
        }
        <AddFormControl
          style={styles.surfaceAddingContainer}
          disabled={addedMaterialIsVisible}
          onClick={() => {
            this.setState({
              count: 1,
              selectedMaterial: '',
              fiberDirection: '',
              addedMaterialIsVisible: true
            })
          }}
          label={
            <span
              style={{
                letterSpacing: -0.2,
                lineHeight: '16px',
                fontSize: '14px',
                fontFamily: 'Roboto',
                fontWeight: 500,
                color: COLORS.TEXT_COLOR,
              }}
            >
              Добавить материал
            </span>
          }
        />
      </div>
    )
  }
};

export default MaterialsAdding;
