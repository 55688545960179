import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import {
  OutlinedTextfield,
  TechDocSelect,
  RemoveIcon
} from '../';
import {CONTAINER_CONSTANTS} from "../../../../Styles/ContainerConstants";

class LayerItemArrmorMaterial extends Component {
  constructor(props){
		super(props);
		this.state = {
			layersCount: 1	
		}

		this.changeSelectedLayersCount = 
			_.debounce(() => this.props.ChangeLayersCount( this.props.surfaceId, this.props.selectedMaterial, this.props.selectedFiberDirection, this.state.layersCount), 1000)
  }
  
  componentDidMount(){
    this.setState({
      layersCount: this.props.count
    })
  }

  componentDidUpdate(prevProps){
    if(prevProps.count !== this.props.count){
      this.setState({
        layersCount: this.props.count
      })
    }
  }

  onChangeLayerCountHandler = (e) => {
    this.setState({
      layersCount: e.target.value
    }, () => this.changeSelectedLayersCount())
  }
  
  getAngle(angle){
    switch (angle) {
      case 'straight':
        return '0-90';
      
      case '0-90':
        return '0-90';
  
      case '±45':
        return '±45';

      default:
        return '±45';
    }
  }

  render(){
    const {
      materialNumber,
      materialsList,
      selectedMaterial,
      fiberDirections,
      selectedFiberDirection,
      disabledInput
    } = this.props;
    return(
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          marginTop: 16,
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection:'column',
            width: `calc(100% - ${CONTAINER_CONSTANTS.ICON_CONTAINER_SIZE}px)`,
          }}
        >
          <TechDocSelect
            disabled={disabledInput}
            fullWidth={true}
            value={selectedMaterial}
            onChange={this.props.selectMaterialHandler}
            label={`Материал ${materialNumber}`}
          > 
            {_.map(materialsList, material => {
              return(
                <MenuItem
                  key={material._id}
                  value={material._id}
                >
                  {material.title}
                </MenuItem>
              )})
            }
          </TechDocSelect>
          <div
            style={{
              display: 'flex',
              flexDirection:'row',
              width: '100%',
              marginTop: 16
            }}
          >
            <div
              style={{
                flex: 3,
                marginRight: 16
              }}
            >
              <TechDocSelect
                disabled={disabledInput}
                value={selectedFiberDirection}
                onChange={this.props.selectFiberDirectionHandler}
                label='Направление'
                fullWidth={true}
              > 
                {_.map(fiberDirections, (fiberDirection) => {
                  return(
                    <MenuItem
                      key={fiberDirection}
                      value={fiberDirection}
                    >
                      {this.getAngle(fiberDirection)}
                    </MenuItem>
                  )})
                }
              </TechDocSelect>
            </div>
            <OutlinedTextfield
              fullWidth
              label='Слои'
              defaultValue={1}
              type='number'
              value={this.state.layersCount}
              onChange={this.onChangeLayerCountHandler}
              style={{
                flex: 1
              }}
            />
          </div>
        </div>
        <RemoveIcon
          onClick={this.props.onClickDelete}
        />
      </div>
    )
  }
}

LayerItemArrmorMaterial.propTypes = {
  materialNumber: PropTypes.number.isRequired,
  materialsList: PropTypes.array.isRequired,
  selectedMaterial: PropTypes.any,
  selectMaterialHandler: PropTypes.func,
  fiberDirections: PropTypes.array.isRequired,
  selectedFiberDirection: PropTypes.string,
  selectFiberDirectionHandler: PropTypes.func,
  count: PropTypes.number.isRequired,
  ChangeLayersCount: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  disabledInput: PropTypes.bool,
}

export default LayerItemArrmorMaterial;
