import React, {Component} from 'react';

import{
  Stepper,
  Step,
  StepButton,
} from '@material-ui/core';

const styles ={
  stepper: {
    padding: '10px',
    paddingTop: '24px',
  }
}

class TechDocStepperSteps extends Component{
  render(){
    const{
      activeStep,
      stepList
    } = this.props;
    return(
      <div
        className='centerStepper'
      >
        <Stepper
          activeStep={activeStep}
          style={styles.stepper}
          alternativeLabel
          nonLinear
        >
          {
            stepList.map((step, stepId) => {
              return (
                <Step key={stepId}>
                  <StepButton
                    onClick={() => this.props.OnClickStep(stepId)}
                    completed={false}
                  >
                  </StepButton>
                </Step>
              )
            })
          }
        </Stepper>
      </div>
    );
  }
}

export default TechDocStepperSteps;
