import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
	Typography,
	IconButton,
} from '@material-ui/core';

import {
	Add,
} from '@material-ui/icons';

import PolymerizationDiagram from './PolymerizationDiagram';
import randomstring from "randomstring";

export default class ResinGraphs extends PureComponent {
	AddNewGraph = () => {
		const {
			resinGraphs
		} = this.props;
		this.props.ChangeResinGraphs(_.concat(resinGraphs, {
			id: randomstring.generate(),
			typeTitle: 'График ' + _.toString((_.size(resinGraphs) + 1)),
			steps: []
		}));
	};

	RemoveGraphById = (rgToRemoveId) => {
		const {
			resinGraphs,
		} = this.props;
		this.props.ChangeResinGraphs(_.reject(resinGraphs, (rg, rgId) => {
			return rgId === rgToRemoveId;
		}));
	};

	ChangePGParam = (rgId, paramTitle, value) => {
		let newGraphs = this.props.resinGraphs;
		newGraphs[rgId][paramTitle] = value;
		this.props.ChangeResinGraphs(newGraphs);
	};

	render() {
		const {
			resinGraphs
		} = this.props;
		return(
			<div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						margin: '12px 0'
					}}
				>
					<Typography>
						ГРАФИК ПОЛИМЕРИЗАЦИИ
					</Typography>
					<IconButton
						onClick={this.AddNewGraph}
					>
						<Add/>
					</IconButton>
				</div>
				{_.map(resinGraphs, (rg, rgId) => {
					return <PolymerizationDiagram
						key={rgId}
						rgId={rgId}
						rg={rg}

						RemoveGraphById={this.RemoveGraphById.bind(this)}
						ChangePGParam={this.ChangePGParam.bind(this)}
					/>
				})}
			</div>
		)
	}
}