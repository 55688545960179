import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
	Select,
	MenuItem,
	Typography,
	IconButton,
	TextField
} from '@material-ui/core';

import {
	Remove,
} from '@material-ui/icons';

import ResinGraphs from './ResinGraphs';

export default class ComplementaryRule extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			lifeCycle: 0,
			proportion: 50,
		};
		this.ChangeParam = _.debounce((paramTitle) =>
			this.props.ChangeRuleByIndex(this.props.ruleIndex, paramTitle, this.state[paramTitle]), 1000
		);
	};

	ChangeResinGraphs = (newGraphs) => {
		this.props.ChangeRuleByIndex(this.props.ruleIndex, 'resinGraphs', newGraphs);
	};

	componentDidMount() {
		const {
			lifeCycle,
			proportion,
		} = this.props.rule;
		this.setState({
			lifeCycle,
			proportion,
		})
	};

	render() {
		const {
			rule,
			ruleIndex,
			hardenerMaterials,
		} = this.props;
		const {
			material,
			resinGraphs,
		} = rule;
		const {
			lifeCycle,
			proportion,
		} = this.state;
		return (
			<div>
				<div
					style={_.assign({
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						paddingTop: 12,
					}, ruleIndex === 0 ? {} : { borderTop: '1px solid gray' })}
				>
					<IconButton
						style={{
							marginRight: 8,
						}}
						onClick={() => this.props.DeleteRuleByIndex(ruleIndex)}
					>
						<Remove/>
					</IconButton>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							width: '100%'
						}}
					>
						<Typography
							variant='caption'
							style={{marginBottom: 12}}
						>
							Выбор материала
						</Typography>
						<Select
							fullWidth
							value={material}
							onChange={(event) => this.props.ChangeRuleByIndex(ruleIndex, 'material', event.target.value)}
						>
							{_.map(hardenerMaterials, hm => {
								return <MenuItem
									key={hm._id}
									value={hm._id}
								>
									{hm.title}
								</MenuItem>
							})}
						</Select>
					</div>
				</div>
				<TextField
					style={{margin: '12px 0'}}
					fullWidth
					label='Пропорции, % отвердителя к смоле'
					type='number'
					value={proportion}
					onChange={(event) => {
						this.setState({
							proportion: event.target.value
						}, () => this.ChangeParam('proportion'))
					}}
				/>
				<TextField
					style={{margin: '12px 0'}}
					fullWidth
					label='Время жизни, мин'
					type='number'
					value={lifeCycle}
					onChange={(event) => {
						this.setState({
							lifeCycle: event.target.value
						}, () => this.ChangeParam('lifeCycle'))
					}}
				/>
				<ResinGraphs
					resinGraphs={resinGraphs}
					ChangeResinGraphs={this.ChangeResinGraphs.bind(this)}
				/>
			</div>
		)
	}
};
