import React, { Component } from 'react';

import {
	Grid,
	Typography
} from '@material-ui/core';

import { connect } from 'react-redux';

import _ from 'lodash';

import {
	SubProjectCard,
} from '../DumbComponents';

import {
	ProjectsActions,
} from '../../Redux/Actions'

import { RemoveStructureDialog } from '../DumbComponents/_NewDesignComponents';

class RealizationsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isRemoveRealizationDialogOpened: false,
			selectedRealization: null
		}
	}

	closeRemoveRealizationDialog = () => {
		this.setState({ isRemoveRealizationDialogOpened: false });
	}

	selectRealizationForRemove = (realizationId) => {
		this.setState({ selectedRealization: realizationId, isRemoveRealizationDialogOpened: true });
	}

	removeRealizationByIdDialog = (realizationId) => {
		this.props.RemoveRealizationById(realizationId);
		this.setState({ isRemoveRealizationDialogOpened: false });
	}

	getHHMM(minutes) {
		const newHours = Math.trunc(minutes / 60) < 10 ? '0' + Math.trunc(minutes / 60) : Math.trunc(minutes / 60);
		const newMinutes = minutes % 60 < 10 ? '0' + minutes % 60 : minutes % 60;
		return (newHours + ":" + newMinutes);
	};

	componentDidMount() {
		this.props.GetProjectById(this.props.projectId)
	}

	render() {
		const {
			currentProject
		} = this.props;
		const {
			realizations,
			title
		} = currentProject;

		const theoreticTimeDuration = !_.isEmpty(currentProject) ? _.sumBy(currentProject.techDoc.productionChain, step => {
			return _.sumBy(step.stages, stage => {
				return stage.durationMinutes
			})
		}) : 0;

		const theoreticPrice = !_.isEmpty(currentProject) ? _.sumBy(currentProject.techDoc.productionChain, step => {
			return _.sumBy(step.stages, stage => {
				return stage.estimatedWorkerPrice
			})
		}) : 0;

		return (
			<div
				className='projectContainer'
			>
				<Typography
					variant='headline'
					style={{
						marginLeft: 12, marginTop: 12
					}}
				>
					Проект <b>{_.toUpper(currentProject.title)}</b>
				</Typography>
				<Typography
					variant='subheading'
					style={{
						marginLeft: 12
					}}
				>
					Теоретическое время: <b>{this.getHHMM(theoreticTimeDuration)}</b>
				</Typography>
				<Typography
					variant='subheading'
					style={{
						marginLeft: 12
					}}
				>
					Теоретическая стоимость работ: <b>{_.floor(theoreticPrice, 2)}</b> ₽
				</Typography>
				<Grid
					container
					style={{ width: '100%' }}
				>
					{_.map(_.sortBy(realizations, 'order'), (realization) => {
						return (
							<Grid
								item
								sm={4}
								lg={3}
								key={realization._id}
							>
								<SubProjectCard
									SelectRealization={this.props.SelectRealization}
									selectRealizationForRemove={this.selectRealizationForRemove}
									realization={realization}
									projectTitle={title}
								/>
							</Grid>
						);
					})
					}
				</Grid>
				<RemoveStructureDialog
					title='Удалить реализацию?'
					content='Это действие удалит реализацию и все ее задачи без возможности отмены.'
					structureId={this.state.selectedRealization}
					isRemoveStructureDialogOpened={this.state.isRemoveRealizationDialogOpened}
					closeStructureProductDialog={this.closeRemoveRealizationDialog}
					RemoveStructureById={this.removeRealizationByIdDialog}
				/>
			</div>
		)
	}
}

export default connect(
	state => ({
		currentProject: state.Projects.currentProject,
	}),
	dispatch => ({
		GetProjectById: (projectId) => {
			dispatch(ProjectsActions.GetProjectById(projectId));
		},
		SelectRealization: (realizationId) => {
			dispatch(ProjectsActions.SelectRealization(realizationId));
		},
		RemoveRealizationById: (realizationId) => {
			dispatch(ProjectsActions.RemoveRealizationById(realizationId));
		}
	})
)(RealizationsList);