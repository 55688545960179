import React, { Component, Fragment } from 'react';

import {
	StepChoosing
} from '.'

import TechDocInfo from "./TechDocInfo";

export default class TechDocHeader extends Component {
	render() {
		const {
			techDocTitle,
			techDocTechnology,
			techDocCover,
			timeDuration,
			techDocPrice,
			selectedStep,
			steps
		} = this.props;
		return (
			<Fragment>
				<TechDocInfo
					techDocTitle={techDocTitle}
					techDocTechnology={techDocTechnology}
					techDocCover={techDocCover}
					techDocPrice={techDocPrice}
					timeDuration={timeDuration}
					SetAvatar={this.props.SetAvatar}
				/>
				<StepChoosing
					steps={steps}
					selectedStep={selectedStep}
					OnClickStep={this.props.OnClickStep}
				/>
			</Fragment>
		)
	}
}
