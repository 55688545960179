import React, {PureComponent} from 'react';
import {
  Typography,
  TextField,
} from '@material-ui/core';

const styles = {
  descriptionText: {
    width: '50%',
  },
  descriptionValue: {
    width: '50%',
  },
  textFieldClass: {
    paddingTop: 0,
  }
};

class TechDocDescriptionParameterInput extends PureComponent {
  HandleChangeInputValue = (event) => {
    this.props.HandleChangeInputValue(event.target.value)
  }

  render() {
    const {
      parameterTitle,
      parameterValue,
    } = this.props;
    return (
      <div
        className='parameterSelectionContainer'
      >
        <Typography
          style={styles.descriptionText}
          variant='body1'
        >
          {parameterTitle}
        </Typography>
        <TextField
          style={styles.descriptionValue}
          fullWidth
          value={parameterValue}
          inputProps={{style: styles.textFieldClass}}
          onChange={this.HandleChangeInputValue}
        />
      </div>
    )
  }
};

export default TechDocDescriptionParameterInput;
