import React, { PureComponent } from 'react';
import {Stage, Group, Rect, Text, Layer} from 'react-konva';
import {CONTAINER_CONSTANTS} from "../../../../Styles/ContainerConstants";
import {COLORS} from "../../../../Styles/AppColors";

export default class HorizontalBottomStraightenEdge extends PureComponent {
	render() {
		const { widthSETrait } = this.props;
 		return (
			<Stage
				width={CONTAINER_CONSTANTS.EDITOR_MATERIAL_LENGTH}
				height={CONTAINER_CONSTANTS.HORIZONTAL_STRAIGHT_EDGE_WIDTH + 8}
				style={{
					background: COLORS.STRAIGHT_EDGE_BG_COLOR,
					borderTop: `1px solid ${COLORS.GRAY_STRAIGHTEN}`
				}}
			>
				<Layer>
					{widthSETrait.map((htrait, htraitIndex) => {
						return <Group
							key={'horizontal_b' + htraitIndex}
							x={htrait}
						>
							<Text
								height={CONTAINER_CONSTANTS.HORIZONTAL_STRAIGHT_EDGE_WIDTH}
								verticalAlign={'top'}
								text={((htraitIndex + 1) / 10).toFixed(1)}
								fontFamily={'Roboto'}
								fontSize={11}
								y={12 + 4}
								x={-7}
								fill={COLORS.GRAY_STRAIGHTEN}
							/>
							<Rect
								width={2}
								height={12}
								y={0}
								x={-1}
								verticalAlign={'bottom'}
								fill={COLORS.GRAY_STRAIGHTEN}
								draggable={false}
							/>
						</Group>
					})}
				</Layer>
			</Stage>
		)
	}
}