import React, { PureComponent, Fragment } from 'react';
import {
	Typography,
	Paper,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
} from '@material-ui/core';

import api from '../../../Utils/API';

import RiggingPreparingDescription from "../Stage/RiggingPreparing/RiggingPreparingDescription";
import LayersFacingDescription from "../TaskTemplates/LayersFacing/LayersFacingDescription";
import OblationalLayersFacingDescription
	from "../TaskTemplates/OblationalLayersFacing/OblationalLayersFacingDescription";
import OblationalLayersInfo from "../TaskTemplates/OblationalLayersFacing/OblationalLayersInfo";
import _ from "lodash";
import EvacuationDescription from "../TaskTemplates/Evacuation/EvacuationDescription";
import ResinLifecycleAndType from "../TaskTemplates/Evacuation/ResinLifecycleAndType";
import PolymetrizationDiagramInfo from "../Stage/Evacuation/PolymerizationDiagramInfo";
import CustomDescription from "../TaskTemplates/Custom/CustomDescription";
import QCCriteria from './QCCriteria';

const width = 760;
const height = 419;

export default class ProductionChain extends PureComponent {
	render() {
		const {
			productionChain
		} = this.props;
		console.log(productionChain);
		return (
			<Fragment>
				<Typography
					variant='headline'
					style={{ marginTop: 16 }}
				>
					<b>3) Производственные этапы</b>
				</Typography>
				{productionChain.map((ps, psIndex) => {
					return <div
						key={ps._id}
						style={{
							width: '100%',
							marginTop: 12,
						}}
					>
						<Typography
							variant='h4'
						>
							Цех {psIndex + 1} <b>{ps.template.title}</b>
						</Typography>
						{ps.stages.map((stage, stageIndex) => {
							return <div
								key={stage._id}
								style={{
									marginTop: 8,
									padding: '0 8px',
								}}
							>
								<Typography
									variant='h5'
								>
									Этап {stageIndex + 1} <b>{stage.template.title}</b>
								</Typography>
								<Typography
									variant='body1'
								>
									<b>Описание задачи</b>
								</Typography>
								<Paper
									style={{
										padding: 12,
										margin: '24px 0'
									}}
								>
									<Typography
										variant='subheading'
									>
										Время выполнения: <b>{`${Math.floor(stage.durationMinutes / 60).toString().padStart(2, 0)}:${(stage.durationMinutes % 60).toString().padStart(2, 0)}`}</b>
									</Typography>
									<Typography
										variant='subheading'
									>
										Требует ОТК: <b>{stage.isQCRequired ? 'Да' : 'Нет'}</b>
									</Typography>
									{stage.isQCRequired &&
										<Fragment>
											<Typography
												variant='subheading'
											>
												Критерии ОТК:
											</Typography>
											<QCCriteria
												criteriaQC={stage.QCCriteria}
											/>
										</Fragment>
									}
									{stage.template.type === 'riggingPreparing' && <RiggingPreparingDescription
										separativeLayer={stage.data.separativeLayer}
										textTemplate={stage.textTemplate}
										timeBetweenLayers={stage.data.timeBetweenLayers}
										additionalDescription={stage.additionalDescription}
									/>}
									{stage.template.type === 'layersFacing' && <Fragment>
										<Typography
											variant='body1'
											style={{
												marginBottom: 12
											}}
										>
											Выложить слои в следующем порядке
										</Typography>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>
														№
													</TableCell>
													<TableCell>
														Название материала
													</TableCell>
													<TableCell>
														Направление выкладки
													</TableCell>
													<TableCell>
														Цвет поверхности
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{stage.layers.map((layer, layerIndex) => {
													return <TableRow
														key={layer._id}
													>
														<TableCell>
															{layerIndex + 1}
														</TableCell>
														<TableCell>
															{layer.material.title}
														</TableCell>
														<TableCell>
															{layer.angle % 90 !== 0 ? '±45' : '0-90'}
														</TableCell>
														<TableCell
															style={{
																backgroundColor: layer.surface.color
															}}
														/>
													</TableRow>
												})}
											</TableBody>
										</Table>
										<LayersFacingDescription
											data={stage.data}
											textTemplate={stage.textTemplate}
											additionalDescription={stage.additionalDescription}
										/>
									</Fragment>}
									{stage.template.type === 'oblationalLayersFacing' && <Fragment>
										<OblationalLayersInfo
											oblationalCuttingGroups={stage.oblationalCuttingGroups}
											oblationalNotCuttingGroups={stage.oblationalNotCuttingGroups}
										/>
										<OblationalLayersFacingDescription
											additionalDescription={stage.additionalDescription}
										/>
									</Fragment>}
									{stage.template.type === 'evacuation' && <Fragment>{!_.isUndefined(stage.data) && !_.isUndefined(stage.data.polymerizationGraph) ? <Fragment>
										<EvacuationDescription
											data={stage.data}
											additionalDescription={stage.additionalDescription}
										/>
										<ResinLifecycleAndType
											polymerizationGraph={stage.data.polymerizationGraph}
										/>
										<PolymetrizationDiagramInfo
											steps={!_.isUndefined(stage.data.polymerizationGraph.steps) ? stage.data.polymerizationGraph.steps : []}
										/>
									</Fragment>
										:
										<Typography
											variant='body1'
										>
											График полимеризации не выбран
											</Typography>}
									</Fragment>}
									{(stage.template.type === 'custom' || stage.template.type === 'assemblyCustom') && <CustomDescription
										textDescription={stage.textTemplate}
									/>}
								</Paper>
								{stage.attachmentsList.length > 0 && <div
									style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
								>
									<Typography
										variant='title'
										style={{
											marginTop: 12,
										}}
									>
										Скриншоты задачи <b>{stage.template.title}</b>
									</Typography>
									<Table>
										<TableBody>
											{stage.attachmentsList.map((attachment, attachmentIndex) => {
												return <TableRow
													key={attachment.url}
												>
													<TableCell>
														<img
															style={{
																width: width,
																height: height,
																marginTop: 12,
															}}
															src={api.getHost() + attachment.url + `?t=${new Date().getTime()}`}
															alt={ps.template.title + stageIndex + 'image' + attachmentIndex}
														/>
													</TableCell>
												</TableRow>
											})}
										</TableBody>
									</Table>
								</div>}
							</div>
						})}
					</div>
				})}
			</Fragment>
		)
	}
}