import React, {Component, Fragment} from 'react';

import {
  Typography,
  MenuItem,
} from '@material-ui/core';

import _ from 'lodash';

import OutlinedTextfield from "../../_NewDesignComponents/OutlinedTextfield";
import TechDocSelect from "../../_NewDesignComponents/TechDocSelect.js";

const styles = {
  separativeLayer : {
    display: 'flex',
    width: '50%',
  },
  selectSeparativeLayer: {
    display: 'flex',
	  flex: '1 1 0%'
  },
  inputProps: {
    paddingTop: 0
  },
  text: {
      flex: 1,
      height: 16,
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 470,
      letterSpacing: -0.2
  }
};

const title = 'Разделительный слой';

class PreparingLayersInfoTaskTemplates extends Component{
  constructor(props){
    super(props);
    this.state = {
      selectedTime: 0
    }
    this.changeTime = _.debounce(() => this.props.SetCustomTaskDescription(this.props.taskId, 'data', _.set(this.props.data, 'timeBetweenLayers', this.state.selectedTime)), 1000)
  }

  componentDidMount(){
    this.setState({
      selectedTime: this.props.timeBetweenLayers
    })
  }
  
  render(){
    const {
      separativeLayers,
      taskId,
      separativeLayer,
      data
    } = this.props;
    return(
    <Fragment>
      <div 
        className='preparingLayersInfoTaskTemplates'
      >
        <div
          style={_.assign({}, styles.separativeLayer, { alignItems: 'center' })}
        >
          <Typography
            variant='body1'
            style={styles.text}
          >
            {title}
          </Typography>
        </div>
        <TechDocSelect
          value={separativeLayer}  
          onChange={(event) => {this.props.SetCustomTaskDescription(taskId, 'data', _.set(data, 'separativeLayer', event.target.value))}}
        >
          {_.map(separativeLayers, (layer) => {
            return(
              <MenuItem
                key={layer.id}
                value={layer.title}
              >
                {layer.title}
              </MenuItem>
            )})
          }                
        </TechDocSelect>
      </div>  
      <div
        className='paramField'
      >
	      <div
		      style={_.assign({ flex: 1, display: 'flex', flexDirection: 'row' }, { alignItems: 'center' })}
	      >
		      <Typography
			      variant='body1'
			      style={styles.text}
		      >
			      Время между нанесениями
		      </Typography>
	      </div>
	      <OutlinedTextfield
		      value={this.state.selectedTime}
		      onChange={(event) => {
			      this.setState({
				      selectedTime: event.target.value < 0 ? 0 : event.target.value
			      }, () => this.changeTime())
			      // this.props.SetCustomTaskDescription(taskId, 'data', _.set(data, 'timeBetweenLayers', event.target.value))
		      }}
		      type='number'
		      measureUnits={'мин'}
		      style={{
			      flex: 1
		      }}
	      />
      </div>
    </Fragment>
    );
  }
}

export default PreparingLayersInfoTaskTemplates;