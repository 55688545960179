import React, { Component } from 'react';

import OblationalLayersInfo from './OblationalLayersInfo';

import ProjectAttachments from '../ProjectAttachments';

import OblationalLayersFacingDescription from './OblationalLayersFacingDescription';
import {
  CardContent
} from '@material-ui/core';

class OblationalLayersFacing extends Component {
  render(){
    const {
      stage,
    } = this.props;
    return(
      <CardContent>
        <OblationalLayersFacingDescription
          additionalDescription={stage.additionalDescription}
        />
        <OblationalLayersInfo
          oblationalCuttingGroups={stage.oblationalCuttingGroups}
          oblationalNotCuttingGroups={stage.oblationalNotCuttingGroups}
        />
        <ProjectAttachments
          attachmentsList={stage.attachmentsList}
        />
      </CardContent>
    );
  }
}

export default OblationalLayersFacing;