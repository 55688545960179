import React, { PureComponent } from 'react';

import {
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

class TechDocStepRequirement extends PureComponent {
  render() {
    const {
      stepTitle,
      stepId,
      isChecked,
    } = this.props;
    return(
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={(event) => this.props.CheckStep(stepId, event.target.checked)}
          />
        }
        label={stepTitle}
      />
    )
  }
};

export default TechDocStepRequirement;
