import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import Navbar from '../DumbComponents/_NewDesignComponents/Navbar';

import _ from 'lodash';

import {
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  withStyles,
} from '@material-ui/core';

import {
  AccountCircle,
} from '@material-ui/icons';

import {
  NavigationActions,
} from '../../Redux/Actions';

// import OutlinedTextfieldWithIcon from '../DumbComponents/_NewDesignComponents/OutlinedTextfieldWithIcon.js'
// import RadioButtons from '../DumbComponents/_NewDesignComponents/RadioButtons.js'

import NoAccessPage from './NoAccessPage';

const DrawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appFrame: {
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'absolute',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    // width: `calc(100% - ${DrawerWidth}px)`,
    width: '100%',
  },
  'appBar-left': {
    marginLeft: DrawerWidth,
  },
  'appBar-right': {
    marginRight: DrawerWidth,
  },
  drawerPaper: {
    position: 'relative',
    width: DrawerWidth,
    zIndex: 0,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column'
  },
  navItem: {
    cursor: 'pointer'
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleOpenMenu = this.handleOpenMenu.bind(this);
  };

  handleCloseMenu() {
    this.setState({ anchorEl: null });
  };

  handleOpenMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  };

  render() {
    const {
      classes,
      children,
      routes,
      hasAccess,
      me,
    } = this.props;
    const {
      anchorEl,
    } = this.state;
    const isProfileMenuOpened = Boolean(anchorEl);
    const routeTitle = !_.isUndefined(children.props.route.routeTitle) ? children.props.route.routeTitle : 'Не опознано';
    const routeKey = !_.isUndefined(children.props.route.routeKey) ? children.props.route.routeKey : 'noroute';
    const first_name = (!_.isUndefined(me) && !_.isUndefined(me.first_name)) ? me.first_name : '';
    const second_name = (!_.isUndefined(me) && !_.isUndefined(me.second_name)) ? me.second_name : '';
    return (
      <div
        className={classes.appFrame}
      >
        <AppBar
          position="absolute"
          className={classes.appBar}
          color='default'
        >
          <Toolbar>
            <Typography
              variant='title'
            >
              {routeTitle}
            </Typography>
            {/* <Navbar /> */}
            <div
              style={{
                marginLeft: 'auto',
                marginRight: 0,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography
                variant='body1'
              >
                {_.toUpper(first_name + ' ' + second_name)}
              </Typography>
              <IconButton
                aria-owns={isProfileMenuOpened ? 'menu-appbar' : null}
                aria-haspopup="true"
                onClick={this.handleOpenMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={isProfileMenuOpened}
                onClose={this.handleCloseMenu}
              >
                <MenuItem onClick={() => this.props.AppLogout()}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{ paper: classes.drawerPaper }}
          anchor='left'
          style={{ overflow: 'hidden' }}
        >
          <div
            className={classes.toolbar}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto'
            }}
          >
            <List
            >
              {_.map(routes, (route) => {
                return <ListItem
                  key={route.key}
                  className={classes.navItem}
                  onClick={() => this.props.PushPage(route.path)}
                >
                  <ListItemText
                    primary={<Typography
                      variant='body1'
                      color={_.indexOf(route.routeKeys, routeKey) !== -1 ? 'primary' : 'default'}
                    >
                      {route.title}
                    </Typography>}
                  />
                </ListItem>
              })}

              {/* <OutlinedTextfieldWithIcon
                placeholder="search"
                icon="search"
                style={{
                  display: 'flex',
                  padding: 8,
                }}
              >
              </OutlinedTextfieldWithIcon> */}

            </List>
            {/*<RadioButtons*/}
            {/*classes={{root: classes.root}}*/}
            {/*title="По cтатусу:"*/}
            {/*value="heh"*/}
            {/*buttons={[{_id: 'heh',label:'heh' },{_id: 'heh1',label:'heh1'}]}*/}
            {/*>*/}
            {/*</RadioButtons>*/}
          </div>
        </Drawer>
        <div
          className={classes.content}
        >
          <div
            className={classes.toolbar}
            style={{ display: 'flex' }}
          />
          {hasAccess ? children : <NoAccessPage />}
        </div>
      </div>
    );
  };
};

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    routes: state.Navigation.routes,
    hasAccess: state.Me.hasAccess,
    me: state.Me.me,
  }),
  dispatch => ({
    PushPage: (path) => {
      dispatch(NavigationActions.PushPage(path));
    },
    AppLogout: () => {
      dispatch(NavigationActions.AppLogout());
    }
  }),
)(withStyles(styles)(App));
