import React, { Component } from 'react';
import _ from "lodash";
import {CONTAINER_CONSTANTS} from "../../../../Styles/ContainerConstants";
import {COLORS} from "../../../../Styles/AppColors";
import EditorBody from "./EditorBody";
import HorizontalBottomStraightenEdge from "./HorizontalBottomStraightenEdge";
import HorizontalTopStraightenEdge from "./HorizontalTopStraightenEdge";
import VerticalStraightenEdge from "./VerticalStraightenEdge";

export default class ScaledEditor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorRealHeight: 0,
			widthSETrait: [],
			heightSETrait: [],
			scale: 1,
			backgroundStyle: {},
		}
	};

	recognizeBrowser = () => {
		let userAgent = window.navigator.userAgent,
			platform = window.navigator.platform,
			macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
			windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
			iosPlatforms = ['iPhone', 'iPad', 'iPod'],
			os = null;

		if (macosPlatforms.indexOf(platform) !== -1) {
			os = 'Mac OS';
		} else if (iosPlatforms.indexOf(platform) !== -1) {
			os = 'iOS';
		} else if (windowsPlatforms.indexOf(platform) !== -1) {
			os = 'Windows';
		} else if (/Android/.test(userAgent)) {
			os = 'Android';
		} else if (!os && /Linux/.test(platform)) {
			os = 'Linux';
		}

		return os !== 'Windows' ? 0 : 13;
	};

	componentDidMount() {
		const sortedSurfacesMaterials = _.keys(this.props.sortSurfaces);
		if (!_.some(sortedSurfacesMaterials, this.props.editorSelectedMaterial)) {
			this.props.ChangeEditorSelectedMaterial(sortedSurfacesMaterials[0]);
		}
		this.setState({
			editorRealHeight: this.cutEditor.clientHeight + this.recognizeBrowser(),
		});
	}

	generatePathToTexture = (imageTitle) => {
    return {background: `url(${process.env.NODE_ENV === 'development' ? '' : '/static'}/${imageTitle}.png) repeat`}
	};

	generateStyleByGroupTag = (groupTag) => {
		switch(groupTag) {
			case 'separative_layer':
				return this.generatePathToTexture('separative_layer');

			case 'conductive_layer':
				return this.generatePathToTexture('conductive_layer');

			case 'vacuum_membrane':
				return { backgroundColor: COLORS.VACUUM_MEMBRANE_COLOR };

			case 'absorbent_layer':
				return this.generatePathToTexture('absorbent_layer');

			default:
				return { backgroundColor: COLORS.GRAY }
		}
	};

	componentDidUpdate(prevProps) {
		const {
			editorSelectedMaterial,
			cutMaterials,
		} = this.props;
		if (prevProps.editorSelectedMaterial !== editorSelectedMaterial) {
			this.cutEditorWithSE.scrollLeft = 0;
			const foundMaterial = _.find(cutMaterials, {'_id': editorSelectedMaterial});
			const foundMaterialWidth = foundMaterial.description.data.width;
			const scale = this.cutEditor.clientHeight / (foundMaterialWidth * 1000);
			let currentWidthSELength = scale * 100;
			let currentHeightSELength = 0;
			let widthSETrait = [];
			let heightSETrait = [];
			while (currentWidthSELength < CONTAINER_CONSTANTS.EDITOR_MATERIAL_LENGTH) {
			  widthSETrait.push(currentWidthSELength);
			  currentWidthSELength += (scale * 100);
			}
			while (currentHeightSELength < this.cutEditor.clientHeight) {
				heightSETrait.push({
					y: currentHeightSELength,
					label: (heightSETrait.length/10).toFixed(1)
				});
				currentHeightSELength += (scale * 100);
			}
			if (_.toNumber(_.last(heightSETrait).label) !== _.toNumber(foundMaterialWidth)) {
				heightSETrait.push({
					y: this.cutEditor.clientHeight + 2,
					label: _.toNumber(foundMaterialWidth).toFixed((foundMaterialWidth * 10) % 1 !== 0 ? 2 : 1),
				});
			} else {
				heightSETrait = _.chain(heightSETrait)
					.dropRight()
					.concat({
						y: this.cutEditor.clientHeight + 2,
						label: _.toNumber(foundMaterialWidth).toFixed((foundMaterialWidth * 10) % 1 !== 0 ? 2 : 1)
					})
					.value();
			}
			if (foundMaterial.category.key === 'am') {
				this.setState({
					backgroundStyle: this.generatePathToTexture('tweel')
				})
			} else if (foundMaterial.category.key === 'omc') {
				this.setState({backgroundStyle: this.generateStyleByGroupTag(foundMaterial.groupTag.key)})
			}
			this.setState({
				widthSETrait,
				scale,
				heightSETrait
			})
		}
	}

	render() {
		const {
			sortSurfaces,
			editorSelectedMaterial,
			editorSelectedLayer,
			cutMaterials,
			selectedMaterialCut
		} = this.props;
		const {
			editorRealHeight,
			widthSETrait,
			heightSETrait,
			scale,
			backgroundStyle
		} = this.state;
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					height: '100%',
					width: '100%',
					overflowX: 'hidden',
				}}
			>
				<div
					style={{
						width: CONTAINER_CONSTANTS.VERTICAL_STRAIGHT_EDGE_WIDTH,
						height: '100%',
						backgroundColor: COLORS.STRAIGHT_EDGE_BG_COLOR,
					}}
				>
					{editorRealHeight > 0 && <VerticalStraightenEdge
						heightSETrait={heightSETrait}
						editorRealHeight={editorRealHeight}
					/>}
				</div>
				<div
					style={{
						width: `calc(100% - ${CONTAINER_CONSTANTS.VERTICAL_STRAIGHT_EDGE_WIDTH}px)`,
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						overflowX: 'scroll',
						overflowY: 'hidden',
					}}
					ref={(cutEditorWithSE) => this.cutEditorWithSE = cutEditorWithSE}
				>
					<div
						style={{
							height: CONTAINER_CONSTANTS.HORIZONTAL_STRAIGHT_EDGE_WIDTH,
							width: CONTAINER_CONSTANTS.EDITOR_MATERIAL_LENGTH,
							backgroundColor: COLORS.STRAIGHT_EDGE_BG_COLOR,
						}}
					>
						{editorRealHeight > 0 &&
						<HorizontalTopStraightenEdge
							widthSETrait={widthSETrait}
						/>}
					</div>
					<div
						style={{
							display: 'flex',
							width: CONTAINER_CONSTANTS.EDITOR_MATERIAL_LENGTH,
							height: `calc(100% - ${2 * CONTAINER_CONSTANTS.HORIZONTAL_STRAIGHT_EDGE_WIDTH + 8}px`,
							border: `1px solid ${COLORS.GRAY_STRAIGHTEN}`
						}}
						ref={(cutEditor) => this.cutEditor = cutEditor}
					>
						{editorRealHeight > 0 && <EditorBody
							selectedMaterialCut={selectedMaterialCut}

							layers={_.sortBy(_.get(sortSurfaces, editorSelectedMaterial), 'position.x')}
							editorRealHeight={editorRealHeight}
							scale={scale}
							materialWidth={_.find(cutMaterials, {'_id': editorSelectedMaterial}).description.data.width}
							ChangeLayerPosition={this.props.ChangeLayerPosition}

							ChangeEditorSelectedLayer={this.props.ChangeEditorSelectedLayer}
							editorSelectedLayer={editorSelectedLayer}

							backgroundStyle={backgroundStyle}
						/>}
					</div>
					<div
						style={{
							height: CONTAINER_CONSTANTS.HORIZONTAL_STRAIGHT_EDGE_WIDTH + 8,
							width: CONTAINER_CONSTANTS.EDITOR_MATERIAL_LENGTH,
							backgroundColor: COLORS.STRAIGHT_EDGE_BG_COLOR,
						}}
					>
						{editorRealHeight > 0 &&
						<HorizontalBottomStraightenEdge
							widthSETrait={widthSETrait}
						/>}
					</div>
				</div>
			</div>
		)
	}
}
