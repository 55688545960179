import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from '@material-ui/core';

import MyStoreChangingMaterialsContent from './MyStoreChangingMaterialsContent';

class MyStoreChangingMaterialsDialog extends PureComponent {
	render() {
		const {
			isChangingMyStoreMaterialDialogOpened,
			selectedMaterial,

			materialsCategories,
			materialsSuperCategories,
			technologies,

			selectedSuperCategory,
			selectedCategory,
			isConfirmed,
			groupTag,
			oblationalGroupTags,

			data,
			chosenTechnologies,
			complementaryRules,

			hardenerMaterials,

			myStoreCode,
		} = this.props;
		return (
			<Dialog
				open={isChangingMyStoreMaterialDialogOpened}
				PaperProps={{ style: { width: 600, height: 600 } }}
			>
				<DialogTitle>
					Изменение материала {_.toUpper(selectedMaterial.title)}
				</DialogTitle>
				<DialogContent>
					<MyStoreChangingMaterialsContent
						materialsCategories={materialsCategories}
						materialsSuperCategories={materialsSuperCategories}
						technologies={technologies}

						materialTitle={selectedMaterial.title}

						selectedSuperCategory={selectedSuperCategory}
						selectedCategory={selectedCategory}
						isConfirmed={isConfirmed}
						data={data}
						chosenTechnologies={chosenTechnologies}
						groupTag={groupTag}

						oblationalGroupTags={oblationalGroupTags}

						hardenerMaterials={hardenerMaterials}

						ChangeMaterialSuperCategory={this.props.ChangeMaterialSuperCategory}
						ChangeMaterialCategory={this.props.ChangeMaterialCategory}

						ChangeMaterialConfirmed={this.props.ChangeMaterialConfirmed}
						ChangeMaterialData={this.props.ChangeMaterialData}

						SetTechnology={this.props.SetTechnology}
						ChangeMaterialGroupTag={this.props.ChangeMaterialGroupTag}
						complementaryRules={complementaryRules}

						SetComplementaryRules={this.props.SetComplementaryRules}

						myStoreCode={myStoreCode}
						ChangeMaterialMyStoreCode={this.props.ChangeMaterialMyStoreCode}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={this.props.CloseChangingMyStoreMaterialDialog}
					>
						Отменить
					</Button>
					<Button
						onClick={this.props.SaveMaterial}
						disabled={!isConfirmed}
					>
						Сохранить
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default MyStoreChangingMaterialsDialog;
