const NewSurfaceMaterialConstants = {
  SET_NEW_SURFACE_MATERIAL_INITIAL_STATE: 'SET_NEW_SURFACE_MATERIAL_INITIAL_STATE',
  INCREMENT_COUNT: 'INCREMENT_COUNT',
  DECREMENT_COUNT: 'DECREMENT_COUNT',

	CHANGE_SELECTED_MATERIAL: 'CHANGE_SELECTED_MATERIAL',

  CHANGE_SELECTED_ANGLE: 'CHANGE_SELECTED_ANGLE',
  
  CHANGE_LAYER_COUNT: 'CHANGE_LAYER_COUNT',
};

export default NewSurfaceMaterialConstants;
