import React, { PureComponent } from 'react';

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from '@material-ui/core';

import ChangingMaterialsCount from './ChangingMaterialsCount';

class ChangingMaterialsDialog extends PureComponent {
	render() {
		const {
			isChangingSurfaceMaterialDialogOpened,
			layersCount,
		} = this.props;
		return (
			<Dialog
				open={isChangingSurfaceMaterialDialogOpened}
			>
				<DialogTitle>
					ИЗМЕНЕНИЕ МАТЕРИАЛА ПОВЕРХНОСТИ
				</DialogTitle>
				<DialogContent>
					<ChangingMaterialsCount
						layersCount={layersCount}
						IncrementCount={this.props.IncrementCount}
						DecrementCount={this.props.DecrementCount}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={this.props.CloseChangingSurfaceMaterialDialog}
					>
						Отменить
					</Button>
					<Button
						onClick={this.props.ChangeLayersCount}
					>
						Сохранить
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
};

export default ChangingMaterialsDialog;
