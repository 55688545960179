import React, { Component } from 'react';

import _ from 'lodash';

import {
  Grid,
} from '@material-ui/core';

import api from '../../../Utils/API';
import {
  OutlinedButton,
  Attachment
} from '../_NewDesignComponents';

import {
  COLORS
} from '../../../Styles/AppColors';

const styles = {
  gridListTile: {
    width: 140,
    height: 105,
    marginTop: 12,
    marginBottom: 12,
    marginRight: 24,
    cursor: 'zoom-in'
  },
  editColor: {
    color: 'rgba(255, 255, 255, 0.54)'
  },
  addAttachmentButton: {
    marginTop: '12px',
  }
}

class AttachmentsTaskTemplates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isItemContentVisible: {}
    };
  }

  ShowContent(id, flag) {
    this.setState({
      isItemContentVisible: {
        ...this.state.isItemContentVisible,
        [id]: flag
      }
    });
  }

  render() {
    const {
      attachmentsList,
      id,
    } = this.props;
    return (
      <div
        className='attachments'
      >
        <span
          style={{
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '16px',
            letterSpacing: -0.2,
            color: COLORS.TEXT_COLOR,
            marginBottom: 24
          }}
        >
          Скриншоты к задаче «Подготовка оснастки»
        </span>
        <div
          className='attachmentsContainer'
        >
          <Grid
            container
            spacing={16}
            alignItems="center"
            direction="row"
          >
            {_.map(attachmentsList, (attachment, attachmentId) => {
              return (
                <Grid
                  item
                  // xs={6}
                  // sm={6}
                  xs={6}
                  md={4}
                  lg={3}
                  key={attachment.id}
                >
                  <Attachment
                    src={api.getHost() + attachment.url + `?t=${new Date().getTime()}`}
                    alt={'image' + attachment.url + attachmentId}
                    onClickImage={() => {
                      this.props.OpenAttachments();
                      this.props.SetCurrentAttachment(attachmentId);
                    }}
                    onClickRemove={(e) => {
                      this.props.DeleteScreenshot(id, attachment.id);
                      e.stopPropagation();
                    }}
                    onClickEdit={() => this.props.OpenGraphicsEditor(attachment)}
                    uri={api.getHost() + attachment.url}
                  />
                </Grid>
              )
            })
            }
          </Grid>

        </div>
        <OutlinedButton
          style={styles.addAttachmentButton}
          label='Сделать скриншот'
          onClick={() => this.props.AddScreenshot(id)}
        />
      </div>
    )
  }
}

export default AttachmentsTaskTemplates;