import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  COLORS
} from '../../../../Styles/AppColors'
// const styles = {

// }

class ResinInfo extends PureComponent {
  render(){
    const {
      resinTitle,
      hardenerTitle,
      proportion,
      lifeCycle
    } = this.props;
    return(
      <div
        style={{
          display: 'flex',
          backgroundColor: COLORS.GRAY,
          padding: 16,
          flexDirection: 'column',
          marginBottom: 24,
        }}
      >
        <span
          style={{
            color: COLORS.TEXT_COLOR,
            fontFamily: 'Roboto',
            fontSize: 14,
            lineHeight: '16px',
            fontWeight: 500,
            letterSpacing: -0.2,
          }}
        >
          Информация
        </span>
        <span
          style={{
            marginTop: 8,
            color: COLORS.TEXT_COLOR,
            fontFamily: 'Roboto',
            fontSize: 14,
            lineHeight: '16px',
            fontWeight: 300,
            letterSpacing: -0.2,
          }}
        >
          Вы выбрали смесь:
        </span>
        <span
          style={{
            color: COLORS.TEXT_COLOR,
            fontFamily: 'Roboto',
            fontSize: 14,
            lineHeight: '16px',
            fontWeight: 300,
            letterSpacing: -0.2,
          }}
        >
          Смола <b>{resinTitle}</b> + Отвердитель <b>{hardenerTitle}</b>
        </span>
        <div
          style={{
            display: 'flex',
            marginTop: 8,
          }}
        >
          <span
            style={{
              color: COLORS.TEXT_COLOR,
              fontFamily: 'Roboto',
              fontSize: 14,
              lineHeight: '16px',
              fontWeight: 300,
              letterSpacing: -0.2,
              flex: 1
            }}
          >
            Пропорции смешивания:
          </span>
          <span
            style={{
              color: COLORS.TEXT_COLOR,
              fontFamily: 'Roboto',
              fontSize: 14,
              lineHeight: '16px',
              fontWeight: 500,
              letterSpacing: -0.2,
              flex: 1
            }}
          >
            100 : {proportion}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: 8,
          }}
        >
          <span
            style={{
              color: COLORS.TEXT_COLOR,
              fontFamily: 'Roboto',
              fontSize: 14,
              lineHeight: '16px',
              fontWeight: 300,
              letterSpacing: -0.2,
              flex: 1
            }}
          >
            Время жизни смолы:
          </span>
          <span
            style={{
              color: COLORS.TEXT_COLOR,
              fontFamily: 'Roboto',
              fontSize: 14,
              lineHeight: '16px',
              fontWeight: 500,
              letterSpacing: -0.2,
              flex: 1
            }}
          >
            {lifeCycle} мин
          </span>
        </div>
      </div>
    )
  }
}

ResinInfo.propTypes = {
  proportion: PropTypes.number,
  lifeCycle: PropTypes.number,
  resinTitle: PropTypes.string,
  hardenerTitle: PropTypes.string,
};

export default ResinInfo;
