import React, { Component } from 'react';

import {
  Rect,
  Group,
	Text,
} from 'react-konva'

import _ from 'lodash';
import {COLORS} from "../../../../Styles/AppColors";

class Rectangle extends Component {
  constructor(props){
    super(props);
    this.state = {
      x: 0,
      y: 0
     }
  }

  componentDidMount(){
    this.setState({
      x: this.props.layer.position ? this.props.layer.position.x : 0,
      y: this.props.layer.position ? this.props.layer.position.y : 0,
    });

    this.changePosition = _.debounce(() => this.props.ChangeLayerPosition(this.state.x, this.state.y, this.props.layer.isOblational), 200);
  }

	hex2rgba = (hex, opacity) => {
		let h = hex.replace('#', '');
		h = h.match(new RegExp('(.{'+h.length/3+'})', 'g'));
		for (var i = 0; i < h.length; i++) h[i] = parseInt(h[i].length === 1? h[i]+h[i] : h[i], 16);
		if (typeof opacity !== 'undefined') h.push(opacity);
		return 'rgba('+h.join(', ')+')';
	};

  render(){
    const {
      scale,
      layer,
      editorSelectedLayer
    } = this.props;
    return(
    	<Group
        draggable
        key={layer._id}
        // width={layer.width * scale}
        // height={layer.height * scale}
        x={this.state.x * scale}
        y={this.state.y * scale}
        rotation={layer.angle}
        onClick={() => this.props.ChangeEditorSelectedLayer(layer._id)}
        onDragStart={() => this.props.ChangeEditorSelectedLayer(layer._id)}
        onDragEnd={(event) => this.setState({
	        x: event.target.x()/scale,
	        y: event.target.y()/scale
        }, () => this.changePosition()) }
	    >
		    <Rect
			    stroke={editorSelectedLayer === layer._id ? COLORS.BLUE : COLORS.CUT_LINE_COLOR}
			    fill={!_.isUndefined(layer.color) ? this.hex2rgba(layer.color, 0.5) : this.hex2rgba('#000000', 0.5)}
			    width={layer.width * 1000 * scale}
			    height={layer.height * 1000 * scale}
			    strokeWidth={editorSelectedLayer === layer._id ? 2 : 0.5}
		    />
		    {/*<Text*/}
			    {/*text={`${layer.width}мм x ${layer.height}мм ${layer.fiberDirection ? layer.fiberDirection: ''}`}*/}
			    {/*fill={'#FFF'}*/}
			    {/*width={layer.width * scale}*/}
			    {/*height={layer.height * scale}*/}
			    {/*align={'center'}*/}
			    {/*verticalAlign={'middle'}*/}
		    {/*/>*/}
		    <Text
			    width={layer.height * 1000 * scale}
			    height={layer.width * 1000 * scale}
			    rotation={90}
			    align={'center'}
			    x={layer.width * 1000 * scale + 4}
			    verticalAlign={'bottom'}
			    text={`${layer.height} м`}
			    fill={COLORS.TEXT_COLOR}
			    fontSize={16}
			    fontFamily={'Roboto'}
		    />
		    <Text
			    width={layer.width * scale * 1000}
			    height={layer.height * scale * 1000}
			    align={'center'}
			    y={4}
			    verticalAlign={'top'}
			    text={`${layer.width} м`}
			    fill={COLORS.TEXT_COLOR}
			    fontSize={16}
			    fontFamily={'Roboto'}
		    />
		    {!_.isUndefined(layer.fiberDirection) && <Text
			    width={layer.width * scale * 1000}
			    height={layer.height * scale * 1000}
			    align={'center'}
			    verticalAlign={'middle'}
			    text={layer.fiberDirection}
			    fill={COLORS.TEXT_COLOR}
			    fontSize={16}
			    fontFamily={'Roboto'}
		    />}
	    </Group>
    )
  }
}

export default Rectangle;
