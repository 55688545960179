import React, { Component, Fragment } from 'react';

import _ from 'lodash';

import {
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';

class TaskStatesCard extends Component {

  translateTitle(state) {
    switch (state) {
      case 'todo':
        return 'К выполнению';

      case 'doing':
        return 'В работе';
  
      case 'done':
        return 'Готово';
      
      case 'blocked':
        return 'Заблокировано';

      case 'waiting':
        return 'В ожидании';
    
      default:
        return;
    }
  }
  
  getHHMM(minutes){
    const newHours = Math.trunc(minutes / 60) < 10 ? '0' + Math.trunc(minutes / 60) : Math.trunc(minutes / 60) ;
    const newMinutes = minutes % 60 < 10 ? '0' + minutes % 60 : minutes % 60;
    return (newHours +":"+ newMinutes);
  };
  
  render(){
    const {
      state,
      stateList,
    } = this.props;
    return(
      <div
	      style={{
		      minWidth: 392,
		      margin: '0 12px',
		      flexDirection: 'column',
		      position:'relative',
		      height: '100%',
          minHeight: 'auto',
		      display: 'inline-block',
		      verticalAlign: 'top'
	      }}
      >
        <Card
	        style={{
		        display: 'flex',
		        flexDirection: 'column',
		        height: 'auto',
            margin: '12px 0',
            maxHeight: 'calc(100% - 24px)',
	        }}
        >
          <CardHeader
            style={{borderBottom:'1px solid #ccc', minHeight: 40, display: 'flex'}}
            title={
              <Typography
                variant='title'
              >
                <b>{this.translateTitle(state)}</b>
              </Typography>
            }
          />
          <CardContent
            style={{
              overflowY: 'auto',
              height: 'auto',
              display:'block'
            }}
          >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {_.isEmpty(stateList) &&
              <Typography
                variant='headline'
              >
                ПУСТО
              </Typography>

            }
            {_.map(stateList, item => {
              return(
                <Card
                  key={item.user._id}
                  style={{marginBottom: 6}}
                >
                  <CardContent
                    style={{display:'flex', flexDirection:'column', backgroundColor:'#fcfcfc'}}
                  >
                    <div
                      style={{marginBottom: 12 }}
                    >
                      <Typography
                        variant='title'
                      >
                        {`${_.capitalize(item.user.first_name)} ${_.capitalize(item.user.second_name)}`}
                      </Typography>
                        <Fragment>
                          <Typography
                            variant='subheading'
                          >
                            Общая длительность работ <b>{this.getHHMM(_.sumBy(item.tasks, (task) => {
                              return !_.isUndefined(task.stage) ? task.stage.durationMinutes : 0
                            } ))}</b>
                          </Typography>
                          <Typography
                            variant='subheading'
                          >
                            Стоимость работ <b>{!_.isNaN(item.user.rate) ? _.floor(item.user.rate * _.sumBy(item.tasks, (task) => {
                              return !_.isUndefined(task.stage) ? task.stage.durationMinutes : 0 } ) / 60) : 0}</b> ₽
                          </Typography>
                        </Fragment>
                      </div>
                      <div
                        style={{display:'flex', flexDirection:'column', }}
                      >
                        <Typography
                          variant='title'
                          style={{
                            marginBottom: 8
                          }}
                        >
                          Задачи
                        </Typography>
                        {_.map(item.tasks, (task, taskId) => {
                          return <div
                              key={task._id}
                              style={{marginTop: taskId === 0 ? 0 : 12}}
                            >
                              <Typography
                                variant='subheading'
                              >
                                <b>{taskId + 1}) {task.title}</b>
                              </Typography>
                              <Typography
                                variant='subheading'
                              >
                                Время выполнения <b>{task.stage ? this.getHHMM(task.stage.durationMinutes) : 'не задано'}</b>
                              </Typography>
                              <Typography
                                variant='subheading'
                              >
                                Проект <b>{task.project.title}</b>
                              </Typography>
                            </div>
                          })
                        }
                      </div>
                  </CardContent>
                </Card>
              )})
            }
            </div>
          </CardContent>
        </Card>
      </div>
    )
  }
}

export default TaskStatesCard;