import React, { Component } from 'react';

import { connect } from 'react-redux';

import _ from 'lodash';

import { 
  Typography,
  CircularProgress
} from '@material-ui/core';

const styles = {
  noAccessContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }
}

class NoAccessPage extends Component {
  render() {
    const {
      isMeLoaded
    } = this.props;
    return (
      <div
        style={styles.noAccessContainer}
      >
        {isMeLoaded ? <Typography
          variant='body1'
        >
          Нет прав доступа
        </Typography>
        :
        <CircularProgress size={56} />
        }
      </div>
    )
  }
};

export default connect(
  state => ({
    isMeLoaded: !_.isEmpty(state.Me.me)
  }),
  dispatch => ({

  }),
)(NoAccessPage)
