export const CONTAINER_CONSTANTS = {
	LEFT_PADDING: 32,
	RIGHT_PADDING: 40,
	STEP_CHOOSING_HEIGHT: 48,
	TEXT_FIELD_HEIGHT: 40,
	ICON_MARGIN: 8,
	ICON_CONTAINER_SIZE: 35,
	HORIZONTAL_STRAIGHT_EDGE_WIDTH: 32,
	VERTICAL_STRAIGHT_EDGE_WIDTH: 48,
	EDITOR_MATERIAL_LENGTH: 10000,

	QC_CRITERION_MARGIN: 16,
};
