import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import {
	Typography,
	Paper
} from '@material-ui/core';
import ColorPick from "../ColorPick";

export default class ArmorCut extends PureComponent {
	sortByAngle = (angle) => {
		switch (_.toNumber(angle) % 90) {
			case 0:
				return '0-90';

			default:
				return '±45'
		}
	};

	render() {
		const {
			surfaces,
		} = this.props;
		return (
			<Fragment>
				<Typography
					variant='headline'
					style={{ marginTop: 16 }}
				>
					<b>2) Раскрой</b>
				</Typography>
				<Typography
					variant='title'
					style={{ marginTop: 16, marginBottom: 16 }}
				>
					<b>Армирующие материалы</b>
				</Typography>
				{surfaces.map((surface, surfaceIndex) => {
					const groupedLayers = _.chain(surface.layers)
						.groupBy('material.title')
						.mapValues(layers => {
							return _.groupBy(layers, layer => {
								return this.sortByAngle(layer.angle)
							})
						})
						.value();
					return <Paper
						key={surface._id}
						style={{ width: '100%', paddingTop: 8, paddingBottom: 8, marginTop: 12, }}
					>
						<div
							style={{
								marginLeft: 24,
								marginRight: 24,
								display: 'block',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<Typography
									variant='subheading'
								>
									<b>Поверхность {surfaceIndex + 1}</b>
								</Typography>
								<ColorPick
									colorPanel={surface.color}
								/>
							</div>
							<Typography>
								Размер: длина <b>{surface.height}</b> м x ширина <b>{surface.width}</b> м
							</Typography>
							{_.map(groupedLayers, (v, k) => {
								return <Fragment
									key={k}
								>
									<Typography
										variant='subheading'
										style={{
											marginTop: 12
										}}
									>
										Материал <b>{k}</b>
									</Typography>
									{_.map(v, (layers, fiberDirection) => {
										return <Typography
											key={fiberDirection}
											variant='body1'
										>
											Направление <b>{fiberDirection}</b>; Слоев: <b>{layers.length}</b>
										</Typography>
									})}
								</Fragment>
							})}
						</div>

					</Paper>
				})}
			</Fragment>
		)
	}
};
