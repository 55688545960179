import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import {
	Typography,
	Paper,
} from "@material-ui/core";

export default class OblationalCut extends PureComponent {
	render() {
		const {
			oblationalCuttingGroups,
			oblationalNotCuttingGroups,
		} = this.props;
		// const groupedONCG = _.groupBy(oblationalNotCuttingGroups, oncg => {
		// 	return oncg.material.title
		// });
		return (
			<Fragment>
				<Typography
					variant='title'
					style={{ marginTop: 16, marginBottom: 16 }}
				>
					<b>Жертвенные материалы</b>
				</Typography>
				{oblationalCuttingGroups.map((ocg) => {
					return <Paper
						key={ocg._id}
						style={{ width: '100%', paddingTop: 8, paddingBottom: 8, marginTop: 12, }}
					>
						<div
							style={{
								marginLeft: 24,
								marginRight: 24,
								display: 'block',
							}}
						>
							<Typography
								variant='subheading'
							>
								Материал <b>{!_.isNil(ocg.material) ? ocg.material.title : 'Не выбран'}</b>
							</Typography>
							{ocg.oblationalSurfaces.map(os => {
								return <Typography
									key={os._id}
									variant='body1'
									style={{
										marginTop: 12,
									}}
								>
									Количество: <b>{os.layers.length}</b> шт.; Размер: длина <b>{os.height}</b> м x ширина <b>{os.width}</b> м
								</Typography>
							})}
						</div>
					</Paper>
				})}
				{_.map(oblationalNotCuttingGroups, (oncg, oncgId) => {
					return <Paper
						key={oncg._id}
						style={{ width: '100%', paddingTop: 8, paddingBottom: 8, marginTop: 12, }}
					>
						<div
							style={{
								marginLeft: 24,
								marginRight: 24,
								display: 'block',
							}}
						>
							<Typography
								variant='subheading'
							>
								Материал <b>{oncg.material ? oncg.material.title : 'Не выбран'}</b>
							</Typography>
							{oncg.sizes.map(oncgsize => {
								return <Typography
									key={oncgsize._id}
									variant='body1'
									style={{
										marginTop: 12,
									}}
								>
									Количество: <b>{oncgsize.count}</b> шт.; Длина: <b>{oncgsize.length}</b> м. п.
								</Typography>
							})}
						</div>
					</Paper>
				})}
			</Fragment>
		)
	}
};
