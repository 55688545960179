import React, { Component } from 'react';

import { connect } from 'react-redux'

import {
  InterfaceActions,
  ProductionChainActions,
  TechDocMaterialsAmountActions
} from '../../Redux/Actions'

import _ from 'lodash';

import {
  Stage
} from '../DumbComponents/index';

import {
  MaterialPreparingHeader
} from '../DumbComponents/_NewDesignComponents'
import DialogGraphicsEditor from "../DumbComponents/Stage/GraphicsEditor/DialogGraphicsEditor";
import {
  RemoveStructureDialog
} from '../DumbComponents/_NewDesignComponents'

class StagesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRemoveTaskDialogOpened: false,
      selectedTask: null
    }
  }

  closeRemoveTaskDialog = () => {
    this.setState({
      isRemoveTaskDialogOpened: false
    })
  };

  selectTaskForRemove = (taskId) => {
    this.setState({
      isRemoveTaskDialogOpened: true,
      selectedTask: taskId
    })
  }

  removeTaskById = (taskId) => {
    this.props.DeleteStage(taskId);
    this.setState({ isRemoveTaskDialogOpened: false })
  };

  render() {
    const {
      stages,
    } = this.props.productionChain;
    const {
      SeparativeLayers,
      Sprays,
      CurrentImageInEditing,
      isAddingQCCriterionDialogOpened,
      isEditQCCriterionDialogOpened,
      isGraphicsEditorOpened,
      selectedResin,
      technology,
      selectedHardener,

      stepDescription,
      techDocType
    } = this.props;
    return (
      <div
        className='taskTemplateList'
      >
        <MaterialPreparingHeader
          materialTypeTitle={stepDescription.title}
          materialTypeDescription={stepDescription.description}
        />
        <div
          style={{
            marginBottom: 56,
          }}
        >
          {_.map(stages, (stage, index) => {
            return (
              <Stage
                stepType={stepDescription.type}
                key={stage._id}
                id={index}
                stage={stage}
                isAddingQCCriterionDialogOpened={isAddingQCCriterionDialogOpened}
                isEditQCCriterionDialogOpened={isEditQCCriterionDialogOpened}
                selectedResin={selectedResin}
                separativeLayers={SeparativeLayers}
                sprays={Sprays}
                technology={technology}

                OpenAddingQCCriterionDialog={this.props.OpenAddingQCCriterionDialog.bind(this)}
                RemoveQCCriterion={this.props.RemoveQCCriterion.bind(this)}
                CloseAddingQCCriterionDialog={this.props.CloseAddingQCCriterionDialog.bind(this)}
                AddQCCriterion={this.props.AddQCCriterion.bind(this)}

                EditQCCriterion={this.props.EditQCCriterion.bind(this)}
                CloseEditQCCriterionDialog={this.props.CloseEditQCCriterionDialog.bind(this)}
                OpenEditQCCriterionDialog={this.props.OpenEditQCCriterionDialog.bind(this)}

                AddScreenshot={this.props.AddScreenshot.bind(this)}
                DeleteScreenshot={this.props.DeleteScreenshot.bind(this)}
                SetCurrentImage={this.props.SetCurrentImage.bind(this)}
                OpenGraphicsEditor={this.props.OpenGraphicsEditor.bind(this)}

                SetTimeDurationHours={this.props.SetTimeDurationHours.bind(this)}
                SetTimeDurationMinutes={this.props.SetTimeDurationMinutes.bind(this)}

                SetCustomTaskDescription={this.props.SetCustomTaskDescription.bind(this)}

                UpdateLayersOrder={this.props.UpdateLayersOrder.bind(this)}

                selectedHardener={selectedHardener}

                CheckQCCriterion={this.props.CheckQCCriterion}
                techDocType={techDocType}
                selectTaskForRemove={this.selectTaskForRemove}
              />
            )
          })
          }
        </div>
        <RemoveStructureDialog
          closeStructureProductDialog={this.closeRemoveTaskDialog}
          RemoveStructureById={this.removeTaskById}
          isRemoveStructureDialogOpened={this.state.isRemoveTaskDialogOpened}
          title='Удалить задачу?'
          content='Это действие удалит задачу без возможности отмены.'
          structureId={this.state.selectedTask}
        />
        <DialogGraphicsEditor
          isGraphicsEditorOpened={isGraphicsEditorOpened}
          CurrentImageInEditing={CurrentImageInEditing}

          EditScreenshot={this.props.EditScreenshot.bind(this)}
          CloseGraphicsEditor={this.props.CloseGraphicsEditor.bind(this)}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    SeparativeLayers: state.TechDocMaterials.separativeLayers,
    Sprays: state.TechDocMaterials.sprays,

    loadedMaterials: state.TechDocMaterials.loadedMaterials,

    IsGraphicsEditorOpened: state.Interface.isGraphicsEditorOpened,
    CurrentImageInEditing: state.Interface.currentImageInEditing,

    isAddingQCCriterionDialogOpened: state.Interface.isAddingQCCriterionDialogOpened,
    isGraphicsEditorOpened: state.Interface.isGraphicsEditorOpened,
    isEditQCCriterionDialogOpened: state.Interface.isEditQCCriterionDialogOpened,

    selectedResin: _.find(state.TechDocMaterialsAmount.materialsAmount, materialAmount => {
      return materialAmount.material.category.key === 'rr';
    }),
    selectedHardener: _.find(state.TechDocMaterialsAmount.materialsAmount, materialAmount => {
      return materialAmount.material.category.key === 'rh';
    }),
    technology: state.TechDocs.SelectedTechDoc.technology,

  }),
  dispatch => ({
    SetPrice: (taskId, price) => {
      dispatch(ProductionChainActions.SetStepPrice(taskId, price));
    },
    EditScreenshot: (url, newImageSrc) => {
      dispatch(ProductionChainActions.EditScreenshot(url, newImageSrc));
    },
    CloseGraphicsEditor: () => {
      dispatch(InterfaceActions.CloseGraphicsEditor());
    },
    OpenGraphicsEditor: (image) => {
      dispatch(InterfaceActions.SetCurrentImage(image));
      dispatch(InterfaceActions.OpenGraphicsEditor());
    },
    CloseEditQCCriterionDialog: () => {
      dispatch(InterfaceActions.CloseEditQCCriterionDialog());
    },
    OpenEditQCCriterionDialog: () => {
      dispatch(InterfaceActions.OpenEditQCCriterionDialog());
    },
    EditQCCriterion: (text, type, criterionId, taskId) => {
      dispatch(ProductionChainActions.EditQCCriterion(text, type, criterionId, taskId));
    },
    AddQCCriterion: (text, type, taskId) => {
      dispatch(ProductionChainActions.AddQCCriterion(text, type, taskId));
    },
    CloseAddingQCCriterionDialog: () => {
      dispatch(InterfaceActions.CloseAddingQCCriterionDialog());
    },
    OpenAddingQCCriterionDialog: () => {
      dispatch(InterfaceActions.OpenAddingQCCriterionDialog());
    },
    RemoveQCCriterion: (criterionId, taskId) => {
      dispatch(ProductionChainActions.RemoveQCCriterion(criterionId, taskId));
    },
    UpdateLayersOrder: (taskId, newOrder) => {
      dispatch(ProductionChainActions.UpdateLayersOrder(taskId, newOrder));
    },
    PreviousAttachment: (taskId) => {
      dispatch(ProductionChainActions.PreviousAttachment(taskId));
    },
    NextAttachment: (taskId) => {
      dispatch(ProductionChainActions.NextAttachment(taskId));
    },
    CloseAttachments: (taskId) => {
      dispatch(ProductionChainActions.CloseAttachments(taskId));
    },
    OpenAttachments: (attachmentId, taskId) => {
      dispatch(ProductionChainActions.OpenAttachments(attachmentId, taskId));
    },
    SelectType: (type) => {
      dispatch(TechDocMaterialsAmountActions.SelectType(type));
    },
    SelectResin: (resinTitle) => {
      dispatch(TechDocMaterialsAmountActions.SelectResin(resinTitle));
    },
    SetStepperOpen: (isOpen) => {
      dispatch(InterfaceActions.ChangeStepperOpen(isOpen));
    },
    SelectSeparativeLayer: (taskId, separativeLayer) => {
      dispatch(ProductionChainActions.SelectSeparativeLayer(taskId, separativeLayer));
    },
    SelectSpray: (taskId, spray) => {
      dispatch(ProductionChainActions.SelectSpray(taskId, spray));
    },
    SetIntermediateControl: (taskId, newIC) => {
      dispatch(ProductionChainActions.SetIntermediateControl(taskId, newIC));
    },
    SetQualityControl: (taskId, newQC) => {
      dispatch(ProductionChainActions.SetQualityControl(taskId, newQC));
    },
    AddScreenshot: (stageId) => {
      dispatch(ProductionChainActions.AddScreenshot(stageId))
    },
    DeleteScreenshot: (stageId, screenId) => {
      dispatch(ProductionChainActions.DeleteScreenshot(stageId, screenId));
    },
    SetCurrentImage: (newImage) => {
      dispatch(InterfaceActions.SetCurrentImage(newImage));
    },
    SetTimeDurationHours: (stageId, durationHours) => {
      dispatch(ProductionChainActions.SetTimeDurationHours(stageId, durationHours));
      // dispatch(ProductionChainActions.SetStepPrice(taskId,  Math.ceil(timeDuration * 6.8)));
    },
    SetTimeDurationMinutes: (stageId, durationMinutes) => {
      dispatch(ProductionChainActions.SetTimeDurationMinutes(stageId, durationMinutes));
      // dispatch(ProductionChainActions.SetStepPrice(taskId,  Math.ceil(timeDuration * 6.8)));
    },
    SetCustomTaskDescription: (stageId, parameter, value) => {
      dispatch(ProductionChainActions.SetCustomTaskDescription(stageId, parameter, value));
    },

    CheckQCCriterion: (stageId, criterionId, checked) => {
      dispatch(ProductionChainActions.CheckQCCriterion(stageId, criterionId, checked))
    }
  })
)(StagesList)
