import React, { Component } from 'react';
import _ from "lodash";
import {CONTAINER_CONSTANTS} from "../../../Styles/ContainerConstants";
import {
	TechDocSelect
} from "../_NewDesignComponents";
import {MenuItem} from "@material-ui/core";

import {
	NotSealingOblational,
	TowMaterialSize
} from '../_NewDesignComponents/OblationalMaterials'

export default class OblationalMaterialNoCut extends Component {
	changeTowMaterialLength(newLength) {
		const { cutGroup } = this.props;
		const { sizes, _id } = cutGroup;
		const newSizes = sizes;
		newSizes[0].length = newLength;
		this.props.SaveONCG(_id, 'sizes', newSizes);
	};

	render() {
		const {
			filteredOblationalCutMaterials,
			cutGroup
		} = this.props;
		return (
			<div>
				<TechDocSelect
					label={'Подбор материала'}
					value={!_.isUndefined(cutGroup.material) ? cutGroup.material._id : ''}
					fullWidth={true}
					style={{
						marginRight: CONTAINER_CONSTANTS.ICON_CONTAINER_SIZE,
					}}
					onChange={(event) => {
						this.props.SaveONCG(cutGroup._id, 'material', event.target.value)
					}}
				>
					{_.map(filteredOblationalCutMaterials, filteredMaterial => {
						return <MenuItem
							key={filteredMaterial._id}
							value={filteredMaterial._id}
						>
							{filteredMaterial.title}
						</MenuItem>
					})}
				</TechDocSelect>
				{cutGroup.groupTag.key !== 'sealing_tow' ?
					<NotSealingOblational
						cutGroupId={cutGroup._id}
						sizes={cutGroup.sizes}
						SaveONCG={this.props.SaveONCG}
					/>
					:
					<TowMaterialSize
						cutGroupId={cutGroup._id}
						length={cutGroup.sizes[0].length}
						changeTowMaterialLength={this.changeTowMaterialLength.bind(this)}
					/>
				}
			</div>
		)
	}
}