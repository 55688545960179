import React, { Component } from 'react';
import {
  FormControlLabel,
  Checkbox,
  withStyles
} from '@material-ui/core';

import _ from 'lodash';
import { CONTAINER_CONSTANTS } from "../../../Styles/ContainerConstants";
import { COLORS } from "../../../Styles/AppColors";

// const styles = {
//   addButton:{
//     marginLeft: 12,
//   },
//   listItemText:{
//     whiteSpace:'pre-wrap',
//   },
//   criteriaTypography:{
//     overflow: 'hidden',
//     textOverflow: 'ellipsis'
//   },
// };

const classStyles = () => ({
  root: {
    color: COLORS.BLUE,
    '&$checked': {
      color: COLORS.BLUE,
    },
  },
  checked: {},
  label: {
    width: 'auto',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }
});

class QCCriteriaList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isItemContentVisible: {}
    };
  }

  ShowContent(id, flag) {
    this.setState({
      isItemContentVisible: {
        ...this.state.isItemContentVisible,
        [id]: flag
      }
    });
  }

  render() {
    const {
      criteriaQC,
      taskId,
      title,
      type,

      classes
    } = this.props;
    return (
      <div
        className='partOfCriteriaList'
      >
        <span
          style={{
            fontFamily: 'Roboto',
            fontSize: 14,
            letterSpacing: -0.2,
            marginLeft: CONTAINER_CONSTANTS.QC_CRITERION_MARGIN,
            marginTop: CONTAINER_CONSTANTS.QC_CRITERION_MARGIN,
            marginBottom: 8,
          }}
        >
          {title}
        </span>
        {_.map(_.filter(criteriaQC, criterion => {
          return criterion.type === type;
        }), (criterion) => {
          return <FormControlLabel
            style={{
              marginLeft: CONTAINER_CONSTANTS.QC_CRITERION_MARGIN - 14,
              height: 40,
            }}
            key={criterion._id}
            classes={{ label: classes.label }}
            control={
              <Checkbox
                classes={{
                  root: classes.root,
                  checked: classes.checked,
                }}
                checked={criterion.checked}
                onChange={(event) => this.props.CheckQCCriterion(taskId, criterion._id, event.target.checked)}
              // indeterminate
              />
            }
            label={criterion.text}
          />
        })}
      </div>
    );
  }
}

export default withStyles(classStyles)(QCCriteriaList);
