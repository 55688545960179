import React, { Component } from 'react';

import {
  ProjectsList,
} from '../SmartComponents';

class Projects extends Component{
  render(){
    return(
      <ProjectsList/>
    );
  }
}

export default Projects