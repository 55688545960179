import React, {Component} from 'react';
import CoveringLayersInfoTaskTemplate from './CoveringLayersInfoTaskTemplate';
import LayersFacingInfoTaskTemplates from './LayersFacingInfoTaskTemplates';

class LayersFacing extends Component {
  render(){
    const{
      layers,
      sprays,
      spray,
      taskId
    } = this.props;
    return(
      <div>
	      <CoveringLayersInfoTaskTemplate
		      sprays={sprays}
		      spray={spray}
		      SetCustomTaskDescription={this.props.SetCustomTaskDescription}
		      taskId={taskId}
	      />
        <LayersFacingInfoTaskTemplates
          taskId={taskId}
          layers={layers}
          SetCustomTaskDescription={this.props.SetCustomTaskDescription}
        />
      </div>
    );
  }
}

export default LayersFacing;
