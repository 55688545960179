import React, {Component, Fragment} from 'react';
import _ from 'lodash';
import {MenuItem} from '@material-ui/core';
import {RemoveIcon} from "..";
import {CONTAINER_CONSTANTS} from "../../../../Styles/ContainerConstants";
import TechDocSelect from "../TechDocSelect";
import OutlinedTextfield from "../OutlinedTextfield";

export default class ResinAndHardenerAdded extends Component {
  render() {
    const {
      resinsList,
      hardenersList,
      techDocResins
    } = this.props;
    const foundResin = _.find(techDocResins, resin => {
      return resin.material.category.key === 'rr'
    });
    const foundHardener = _.find(techDocResins, resin => {
      return resin.material.category.key === 'rh'
    });
    return (
      <Fragment>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              width: `calc(100% - ${CONTAINER_CONSTANTS.ICON_CONTAINER_SIZE}px`
            }}
          >
            <div
              style={{
                flex: 4,
                marginRight: 12,
              }}
            >
              <TechDocSelect
                label='Смола'
                value={foundResin.material._id}
                disabled={true}
                fullWidth={true}
              >
                {resinsList.map(resin => {
                  return <MenuItem
                    key={resin._id}
                    value={resin._id}
                  >
                    {resin.title}
                  </MenuItem>
                })}
              </TechDocSelect>
            </div>
            <OutlinedTextfield
              measureUnits='кг'
              disabled
              style={{
                flex: 1
              }}
              value={foundResin.amount}
            />
          </div>
          <RemoveIcon
            onClick={() => this.props.DeleteMaterial(foundResin.material._id)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              width: `calc(100% - ${CONTAINER_CONSTANTS.ICON_CONTAINER_SIZE}px`,
              marginTop: 24,
            }}
          >
            <div
              style={{
                flex: 4,
                marginRight: 12,
              }}
            >
              <TechDocSelect
                label='Отвердитель'
                value={foundHardener.material._id}
                disabled={true}
                fullWidth={true}
              >
                {hardenersList.map(hardener => {
                  return <MenuItem
                    key={hardener._id}
                    value={hardener._id}
                  >
                    {hardener.title}
                  </MenuItem>
                })}
              </TechDocSelect>
            </div>
            <OutlinedTextfield
              measureUnits='кг'
              disabled
              style={{
                flex: 1
              }}
              value={foundHardener.amount}
            />
          </div>
        </div>
      </Fragment>
    )
  }
};
