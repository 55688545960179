import React, { PureComponent } from 'react';

import {
	Typography,
	IconButton,
} from '@material-ui/core';

import {
	Add,
	Remove,
} from '@material-ui/icons';

const styles = {
	changingMaterialsCountContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginBottom: 8,
	},
	changingMaterialsCount: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	}
}

class ChangingMaterialsCount extends PureComponent {
	render(){
		const {
			layersCount
		} = this.props;
		return(
			<div
				style={styles.changingMaterialsCountContainer}
			>
				<div
					style={styles.changingMaterialsCount}
				>
					<IconButton
						onClick={this.props.DecrementCount}
						disabled={layersCount === 1}
					>
						<Remove/>
					</IconButton>
					<Typography
						variant='body1'
					>
						{layersCount}
					</Typography>
					<IconButton
						onClick={this.props.IncrementCount}
					>
						<Add/>
					</IconButton>
				</div>
				<Typography
					variant='caption'
				>
					Выбор количества слоев
				</Typography>
			</div>
		)
	}
}

export default ChangingMaterialsCount;