import React, { Component, Fragment } from "react";

import {
  Typography
} from '@material-ui/core';

import _ from 'lodash';

const styles = {
  taskDescriptionText:{
    marginBottom:'12px',
    whiteSpace:'pre-wrap'
  },
  taskDescription:{
    display:'flex',
    flexDirection:'column', 
    alignContent:'space-around' 
  }
}

class RiggingPreparingDescription extends Component{
  render(){
    const{
      separativeLayer,
      textTemplate,
      timeBetweenLayers,
      additionalDescription
    } = this.props;
    const textDescription = _.template(textTemplate);
    return(
      <Fragment>
        <Typography
          headlineMapping={{body1:'p'}}
          variant='body1'
          align='left'
          style={styles.taskDescriptionText}
        >
          {separativeLayer !== '' ? textDescription({'separativeLayer': separativeLayer, 'timeBetweenLayers': timeBetweenLayers}) : <b>Выберите разделительный слой и время между нанесением слоев</b>}
        </Typography>
        <Typography
          headlineMapping={{body1:'p'}}
          variant='body1'
          align='left'
          style={styles.taskDescriptionText}
        >
          <b>Дополнительная информация</b>: {additionalDescription.length > 0 ? additionalDescription: 'нет информации'}
        </Typography>
      </Fragment>
    );
  }
}

export default RiggingPreparingDescription;