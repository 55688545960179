import React, { PureComponent } from 'react';

import _ from "lodash";

import {
	Typography,
	Select,
	MenuItem,
} from '@material-ui/core';

class OblationalMaterialsNoCutDescription extends PureComponent {
	componentDidMount() {
		const {
			materialTitle,
			oblationalGroupTags,
			groupTag,
		} = this.props;
		if (_.isEmpty(groupTag)) {
			const materialWords = _.words(_.toLower(materialTitle));
			const foundGroupTag = _.find(oblationalGroupTags, ogt => {
				return _.startsWith(_.toLower(ogt.title), (materialWords[0] + ' ' + materialWords[1]))
			});
			this.props.ChangeMaterialGroupTag((!_.isUndefined(foundGroupTag) && !_.isUndefined(foundGroupTag._id)) ? foundGroupTag._id : '')
		}
	};

	render() {
		const {
			groupTag,
			oblationalGroupTags
		} = this.props;
		return(
			<div>
				<Typography
					variant='caption'
				>
					Выбор тега
				</Typography>
				<Select
					value={groupTag}
					onChange={(event) => this.props.ChangeMaterialGroupTag(event.target.value)}
					fullWidth
				>
					{_.map(oblationalGroupTags, ogt => {
						return <MenuItem
							key={ogt._id}
							value={ogt._id}
						>
							{ogt.title}
						</MenuItem>
					})}
				</Select>
			</div>
		)
	}
}

export default OblationalMaterialsNoCutDescription;
