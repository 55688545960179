import React, { Component } from 'react';

import {
  connect,
} from 'react-redux';

import _ from 'lodash';

import {
  TechDocCutActions,
  InterfaceActions,
  NewSurfaceMaterialActions,
	TechDocMaterialsAmountActions
} from '../../Redux/Actions';

import {
  ArmorMaterialsCut,
  AddingMaterialsDialog,
  ColorPicker,
  Editor,
  OblationalMaterialsCut,
  ChangingMaterialsDialog,
  AreYouSureDialog
} from '../DumbComponents/';
import {ResinChoosing} from "../DumbComponents/_NewDesignComponents/Resin";

const styles = {
  cutContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
	  marginBottom: 24,
  }
};

class Cut extends Component {
  render() {
    const {
      surfacesList,
      activeSurfaceId,
	    oblationalCuttingGroups,
	    oblationalNotCuttingGroups,

      isAddingMaterialDialogOpened,
      selectedMaterialId,
      layersCount,
      selectedAngle,

      selectedColor,
      colorPickerPosition,
      isColorPickerOpened,

      armorMaterials,
      oblationalCutMaterials,

	    isCutEditorOpened,

      angles,

	    isChangingSurfaceMaterialDialogOpened,

      editorSelectedMaterial,
      editorSelectedLayer,
      
      materialsAmount,

      isAreYouSureDialogOpened,

      resinsList,
      selectedResin,
      techDocResins,
      hardenersList,

      technology,
    } = this.props;
    const newResinAmount = 1.3 * _.sumBy(surfacesList, surface => {
      return surface.width * surface.height * _.sumBy(surface.layers, layer => {
        return !_.isUndefined(layer.material.description.data.dens) ? layer.material.description.data.dens / 1000 : 0
      })
    });
    return (
      <div
        style={styles.cutContainer}
        onClick={() => this.props.SetActiveArmorSurface(-1)}
      >
        <ArmorMaterialsCut
          armorSurfacesList={surfacesList}
          AddArmorSurface={this.props.AddArmorSurface.bind(this)}
          DeleteArmorSurface={this.props.DeleteArmorSurface.bind(this)}
          OpenAreYouSureDialog={this.props.OpenAreYouSureDialog.bind(this)}

          activeSurfaceId={activeSurfaceId}
          SetActiveArmorSurface={this.props.SetActiveArmorSurface.bind(this)}
          SetSurfaceWidth={this.props.SetSurfaceWidth.bind(this)}
          SetSurfaceHeight={this.props.SetSurfaceHeight.bind(this)}

          OpenAddingSurfaceMaterialDialog={this.props.OpenAddingSurfaceMaterialDialog.bind(this)}
          SetNewSurfaceMaterialInitialState={this.props.SetNewSurfaceMaterialInitialState.bind(this)}

          OpenColorPicker={this.props.OpenColorPicker.bind(this)}

          angles={angles}
          DeleteLayers={this.props.DeleteLayers.bind(this)}
          OpenChangingSurfaceMaterialDialog={this.props.OpenChangingSurfaceMaterialDialog.bind(this)}
          ConnectSurfaceWithEdges={this.props.ConnectSurfaceWithEdges.bind(this)}

          selectedMaterialId={selectedMaterialId}
          layersCount={layersCount}
          selectedAngle={selectedAngle}
          ChangeLayersCount={this.props.ChangeLayersCount.bind(this)}

          armorMaterials={armorMaterials}

          ChangeSelectedMaterial={this.props.ChangeSelectedMaterial.bind(this)}
          AddSurfaceLayers={this.props.AddSurfaceLayers.bind(this)}

          ChangeSelectedAngle={this.props.ChangeSelectedAngle.bind(this)}
          SetLayersCount={this.props.SetLayersCount.bind(this)}

        />
        <OblationalMaterialsCut
	        oblationalCuttingGroups={_.concat(oblationalCuttingGroups, oblationalNotCuttingGroups)}
          oblationalCutMaterials={oblationalCutMaterials}
	        ChangeOblationalLayerData={this.props.ChangeOblationalLayerData.bind(this)}

	        ChangeCuttingGroupMaterial={this.props.ChangeCuttingGroupMaterial.bind(this)}

	        AddOblationalSurface={this.props.AddOblationalSurface.bind(this)}
	        DeleteOblationalSurface={this.props.DeleteOblationalSurface.bind(this)}
	        SaveCuttingGroup={this.props.SaveCuttingGroup.bind(this)}
	        SetCuttingGroupIsBag={this.props.SetCuttingGroupIsBag.bind(this)}

	        SaveONCG={this.props.SaveONCG}
        />

        {technology === 'Инфузия' && <ResinChoosing
          resinsList={resinsList}
          hardenersList={hardenersList}
          selectedResin={selectedResin}

          SetSelectedResin={this.props.SetSelectedResin}
          newResinAmount={newResinAmount}

          techDocResins={techDocResins}
          SaveResin={this.props.SaveResin}

          DeleteMaterial={this.props.DeleteMaterial}
        />}

        <AddingMaterialsDialog
          isAddingMaterialDialogOpened={isAddingMaterialDialogOpened}
          CloseAddingSurfaceMaterialDialog={this.props.CloseAddingSurfaceMaterialDialog.bind(this)}

          selectedMaterialId={selectedMaterialId}
          layersCount={layersCount}
          selectedAngle={selectedAngle}

          IncrementCount={this.props.IncrementCount.bind(this)}
          DecrementCount={this.props.DecrementCount.bind(this)}

          armorMaterials={armorMaterials}

          ChangeSelectedMaterial={this.props.ChangeSelectedMaterial.bind(this)}
          AddSurfaceLayers={this.props.AddSurfaceLayers.bind(this)}

          ChangeSelectedAngle={this.props.ChangeSelectedAngle.bind(this)}
          angles={angles}
        />

        <ChangingMaterialsDialog
          layersCount={layersCount}
          IncrementCount={this.props.IncrementCount.bind(this)}
          DecrementCount={this.props.DecrementCount.bind(this)}

	        isChangingSurfaceMaterialDialogOpened={isChangingSurfaceMaterialDialogOpened}
	        CloseChangingSurfaceMaterialDialog={this.props.CloseChangingSurfaceMaterialDialog.bind(this)}

          ChangeLayersCount={this.props.ChangeLayersCount.bind(this)}
        />

        <ColorPicker
	        selectedColor={selectedColor}
	        isColorPickerOpened={isColorPickerOpened}
	        colorPickerPosition={colorPickerPosition}
	        CloseColorPicker={this.props.CloseColorPicker.bind(this)}
	        ChangeSelectedColor={this.props.ChangeSelectedColor.bind(this)}
	        ChangeSurfaceColor={this.props.ChangeSurfaceColor.bind(this)}
        />

	      {isCutEditorOpened && <Editor
	        isCutEditorOpened={isCutEditorOpened}
	        armorMaterials={armorMaterials}
	        oblationalCutMaterials={oblationalCutMaterials}
	        armorSurfacesList={surfacesList}
	        CloseCutEditor={this.props.CloseCutEditor.bind(this)}

	        ChangeEditorSelectedMaterial={this.props.ChangeEditorSelectedMaterial.bind(this)}
	        editorSelectedMaterial={editorSelectedMaterial}
	        ChangeEditorSelectedLayer={this.props.ChangeEditorSelectedLayer.bind(this)}
          editorSelectedLayer={editorSelectedLayer}

	        ChangeLayerPosition={this.props.ChangeLayerPosition.bind(this)}

	        RotateLayer={this.props.RotateLayer.bind(this)}

	        CalculateCuttingMaterials={this.props.CalculateCuttingMaterials.bind(this)}
	        materialsAmount={materialsAmount}

	        oblationalCuttingGroups={oblationalCuttingGroups}
        />}

        <AreYouSureDialog
          isAreYouSureDialogOpened={isAreYouSureDialogOpened}
          CloseAreYouSureDialog={this.props.CloseAreYouSureDialog.bind(this)}
          DeleteArmorSurface={this.props.DeleteArmorSurface.bind(this)}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    surfacesList: state.TechDocCut.surfacesList,
    activeSurfaceId: state.TechDocCut.activeSurfaceId,
	  oblationalCuttingGroups: state.TechDocCut.oblationalCuttingGroups,
	  oblationalNotCuttingGroups: state.TechDocCut.oblationalNotCuttingGroups,

    isAddingMaterialDialogOpened: state.Interface.isAddingMaterialDialogOpened,
    isChangingSurfaceMaterialDialogOpened: state.Interface.isChangingSurfaceMaterialDialogOpened,

    selectedMaterialId: state.NewSurfaceMaterial.selectedMaterial,
    layersCount: state.NewSurfaceMaterial.layersCount,
    selectedAngle: state.NewSurfaceMaterial.selectedAngle,
    angles: state.NewSurfaceMaterial.angles,

    selectedColor: state.Interface.selectedColor,
    isColorPickerOpened: state.Interface.isColorPickerOpened,

    isAreYouSureDialogOpened: state.Interface.isAreYouSureDialogOpened,

	  armorMaterials: _.filter(state.TechDocMaterials.loadedMaterials, material => {
		  return material.category.key === 'am';
	  }),

	  oblationalCutMaterials: _.filter(state.TechDocMaterials.loadedMaterials, material => {
		  return (material.category.key === 'omc' || material.category.key === 'omnc')
    }),

    isCutEditorOpened: state.Interface.isCutEditorOpened,

	  editorSelectedMaterial: state.Interface.editorSelectedMaterial,
    editorSelectedLayer: state.Interface.editorSelectedLayer,
    materialsAmount: state.TechDocMaterialsAmount.materialsAmount,

    resinsList: _.filter(state.TechDocMaterials.loadedMaterials, material => {
      const catKey = material.category.key;
      return catKey === 'rr';
    }),
    hardenersList: _.filter(state.TechDocMaterials.loadedMaterials, material => {
      const catKey = material.category.key;
      return catKey === 'rh';
    }),
    selectedResin: state.TechDocMaterialsAmount.selectedResin,

    techDocResins: _.filter(state.TechDocMaterialsAmount.materialsAmount, ma => {
      const catKey = ma.material.category.key;
      return catKey === 'rr' || catKey === 'rh';
    }),

    technology: state.TechDocs.SelectedTechDoc.technology,
  }),
  dispatch => ({
    ChangeLayersCount: (surfaceId, materialId, angle, layersCount) => {
      // dispatch(NewSurfaceMaterialActions.ChangeLayersCount(layersCount));
      // dispatch(NewSurfaceMaterialActions.SetNewSurfaceMaterialInitialState(materialId, angle, layersCount))
      dispatch(TechDocCutActions.ChangeLayersCount(surfaceId, materialId, angle, layersCount))
    },
    SetLayersCount: (layersCount) => {
      dispatch(NewSurfaceMaterialActions.ChangeLayersCount(layersCount));
    },
    OpenAreYouSureDialog: () => {
      dispatch(InterfaceActions.OpenAreYouSureDialog());
    },
    CloseAreYouSureDialog: () => {
      dispatch(InterfaceActions.CloseAreYouSureDialog());
    },
    SetOblationalNotCutMaterial: (materialId, amount) => {
      dispatch(TechDocMaterialsAmountActions.SetOblationalNotCutMaterial(materialId, amount));
    }, 
    AddArmorSurface: () => {
      dispatch(TechDocCutActions.AddArmorSurface());
    },
    DeleteArmorSurface: () => {
      dispatch(TechDocCutActions.DeleteArmorSurface());
    },
    SetActiveArmorSurface: (surfaceId) => {
      dispatch(TechDocCutActions.SetActiveArmorSurface(surfaceId));
    },
    SetSurfaceWidth: (newWidth) => {
      dispatch(TechDocCutActions.SetSurfaceWidth(newWidth));
    },
    SetSurfaceHeight: (newHeight) => {
      dispatch(TechDocCutActions.SetSurfaceHeight(newHeight));
    },
    ChangeSurfaceColor: () => {
      dispatch(TechDocCutActions.ChangeSurfaceColor());
      dispatch(InterfaceActions.CloseColorPicker());
    },
    OpenAddingSurfaceMaterialDialog: () => {
	    dispatch(NewSurfaceMaterialActions.SetNewSurfaceMaterialInitialState('', '', 1));
      dispatch(InterfaceActions.OpenAddingSurfaceMaterialDialog());
    },
    CloseAddingSurfaceMaterialDialog: () => {
      dispatch(InterfaceActions.CloseAddingSurfaceMaterialDialog());
    },
    SetNewSurfaceMaterialInitialState: (selectedMaterialId, selectedRotationAngle, selectedMaterialLayersCount) => {
      dispatch(NewSurfaceMaterialActions.SetNewSurfaceMaterialInitialState(selectedMaterialId, selectedRotationAngle, selectedMaterialLayersCount));
    },
    IncrementCount: () => {
      dispatch(NewSurfaceMaterialActions.IncrementCount());
    },
    DecrementCount: () => {
      dispatch(NewSurfaceMaterialActions.DecrementCount());
    },
    OpenColorPicker: (newColor) => {
      dispatch(InterfaceActions.ChangeSelectedColor(newColor));
      dispatch(InterfaceActions.OpenColorPicker());
    },
    CloseColorPicker: () => {
      dispatch(InterfaceActions.CloseColorPicker());
    },
    ChangeSelectedColor: (newColor) => {
      dispatch(InterfaceActions.ChangeSelectedColor(newColor))
    },
    OpenChangingSurfaceMaterialDialog: (materialId, angle, layersCount) => {
      dispatch(InterfaceActions.OpenChangingSurfaceMaterialDialog());
      dispatch(NewSurfaceMaterialActions.SetNewSurfaceMaterialInitialState(materialId, angle, layersCount))
    },
    CloseChangingSurfaceMaterialDialog: () => {
      dispatch(InterfaceActions.CloseChangingSurfaceMaterialDialog());
    },
    CloseCutEditor: () => {
      dispatch(InterfaceActions.CloseCutEditor());
    },
	  ChangeSelectedMaterial: (newMaterial) => {
		  dispatch(NewSurfaceMaterialActions.ChangeSelectedMaterial(newMaterial));
    },
    ChangeSelectedAngle: (newAngle) => {
      dispatch(NewSurfaceMaterialActions.ChangeSelectedAngle(newAngle))
    },
    AddSurfaceLayers: () => {
      dispatch(TechDocCutActions.AddSurfaceLayers());
	    dispatch(InterfaceActions.CloseAddingSurfaceMaterialDialog());
    },
    DeleteLayers: (layersDescription, surfaceId) => {
      dispatch(TechDocCutActions.DeleteLayers(layersDescription, surfaceId));
    },
    // ChangeLayersCount: () => {
    //   dispatch(TechDocCutActions.ChangeLayersCount())
    // },
	  ChangeEditorSelectedMaterial: (newMaterial) => {
      dispatch(InterfaceActions.ChangeEditorSelectedMaterial(newMaterial));
      dispatch(InterfaceActions.ChangeEditorSelectedLayer(''));
    },
	  ChangeLayerPosition: (x, y, isOblational) => {
      dispatch(TechDocCutActions.ChangeLayerPosition(x, y, isOblational))
    },
	  ChangeEditorSelectedLayer: (newLayer) => {
      dispatch(InterfaceActions.ChangeEditorSelectedLayer(newLayer));
    },
	  ConnectSurfaceWithEdges: () => {
      dispatch(TechDocCutActions.ConnectSurfaceWithEdges());
    },
	  RotateLayer: (direction) => {
      dispatch(TechDocCutActions.RotateLayer(direction));
    },
	  // SelectArmorMaterial: (amount) => {
    // 	dispatch(TechDocMaterialsAmountActions.SelectArmorMaterial(amount))
	  // },
	  CalculateCuttingMaterials: () => {
    	dispatch(TechDocMaterialsAmountActions.CalculateCuttingMaterials());
	  },
	  ChangeOblationalLayerData: (layerId, parameter, value) => {
    	dispatch(TechDocCutActions.ChangeOblationalLayerData(layerId, parameter, value))
	  },
	  ChangeCuttingGroupMaterial: (cuttingGroupId, materialId) => {
    	dispatch(TechDocCutActions.ChangeCuttingGroupMaterial(cuttingGroupId, materialId));
	  },

	  AddOblationalSurface: (cuttingGroupId, surfaces) => {
    	dispatch(TechDocCutActions.AddOblationalSurface(cuttingGroupId, surfaces));
	  },
	  DeleteOblationalSurface: (cuttingGroupId, surfaceId, surfaces) => {
    	dispatch(TechDocCutActions.DeleteOblationalSurface(cuttingGroupId, surfaceId, surfaces));
	  },
	  SaveCuttingGroup: (surfaces) => {
    	dispatch(TechDocCutActions.SaveCuttingGroup(surfaces));
	  },
	  SaveONCG: (oncgId, parameterTitle, parameterValue) => {
    	dispatch(TechDocCutActions.SaveONCG(oncgId, parameterTitle, parameterValue))
	  },
	  SetCuttingGroupIsBag: (cgId, isBag) => {
    	dispatch(TechDocCutActions.SetCuttingGroupIsBag(cgId, isBag))
	  },

    SetSelectedResin: (resin) => {
      dispatch(TechDocMaterialsAmountActions.SetSelectedResin(resin));
    },
    SaveResin: () => {
      dispatch(TechDocMaterialsAmountActions.SaveResin())
    },
    DeleteMaterial: (materialId) => {
      dispatch(TechDocMaterialsAmountActions.DeleteMaterial(materialId))
    }
  }),
)(Cut);
