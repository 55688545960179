import React, { Component } from 'react';
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import {
  BlueButton
} from '.'

class RemoveStructureDialog extends Component {
  onCloseDialogHandler = () => {
    this.props.closeStructureProductDialog();
  }

  onRemoveStructureHandler = () => {
    this.props.RemoveStructureById(this.props.structureId);
  }

  render() {
    const {
      isRemoveStructureDialogOpened,
      title,
      content
    } = this.props;

    return (
      <Dialog
        open={isRemoveStructureDialogOpened}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
        <DialogActions>
          <BlueButton
            color='primary'
            onClick={this.onCloseDialogHandler}
            label='Отмена'
          />
          <BlueButton
            color='primary'
            onClick={this.onRemoveStructureHandler}
            label='Удалить'
          />
        </DialogActions>
      </Dialog>
    )
  }
}

RemoveStructureDialog.propTypes = {
  closeStructureProductDialog: PropTypes.func.isRequired,
  RemoveStructureById: PropTypes.func.isRequired,
  isRemoveStructureDialogOpened: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  structureId: PropTypes.any.isRequired
};

export default RemoveStructureDialog;
