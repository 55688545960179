import React, { Component } from 'react'

class ColorPick extends Component {
  render() {
    const {
      colorPanel
    } = this.props;
    return (
      <div 
        onClick={this.props.onClick}
        style={{background: colorPanel}}
        className='colorPick'
      />
    )
  }
}

export default ColorPick;
