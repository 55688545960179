import {
  MeConstants,
} from '../Constants';

const initState = {
  me: {},
  hasAccess: false,
};

const CLEAR_ME = (oldState) => {
  return {
    ...oldState,
    me: {},
    hasAccess: false,
  }
}

const SET_ME = (newState, oldState) => {
  return {
    ...oldState,
    me: newState,
  }
};

const SET_ACCESS = (oldState) => {
  return {
    ...oldState,
    hasAccess: true,
  };
};

const SET_NO_ACCESS = (oldState) => {
  return {
    ...oldState,
    hasAccess: false,
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case MeConstants.SET_ME:
      return SET_ME(action.data, state);

    case MeConstants.SET_ACCESS:
      return SET_ACCESS(state);

    case MeConstants.SET_NO_ACCESS:
      return SET_NO_ACCESS(state);

    case MeConstants.CLEAR_ME:
      return CLEAR_ME(state);

    default:
      return state;
  }
}