import React, { PureComponent } from 'react';
import {
	Typography,
} from '@material-ui/core';


export default class CategoryDescription extends PureComponent {
	render() {
		const {
			title,
			value,
		} = this.props;
		return(
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					margin: '12px 0'
				}}
			>
				<Typography
					variant='body1'
					style={{ flex: 1 }}
				>
					{title}
				</Typography>
				<Typography
					variant='body2'
					style={{ flex: 2 }}
				>
					{value}
				</Typography>
			</div>
		)
	}
};
