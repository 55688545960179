const NewTechDocConstants = {
  CHANGE_NEW_TECH_DOC_TITLE: 'CHANGE_NEW_TECH_DOC_TITLE',
  CHANGE_NEW_TECH_DOC_TYPE: 'CHANGE_NEW_TECH_DOC_TYPE',
  CHANGE_NEW_TECH_DOC_TECHNOLOGY: 'CHANGE_NEW_TECH_DOC_TECHNOLOGY',
  CHANGE_MODEL_LINK: 'CHANGE_MODEL_LINK',
  SET_DRAWING_REQUIREMENT: 'SET_DRAWING_REQUIREMENT',
  SET_ASSEMBLING_REQUIREMENT: 'SET_ASSEMBLING_REQUIREMENT',

  GET_NEW_TECH_DOC_CREATING_DATA: 'GET_NEW_TECH_DOC_CREATING_DATA',
	CHANGE_CHOSEN_PRODUCTION_STEPS: 'CHANGE_CHOSEN_PRODUCTION_STEPS',

  RESET_NEW_TECH_DOC_CREATING: 'RESET_NEW_TECH_DOC_CREATING',
  
  SET_TECHDOC_UPLOADING: 'SET_TECHDOC_UPLOADING',
  SET_TECHDOC_UPLOADED: 'SET_TECHDOC_UPLOADED',
};

export default NewTechDocConstants;
