import React, { Component } from 'react';
import { RemoveIcon } from '../../../DumbComponents/_NewDesignComponents';

import {
  Card,
  CardContent,
  Typography,
  CardHeader,
  CardActionArea,
} from '@material-ui/core';

import _ from 'lodash';

import moment from 'moment';

const styles = {
  text: {
    marginTop: 6
  },
  realizationCard: {
    cursor: 'pointer',
    margin: 12,
  },
  removeIcon: {
    position: 'absolute',
    zIndex: 1,
    top: -10,
    right: 0,
    backgroundColor: '#fff',
    borderRadius: 20,
    fontSize: '40px',
    width: 40,
    height: 40,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto'
  },
  cardInfo: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 6
  },
  cardHeader: {
    backgroundColor: '#EFEFEF'
  }
}

class SubProjectCard extends Component {

  getHHMM = (minutes) => {
    const newHours = Math.trunc(minutes / 60) < 10 ? '0' + Math.trunc(minutes / 60) : Math.trunc(minutes / 60);
    const newMinutes = minutes % 60 < 10 ? '0' + minutes % 60 : minutes % 60;
    return (newHours + ":" + newMinutes);
  };

  getTaskSpentTime = (reversedHistory) => {
    const history = _.sortBy(reversedHistory, 'date');
    if (_.isUndefined(history)) {
      return 0;
    };
    if (_.findIndex(history, historyItem => {
      return historyItem.state === 'doing'
    }) === -1) {
      return 0;
    };
    let attemptsCounter = 0;
    const taskAttempts = _.reduce(history, (historyAttempts, historyItem) => {
      if (_.isEmpty(_.last(historyAttempts)) && historyItem.state === 'doing') {
        const attempt = historyAttempts[attemptsCounter]
        historyAttempts[attemptsCounter] = _.assign(attempt, { doing: historyItem.date });
        return historyAttempts;
      };
      if (!_.isEmpty(_.last(historyAttempts)) && (historyItem.state === 'inReview' || historyItem.state === 'done')) {
        const attempt = historyAttempts[attemptsCounter]
        historyAttempts[attemptsCounter] = _.assign(attempt, { done: historyItem.date });
        attemptsCounter = attemptsCounter + 1;
        return _.concat(historyAttempts, {});
      };
      return historyAttempts;
    }, [{}]);
    const updatedAttempts = !_.isEmpty(_.last(taskAttempts)) ? taskAttempts : _.dropRight(taskAttempts);
    return _.sumBy(updatedAttempts, attempt => {
      return !_.isUndefined(attempt.done) ? moment(attempt.done).diff(moment(attempt.doing), "minutes") : moment().diff(moment(attempt.doing), "minutes");
    })
  };

  getCurrentStatus = (tasks) => {
    return _.chain(tasks).sortBy('order').find(task => {
      return task.state !== 'done'
    }).value();
  }

  translateState = (state) => {
    switch (_.toLower(state)) {
      case 'todo':
        return 'Сделать';

      case 'doing':
        return 'В работе';

      case 'inreview':
        return 'В проверке';

      case 'waiting':
        return 'В ожидании';

      case 'blocked':
        return 'Заблокировано';

      case 'failed':
        return 'Забраковано';

      default:
        return;
    }
  }

  render() {
    const {
      projectTitle,
      realization
    } = this.props;
    const {
      order,
      tasks
    } = realization;
    const completedTasksCount = _.size(_.filter(realization.tasks, task => {
      return task.state === 'done';
    }));
    const sumHistoryTime = _.sumBy(tasks, (task) => {
      return this.getTaskSpentTime(task.history);
    })
    let status = this.getCurrentStatus(realization.tasks);
    return (
      <div
        style={{ position: 'relative' }}
      >
        <RemoveIcon
          style={styles.removeIcon}
          onClick={() => this.props.selectRealizationForRemove(realization._id)}
        />
        <Card
          onClick={() => this.props.SelectRealization(realization._id)}
          style={styles.realizationCard}
        >
          <CardActionArea
            style={{ width: '100%', height: '100%' }}
          >
            <CardHeader
              title={
                <Typography
                  variant='title'
                >
                  <b>Реализация {projectTitle} {order + 1}</b>
                </Typography>
              }
            />
            <CardContent>
              <div
                className='subProjectCardInfo'
              >
                <Typography
                  style={{ flex: 3 }}
                  variant='body1'
                >
                  Количество изделий
              </Typography>
                <Typography
                  style={{ flex: 2 }}
                  variant='body1'
                >
                  <b>{realization.count}</b>
                </Typography>
              </div>
              <div
                className='subProjectCardInfo'
              >
                <Typography
                  style={{ flex: 3 }}
                  variant='body1'
                >
                  Метка
              </Typography>
                <Typography
                  style={{ flex: 2 }}
                  variant='body1'
                >
                  <b>{_.isNil(realization.nfc) ? 'Не привязана' : realization.nfc}</b>
                </Typography>
              </div>
              <div
                className='subProjectCardInfo'
              >
                <Typography
                  style={{ flex: 3 }}
                  variant='body1'
                >
                  Текущая задача
              </Typography>
                <Typography
                  style={{ flex: 2 }}
                  variant='body1'
                >
                  <b>{!_.isUndefined(status) ? status.title : 'НЕТ'}</b>
                </Typography>
              </div>
              <div
                className='subProjectCardInfo'
              >
                <Typography
                  style={{ flex: 3 }}
                  variant='body1'
                >
                  Текущий статус
              </Typography>
                <Typography
                  style={{ flex: 2 }}
                  variant='body1'
                >
                  <b>{!_.isUndefined(status) ? this.translateState(status.state) : 'Готова'}</b>
                </Typography>
              </div>
              <div
                style={styles.cardInfo}
              >
                <Typography
                  style={{ flex: 3 }}
                  variant='body1'
                >
                  Выполнено
              </Typography>
                <Typography
                  style={{ flex: 2 }}
                  variant='body1'
                >
                  <b>{_.floor(completedTasksCount / _.size(realization.tasks) * 100)}</b>%
              </Typography>
              </div>
              <div
                style={styles.cardInfo}
              >
                <Typography
                  style={{ flex: 3 }}
                  variant='body1'
                >
                  Время выполнения
              </Typography>
                <Typography
                  style={{ flex: 2 }}
                  variant='body1'
                >
                  <b>{`${this.getHHMM(sumHistoryTime)}`}</b>
                </Typography>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    )
  }
}

export default SubProjectCard;