import React, {Component} from 'react';

import _ from 'lodash';

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {OutlinedTable} from "../../_NewDesignComponents";

const head = ['№', 'Время', 'Температура'];

class PolymerizationDiagramInfo extends Component{
  render(){
    const {
      steps
    } = this.props;
    return(
      <OutlinedTable
        style={{
          marginTop: 24
        }}
      >
        <TableHead>
          <TableRow>
            {_.map(head, (title, index) => {
              return (
                <TableCell
                  key={index}
                >
                  {title}
                </TableCell>
              )})
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(steps, (step, stepId) => {
            return <TableRow
              key={step._id}
            >
              <TableCell>
                {stepId + 1}
              </TableCell>
              <TableCell>
                {step.time} ч.
              </TableCell>
              <TableCell>
                {step.temperature}°
              </TableCell>
            </TableRow>
          })}
        </TableBody>
      </OutlinedTable>
    );
  }
}

export default PolymerizationDiagramInfo;