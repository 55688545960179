import React, { Component, Fragment } from 'react';

import _ from 'lodash';

import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  CardActionArea,
  Grow,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem
} from '@material-ui/core';

import moment from 'moment';

import TaskState from './TaskState';

const styles = {
  taskCardHeader: {
    borderBottom: '1px solid grey'
  },
  taskCard: {
    margin: 12
  }
}

class TaskMiniDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSwitchTaskStatusOpened: false
    }
  }

  handleCLose = () => {
    this.setState({
      isSwitchTaskStatusOpened: false
    })
  }

  getHHMM = (minutes) => {
    const newHours = Math.trunc(minutes / 60) < 10 ? '0' + Math.trunc(minutes / 60) : Math.trunc(minutes / 60);
    const newMinutes = minutes % 60 < 10 ? '0' + minutes % 60 : minutes % 60;
    return (newHours + ":" + newMinutes);
  };

  getTaskSpentTime = (reversedHistory) => {
    const history = _.sortBy(reversedHistory, 'date');
    if (_.isUndefined(history)) {
      return this.getHHMM(0);
    };
    if (_.findIndex(history, historyItem => {
      return historyItem.state === 'doing'
    }) === -1) {
      return this.getHHMM(0);
    };
    let attemptsCounter = 0;
    const taskAttempts = _.reduce(history, (historyAttempts, historyItem) => {
      if (_.isEmpty(_.last(historyAttempts)) && historyItem.state === 'doing') {
        const attempt = historyAttempts[attemptsCounter]
        historyAttempts[attemptsCounter] = _.assign(attempt, { doing: historyItem.date });
        return historyAttempts;
      };
      if (!_.isEmpty(_.last(historyAttempts)) && (historyItem.state === 'inReview' || historyItem.state === 'done')) {
        const attempt = historyAttempts[attemptsCounter]
        historyAttempts[attemptsCounter] = _.assign(attempt, { done: historyItem.date });
        attemptsCounter = attemptsCounter + 1;
        return _.concat(historyAttempts, {});
      };
      return historyAttempts;
    }, [{}]);
    const updatedAttempts = !_.isEmpty(_.last(taskAttempts)) ? taskAttempts : _.dropRight(taskAttempts);
    return this.getHHMM(_.sumBy(updatedAttempts, attempt => {
      return !_.isUndefined(attempt.done) ? moment(attempt.done).diff(moment(attempt.doing), "minutes") : moment().diff(moment(attempt.doing), "minutes");
    }))
  };

  render() {
    const {
      task,
      isSelected
    } = this.props;
    const {
      title,
      state,
      assignedTo,
      date,
      type,
      history,
    } = task;
    const stage = !_.isUndefined(task.stage) ? task.stage : {}
    const switchItems = [
      { switchState: 'todo', onClick: this.props.BeginTask, title: 'Задача выполняется' },
      { switchState: 'doing', onClick: this.props.EndTask, title: 'Задача завершена' },
      { switchState: 'inreview', onClick: this.props.EndTask, title: 'ОТК пройден' },
      { switchState: 'inreview', onClick: this.props.WaitingTask, title: 'ОТК не пройден' }
    ];
    return (
      <Fragment>
        <div
          ref={(node) => {
            this.anchorEl = node;
          }}
          aria-owns={this.state.isSwitchTaskStatusOpened ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
        >
          <Card
            style={_.assign({}, styles.taskCard, isSelected ? { boxShadow: 'inset 0px 0px 0px 1px #2196F3' } : {})}
            onClick={() => {
              this.props.GetTask(task._id)
              this.setState({
                isSwitchTaskStatusOpened: _.includes(['todo', 'doing', 'inreview'], _.toLower(state))
              })
            }}
          >
            <CardActionArea
              style={{ width: '100%', height: '100%' }}
            >
              <CardHeader
                title={<div>
                  <Typography
                    variant='subheading'
                  >
                    <b>{title}</b>
                  </Typography>
                  <TaskState
                    state={state}
                  />
                </div>}
                style={styles.taskCardHeader}
              />
              <CardContent>
                <div
                  className='taskMiniDescriptionCard'
                >
                  <Typography
                    variant='body1'
                  >
                    {this.getTaskSpentTime(history)}/{(type !== 'materialPreparing' && type !== 'QC') ? this.getHHMM(stage.durationMinutes) : 'Не определено'}
                  </Typography>
                  <Typography
                    variant='body1'
                  >
                    Дата выполнения: <b>{!_.isUndefined(date) ? moment(date).format('DD.MM.YY') : 'Не выбрана'}</b>
                  </Typography>
                  <Typography
                    variant='body1'
                  >
                    Исполнитель: <b>{!_.isUndefined(assignedTo) ? assignedTo.second_name + ' ' + assignedTo.first_name : 'Не выбран'}</b>
                  </Typography>
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
        <Popper
          open={this.state.isSwitchTaskStatusOpened}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          style={{ zIndex: 1000, marginTop: 12, marginBottom: 12 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={this.handleCLose}
                >
                  <MenuList>
                    {_.map(switchItems, (item, k) => (
                      <MenuItem
                        key={k}
                        disabled={_.toLower(state) !== item.switchState}
                        onClick={item.onClick}
                      >
                        {item.title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Fragment>
    );
  }
}

export default TaskMiniDescription;