import React, { Component } from 'react'
import {connect} from 'react-redux';

import {
  InterfaceActions,
  TechDocMaterialsAmountActions
} from '../../../../Redux/Actions'

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import {
  BlueButton
} from '../../../DumbComponents/_NewDesignComponents'

import {
  MaterialTable
} from '../../../DumbComponents/_NewDesignComponents/Materials'

import _ from 'lodash';

class MaterialsDialog extends Component {
  componentDidMount() {
    this.props.GetSuperCategories();
  };

  render() {
    const {
	    materialsSuperCategories,
      materialsAmount,
      isMaterialDialogOpened
    } = this.props;
    
    return(
      <Dialog
        open={isMaterialDialogOpened}
        PaperProps={{
          style: {
            width: 1200,
            height: 650
          }
        }}
        fullWidth
        maxWidth='md'
        onClose={() => this.props.CloseMaterialDialog()}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: '#212121'
            }}
          >
            <span
              style={{
                fontFamily: 'Roboto',
                fontSize: 26,
                lineHeight: '30px',
                fontWeight: 300
              }}
            >
              Материалы
            </span>
            <span
              style={{
                fontFamily: 'Roboto',
                fontSize: 16,
                lineHeight: '19px',
                fontWeight: 500
              }}
            >
              Общие траты {_.sumBy(materialsAmount, ma => {
                return ma.amount * ma.material.price;
              }).toFixed(2)} ₽
            </span>
          </div>
        </DialogTitle>
        <DialogContent>          
          {_.map(materialsSuperCategories, (superCat) => {
            const filteredMaterialsAmount = _.filter(materialsAmount, (materialAmount) => {
              return materialAmount.material.category.superCategory === superCat._id
            });
            return <MaterialTable
              key={superCat.title}
              price={_.sumBy(filteredMaterialsAmount, ma => {
                return ma.amount * ma.material.price;
              })}
              superCategory={superCat.title}
              materials={filteredMaterialsAmount}
            />
            })
          }
        </DialogContent>
        <DialogActions>
          <BlueButton
            label='Скачать PDF'
            disabled={true}
          />
          <BlueButton
            onClick={() => this.props.CloseMaterialDialog()}
            label='Закрыть'
          />
        </DialogActions>
      </Dialog>
    )
  }
}


export default connect(
  state => ({
	  materialsSuperCategories: state.TechDocMaterialsAmount.materialsSuperCategories,
    materialsAmount: state.TechDocMaterialsAmount.materialsAmount,
    isAddingResinDialogOpened: state.Interface.isAddingResinDialogOpened,
    resinsList: _.filter(state.TechDocMaterials.loadedMaterials, material => {
      return material.category.key === 'rr'
    }),
    //to do another logic of filtering
    addingOblationalMaterials: _.filter(state.TechDocMaterials.loadedMaterials, material => {
      return material.category.key === 'omo'
    }),
	  selectedResin: state.TechDocMaterialsAmount.selectedResin,

	  isAddingOblationalMaterialDialogOpened: state.Interface.isAddingOblationalMaterialDialogOpened,
    selectedOblationalMaterial: state.TechDocMaterialsAmount.selectedOblationalMaterial,
    
    surfacesList: state.TechDocCut.surfacesList,
    technology: state.TechDocs.SelectedTechDoc.technology,
    isMaterialDialogOpened: state.Interface.isMaterialDialogOpened,
  }),
  dispatch => ({
    CloseMaterialDialog: () => {
      dispatch(InterfaceActions.CloseMaterialDialog());
    },
    AddConnector : () =>{
      dispatch(TechDocMaterialsAmountActions.AddConnector());
    },
    SetConnectorAmount: (amount, index) => {
      dispatch(TechDocMaterialsAmountActions.SetConnectorAmount(amount,index));
    },
    SelectConnector: (title,index) => {
      dispatch(TechDocMaterialsAmountActions.SelectConnector(title,index));
    },
    SetResinAmount: (amount) => {
      dispatch(TechDocMaterialsAmountActions.SetResinAmount(amount));
    },
    SelectResin: (resinTitle) =>  {
      dispatch(TechDocMaterialsAmountActions.SelectResin(resinTitle));
    },
	  GetSuperCategories: () => {
      dispatch(TechDocMaterialsAmountActions.GetSuperCategories());
    },
    OpenResinAddingDialog: () => {
      dispatch(InterfaceActions.OpenResinAddingDialog());
    },
    CloseResinAddingDialog: () => {
      dispatch(InterfaceActions.CloseResinAddingDialog());
    },
	  SetSelectedResin: (resin) => {
      dispatch(TechDocMaterialsAmountActions.SetSelectedResin(resin));
    },
	  SaveResin: () => {
      dispatch(TechDocMaterialsAmountActions.SaveResin())
    },
	  SetSelectedOblationalMaterial: (oblationalMaterial) => {
      dispatch(TechDocMaterialsAmountActions.SetSelectedOblationalMaterial(oblationalMaterial))
    },
    OpenOblationalMaterialAddingDialog: () => {
      dispatch(InterfaceActions.OpenOblationalMaterialAddingDialog());
    },
    CloseOblationalMaterialAddingDialog: () => {
      dispatch(InterfaceActions.CloseOblationalMaterialAddingDialog());
    },
	  SaveOblationalMaterial: () => {
      dispatch(TechDocMaterialsAmountActions.SaveOblationalMaterial())
    },
	  DeleteMaterial: (materialId) => {
      dispatch(TechDocMaterialsAmountActions.DeleteMaterial(materialId))
    }
  })
)(MaterialsDialog);
