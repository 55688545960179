import React, { PureComponent } from 'react';

import {
  BlueButton
} from '../DumbComponents/_NewDesignComponents';

const styles = {
  button: {
    marginLeft: 12,
    marginRight: 12,
    alignSelf: 'center',
  }
}
class NavigationButtons extends PureComponent {
  openMaterialDialogHandler = () => {
    this.props.OpenMaterialDialog()
  }

  render() {
    const {
      CanBack,
      techDocType
    } = this.props;
    return (
      <div
        className='navigationButtonsContainer'
      >
        <div
          className='navigationButtonsFlex'
        >
          <div
            className='navigationButtonsTwoButtons'
          >
            {
              CanBack && <BlueButton
                onClick={this.props.OpenCutEditor}
                color='primary'
                style={styles.button}
                label='Раскрой'
              />
            }
            {techDocType === 'assembly' ?
              <BlueButton
                color='primary'
                style={styles.button}
                label='Добавить задачу'
                onClick={this.props.addStage}
              />
              :
              <BlueButton
                color='primary'
                style={styles.button}
                label='Материалы'
                onClick={this.openMaterialDialogHandler}
              />
            }
          </div>
          <div>
            <BlueButton
              color='primary'
              onClick={this.props.PushToPDF}
              style={{
                marginRight: 18
              }}
              label='PDF'
            />
            <BlueButton
              color='primary'
              onClick={this.props.OnClickSave}
              style={{
                marginRight: 45
              }}
              label='Выйти'
            />
          </div>
        </div>
      </div>
    )
  }
}

export default NavigationButtons;
