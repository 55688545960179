import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { COLORS } from '../../../Styles/AppColors';
import { CONTAINER_CONSTANTS } from '../../../Styles/ContainerConstants';
import _ from 'lodash';

const styles = () => ({
  removeIcon: {
    // width: CONTAINER_CONSTANTS.ICON_CONTAINER_SIZE,
    marginRight: 8,
    height: CONTAINER_CONSTANTS.ICON_CONTAINER_SIZE,
    '&:hover': {
      color: COLORS.ICON_GRAY,
      cursor: 'pointer',
    },
  }
});

class RemoveArmorSurfaceIcon extends PureComponent {
  render(){
    const { 
      classes
    } = this.props;
    return(
      <Icon
        color='disabled'
        className={classes.removeIcon}
        style={_.assign({
	        display: 'flex',
	        alignItems: 'center',
	        justifyContent: 'center',
        }, this.props.style)}
        {...this.props}
      >
        cancel
      </Icon>
    )
  }
}

RemoveArmorSurfaceIcon.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RemoveArmorSurfaceIcon);