import React, { Component, Fragment } from "react";

import {
  Typography
} from '@material-ui/core';

import _ from 'lodash';

const styles = {
  taskDescriptionText:{
    marginBottom:'12px',
    marginRight: '12px',
    marginTop: '12px',
    whiteSpace:'pre-wrap'
  },
  taskDescription:{
    display:'flex',
    flexDirection:'column', 
    alignContent:'space-around'  
  }
}

class LayersFacingDescription extends Component{
  render(){
    const{
      data,
      textTemplate,
      additionalDescription
    } = this.props;
    const spray = !_.isUndefined(data.spray) ? data.spray : '';
    const textDescription = _.template(textTemplate);
    return(
      <Fragment>
        <Typography
          headlineMapping={{body1:'p'}}
          variant='body1'
          align='left'
          style={styles.taskDescriptionText}
        >
          {spray !== '' ? textDescription({'spray': spray}) : 'Спрей не выбран'}
        </Typography>
        <Typography
          headlineMapping={{body1:'p'}}
          variant='body1'
          align='left'
          style={styles.taskDescriptionText}
        >
          <b>Дополнительная информация</b>: {additionalDescription.length > 0 ? additionalDescription: 'нет информации'}
        </Typography>
      </Fragment>
    );
  }
}

export default LayersFacingDescription;