import React, { Component, Fragment } from 'react';
import { TechDocSelect, OutlinedTextfield, OutlinedButton } from "..";
import { CONTAINER_CONSTANTS } from "../../../../Styles/ContainerConstants";
import { MenuItem } from "@material-ui/core";
import _ from "lodash";

export default class ResinAndHardenerChoosing extends Component {
  componentDidMount() {
    const {
      selectedResin,
      newResinAmount
    } = this.props;
    if (_.isUndefined(selectedResin.material)) {
      this.props.SetSelectedResin({ material: '' });
    }
    if (_.isUndefined(selectedResin.amount) || selectedResin.amount !== newResinAmount) {
      this.props.SetSelectedResin({ amount: _.ceil(newResinAmount, 2) });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newResinAmount !== this.props.newResinAmount) {
      this.props.SetSelectedResin({ amount: _.ceil(this.props.newResinAmount, 2) });
    }
  }

  componentWillUnmount() {
    this.props.SetSelectedResin({ amount: _.ceil(this.props.newResinAmount, 2), material: '' });
  }

  render() {
    const {
      resinsList,
      selectedResin,
    } = this.props;
    const mixedResins = _.reduce(resinsList, (newResinsList, resin) => {
      return _.concat(newResinsList, (resin.description && resin.description.complementaryRules) ? _.map(resin.description.complementaryRules, cr => {
        return {
          resinTitle: resin.title,
          resinId: resin._id,
          hardenerTitle: cr.material.title,
          hardenerId: cr.material._id
        }
      }) : []);
    }, []);
    return (
      <Fragment>
        <TechDocSelect
          value={selectedResin.material ? selectedResin.material : ''}
          onChange={event => this.props.SetSelectedResin({ material: event.target.value })}
          fullWidth={true}
          style={{
            marginRight: CONTAINER_CONSTANTS.ICON_CONTAINER_SIZE,
          }}
          label='Подбор смеси'
        >
          {mixedResins.map(resin => {
            return <MenuItem
              key={resin.resinId + '/' + resin.hardenerId}
              value={resin.resinId + '/' + resin.hardenerId}
            >
              {resin.resinTitle + ' + ' + resin.hardenerTitle}
            </MenuItem>
          })}
        </TechDocSelect>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: `calc(100% - ${CONTAINER_CONSTANTS.ICON_CONTAINER_SIZE}px`,
            marginTop: 16,
            alignitems: 'center'
          }}
        >
          <OutlinedTextfield
            measureUnits='кг'
            type='number'
            value={selectedResin.amount}
            style={{ width: '100%', marginRight: 12 }}
            defaultValue={0}
            onChange={event => this.props.SetSelectedResin({ amount: event.target.value })}
          />
          <OutlinedButton
            label='ОК'
            style={{ marginTop: 0, height: 42 }}
            onClick={this.props.SaveResin}
            disabled={_.isEmpty(selectedResin.material)}
          />
        </div>
      </Fragment>
    )
  }
};
