import React, { PureComponent } from 'react';

import {
	Icon,
	FormControlLabel,
} from '@material-ui/core';

import {
	Straighten,
	CropSquare,
	InvertColors,
	InvertColorsOff
} from '@material-ui/icons';

class FreeCadCommands extends PureComponent {
	render() {
		const {
			currentMeasureResult
		} = this.props;
		return(
			<div
				className='freeCadCommandsContainer'
			>
				<FormControlLabel
					style={{
						marginLeft: 12
					}}
					label='Площадь'
					onClick={this.props.GetArea}
					control={
						<Icon
							style={{
								marginRight: 8,
							}}
						>
							<CropSquare/>
						</Icon>
					}
				/>
				<FormControlLabel
					label='Восстановить цвета'
					onClick={this.props.SetColors}
					control={
						<Icon
							style={{
								marginRight: 8,
							}}
						>
							<InvertColors/>
						</Icon>
					}
				/>
				<FormControlLabel
					label='Сбросить цвета'
					onClick={this.props.ResetColors}
					control={
						<Icon
							style={{
								marginRight: 8,
							}}
						>
							<InvertColorsOff/>
						</Icon>
					}
				/>
				<FormControlLabel
					label='Длина'
					onClick={this.props.GetLength}
					control={
						<Icon
							style={{
								marginRight: 8,
							}}
						>
							<Straighten/>
						</Icon>
					}
				/>
				<div
					className='measureTextContainer'
				>
					<span
						style={{
							fontSize: '14px',
							fontFamily: 'Roboto',
							letterSpacing: '0.5px',
							lineHeight: '16px'
						}}
					>
						{currentMeasureResult}
					</span>
				</div>
			</div>
		)
	}
}

export default FreeCadCommands;