import React, { PureComponent } from 'react';
import Doc from '../DumbComponents/Doc';
import { noAvatar } from '../../Models/Avatars';
import _ from 'lodash';
import api from '../../Utils/API';
import {
  Grid
} from '@material-ui/core';

class DocsList extends PureComponent {

  filterTechDoc = (TechDocsList, type) => {
    return _.filter(TechDocsList, { type: type });
  };

  render() {
    const {
      TechDocsList,
      productId,
      type
    } = this.props;

    const filteredTechDocsList = this.filterTechDoc(TechDocsList, type);
    return (
      <Grid
        container
        justify='space-between'
      >
        {_.map(filteredTechDocsList, (techDoc, key) =>
          <Grid
            item
            sm={6}
            key={key}
          >
            <Doc
              Title={techDoc.title}
              WorkerPrice={techDoc.price.wp}
              MaterialPrice={techDoc.price.mp}
              Durability={!_.isUndefined(techDoc.time) ? techDoc.time : 0}
              TechDocId={techDoc._id}
              AvatarPath={!_.isUndefined(techDoc.coverImageUrl) ? api.getHost() + techDoc.coverImageUrl : noAvatar}
              selectTechDocForRemove={this.props.selectTechDocForRemove}
              selectTechDoc={() => this.props.selectTechDoc(productId, techDoc._id)}
            />
          </Grid>
        )
        }
      </Grid>
    )
  }
}

export default DocsList;
