import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
	TextField,
	MenuItem,
	Typography,
	Select
} from '@material-ui/core';

const styles = {
	newProjectTypography: {
		flex: 1,
		display: 'flex'
	},
	newProjectTextField: {
		flex: 1,
		display: 'flex',
		width: '170px'
	}
}

class CreateNewProjectContent extends PureComponent {
	componentDidMount() {
		// this.props.GetTechDocsForProjects();
		this.props.GetProducts();
	};

	render() {
		const {
			newProject,
			productsList,
			product
		} = this.props;
		const {
			title,
			productsCount,
			techDocId,
			productId,
			riggingCapacity
		} = newProject;
		return (
			<div
				className='createNewProjectContainer'
			>
				<div
					className='createNewProjectRow'
				>
					<div
						style={styles.newProjectTypography}
					>
						<Typography
							variant='body1'
						>
							Название проекта
						</Typography>
					</div>
					<div
						style={styles.newProjectTextField}
					>
						<TextField
							fullWidth
							value={title}
							onChange={(event) => { this.props.ChangeNewProjectData('title', event.target.value) }}
						/>
					</div>
				</div>
				<div
					className='createNewProjectRow'
				>
					<div
						style={styles.newProjectTypography}
					>
						<Typography
							variant='body1'
						>
							Изделие
						</Typography>
					</div>
					<div
						style={styles.newProjectTextField}
					>
						<Select
							value={productId}
							fullWidth
							onChange={(event) => { this.props.ChangeNewProjectData('productId', event.target.value); this.props.SetProduct(event.target.value) }}
						>
							{_.map(productsList, (product) => {
								return (
									<MenuItem
										key={product._id}
										value={product._id}
									>
										{product.title}
									</MenuItem>
								)
							})
							}
						</Select>
					</div>
				</div>
				{!_.isEmpty(productId) &&
					<div
						className='createNewProjectRow'
					>
						<div
							style={styles.newProjectTypography}
						>
							<Typography
								variant='body1'
							>
								Технологическая карта
						</Typography>
						</div>
						<div
							style={styles.newProjectTextField}
						>
							<Select
								disabled={_.isEmpty(productId) || _.isEmpty(product.techDocs)}
								value={techDocId}
								fullWidth
								onChange={(event) => { this.props.ChangeNewProjectData('techDocId', event.target.value) }}
							>
								{_.map(product.techDocs, (techDoc) => {
									return (
										<MenuItem
											key={techDoc._id}
											value={techDoc._id}
										>
											{techDoc.title}
										</MenuItem>
									)
								})
								}
							</Select>
						</div>
					</div>
				}
				{!_.isEmpty(techDocId) &&
					<div
						className='createNewProjectRow'
					>
						<div
							style={styles.newProjectTypography}
						>
							<Typography
								variant='body1'
							>
								Количество изделий
						</Typography>
						</div>
						<div
							style={styles.newProjectTextField}
						>
							<TextField
								type='number'
								fullWidth
								value={productsCount}
								onChange={(event) => { this.props.ChangeNewProjectData('productsCount', event.target.value < 1 ? 1 : event.target.value) }}
							/>
						</div>
					</div>
				}
				{!_.isEmpty(techDocId) && _.find(product.techDocs, { _id: techDocId }).type === 'default' &&
					<div
						className='createNewProjectRow'
					>
						<div
							style={styles.newProjectTypography}
						>
							<Typography
								variant='body1'
							>
								Вместимость оснастки
						</Typography>
						</div>
						<div
							style={styles.newProjectTextField}
						>
							<TextField
								type='number'
								fullWidth
								value={riggingCapacity}
								onChange={(event) => { this.props.ChangeNewProjectData('riggingCapacity', event.target.value < 1 ? 1 : event.target.value) }}
							/>
						</div>
					</div>
				}
			</div>
		)
	}
};

export default CreateNewProjectContent;
