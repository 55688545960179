import {
  NewSurfaceMaterialConstants,
} from '../Constants';

const SetNewSurfaceMaterialInitialState = (selectedMaterialId, selectedRotationAngle, selectedMaterialLayersCount) => {
  return (dispatch, getState) => {
    return dispatch ({
      type: NewSurfaceMaterialConstants.SET_NEW_SURFACE_MATERIAL_INITIAL_STATE,
			data: {
				material: selectedMaterialId,
				rotationAngle: selectedRotationAngle,
				layersCount: selectedMaterialLayersCount,
			}})
  };
};

const ChangeLayersCount = (count) => {
	return {
		type: NewSurfaceMaterialConstants.CHANGE_LAYER_COUNT,
		data: count
	}
}

const IncrementCount = () => {
  return {
    type: NewSurfaceMaterialConstants.INCREMENT_COUNT,
  };
};

const DecrementCount = () => {
  return {
    type: NewSurfaceMaterialConstants.DECREMENT_COUNT,
  };
};

const ChangeSelectedMaterial = (newMaterial) => {
	return {
		type: NewSurfaceMaterialConstants.CHANGE_SELECTED_MATERIAL,
		data: newMaterial,
	};
};

const ChangeSelectedAngle = (newAngle) => {
	return {
		type: NewSurfaceMaterialConstants.CHANGE_SELECTED_ANGLE,
		data: newAngle,
	}
}
 
export default {
  SetNewSurfaceMaterialInitialState,
  IncrementCount,
  DecrementCount,
	ChangeSelectedMaterial,
	ChangeSelectedAngle,
	ChangeLayersCount
};
