import React, { Component } from 'react';

import ColorPick from '../../ColorPick'

import{
  Card,
  CardContent,
  Typography
} from '@material-ui/core';

const styles ={
  rowContent:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  card:{
    marginBottom: 6,
    backgroundColor: '#f5f5f5'
  }
}

class SingleLayerRow extends Component{
  render(){
    const {
      index,
      materialTitle,
      rotationAngle,
      surfaceColor
    } = this.props;
    return(
      <Card
        style={styles.card}
      >
        <CardContent 
          style={styles.rowContent}
        >
          <div 
            style={{flex:'1'}}
          >
            <Typography>
              {index + 1}
            </Typography>
          </div>
          <div 
            style={{flex:'5'}}
          >
            <Typography>
              {materialTitle}
            </Typography>
          </div>
          <div 
            style={{flex:'2'}}
          >
            <Typography>
              {rotationAngle}
            </Typography>
          </div>
          <div 
            className='colorPickContainer'
          >
            <ColorPick
              colorPanel={surfaceColor}
            />
            <Typography>
              Поверхность
            </Typography>
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default SingleLayerRow;