import {
  AuthConstants,
} from '../Constants';

const initState = {
  username: '',
  password: '',
  authFailed: false,
};

const CHANGE_USERNAME = (newState, oldState) => {
  return {
    ...oldState,
    username: newState,
    authFailed: false,
  };
};

const CHANGE_PASSWORD = (newState, oldState) => {
  return {
    ...oldState,
    password: newState,
    authFailed: false,
  };
};

const SET_AUTH_ERROR = (oldState) => {
  return {
    ...oldState,
    password: '',
    authFailed: true,
  };
};

export default (state = initState, action) => {
  switch (action.type) {
    case AuthConstants.CHANGE_USERNAME:
      return CHANGE_USERNAME(action.data, state);

    case AuthConstants.CHANGE_PASSWORD:
      return CHANGE_PASSWORD(action.data, state);

    case AuthConstants.SET_AUTH_ERROR:
      return SET_AUTH_ERROR(state);

    default:
      return state;
  }
}