import React, { PureComponent } from 'react';

import _ from 'lodash';

import ArmorSurface from './ArmorSurface';

class ArmorSurfacesList extends PureComponent {
  render() {
    const {
      armorSurfacesList,
      activeSurfaceId,

      angles,

      selectedMaterialId,
      layersCount,
      selectedAngle,
      armorMaterials

    } = this.props;
    return(
      <div>
        {_.map(armorSurfacesList, (surface, surfaceId) => {
          return <ArmorSurface
            key={surface._id}
            title={'ПОВЕРХНОСТЬ ' + (surfaceId + 1)}
            width={surface.width}
            height={surface.height}
            color={surface.color}
            layers={surface.layers}
            DeleteArmorSurface={this.props.DeleteArmorSurface}
            OpenAreYouSureDialog={this.props.OpenAreYouSureDialog}

            surfaceId={surface._id}
            isActive={activeSurfaceId === surface._id}
            SetActiveArmorSurface={this.props.SetActiveArmorSurface}

            SetSurfaceWidth={this.props.SetSurfaceWidth}
            SetSurfaceHeight={this.props.SetSurfaceHeight}

            OpenAddingSurfaceMaterialDialog={this.props.OpenAddingSurfaceMaterialDialog}

            OpenColorPicker={this.props.OpenColorPicker}

            angles={angles}
            DeleteLayers={this.props.DeleteLayers}

            OpenChangingSurfaceMaterialDialog={this.props.OpenChangingSurfaceMaterialDialog}

            ConnectSurfaceWithEdges={this.props.ConnectSurfaceWithEdges}          

            selectedMaterialId={selectedMaterialId}
            layersCount={layersCount}
            selectedAngle={selectedAngle}

            armorMaterials={armorMaterials}

            ChangeSelectedMaterial={this.props.ChangeSelectedMaterial}
            AddSurfaceLayers={this.props.AddSurfaceLayers}

            ChangeSelectedAngle={this.props.ChangeSelectedAngle}

            ChangeLayersCount={this.props.ChangeLayersCount}

            SetLayersCount={this.props.SetLayersCount}
          />
        })}
      </div>
    )
  }
};

export default ArmorSurfacesList;