import React, { Component } from 'react';

import appStyles from '../../Styles/AppThemStyles';

import {
  InterfaceActions,
  UsersActions
} from '../../Redux/Actions';

import AddUserDialog from '../DumbComponents/Users/AddUserDialog';
import UsersTable from '../DumbComponents/Users/UsersTable';
import { connect } from 'react-redux';

import {
  Button
} from '@material-ui/core';

import {
  Add
} from '@material-ui/icons';

class UsersAdding extends Component{
  componentDidMount(){
    this.props.GetAllUsers();
  }

  render(){
    const {
      users,
      newUser,
      isAddUserDialogOpened,
      selectedUserId,
      roles
    } = this.props;
    return(
      <div
        className='usersContainer'
      >
        <UsersTable
          users={users}
          isAddUserDialogOpened={isAddUserDialogOpened}

          OpenAddingUserDialog={this.props.OpenAddingUserDialog.bind(this)}
          OpenEditingUserDialog={this.props.OpenEditingUserDialog.bind(this)}
          DeleteUser={this.props.DeleteUser.bind(this)}
        />
        {isAddUserDialogOpened &&
          <AddUserDialog
            isAddUserDialogOpened={isAddUserDialogOpened}
            newUser={newUser}
            selectedUserId={selectedUserId}
            usersRoles={roles}

            GetAllRoles={this.props.GetAllRoles.bind(this)}
            EditUser={this.props.EditUser.bind(this)}
            ChangeNewUserData={this.props.ChangeNewUserData.bind(this)}
            AddUser={this.props.AddUser.bind(this)}
            CloseAddingUserDialog={this.props.CloseAddingUserDialog.bind(this)}
        />
        }
        <Button
          variant='fab'
          color='primary'
          aria-label='add'
          onClick={() => this.props.OpenAddingUserDialog()}
          style={appStyles.AddButtonStyle}
        >
          <Add />
        </Button>
      </div>
    )
  }
}

export default connect(
  state => ({
    users: state.Users.usersList,
    isAddUserDialogOpened: state.Interface.isAddUserDialogOpened,
    newUser: state.Users.newUser,
    selectedUserId: state.Users.selectedUserId,
    roles: state.Users.roles
  }),
  dispatch => ({
    GetAllRoles: () => {
      dispatch(UsersActions.GetAllRoles())
    },
    DeleteUser: (userId) => {
      dispatch(UsersActions.DeleteUser(userId));
    },
    OpenEditingUserDialog: (userId) => {
      dispatch(UsersActions.OpenEditingUserDialog(userId));
    },
    GetAllUsers: () => {
      dispatch(UsersActions.GetAllUsers());
    },
    EditUser: (userId) => {
      dispatch(UsersActions.EditUser(userId));
    },
    CleanUserData: () => {
      dispatch(UsersActions.CleanUserData());
    },
    ChangeNewUserData: (parameter, value) => {
      dispatch(UsersActions.ChangeNewUserData(parameter, value));
    },
    AddUser: () => {
      dispatch(UsersActions.AddUser());
    },  
    OpenAddingUserDialog: () => {
      dispatch(InterfaceActions.OpenAddingUserDialog());
    },
    CloseAddingUserDialog: () => {
      dispatch(InterfaceActions.CloseAddingUserDialog());
    }
  })
)(UsersAdding);
