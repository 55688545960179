import React, {Component} from 'react';
import _ from 'lodash';
import {
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";

import {
  OutlinedExpansionPanel,
  MaterialPreparingHeader,
} from "..";

import {
  ResinAndHardenerAdded,
  ResinAndHardenerChoosing
} from ".";

export default class ResinChoosing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    }
  }

  render() {
    const { isOpen } = this.state;
    const {
      resinsList,
      hardenersList,
      selectedResin,

      newResinAmount,

      techDocResins,
    } = this.props;
    return (
      <div
        style={{ paddingBottom: 56 }}
      >
        <MaterialPreparingHeader
          materialTypeTitle='Смола'
          materialTypeDescription='Рекомендуемое количество смолы и отвердителя рассчитывается автоматически по размерам заданных отрезов материалов'
        />
        <OutlinedExpansionPanel
          style={{ borderRadius: 4 }}
        >
          <ExpansionPanelSummary
            onClick={() => this.setState({isOpen: !isOpen})}
            expandIcon={<ExpandMore/>}
            style={isOpen ?
              {
                fontSize: 16,
                fontFamily: 'Roboto',
                letterSpacing: -0.3,
                fontWeight: '500',
              }
              :
              {
                fontSize: 14,
                fontFamily: 'Roboto',
                letterSpacing: -0.2,
                lineHeight: '16px'
              }
            }
          >
            Смола
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{
              display: 'block'
            }}
          >
            {_.isEmpty(techDocResins) ? <ResinAndHardenerChoosing
              resinsList={resinsList}
              selectedResin={selectedResin}
              SetSelectedResin={this.props.SetSelectedResin}

              newResinAmount={newResinAmount}
              SaveResin={this.props.SaveResin}
            />
            :
            <ResinAndHardenerAdded
              resinsList={resinsList}
              hardenersList={hardenersList}
              techDocResins={techDocResins}
              DeleteMaterial={this.props.DeleteMaterial}
            />}
          </ExpansionPanelDetails>
        </OutlinedExpansionPanel>
      </div>
    )
  }
};
