import React, { Component, Fragment } from 'react'

import _ from 'lodash';

import {
  connect,
} from 'react-redux';

import {
  InterfaceActions,
  TechDocsActions,
  NavigationActions
} from '../../Redux/Actions/';

import {
  NavigationButtons,
  FreeCadCommands
} from '../DumbComponents';

import {
  VNCDisplay,
  StagesList,
  Cut,
  // MaterialList
} from './';
import {
  TechDocHeader,

} from "./_NewDesignComponents";
import {
  MaterialsDialog
} from './_NewDesignComponents/Materials'

import { CONTAINER_CONSTANTS } from '../../Styles/ContainerConstants'

const styles = {
  freeCad: {
    height: 'calc(100% - 64px)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
};

class DefaultTechDoc extends Component {
  getStepsList = (cut, materials, productionChain) => {
    const newProductionChain = _.map(productionChain, (item) => {
      return {
        id: item._id,
        title: item.template.title,
        timeDuration: _.sumBy(item.stages, stage => {
          return stage.durationMinutes
        }),
        price: _.ceil(_.sumBy(item.stages, stage => {
          return stage.estimatedWorkerPrice;
        })),
      }
    });
    const newCut = _.pick(cut, ['id', 'title']);
    // const newMaterials = _.pick(materials, ['id', 'title']);

    // return _.concat([], newCut, _.assign(newMaterials, {
    //   title: 'Материалы',
    //   price: !_.isUndefined(techDocPrice.mp) ? techDocPrice.mp : 0,
    //   materialsCount: materialsAmountCount,
    // }), newProductionChain );
    return _.concat([], newCut, newProductionChain);
  };

  generateStepDescriptionById = (stepId) => {
    switch (stepId) {
      case 2:
        return {
          title: 'Этапы опиловки изделия',
          description: 'Заполните описание этапов опиловки изделия',
          type: 'filing'
        };

      case 3:
        return {
          title: 'Этапы покраски изделия',
          description: 'Заполните описание этапов покраски изделия',
          type: 'painting',
        };

      default: {
        return {
          title: 'Этапы формовки изделия',
          description: 'Заполните характеристики подготовки оснастки, выкладки ламината армирующих материалов и жертвенных слоёв',
          type: 'molding',
        }
      }
    }
  };

  getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Cut
          TechDocCut={this.props.TechDocCut}
        />
      // case 1:
      //   return <MaterialList
      //     resin={this.props.Resin}
      //   />
      default:
        return <StagesList
          resin={this.props.Resin}
          productionChain={this.props.ProductionChain[step - 1]}
          stepDescription={this.generateStepDescriptionById(step)}
        />
    }
  }

  render() {
    const {
      SelectedTechDoc,
      Interface,
      ProductionChain,
      TechDocCut,
      TechDocMaterials,
      dockerUrls,
      currentMeasureResult,
      isTechDocLoaded,
      // warning,

      techDocPrice,
      timeDuration
    } = this.props;
    return (
      <div
        className='techDocCreatingPageRoot'
      >
        {isTechDocLoaded && <Fragment>
          <div
            className='mainWindow'
          >
            <div
              className='techDocCreatingPageVNCDisplay'
            >
              <div
                style={styles.freeCad}
              >
                <VNCDisplay
                  VNCHostURL={dockerUrls ? dockerUrls.VNCAddress : ''}
                  OnVNCConnectionLose={() => {}}
                  RunDocker={this.props.RunDocker}
                  techDocId={this.props.techDocId}
                  isTechDocLoaded={isTechDocLoaded}
                />
              </div>
              <FreeCadCommands
                currentMeasureResult={currentMeasureResult}

                SetColors={this.props.SetColors}
                ResetColors={this.props.ResetColors}

                GetArea={this.props.GetArea}
                GetLength={this.props.GetLength}
              />
            </div>
            <div
              style={{
                width: '40%',
                height: 'calc(100% - 64px)',
                boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.5)',
                overflowY: 'scroll',
                marginBottom: 64,
              }}
            >
              <TechDocHeader
                techDocPrice={techDocPrice}
                timeDuration={timeDuration}
                techDocTitle={SelectedTechDoc.title}
                techDocCover={SelectedTechDoc.coverImageUrl}
                techDocTechnology={SelectedTechDoc.technology}

                SetAvatar={this.props.SetAvatar}
                selectedStep={Interface.ActiveStep}
                steps={this.getStepsList(TechDocCut, TechDocMaterials, ProductionChain ? ProductionChain : [])}
                OnClickStep={this.props.CheckStep.bind(this)}
              />
              <div
                style={{
                  width: `calc(100% - ${CONTAINER_CONSTANTS.LEFT_PADDING + CONTAINER_CONSTANTS.RIGHT_PADDING})`,
                  maxHeight: '100%',
                  minHeight: 'auto',
                  display: 'flex',
                  paddingLeft: CONTAINER_CONSTANTS.LEFT_PADDING,
                  paddingRight: CONTAINER_CONSTANTS.RIGHT_PADDING,
                }}
              >
                {this.getStepContent(Interface.ActiveStep)}
              </div>
              <NavigationButtons
                CanBack={Interface.ActiveStep === 0}
                OnClickSave={this.props.SaveTechDoc.bind(this)}
                PushToPDF={this.props.PushToPDF.bind(this)}
                needCutting={false}
                OpenCutEditor={this.props.OpenCutEditor}
                OpenMaterialDialog={this.props.OpenMaterialDialog.bind(this)}
              />
            </div>
          </div>
          <MaterialsDialog />
        </Fragment>}
      </div>
    )
  }
}

export default connect(
  state => ({
    Interface: state.Interface,
    isTechDocLoaded: state.HTTP.isTechDocLoaded,
    warning: state.HTTP.warning,
    timeDuration: _.sumBy(state.ProductionChain.productionChain, ps => {
      return _.sumBy(ps.stages, st => {
        return st.durationMinutes
      })
    })
  }),
  dispatch => ({
    PushToPDF: () => {
      dispatch(NavigationActions.PushToPDF());
    },
    OpenMaterialDialog: () => {
      dispatch(InterfaceActions.OpenMaterialDialog());
    },
    SaveTechDoc: () => {
      dispatch(NavigationActions.SaveTechDoc());
    },
    SetStepperOpen: (isOpen) => {
      dispatch(InterfaceActions.ChangeStepperOpen(isOpen));
    },
    CheckStep: (index) => {
      dispatch(InterfaceActions.SetActiveStep(index));
    },
    GetTechDocById: (techDocId) => {
      dispatch(TechDocsActions.GetTechDocById(techDocId))
    },
  }),
)(DefaultTechDoc);
