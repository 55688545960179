import {
  UsersConstants
} from '../Constants';

const initState = {
  selectedUserId: '',
  usersList: [],
  roles: [],
  newUser: {
    first_name: '',
    second_name: '',
    password: '',
    rate: 0,
    roles: [],
    username: '',
    nfc: ''
  }
};

const GET_ALL_ROLES = (newState, oldState) => {
  const roles = JSON.parse(JSON.stringify(newState));
  return{
    ...oldState,
    roles:roles
  }
}

const OPEN_EDITING_DIALOG = (newState, oldState) => {
  const userData = JSON.parse(JSON.stringify(newState[0]));
  return{
    ...oldState,
    newUser: userData,
    selectedUserId: newState[1]
  }
}

const GET_ALL_USERS = (newState, oldState) => {
  const newUsersList = JSON.parse(JSON.stringify(newState));
  return{
    ...oldState,
    usersList: newUsersList
  }
}

const CLEAN_USER_DATA = (oldState) => {
  let newUser = {
    first_name: '',
    second_name: '',
    password: '',
    rate: 0,
    roles:[],
    nfc: '',
    username: '',
  }
  return{
    ...oldState,
    newUser: newUser,
    selectedUserId: ''
  }
}

const CHANGE_NEW_USER_DATA = (newState, oldState) => {
  const newUser = JSON.parse(JSON.stringify(newState));
  return {
    ...oldState,
    newUser: newUser
  }
}

const ADD_USER = (oldState) => {
  let newUser = JSON.parse(JSON.stringify(oldState.newUser));
  newUser = {
    first_name: '',
    second_name: '',
    password: '',
    rate: 0,
    roles: [],
    nfc: '',
    username: '',
  }
  return{
    ...oldState,
    newUser: newUser,
    selectedUserId: ''
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    
    case UsersConstants.CLEAN_USER_DATA:
      return CLEAN_USER_DATA(state);

    case UsersConstants.ADD_USER:
      return ADD_USER(state);

    case UsersConstants.CHANGE_NEW_USER_DATA:
      return CHANGE_NEW_USER_DATA(action.data, state);

    case UsersConstants.GET_ALL_USERS:
      return GET_ALL_USERS(action.data, state);

    case UsersConstants.OPEN_EDITING_DIALOG:
      return OPEN_EDITING_DIALOG(action.data, state);

    case UsersConstants.GET_ALL_ROLES:
      return GET_ALL_ROLES(action.data, state);

    default:
      return state;
  }
}