import React, { PureComponent } from 'react';

import {
  Grid
} from '@material-ui/core';

import _ from 'lodash';

import TaskMiniDescription from './TaskMiniDescription';
import QCMiniDescription from './QCMiniDescription';

const styles = {
  productionChainCardsContainer: {
    width: 'calc(100% - 24px)'
  }
};

class TaskConveyor extends PureComponent {
  render() {
    const {
      tasks,
      selectedTaskId
    } = this.props;
    const tasksWithQC = _.reduce(tasks, (newTasks, task) => {
      if (!_.isUndefined(task.stage) && task.stage.isQCRequired) {
        return _.concat(newTasks, [task, {
          _id: 'QC' + task._id,
          type: 'QC',
          QCCriteria: !_.isUndefined(task.stage.QCCriteria) ? task.stage.QCCriteria : [],
          title: 'Технический контроль ' + task.title,
          state: _.toLower(task.state) === 'done' ? 'done' : 'waiting',
        }])
      }
      return _.concat(newTasks, task)
    }, []);
    return (
      <Grid
        container
        style={styles.productionChainCardsContainer}
      >
        {_.map(tasksWithQC, task => {
          return <Grid
            item
            key={task._id}
            sm={4}
          >
            {task.type !== 'QC' &&
              <TaskMiniDescription
                task={task}
                GetTask={this.props.GetTask}
                isSelected={selectedTaskId === task._id}

                BeginTask={this.props.BeginTask}
                EndTask={this.props.EndTask}
                WaitingTask={this.props.WaitingTask}
              />
            }
            {task.type === 'QC' &&
              <QCMiniDescription
                task={task}
              />
            }
          </Grid>
        })}
        {/*{_.map(sortTasks, (sortTaskQCRequired) => {*/}
        {/*return (*/}
        {/*<Grid*/}
        {/*container*/}
        {/*key={sortTaskQCRequired._id}*/}
        {/*sm={12}*/}
        {/*>*/}
        {/*<Grid*/}
        {/*container*/}
        {/*sm={9}*/}
        {/*>*/}
        {/*{_.map(sortTaskQCRequired, (sortTask, sortTaskId) => {*/}
        {/*return (*/}
        {/*<Grid*/}
        {/*item*/}
        {/*key={sortTaskId}*/}
        {/*sm={4}*/}
        {/*>*/}

        {/*</Grid>*/}
        {/*)*/}
        {/*})*/}
        {/*}*/}
        {/*</Grid>*/}
        {/*{!_.isUndefined(_.last(sortTaskQCRequired).stage) && _.last(sortTaskQCRequired).stage.isQCRequired &&*/}
        {/*<Grid*/}
        {/*item*/}
        {/*sm={3}*/}
        {/*direction='column'*/}
        {/*justify='flex-end'*/}
        {/*>*/}
        {/*<Grid*/}
        {/*item*/}
        {/*sm={12}*/}
        {/*>*/}
        {/*<TaskMiniDescription*/}
        {/*title='OTK'*/}
        {/*timeDuration={122}*/}
        {/*executionDate='12.22.22'*/}
        {/*state='toDo'*/}
        {/*SelectTask={this.props.SelectTask}*/}
        {/*/>*/}
        {/*</Grid>*/}
        {/*</Grid>*/}
        {/*}*/}
        {/*</Grid>*/}
        {/*)*/}
        {/*})*/}
        {/*}*/}
      </Grid>
    )
  }
}

export default TaskConveyor;