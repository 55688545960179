import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Select,
	FormControl,
	FormHelperText,
	MenuItem,
	TextField,
} from '@material-ui/core';

class AddingOblationalMaterialDialog extends PureComponent {
	componentDidMount() {
		const {
			selectedOblationalMaterial,
		} = this.props;
		if (_.isUndefined(selectedOblationalMaterial.material)) {
			this.props.SetSelectedOblationalMaterial({material: ''});
		};
		if (_.isUndefined(selectedOblationalMaterial.amount)) {
			this.props.SetSelectedOblationalMaterial({amount: 0});
		};
	}

	render() {
		const {
			isAddingOblationalMaterialDialogOpened,
			selectedOblationalMaterial,

			addingOblationalMaterials,
		} = this.props;
		return(
			<Dialog
				open={isAddingOblationalMaterialDialogOpened}
			>
				<DialogTitle>
					ДОБАВЛЕНИЕ НОВОГО ЖЕРТВЕННОГО МАТЕРИАЛЫ
				</DialogTitle>
				<DialogContent>
					<FormControl
						fullWidth
					>
						<Select
							value={selectedOblationalMaterial.material}
							onChange={(event) => {
								this.props.SetSelectedOblationalMaterial({material: event.target.value})
							}}
						>
							{_.map(addingOblationalMaterials, addingMaterial => {
								return <MenuItem
									key={addingMaterial._id}
									value={addingMaterial._id}
								>
									{addingMaterial.title}
								</MenuItem>
							})}
						</Select>
						<FormHelperText>
							Подбор материала
						</FormHelperText>
					</FormControl>
					<TextField
						fullWidth
						value={selectedOblationalMaterial.amount}
						type='number'
						onChange={(event) => {
							if (event.target.value >= 0) {
								this.props.SetSelectedOblationalMaterial({amount: _.floor(event.target.value)})
							}}}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={this.props.CloseOblationalMaterialAddingDialog}
					>
						Отменить
					</Button>
					<Button
						onClick={this.props.SaveOblationalMaterial}
					>
						Сохранить
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
};

export default AddingOblationalMaterialDialog;
