import React, { Component } from 'react';

import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow
} from '@material-ui/core';

import _ from 'lodash';

class QCCriteria extends Component {
  render() {
    const {
      criteriaQC
    } = this.props
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Table
          style={{
            maxWidth: '50%'
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                Обязательные
            </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.chain(criteriaQC)
              .filter(criterion => criterion.checked)
              .map(criterion => {
                if (criterion.type === 'important') {
                  return <TableRow>
                    <TableCell>
                      {criterion.text}
                    </TableCell>
                  </TableRow>
                }
              }).value()
            }
          </TableBody>
        </Table>
        <Table
          style={{
            maxWidth: '50%'
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                Необязательные
            </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.chain(criteriaQC)
              .filter(criterion => criterion.checked)
              .map(criterion => {
                if (criterion.type === 'notImportant') {
                  return <TableRow>
                    <TableCell>
                      {criterion.text}
                    </TableCell>
                  </TableRow>
                }
              }).value()
            }
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default QCCriteria;