import React, {Component} from 'react';

import _ from 'lodash';

import {
  Typography
} from '@material-ui/core';

import moment from 'moment';

class Planning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: '',
      selectedUser: '',
    };
  }

  getHHMM(minutes){
    const newHours = Math.trunc(minutes / 60) < 10 ? '0' + Math.trunc(minutes / 60) : Math.trunc(minutes / 60) ;
    const newMinutes = minutes % 60 < 10 ? '0' + minutes % 60 : minutes % 60;
    return (`${newHours}:${newMinutes}`);
  };

  componentDidMount(){
    this.props.GetTaskExecutorsPlan(this.props.currentTask)
    this.setState({
      selectedDate: this.props.date,
      selectedUser: this.props.assignedTo
    })
  }

  render(){
    const {
      curentTaskExecutorsPlan,
    } = this.props;
    const {
      selectedUser,
      selectedDate,
    } = this.state;
    const users = _.uniqBy(curentTaskExecutorsPlan, 'userId');
    const days = _.uniqBy(curentTaskExecutorsPlan,'date');
    // return(
    //   <Table
    //     style={{overflowX: 'auto', display: 'block'}}
    //   >
    //     <TableHead>
    //       <TableRow>
    //         <TableCell/>
    //         {_.map(days, (day, dayId)  => <TableCell 
    //             key={dayId}
    //           >
    //             {moment(day.date).format('DD.MM.YY')}
    //           </TableCell>
    //         )}
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       {_.map(users, (user) => {
    //         return(
    //           <TableRow
    //             key={user.userId}
    //           >
    //             <TableCell>
    //               {_.toUpper(user.userName)}
    //             </TableCell>
    //             {_.map(days, (day) => {
    //               const foundExecutorTime = _.find(curentTaskExecutorsPlan, (planItem) => {
    //                 return (planItem.userId === user.userId && planItem.date === day.date)
    //               }) 
    //               return(
    //                 <TableCell
    //                   style={_.assign(
    //                     {  },
    //                     {cursor: 'pointer'},
    //                     (moment(day.date).format('DD.MM.YY') === moment(selectedDate).format('DD.MM.YY') && user.userId === selectedUser) ? { backgroundColor: '#BBB' } : null
    //                   )}
    //                   onClick={() => {
    //                     this.props.SetTaskParam({
    //                       assignedTo: user.userId,
    //                       date: day.date,
    //                     })
    //                     this.setState({
    //                       selectedUser: user.userId,
    //                       selectedDate: day.date
    //                     }) 
    //                   }}
    //                   key={day.date}
    //                 >
    //                   <b>{this.getHHMM((!_.isUndefined(foundExecutorTime) && !_.isUndefined(foundExecutorTime.estimatedTime)) ? foundExecutorTime.estimatedTime : 0)}</b>
    //                 </TableCell>
    //               )
    //             })
    //             }
    //           </TableRow>
    //         )})
    //       }
    //     </TableBody>
    //   </Table>
    // )
    return(
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'auto'
        }}
      > 
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '48px'
          }}
        >
          <div 
            style={{
              flex: 2,
              alignItems: 'center',
              justifyContent:'center',
              display: 'flex',
              minWidth: '120px',
              maxWidth: '160px',
              width: '120px',
              borderBottom: '1px solid rgb(224, 224, 224)',
            }}
          />
          {_.map(days, (day, dayId)  => 
            <div
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent:'center',
                display: 'flex',
                minWidth: '80px',
                maxWidth: '120px',
                borderBottom: '1px solid rgb(224, 224, 224)',
              }}
              key={dayId}
            >
              <Typography
                variant='body1' 
              >
                {moment(day.date).format('DD.MM.YY')}
              </Typography>
            </div>
            )
          }
        </div>
        <div
          style={{
            flexDirection: 'column'
          }}
        >
          {_.map(users, user => {
            return(
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '56px',
                  minHeight: '56px',
                }}
                key={user.userId}
              >
                <div 
                  style={{
                    flex: 2,
                    paddingTop: '6px',
                    paddingBottom: '6px',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    display: 'flex',
                    minWidth: '120px',
                    maxWidth: '160px',
                    borderBottom: '1px solid rgb(224, 224, 224)',
                  }}
                >
                  <Typography
                    variant='body1'
                  >
                    {_.toUpper(user.userName)}
                  </Typography>
                </div>
                {_.map(days, (day) => {
                  const foundExecutorTime = _.find(curentTaskExecutorsPlan, (planItem) => {
                    return (planItem.userId === user.userId && planItem.date === day.date)
                  })
                  return(
                    <div
                      style={_.assign(
                        {  },
                        {borderBottom: '1px solid rgb(224, 224, 224)',
                          minWidth: '80px',
                          maxWidth: '120px',
                          cursor: 'pointer', 
                          flex: 1, 
                          alignItems: 'center', 
                          justifyContent:'center', 
                          display: 'flex',
                          paddingTop: '6px',
                          paddingBottom: '6px',},
                        (moment(day.date).format('DD.MM.YY') === moment(selectedDate).format('DD.MM.YY') && user.userId === selectedUser) ? { backgroundColor: '#BBB' } : null
                      )}
                      onClick={() => {
                        this.props.SetTaskParam({
                          assignedTo: user.userId,
                          date: day.date,
                        })
                        this.setState({
                          selectedUser: user.userId,
                          selectedDate: day.date
                        })
                      }}
                      key={day.date}
                    >
                      <Typography
                        variant='body1'
                      >
                        <b>{this.getHHMM((!_.isUndefined(foundExecutorTime) && !_.isUndefined(foundExecutorTime.estimatedTime)) ? foundExecutorTime.estimatedTime : 0)}</b>
                      </Typography>
                    </div>
                  )})
                }
              </div>
            )})
          }
        </div>
      </div>
    )
  }
}

export default Planning;