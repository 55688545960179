import React, { PureComponent, Fragment} from 'react';
import _ from 'lodash';
import {
	Typography,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@material-ui/core';

export default class MaterialsAmounts extends PureComponent {
	render() {
		const {
			materialsAmounts,
			msc
		} = this.props;
		const groupedMaterialsAmounts = _.chain(materialsAmounts)
			.sortBy('material.category.superCategory')
			.groupBy('material.category.superCategory')
			.value();
		return (
			<Fragment>
				<Typography
					variant='headline'
					style={{ marginTop: 16 }}
				>
					<b>1) Материалы</b>
				</Typography>
				{_.map(groupedMaterialsAmounts, (gma, gmaKey) => {
					return <Fragment
						key={gmaKey}
					>
						<Typography
							variant='subtitle1'
							style={{ marginTop: 12, }}
						>
							<b>{_.find(msc, { '_id': gmaKey }).title}</b>
						</Typography>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										№
									</TableCell>
									<TableCell>
										Название материала
									</TableCell>
									<TableCell>
										Количество
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{_.map(gma, (ma, maIndex) => {
									return <TableRow
										key={ma.material._id}
									>
										<TableCell>
											{maIndex + 1}
										</TableCell>
										<TableCell>
											{ma.material.title}
										</TableCell>
										<TableCell>
											{ma.amount.toFixed(2)} {ma.material.category.measureUnits}
										</TableCell>
									</TableRow>
								})}
							</TableBody>
						</Table>
					</Fragment>
				})}
			</Fragment>
		)
	}
}