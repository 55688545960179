import React, { Component, Fragment } from 'react';

import {
  Card,
  Typography,
  CardHeader,
} from '@material-ui/core';

import _ from 'lodash';

import LayersFacing from './LayersFacing/LayersFacing';
import PolymerizationDiagram from './Evacuation/PolymerizationDiagram';
import OblationalLayersFacing from './OblationalLayersFacing/OblationalLayersFacing';
import RiggingPreparing from './RiggingPreparing/RiggingPreparing';
import TaskDescriptionTopPanel from './TaskDescriptionTopPanel';
import Custom from './Custom/Custom';

import TaskHistory from './TaskHistory';

import Planing from './Planing';

const styles = {
  card: {
    width: '100%',
    display: 'block',
    marginTop: 24,
    marginBottom: 24,
  },
  cardContent: {
    overflowY: 'auto',
    height: '100%'
  },
  cardHeader: {
    borderBottom: '1px solid gray'
  },
  title: {
    marginTop: 12,
    marginBottom: 12
  }
}

class TaskTemplate extends Component {
  render() {
    const {
      currentTask,
      taskExecutorsPlan
    } = this.props;
    const {
      _id,
      title,
      stage,
      type,
      history,
      state,
    } = currentTask;
    return (
      <div
        // className='selectedTask'
        key={_id}
      >
        {/* <Typography>
          {currentTask._id} + {currentTask.title}
        </Typography>
        <Button
          variant='contained'
          onClick={this.props.BeginTask}
          style={{marginRight: 24}}
        >
          перевести в doing
        </Button>
        <Button
          variant='contained'
          onClick={this.props.EndTask}
        >
          перевести в done
        </Button>
	      <Button
		      variant='contained'
		      onClick={this.props.WaitingTask}
	      >
		      перевести в waiting
	      </Button> */}
        {/* {_.map(currentTask.history, (historyItem, historyItemId) => {
          return <div
            key={historyItem.date}
          >
            {historyItemId}
            <Typography>
              {!_.isUndefined(historyItem.state) ? historyItem.state : 'нет стейта'}
            </Typography>
	          <Typography>
		          {!_.isUndefined(historyItem.date) ? historyItem.date : 'нет даты'}
	          </Typography>
	          <Typography>
		          {!_.isUndefined(historyItem.assignedTo) ? historyItem.assignedTo.first_name : 'не назначено'}
	          </Typography>
          </div>
        })} */}
        <Typography
          variant='subheading'
          style={styles.title}
        >
          ОПИСАНИЕ ЗАДАЧИ <b>{_.toUpper(title)}</b>
        </Typography>
        <TaskDescriptionTopPanel
          assignedTo={!_.isUndefined(currentTask.assignedTo) ? _.toUpper(currentTask.assignedTo.second_name + ' ' + currentTask.assignedTo.first_name) : 'Не задан'}
          durationMinutes={!_.isUndefined(stage) ? (`${Math.floor(stage.durationMinutes / 60).toString().padStart(2, 0)}:${(stage.durationMinutes % 60).toString().padStart(2, 0)}`) : 'Не задано'}
          date={currentTask.date}
          isQCRequired={!_.isUndefined(stage) ? stage.isQCRequired : false}
        />
        <Card
          style={styles.card}
        >
          <CardHeader
            title={<Typography
              variant='subheading'
            >
              Полное описание задачи <b>{title}</b>
            </Typography>}
            style={styles.cardHeader}
          />
          {/* <CardContent
            style={{
	            overflowX: 'auto',
              padding: 0,
              margin: 0
            }}
          > */}
          <Fragment>
            {type === 'layersFacing' &&
              <LayersFacing
                stage={stage}
              />
            }
            {type === 'oblationalLayersFacing' &&
              <OblationalLayersFacing
                stage={stage}
              />
            }
            {type === 'riggingPreparing' &&
              <RiggingPreparing
                stage={stage}
              />
            }
            {(type === 'custom' || type === 'assemblyCustom') &&
              <Custom
                stage={stage}
              />
            }
            {type === 'evacuation' &&
              <PolymerizationDiagram
                stage={stage}
              />
            }
            {/* </CardContent> */}
          </Fragment>
        </Card>
        {/* <DateAndAssignedToChoosing
          key={_id}
          assignedTo={!_.isUndefined(currentTask.assignedTo) ? currentTask.assignedTo._id : ''}
          date={!_.isUndefined(currentTask.date) ? currentTask.date : new Date()}
          SetTaskParam={this.props.SetTaskParam}

          roles={!_.isUndefined(currentTask.productionStep) ? currentTask.productionStep.template.userTypes : ['worker']}
          GetUsersByRoles={this.props.GetUsersByRoles}
          taskUsers={taskUsers}
        /> */}
        {(_.toLower(state) === 'todo' || _.toLower(state) === 'waiting' || _.toLower(state) === 'blocked') && <Planing
          currentTask={_id}
          curentTaskExecutorsPlan={taskExecutorsPlan}
          GetTaskExecutorsPlan={this.props.GetTaskExecutorsPlan}
          SetTaskParam={this.props.SetTaskParam}
          assignedTo={!_.isUndefined(currentTask.assignedTo) ? currentTask.assignedTo._id : ''}
          date={!_.isUndefined(currentTask.date) ? currentTask.date : ''}
        />}
        <Typography
          variant='subheading'
          style={styles.title}
        >
          ИСТОРИЯ ВЫПОЛНЕНИЯ
        </Typography>
        <TaskHistory
          taskHistory={_.sortBy(history, 'date')}
          type={type}
        />
      </div>
    );
  }
}

export default TaskTemplate;