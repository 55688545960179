import React, { Component } from 'react';

import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActionArea
} from '@material-ui/core';

import { RemoveIcon } from '../';

import _ from 'lodash';
import api from '../../../../Utils/API';
import { noAvatar } from '../../../../Models/Avatars';
import moment from 'moment';

const styles = {
  removeIcon: {
    position: 'absolute',
    zIndex: 1,
    top: -10,
    right: 0,
    backgroundColor: '#fff',
    borderRadius: 25,
    fontSize: '50px',
    width: 50,
    height: 50,
  },
  projectCard: {
    position: 'relative',
    cursor: 'pointer',
    margin: 12,
  },
  projectCardMedia: {
    height: 0,
    paddingTop: '75%',
  }
}

class ProjectCard extends Component {
  render() {
    const {
      project
    } = this.props;
    return (
      <div
        style={{ position: 'relative' }}
      >
        <RemoveIcon
          style={styles.removeIcon}
          onClick={() => this.props.selectProjectForRemove(project._id)}
        />
        <Card
          style={styles.projectCard}
          onClick={() => { this.props.SelectProject(project._id) }}
        >
          <CardActionArea
            style={{ width: '100%', height: '100%' }}
          >
            <CardMedia
              style={styles.projectCardMedia}
              image={!_.isUndefined(project.techDoc.coverImageUrl) ? api.getHost() + project.techDoc.coverImageUrl : noAvatar}
            />
            <CardContent>
              <Typography
                variant='title'
              >
                {!_.isUndefined(project.title) ? _.toUpper(project.title) : 'Нет названия'}
              </Typography>
              <Typography
                variant='body1'
              >
                Техкарта <b>{project.techDoc.title}</b>
              </Typography>
              <Typography>
                Количество изделий: <b>{project.productsCount}</b>
              </Typography>
              {project.techDoc.type !== 'assembly' &&
                <Typography>
                  Вместимость оснастки: <b>{project.riggingCapacity}</b>
                </Typography>
              }
              <Typography>
                Тип техкарты: <b>{project.techDoc.type === 'assembly' ? 'Сборочная' : 'Обычная'}</b>
              </Typography>
              {!_.isUndefined(project.date) &&
                <Typography>
                  Дата создания: <b>{moment(project.date).format('DD.MM.YY')}</b>
                </Typography>
              }
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    )
  }
}

export default ProjectCard;