import  React, {Component} from 'react';
import {
  Typography
} from '@material-ui/core';
import _ from 'lodash';

class TechDocStepperTotalCosts extends Component{
  render(){
    const {
      totalPrice,
	    timeDuration
    } = this.props;
    return(
      <div 
        className='stepperRightSide'
      >
        <div 
          className='totalCosts'
        >
          <div
            className='center'
          >
            <Typography 
              variant='subheading'
            >
              <b>ОБЩИЕ ЗАТРАТЫ</b>
            </Typography>
          </div>
          <div 
            className='totalCostsPriceTime'
          >
            <div>
              <Typography 
                variant='subheading'
              >
                ₽=<b>{isNaN(totalPrice) ? 0 : _.floor(totalPrice,2)}</b>
              </Typography>
            </div>
            <div>
              <Typography
                variant='subheading'
              >
                T=<b>{`${Math.floor(timeDuration / 60).toString().padStart(2,0)}:${(timeDuration % 60).toString().padStart(2,0)}`}</b>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TechDocStepperTotalCosts;