import {
  NewTechDocConstants,
} from '../Constants';

import _ from 'lodash';

const initState = {
  newTechDoc: {
    title: '',
    type: 'default',
    technology: '',
    productionChain: [],
  },
  model: null,
  techDocTypes: [
  ],
  techDocTechnologies: [],
	notRequiredProductionSteps: [],
  chosenProductionSteps: [],
  isTechDocUploading: false,
};

const SET_TECHDOC_UPLOADING = (oldState) => {
  return {
    ...oldState,
    isTechDocUploading: true,
  }
};

const SET_TECHDOC_UPLOADED = (oldState) => {
  return {
    ...oldState,
    isTechDocUploading: false,
  }
}

const RESET_NEW_TECH_DOC_CREATING = (oldState) => {
  let newTechDoc = JSON.parse(JSON.stringify(oldState.newTechDoc));
  newTechDoc.title = '';
  newTechDoc.type = 'default';
  newTechDoc.technology = '';
  newTechDoc.productionChain = [];
  return {
    ...oldState,
    newTechDoc: newTechDoc,
	  model: null,
  }
}

const CHANGE_NEW_TECH_DOC_TITLE = (newState, oldState) => {
  let newTechDoc = JSON.parse(JSON.stringify(oldState.newTechDoc));
  newTechDoc.title = newState;
  return {
    ...oldState,
    newTechDoc: newTechDoc,
  };
};

const CHANGE_NEW_TECH_DOC_TYPE = (newState, oldState) => {
	let newTechDoc = JSON.parse(JSON.stringify(oldState.newTechDoc));
	newTechDoc.type = newState;
  return {
    ...oldState,
    newTechDoc: newTechDoc,
  };
};

const CHANGE_NEW_TECH_DOC_TECHNOLOGY = (newState, oldState) => {
	let newTechDoc = JSON.parse(JSON.stringify(oldState.newTechDoc));
	newTechDoc.technology = newState;
  return {
    ...oldState,
    newTechDoc: newTechDoc,
  };
};

const CHANGE_MODEL_LINK = (newState, oldState) => {
	let newModel = JSON.parse(JSON.stringify(oldState.model));
	newModel = newState;
	return {
		...oldState,
		model: newModel,
	};
};

const GET_NEW_TECH_DOC_CREATING_DATA = (newState, oldState) => {
  return {
    ...oldState,
    techDocTechnologies: newState.technologies,
	  notRequiredProductionSteps: newState.notRequiredProductionSteps,
    techDocTypes: newState.techDocTypes,
  }
};

const CHANGE_CHOSEN_PRODUCTION_STEPS = (newState, oldState) => {
	let newTechDoc = JSON.parse(JSON.stringify(oldState.newTechDoc));
	let chosenProductionSteps = newTechDoc.productionChain;
	let newChosenProductionSteps = [];
  newState.checked ? newChosenProductionSteps = _.concat(chosenProductionSteps, newState.stepId) :
	  newChosenProductionSteps = _.without(chosenProductionSteps, newState.stepId);
  newTechDoc.productionChain = newChosenProductionSteps;
	return {
    ...oldState,
    newTechDoc: newTechDoc,
  };
};

export default (state = initState, action) => {
  switch (action.type) {
    case NewTechDocConstants.CHANGE_NEW_TECH_DOC_TITLE:
      return CHANGE_NEW_TECH_DOC_TITLE(action.data, state);

    case NewTechDocConstants.CHANGE_NEW_TECH_DOC_TYPE:
      return CHANGE_NEW_TECH_DOC_TYPE(action.data, state);

    case NewTechDocConstants.CHANGE_NEW_TECH_DOC_TECHNOLOGY:
      return CHANGE_NEW_TECH_DOC_TECHNOLOGY(action.data, state);

    case NewTechDocConstants.CHANGE_MODEL_LINK:
      return CHANGE_MODEL_LINK(action.data, state);

    case NewTechDocConstants.GET_NEW_TECH_DOC_CREATING_DATA:
      return GET_NEW_TECH_DOC_CREATING_DATA(action.data, state);

    case NewTechDocConstants.CHANGE_CHOSEN_PRODUCTION_STEPS:
      return CHANGE_CHOSEN_PRODUCTION_STEPS(action.data, state);

    case NewTechDocConstants.RESET_NEW_TECH_DOC_CREATING:
      return RESET_NEW_TECH_DOC_CREATING(state);

    case NewTechDocConstants.SET_TECHDOC_UPLOADING:
      return SET_TECHDOC_UPLOADING(state);

    case NewTechDocConstants.SET_TECHDOC_UPLOADED:
      return SET_TECHDOC_UPLOADED(state);
    
    default:
      return state;
  }
};