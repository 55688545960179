import React, { Component } from 'react';
import { 
  LiterallyCanvasReactComponent, 
  createShape
} from 'literallycanvas';

import api from '../../../../Utils/API';

const styles = {
  editor:{
    height: '100%'
  }
};

class GraphicsEditor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataUrl: '',
		}
	}

	toDataUrl = (src, callback, outputFormat) => {
		var img = new Image();
		img.crossOrigin = 'Anonymous';
		img.onload = function() {
			var canvas = document.createElement('CANVAS');
			var ctx = canvas.getContext('2d');
			var dataURL;
			canvas.height = this.naturalHeight;
			canvas.width = this.naturalWidth;
			ctx.drawImage(this, 0, 0);
			dataURL = canvas.toDataURL(outputFormat);
			callback(dataURL);
		};
		img.src = src;
		if (img.complete || img.complete === undefined) {
			img.src = "data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
			img.src = src;
		}
	};

	componentDidMount(){
		this.toDataUrl(api.getHost() + this.props.editingImage.url, (myBase64) => {
			this.props.onChangeScreen(myBase64);
			this.setState({
				dataUrl: myBase64
			})
		});
	};

  render() {
    const {
    	dataUrl
    } = this.state;
    return (
        <div
					className='graphicsEditor'
				>
	        {dataUrl !== '' && <LiterallyCanvasReactComponent
		        imageURLPrefix='/img'
		        onInit={(lc) => {
			        const newImage = new Image();
			        newImage.src = dataUrl;
			        lc.saveShape(createShape('Image', { x: 0, y: 0, width: newImage.width, height: newImage.height, image: newImage }));

			        setTimeout(() => {}, 1000);
			        lc.on('drawingChange', () => {
				        try{
					        this.props.onChangeScreen(lc.getImage().toDataURL());
				        }catch(e){
					       
				        }
			        });
		        }}
		        style={styles.editor}
	        />}
        </div>
    );
  }
}

export default GraphicsEditor;
