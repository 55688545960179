import React, { Component, Fragment } from 'react'

import _ from 'lodash';

import {
  connect
} from 'react-redux';

import {
  InterfaceActions,
  TechDocsActions,
  HTTPActions,
} from '../../Redux/Actions/';

import NoAccessPage from './NoAccessPage';

import {
  DefaultTechDoc,
  AssemblyTechDoc
} from '../SmartComponents';

class TechDocCreatingPage extends Component {
	componentDidMount() {
		this.props.GetTechDocById(this.props.params.techDocId);
  };
  
  render() {
    const {
      SelectedTechDoc,
      dockerUrls,
      TechDocCut,
      TechDocMaterials,
      ProductionChain,
      currentMeasureResult,
      Resin,

      techDocPrice,
      materialsAmountCount,

      hasAccess,
    } = this.props;
    if (!hasAccess) {
      return (
        <div
          style={{position: 'absolute', height: '100%', width: '100%'}}
        >
          <NoAccessPage/>
        </div>
    )}
    return (
      <Fragment>
        {SelectedTechDoc.type === 'default' &&
          <DefaultTechDoc
            SelectedTechDoc={SelectedTechDoc}
            techDocId={this.props.params.techDocId}
            dockerUrls={dockerUrls}
            TechDocCut={TechDocCut}
            TechDocMaterials={TechDocMaterials}
            ProductionChain={ProductionChain}
            currentMeasureResult={currentMeasureResult}
            Resin={Resin}

            techDocPrice={techDocPrice}
            materialsAmountCount={materialsAmountCount}

            SetAvatar={this.props.SetAvatar.bind(this)}
            SetColors={this.props.SetColors.bind(this)}
            ResetColors={this.props.ResetColors.bind(this)}
            RunDocker={this.props.RunDocker.bind(this)}
            OpenCutEditor={this.props.OpenCutEditor.bind(this)}
            GetArea={this.props.GetArea.bind(this)}
            GetLength={this.props.GetLength.bind(this)}
            CloseWarning={this.props.CloseWarning.bind(this)}
          />
        }
        {SelectedTechDoc.type === 'assembly' &&
          <AssemblyTechDoc
            techDocId={this.props.params.techDocId}
            dockerUrls={dockerUrls}
            TechDocCut={TechDocCut}
            TechDocMaterials={TechDocMaterials}
            ProductionChain={ProductionChain}
            currentMeasureResult={currentMeasureResult}

            SetAvatar={this.props.SetAvatar.bind(this)}
            SetColors={this.props.SetColors.bind(this)}
            ResetColors={this.props.ResetColors.bind(this)}
            RunDocker={this.props.RunDocker.bind(this)}
            OpenCutEditor={this.props.OpenCutEditor.bind(this)}
            GetArea={this.props.GetArea.bind(this)}
            GetLength={this.props.GetLength.bind(this)}
            CloseWarning={this.props.CloseWarning.bind(this)}
          />
        }
      </Fragment>
    )
  }
}

export default connect(
  state => ({
    SelectedTechDoc: state.TechDocs.SelectedTechDoc,
    dockerUrls: state.TechDocs.dockerUrls,
    Interface: state.Interface,
    TechDocCut: state.TechDocCut.cut,
    TechDocMaterials: state.TechDocMaterials.materials,
    ProductionChain: state.ProductionChain.productionChain,
    Resin: state.TechDocMaterialsAmount.resin,
    currentMeasureResult: state.Interface.currentMeasureResult,
    isTechDocLoaded: state.HTTP.isTechDocLoaded,
    warning: state.HTTP.warning,
    techDocPrice: state.TechDocs.techDocPrice,
    materialsAmountCount: _.size(state.TechDocMaterialsAmount.materialsAmount),
    hasAccess: state.Me.hasAccess,
  }),
  dispatch => ({
    SetAvatar: () => {
      dispatch(TechDocsActions.SetAvatar());
    },
    SetColors: () => {
      dispatch(TechDocsActions.SetColors());
    },
    ResetColors: () => {
      dispatch(TechDocsActions.ResetColors());
    },
    SetStepperOpen: (isOpen) => {
      dispatch(InterfaceActions.ChangeStepperOpen(isOpen));
    },
    CheckStep: (index) => {
      dispatch(InterfaceActions.SetActiveStep(index));
    },
    GetTechDocById: (techDocId) => {
      dispatch(TechDocsActions.GetTechDocById(techDocId))
    },
    RunDocker: (techDocId, width, height) => {
      dispatch(TechDocsActions.RunDocker(techDocId, width, height))
    },
	  OpenCutEditor: () => {
      dispatch(InterfaceActions.OpenCutEditor());
    },
    GetArea: () => {
      dispatch(TechDocsActions.GetArea());
    },
    GetLength: () => {
      dispatch(TechDocsActions.GetLength());
    },
	  CloseWarning: () => {
      dispatch(HTTPActions.CloseWarning());
    },
  }),
)(TechDocCreatingPage);
