import React,{Component} from 'react';
import DnDColorPick from '../../DnDColorPick'

import DragHandle from '@material-ui/icons/DragHandle';
import { COLORS } from  '../../../../Styles/AppColors';

const styles ={
  rowContent:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    // borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    // boxShadow: '0px -1px #dbdbdb',
    // boxShadow: '0px 1px #dbdbdb',
    width: '100%',
    boxShadow: '0 -1px 0px 0 rgba(0, 0, 0, 0.12), 0 1px 0px 0 rgba(0, 0, 0, 0.12)',
    paddingTop: 16,
    paddingBottom: 16,
  }
}

class SingleLayerRow extends Component{
  render(){
    const {
      materialTitle,
      rotationAngle,
      surfaceColor
    } = this.props;
    return(
      <div
        style={styles.rowContent}
      >
        <DragHandle
          style={{
            color: 'rgba(0, 0, 0, 0.12)',
            marginLeft: 8
          }}
        />
        <span
          style={{
            flex:14,
            marginLeft: 16,
            color: COLORS.TEXT_COLOR,
            fontsize: '14px',
            lineHeight: '20px',
            letterSpacing: -0.2,
          }}
        >
          {materialTitle}
        </span>
        <div
          className='colorPickContainerDnd'
        > 
          <span 
            style={{
              color: COLORS.TEXT_COLOR,
              fontsize: '14px',
              lineHeight: '20px',
              letterSpacing: -0.2,
              flex: 3
            }}
          >
            {rotationAngle}
          </span>
          <div
            style={{
              flex: 1
            }}
          >
            <DnDColorPick
              colorPanel={surfaceColor}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SingleLayerRow;