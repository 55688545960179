import React, { PureComponent } from 'react';

import { 
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core';

import { 
  TrendingFlat,
  ExpandMore
} from '@material-ui/icons';

import _ from 'lodash';

import moment from 'moment';

import 'moment/locale/ru';

import TaskState from './TaskState';

export default class TaskHistory extends PureComponent {
  getEventView = (dataItem, dataItemId) => {
    const date = moment(dataItem.date).locale('ru').format('HH:mm DD MMM YYYY');
    const typographyStyle = {
      marginTop: 12,
      marginBottom: 12,
    }
    switch (dataItem.event) {
      case 'layer':
        return <Typography
          style={typographyStyle}
          variant='body1'
        >
          Слой <b>{dataItemId + 1}</b> выложен в <b>{date}</b>
        </Typography>

      case 'pause':
        return <Typography
          style={typographyStyle}
          variant='body1'
        >
          Изделие <b>положено в</b> печь в <b>{date}</b>
        </Typography>

      default:
        return <Typography
          style={typographyStyle}
          variant='body1'
        >
          Изделие <b>извлечено из</b> печи в <b>{date}</b>
        </Typography>
    }
  }

  render() {
    const {
      taskHistory,
    } = this.props
    return (
      <div
      >
        {_.map(_.reverse(taskHistory), (historyItem, historyItemId) => {
          const {
            state,
            date,
            data,
            assignedTo,
          } = historyItem
          return(
            <div
              key={historyItem.date}
              style={{
                display: 'flex',
                flexDirection: 'column',
                borderTop: historyItemId === 0 ? null : '0.5px solid gray',
              }}
            >
              <Typography
                variant='body1'
                style={{
                  marginTop: historyItemId === 0 ? null : 18,
                  marginBottom: 8
                }}
              >
                {!_.isUndefined(assignedTo) ? _.upperFirst(assignedTo.first_name) + ' ' + _.upperFirst(assignedTo.second_name) + ' в ' + 
                moment(date).locale('ru').format('HH:mm DD MMM YYYY')
                : 
                'Была не назначена, ' + moment(date).locale('ru').format('HH:mm DD MMM YYYY')}
              </Typography>
              <div
                style={{
                  marginBottom: 18, 
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                {!_.isUndefined(assignedTo) &&
                  <TrendingFlat
                    style={{ marginRight: 18 }}
                  />
                }
                <TaskState
                  state={state}
                />
              </div>
              {state === 'doing' && !_.isUndefined(data) &&
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant='body1'>Развернуть</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    {_.map(data, (dataItem, dataItemId) => {
                      return this.getEventView(dataItem, dataItemId);
                    })}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              }
            </div>
          )
        })}
      </div>
    )
  }
}
