const InterfaceConstants = {
  CHANGE_STEPPER_OPEN: 'CHANGE_STEPPER_OPEN',
  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',

  OPEN_CREATING_TECHDOC_DIALOG: 'OPEN_CREATING_TECHDOC_DIALOG',
  CLOSE_CREATING_TECHDOC_DIALOG: 'CLOSE_CREATING_TECHDOC_DIALOG',
  
  CLOSE_GRAPHICS_EDITOR: 'CLOSE_GRAPHICS_EDITOR',
  OPEN_GRAPHICS_EDITOR: 'OPEN_GRAPHICS_EDITOR',
  
  OPEN_ADDING_SURFACE_MATERIAL_DIALOG: 'OPEN_ADDING_SURFACE_MATERIAL_DIALOG',
  CLOSE_ADDING_SURFACE_MATERIAL_DIALOG: 'CLOSE_ADDING_SURFACE_MATERIAL_DIALOG',

  OPEN_ADDING_QC_CRITERION_DIALOG: 'OPEN_ADDING_QC_CRITERION_DIALOG',
  CLOSE_ADDING_QC_CRITERION_DIALOG: 'CLOSE_ADDING_QC_CRITERION_DIALOG',

  OPEN_EDIT_QC_CRITERION_DIALOG: 'OPEN_EDIT_QC_CRITERION_DIALOG',
  CLOSE_EDIT_QC_CRITERION_DIALOG: 'CLOSE_EDIT_QC_CRITERION_DIALOG',

  CLOSE_CREATING_NEW_PRODUCT_DIALOG: 'CLOSE_CREATING_NEW_PRODUCT_DIALOG',
  OPEN_CREATING_NEW_PRODUCT_DIALOG: 'OPEN_CREATING_NEW_PRODUCT_DIALOG',

	CHANGE_SELECTED_COLOR: 'CHANGE_SELECTED_COLOR',
	OPEN_COLOR_PICKER: 'OPEN_COLOR_PICKER',
	CLOSE_COLOR_PICKER: 'CLOSE_COLOR_PICKER',

  OPEN_CUT_EDITOR: 'OPEN_CUT_EDITOR',
  CLOSE_CUT_EDITOR: 'CLOSE_CUT_EDITOR',

	OPEN_CHANGING_SURFACE_MATERIAL_DIALOG: 'OPEN_CHANGING_SURFACE_MATERIAL_DIALOG',
	CLOSE_CHANGING_SURFACE_MATERIAL_DIALOG: 'CLOSE_CHANGING_SURFACE_MATERIAL_DIALOG',

	CHANGE_EDITOR_SELECTED_MATERIAL: 'CHANGE_EDITOR_SELECTED_MATERIAL',
	CHANGE_EDITOR_SELECTED_LAYER: 'CHANGE_EDITOR_SELECTED_LAYER',

	SET_CURRENT_IMAGE: 'SET_CURRENT_IMAGE',

	SET_MEASURE_RESULT: 'SET_MEASURE_RESULT',

	OPEN_CHANGING_MY_STORE_MATERIAL_DIALOG: 'OPEN_CHANGING_MY_STORE_MATERIAL_DIALOG',
  CLOSE_CHANGING_MY_STORE_MATERIAL_DIALOG: 'CLOSE_CHANGING_MY_STORE_MATERIAL_DIALOG',
  
  OPEN_CREATING_NEW_PROJECT_DIALOG:'OPEN_CREATING_NEW_PROJECT_DIALOG',
  CLOSE_CREATING_NEW_PROJECT_DIALOG:'CLOSE_CREATING_NEW_PROJECT_DIALOG',

  OPEN_RESIN_ADDING_DIALOG: 'OPEN_RESIN_ADDING_DIALOG',
  CLOSE_RESIN_ADDING_DIALOG: 'CLOSE_RESIN_ADDING_DIALOG',

  OPEN_CREATING_NEW_SUB_PROJECT_DIALOG:'OPEN_CREATING_NEW_SUB_PROJECT_DIALOG',
  CLOSE_CREATING_NEW_SUB_PROJECT_DIALOG:'CLOSE_CREATING_NEW_SUB_PROJECT_DIALOG',

	OPEN_OBLATIONAL_MATERIAL_ADDING_DIALOG: 'OPEN_OBLATIONAL_MATERIAL_ADDING_DIALOG',
  CLOSE_OBLATIONAL_MATERIAL_ADDING_DIALOG: 'CLOSE_OBLATIONAL_MATERIAL_ADDING_DIALOG',
  
  OPEN_ADDING_USER_DIALOG: 'OPEN_ADDING_USER_DIALOG',
  CLOSE_ADDING_USER_DIALOG: 'CLOSE_ADDING_USER_DIALOG',

  OPEN_ARE_YOU_SURE_DIALOG: 'OPEN_ARE_YOU_SURE_DIALOG',
  CLOSE_ARE_YOU_SURE_DIALOG: 'CLOSE_ARE_YOU_SURE_DIALOG',

  OPEN_MATERIAL_DIALOG: 'OPEN_MATERIAL_DIALOG',
  CLOSE_MATERIAL_DIALOG: 'CLOSE_MATERIAL_DIALOG',


  OPEN_SET_NFC_REALIZATION_DIALOG: 'OPEN_SET_NFC_REALIZATION_DIALOG',
  CLOSE_SET_NFC_REALIZATION_DIALOG: 'CLOSE_SET_NFC_REALIZATION_DIALOG',
};

export default InterfaceConstants;
