import React,{Component} from 'react';

import {
	Typography,
	MenuItem,
} from '@material-ui/core';

import _ from 'lodash';

import TechDocSelect from "../../_NewDesignComponents/TechDocSelect";

const styles ={
  spray:{
    width: '50%',
  },
  inputProps: {
    paddingTop: 0
  },
	text: {
		flex: 1,
		height: 16,
		fontFamily: 'Roboto',
		fontSize: 14,
		fontWeight: 470,
		letterSpacing: -0.2
	}
}
const title = 'Спрей';

class CoveringLayersInfoTaskTemplate extends Component{
  render(){
    const {
      sprays,
      spray,
      taskId
    } = this.props;
    return(
      <div
        className='coveringLayersInfoTemplate'
      >
	      <div
		      style={_.assign({}, styles.spray, { alignItems: 'center', display: 'flex', flexDirection: 'row' })}
	      >
		      <Typography
			      variant='body1'
			      style={styles.text}
		      >
			      {title}
		      </Typography>
	      </div>
	      <TechDocSelect
		      value={spray}
		      onChange={(event) => {this.props.SetCustomTaskDescription(taskId, 'data', {spray: event.target.value})}}
		      inputProps={{style: styles.inputProps }}
	      >
		      {_.map(sprays, (spray, index) => {
			      return(
				      <MenuItem
					      key={index}
					      value={spray.title}
				      >
					      {spray.title}
				      </MenuItem>
			      )})
		      }
	      </TechDocSelect>
      </div>
    );
  }
}

export default CoveringLayersInfoTaskTemplate;