import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../Styles/AppColors';
import { CONTAINER_CONSTANTS } from "../../../../Styles/ContainerConstants";
import {
  OutlinedTextfield,
  BlueCheck
} from '../'

class EvacuationMembrane extends Component {
  render(){
    const {
      width,
      length,
      isBag
    } = this.props;
    return(
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          width: `calc(100% - ${CONTAINER_CONSTANTS.ICON_CONTAINER_SIZE}px`,
          marginTop: 12,
        }}
      >
        <span
          style={{
            letterSpacing: -0.2,
            lineHeight: '16px',
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            color: COLORS.TEXT_COLOR,
            marginBottom: 20
          }}
        >
          Размеры куска
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <OutlinedTextfield
            required
            label={ isBag ? 'x2 Ширина' : 'Ширина' }
            type='number'
            defaultValue={0}
            value={width}
            measureUnits='м'
            onChange={this.props.onChangeWidth}
            style={{
              marginRight: '10px',
              flex: 1,
            }}
          />
          <OutlinedTextfield
            required
            label='Длина'
            type='number'
            defaultValue={0}
            measureUnits='м'
            value={length}
            onChange={this.props.onChangeLength}
            style={{
              flex: 1,
            }}
          />
        </div>
        <BlueCheck
          checked={isBag}
          onChange={this.props.onChangeBag}
          label='Мешок'
        />
      </div>
    )
  }
}

EvacuationMembrane.propTypes = {
  width: PropTypes.number.isRequired,
  onChangeWidth: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  onChangeLength: PropTypes.func.isRequired,
  isBag: PropTypes.bool.isRequired,
  onChangeBag: PropTypes.func.isRequired,
}

export default EvacuationMembrane;