import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  OutlinedTable
} from  '../';

import {
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { COLORS } from '../../../../Styles/AppColors'

class MaterialsTable extends Component {
  render(){
    const {
      superCategory,
      price,
      materials
    } = this.props;
    
    return(
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 16
          }}
        >
          <span
            style={{
              fontFamily: 'Roboto',
              fontSize: 16,
              fontWeight: 500,
              color: COLORS.TEXT_COLOR,
              lineHeight: '19px',

            }}
          >
            {superCategory}
          </span>
          <span
            style={{
              fontFamily: 'Roboto',
              fontSize: 16,
              fontWeight: 500,
              color: COLORS.TEXT_COLOR,
              lineHeight: '19px',
            }}
          >
            {price.toFixed(2)} ₽
          </span>
        </div>
        <OutlinedTable>
          <TableHead>
            <TableRow>
              <TableCell
                padding='dense'
              >
                #
              </TableCell>
              <TableCell
                component="th" 
                scope="row"
              >
                Наименование материала
              </TableCell>
              <TableCell
                numeric
              >
                Количество
              </TableCell>
              <TableCell
                numeric
              >
                Стоимость
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(materials, (material, materialId) => {
              return <TableRow
                key={material.material._id}
              >
                <TableCell
                  padding='dense'
                >
                  {materialId + 1}
                </TableCell>
                <TableCell
                  component="th" 
                  scope="row"
                >
                  {material.material.title}
                </TableCell>
                <TableCell
                  numeric
                >
                  { `${_.floor(material.amount * 100) / 100} ${material.material.category.measureUnits}`}
                </TableCell>
                <TableCell
                  numeric
                >
                {_.floor(material.material.price * material.amount, 2)} ₽
                </TableCell>
              </TableRow>
            })
            }
          </TableBody>
        </OutlinedTable>
      </div>
    )
  }
}

MaterialsTable.propTypes = {
  superCategory: PropTypes.string.isRequired,
  materials: PropTypes.array,
  price: PropTypes.number
};

export default MaterialsTable;