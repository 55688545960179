import React, {PureComponent} from 'react';
import {
  Typography,
} from '@material-ui/core';

import _ from 'lodash';

import TechDocStepRequirement from './TechDocStepRequirement';

const styles = {
  descriptionText: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  descriptionValue: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
  },
};

class TechDocDescriptionChoosingAdditionalSteps extends PureComponent {
  render() {
    const {
      parameterTitle,
	    notRequiredProductionSteps,
	    chosenProductionSteps,
    } = this.props;
    return (
      <div
        className='parameterSelectionContainer'
      >
        <div
          style={styles.descriptionText}
        >
          <Typography
              variant='body1'
            >
            {parameterTitle}
          </Typography>
        </div>
        <div
          style={styles.descriptionValue}
        >
          {_.map(notRequiredProductionSteps, productionStep => {
            return <TechDocStepRequirement
              key={productionStep._id}
	            stepTitle={productionStep.title}
              stepId={productionStep._id}
	            isChecked={_.indexOf(chosenProductionSteps, productionStep._id) >= 0}
	            CheckStep={this.props.ChangeChosenProductionSteps}
            />
          })}
        </div>
      </div>
    )
  }
};

export default TechDocDescriptionChoosingAdditionalSteps;
