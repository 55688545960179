import React, {Component} from 'react';

import TitleAndTechnologyDescription from './TitleAndTechnologyDescription';
import TechDocStepperSteps from './TechDocStepperSteps';
import TechDocStepperStepsOpen from './TechDocStepperStepsOpen';
import TechDocStepperTotalCosts from './TechDocStepperTotalCosts';

import _ from 'lodash';

import {
  Paper
} from '@material-ui/core';

const styles = {
  paperContainer:{
    display: 'flex',
    width: '100%',
  },
  paperContainerOpen:{
    display: 'flex',
    position: 'absolute',
    width: '100%',
    zIndex: 10,
  }
}

class TechDocStepper extends Component{
  onHover = (isHover) => () => {
    this.props.OnHover(isHover);
  }

  getTotalTImeDuration = (stepList) => {
    let totalTimeDuration = 0;
    _.forEach(stepList,(step) => {
      totalTimeDuration += _.isUndefined(step.timeDuration) ? 0 
        : step.timeDuration;
    })
    return totalTimeDuration;
  }

  getTotalPrice = (stepList) => {
    let totalPrice = 0;
    _.forEach(stepList, (step) => {
      totalPrice += _.isUndefined(step.price) ? 0
        : step.price;
    })
    return totalPrice;
  }

  render(){
    const {
      isOpen,
      activeStep,
      title,
      technology,
      cover,
      stepList,
      techDocPrice,

	    timeDuration,
    } = this.props;
    return(
      <div>
        {isOpen && 
          <Paper 
            style={styles.paperContainerOpen}
            onMouseLeave={this.onHover(false)}
          >
            <TitleAndTechnologyDescription
              title={title}
              technology={technology}
              cover={cover}

              SetAvatar={this.props.SetAvatar}
            />
            <TechDocStepperStepsOpen
              activeStep={activeStep}
              stepList={stepList}
              OnClickStep={this.props.OnClickStep}
            />
            <TechDocStepperTotalCosts
              totalPrice={(!_.isUndefined(techDocPrice.mp) && !_.isUndefined(techDocPrice.wp)) ? _.toNumber(techDocPrice.wp) + _.toNumber(techDocPrice.mp) : 0}
              timeDuration={timeDuration}
            />
          </Paper>
        }
        <div>
          <Paper 
            style={styles.paperContainer}
            onMouseEnter={this.onHover(true)}
          >
            <TitleAndTechnologyDescription
              title={title}
              technology={technology}
              cover={cover}
            />
            <TechDocStepperSteps
              activeStep={activeStep}
              stepList={stepList}
              OnClickStep={this.props.OnClickStep}
            />
            <TechDocStepperTotalCosts
              totalPrice={(!_.isUndefined(techDocPrice.mp) && !_.isUndefined(techDocPrice.wp)) ? _.toNumber(techDocPrice.wp) + _.toNumber(techDocPrice.mp) : 0}
              timeDuration={timeDuration}
            />
          </Paper>
        </div>
      </div>
    );
  }
}

export default TechDocStepper;