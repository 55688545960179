import React, { PureComponent } from 'react';

import _ from 'lodash';

import {
	AppBar,
	Tabs,
	Tab,
	Typography,
	Toolbar,
	withStyles,
} from '@material-ui/core';

import EditorFunctions from './EditorFunctions';
import {COLORS} from "../../../../Styles/AppColors";
import ScaledEditor from "./ScaledEditor";

const styles = {
	editorWorkingPlaceContainer: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: '100%',
	},
	editorFunctionsContainer: {
		width: 360,
		display: 'flex',
		borderRight: '1px solid grey'
	},
	editorBodyContainer: {
		width: '100%',
		display: 'flex',
		overflowX: 'scroll',
	},
	editorTitle: {
		width: 260,
	}
};

const classStyles = (theme) => ({
	tabsIndicator: {
		backgroundColor: COLORS.YELLOW,
	},
	tabRoot: {
		textTransform: 'uppercase',
		// minWidth: 108,
		// maxWidth: 256,
		fontSize: 14,
		lineHeight: 1.14,
		letterSpacing: 1.3,
		textAlign: 'center',
		fontWeight: theme.typography.fontWeightMedium,
		marginLeft: theme.spacing.unit * 2,
		fontFamily: [
			'Roboto',
		].join(','),
		'&:hover': {
			color: '#40a9ff',
			opacity: 1,
		},
	},
	tabsRoot: {
		// height: 'auto',
		// alignItems: 'center',
		// display: 'flex',
		// width: '100%',
		height: '100%',
	},
	tabsFlexContainer: {
		height: '100%',
	},
	labelContainer: {
		padding: '6px 0'
	},
});

class EditorContent extends PureComponent {
	render() {
		const {
			armorMaterials,
			oblationalCutMaterials,
			sortSurfaces,
			editorSelectedMaterial,
			editorSelectedLayer,
			materialsAmount,

			classes
		} = this.props;
		const cutMaterials = _.concat(armorMaterials, oblationalCutMaterials);
		const foundMaterial = _.find(materialsAmount, (materialAmount) => {
			return materialAmount.material._id === editorSelectedMaterial
		});
		const selectedMaterialCut = !_.isUndefined(foundMaterial) ? foundMaterial.amount : -1;
		return(
			<div
				className='editorContentContainer'
			>
				<AppBar
					position='static'
					color='default'
				>
					<Toolbar>
					<Typography
						variant='subheading'
						style={styles.editorTitle}
					>
						Редактор раскроя
					</Typography>
						<Tabs
							value={editorSelectedMaterial}
							onChange={(event, value) => {
								this.props.ChangeEditorSelectedMaterial(value);
							}}
							classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator, flexContainer: classes.tabsFlexContainer }}
							scrollable
							scrollButtons="on"
							style={{ width: '100%' }}
						>
							{_.map(sortSurfaces, (sortMaterial, sortMaterialKey) => {
								return <Tab
									key={sortMaterialKey}
									value={sortMaterialKey}
									label={_.find(cutMaterials, {'_id': sortMaterialKey}).title}
									classes={{
										root: classes.tabRoot,
										labelContainer: classes.labelContainer,
									}}
									disableRipple={true}
								/>
							})}
						</Tabs>
					</Toolbar>
				</AppBar>
				<div
					className='editorWorkingPlaceContainer'
				>
					<div
						className='editorFunctionsContainer'
					>
						<EditorFunctions
							selectedMaterialCut={selectedMaterialCut}
							layers={_.get(sortSurfaces, editorSelectedMaterial)}
							RotateLayer={this.props.RotateLayer}
							isBlocked={editorSelectedLayer === ''}
							ChangeEditorSelectedLayer={this.props.ChangeEditorSelectedLayer}
							editorSelectedLayer={editorSelectedLayer}

							CalculateCuttingMaterials={this.props.CalculateCuttingMaterials}

							CloseCutEditor={this.props.CloseCutEditor}
						/>
					</div>
					<ScaledEditor
						sortSurfaces={sortSurfaces}
						editorSelectedMaterial={editorSelectedMaterial}

						ChangeEditorSelectedMaterial={this.props.ChangeEditorSelectedMaterial}
						editorSelectedLayer={editorSelectedLayer}
						cutMaterials={cutMaterials}
						selectedMaterialCut={selectedMaterialCut}

						ChangeLayerPosition={this.props.ChangeLayerPosition}
						ChangeEditorSelectedLayer={this.props.ChangeEditorSelectedLayer}
					/>
				</div>
			</div>
		);
	};
}

export default withStyles(classStyles)(EditorContent);
