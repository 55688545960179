import React, { PureComponent, Fragment } from 'react';

import _ from 'lodash';

import {
	Typography,
	IconButton
} from '@material-ui/core';

import { DeleteOutline } from '@material-ui/icons';

import MaterialPreviewDescription from './MaterialPreviewDescription';
import {RemoveStructureDialog} from "../_NewDesignComponents";

const styles = {
	tableHeaderItem: {
		position: 'sticky',
		top: 0,
		background: '#EEEEEE'
	},
	myStoreMaterialRow: {
		cursor: 'pointer',
		flexDirection: 'row',
		display: 'flex',
		borderBottom: '1px solid gray',
		padding: '8px 0',
		alignItems: 'center',
	}
};

const myStoreMaterialsHeader = [
	{
		title: '№',
		widthFlex: 1
	},
	{
		title: 'Наименование',
		widthFlex: 15
	},
	{
		title: 'Описание',
		widthFlex: 20
	},
	{
		title: 'Удаление',
		widthFlex: 1
	}
];

class MyStoreMaterialsTable extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isDialogOpened: false,
			materialToDelete: '',
		};
	}

	DeleteMaterial = (mId) => {
		this.props.DeleteMaterialById(mId);
		this.setState({
			isDialogOpened: false,
			materialToDelete: '',
		})
	};

	render() {
		const {
			materials,
		} = this.props;
		return (
			<Fragment>
				<RemoveStructureDialog
					closeStructureProductDialog={() => this.setState({
						isDialogOpened: false,
						materialToDelete: '',
					})}
					RemoveStructureById={this.DeleteMaterial}
					isRemoveStructureDialogOpened={this.state.isDialogOpened}
					title="Удаление материала"
					content="Это действие удалит материал. Восстановление возможно при наличии материала в системе МойСклад."
					structureId={this.state.materialToDelete}
				/>
				<div
					style={{
						width: '100%',
						flexDirection: 'column'
					}}
				>
					<div
						style={{
							borderBottom: '1px solid gray',
							flexDirection: 'row',
							display: 'flex',
							padding: '8px 0'
						}}
					>
						{_.map(myStoreMaterialsHeader, (headerItem, headerItemId) => {
							return <div
								key={headerItemId}
								style={{
									flex: _.toNumber(headerItem.widthFlex),
								}}
							>
								<Typography
									variant='body2'
								>
									{headerItem.title}
								</Typography>
							</div>
						})}
					</div>
					<div
						style={{
							flexDirection: 'column'
						}}
					>
						{_.map(materials, (material, materialId) => {
							return <div
								key={material._id}
								style={styles.myStoreMaterialRow}
								onClick={() => this.props.OpenChangingMyStoreMaterialDialog(material._id)}
							>
								<div
									style={{
										flex: 1,
									}}
								>
									<Typography
										variant='body1'
									>
										{materialId + 1}
									</Typography>
								</div>
								<div
									style={{
										flex: 15,
										paddingRight: 12
									}}
								>
									<Typography
										variant='body1'
										style={{
											width: 'calc(100% - 12px)',
										}}
									>
										{material.title}
									</Typography>
									<Typography
										variant='body1'
										style={{
											width: 'calc(100% - 12px)',
										}}
									>
										Код МойСклад: <b>{material.moySkladCode ? material.moySkladCode : 'не задан'}</b>
									</Typography>
								</div>
								<div
									style={{ flex: 20 }}
								>
									{_.isNil(material.description) && _.isNil(material.category) ?
										'Нет описания' :
										<MaterialPreviewDescription
											description={!_.isNil(material.description) ? material.description : {}}
											category={!_.isEmpty(material.category) ? material.category : {}}
										/>
									}
								</div>
								<div
									style={{
										flex: 2
									}}
								>
									<IconButton
										onClick={(e) => {
											e.stopPropagation();
											this.setState({
												isDialogOpened: true,
												materialToDelete: material._id
											})
										}}
									>
										<DeleteOutline />
									</IconButton>
								</div>
							</div>
						})}
					</div>
				</div>
			</Fragment>
		)
	}
}

export default MyStoreMaterialsTable;
