import React, { PureComponent } from 'react';

import {
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import _ from 'lodash';

const styles = {
  descriptionText: {
    width: '30%',
  },
  descriptionValue: {
    width: '70%',
  },
};

class ParameterSelection extends PureComponent {
  render() {
    const {
      descriptionText,
      descriptionValue,
      selectItemsList,
    } = this.props;
    return(
      <div
        className='parameterSelectionContainer'
      >
        <Typography
          variant="body1"
          style={styles.descriptionText}
          margin="normal"
        >
          {descriptionText}
        </Typography>
        <Select
          style={styles.descriptionValue}
          value={descriptionValue}
        >
          {_.map(selectItemsList, (selectItem) => {
            return (
              <MenuItem
                key={selectItem.id}
              >
                {selectItem.title}
              </MenuItem>
            )
          })}
        </Select>
      </div>
    )
  }
};

export default ParameterSelection;
