import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../Styles/AppColors';
import {
  OutlinedTextfield
} from '../';

class ArmorMaterialSizes extends Component {
  render(){
    const {
      width,
      length
    } = this.props;
    return(
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          marginRight: 35
        }}
      >
        <span
          style={{
            letterSpacing: -0.2,
            lineHeight: '16px',
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            color: COLORS.TEXT_COLOR,
            marginBottom: 20
          }}
        >
          Размеры
        </span>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <OutlinedTextfield
            defaultValue={0}
            required
            style={{
              flex: 5,
              marginRight: 16
            }}
            label='Ширина'
            measureUnits='м'
            value={width}
            onChange={this.props.onChangeWidth}
            type='number'
          />
          <OutlinedTextfield
            required
            defaultValue={0}
            style={{
              flex: 4
            }}
            label='Длина'
            type='number'
            measureUnits='м'
            value={length}
            onChange={this.props.onChangeLength}
          />
        </div>
      </div>
    )
  }
}

ArmorMaterialSizes.propTypes = {
  width: PropTypes.number.isRequired,
  onChangeWidth: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  onChangeLength: PropTypes.func.isRequired,
}

export default ArmorMaterialSizes;