import React, { Component, Fragment } from 'react';
import {TechDocSelect} from "../_NewDesignComponents";
import _ from "lodash";
import {CONTAINER_CONSTANTS} from "../../../Styles/ContainerConstants";
import {
	IconButton,
	MenuItem
} from "@material-ui/core";
import OblationalSurface from "./OblationalSurface";
import {Add} from "@material-ui/icons";

const styles = {
	oblationalSurfacesDescription: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	oblationalLayerSizeTextfield: {
		marginRight: 12,
	}
};

export default class OblationalMaterialsWithCut extends Component {
	constructor(props){
		super(props);
		this.state = {
			oblationalSurfaces: [],
		};
		this.SaveSurfaces = _.debounce(() => this.props.SaveCuttingGroup(this.state.oblationalSurfaces), 2000)
	}

	static getDerivedStateFromProps(props, state) {
		const propsos = props.cutGroup.oblationalSurfaces;
		const stateos = state.oblationalSurfaces;
		if (_.size(stateos) !== _.size(propsos)) {
			const newOblationalSurfaces = _.map(propsos, surface => {
				return _.assign(_.omit(surface, ['layers']), { layersCount: surface.layers.length })
			});
			return {
				oblationalSurfaces: newOblationalSurfaces
			}
		}
		return null;
	}

	ChangeSurfaceParam(surfaceIndex, paramTitle, paramValue) {
		const newOblationalSurfaces = this.state.oblationalSurfaces;
		newOblationalSurfaces[surfaceIndex][paramTitle] = paramValue;
		this.setState({
			oblationalSurfaces: newOblationalSurfaces,
		}, () => this.SaveSurfaces());
	};

	DeleteSurfaceFromGroup(groupId, surfaceId) {
		this.props.DeleteOblationalSurface(groupId, surfaceId, this.state.oblationalSurfaces)
	};

	render() {
		const { oblationalSurfaces } = this.state;
 		const {
			cutGroup,
			filteredOblationalCutMaterials,
		} = this.props;
		const isVacuum = !_.isUndefined(cutGroup.isBag);
		return (
			<Fragment>
				<TechDocSelect
					label={'Подбор материала'}
					value={!_.isUndefined(cutGroup.material) ? cutGroup.material._id : ''}
					fullWidth={true}
					style={{
						marginRight: CONTAINER_CONSTANTS.ICON_CONTAINER_SIZE,
					}}
					onChange={(event) => {
						this.props.ChangeCuttingGroupMaterial(cutGroup._id, event.target.value)
					}}
				>
					{_.map(filteredOblationalCutMaterials, filteredMaterial => {
						return <MenuItem
							key={filteredMaterial._id}
							value={filteredMaterial._id}
						>
							{filteredMaterial.title}
						</MenuItem>
					})}
				</TechDocSelect>
				<div
					style={styles.oblationalSurfacesDescription}
				>
					{_.map(oblationalSurfaces, (surface, surfaceIndex) => {
						return <OblationalSurface
							key={surface._id}
							surfaceIndex={surfaceIndex}
							surface={surface}
							groupId={cutGroup._id}
							ChangeSurfaceParam={this.ChangeSurfaceParam.bind(this)}
							DeleteSurfaceFromGroup={this.DeleteSurfaceFromGroup.bind(this)}

							SetCuttingGroupIsBag={this.props.SetCuttingGroupIsBag}
							isVacuum={isVacuum}
							isBag={cutGroup.isBag}
							cutGroupId={cutGroup._id}
						/>
					})}
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							marginTop: 8,
						}}
					>
						{!isVacuum && <Fragment>
							<IconButton
								onClick={() => this.props.AddOblationalSurface(cutGroup._id, oblationalSurfaces)}
							>
								<Add/>
							</IconButton>
							<span
								style={{
									marginLeft: 3,
									fontSize: 14,
									letterSpacing: 0,
									lineHeight: '16px',
									fontWeight: '400',
									fontFamily: 'Roboto'
								}}
							>
									Добавить размер
								</span>
						</Fragment>}
					</div>
				</div>
			</Fragment>
		)
	}
};
