import React, { Component } from 'react';

import { connect } from 'react-redux';

import TaskTemplate from '../DumbComponents/TaskTemplates/TaskTemplate';
import TaskConveyor from '../DumbComponents/TaskTemplates/TaskConveyor';
import SetNFCToRealization from '../DumbComponents/TaskTemplates/SetNFCToRealization';

import {
  ProjectsActions,
  InterfaceActions
} from '../../Redux/Actions';

import {
  Typography,
} from '@material-ui/core'

import _ from 'lodash';

class Tasks extends Component {
  componentDidMount() {
    this.interval = setInterval(this.props.GetRealization, 10000, this.props.params.realizationId);
    this.props.GetRealization(this.props.params.realizationId);
    this.props.GetProjectById(this.props.params.projectId)
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {
      currentRealization,
      currentTask,
      taskUsers,
      taskExecutorsPlan,
      isSetNFCToRealisationOpened,
      nfcProblemSnackbarOpened,
      currentProject
    } = this.props;
    return (
      <div
        className='tasksContainer'
      >
        <div
          className='taskConveyor'
        >
          <Typography
            variant='headline'
            style={{
              marginLeft: 12, marginTop: 12
            }}
          >
            Проект <b>{currentProject.title}</b>
          </Typography>
          <Typography
            variant='headline'
            style={{
              marginLeft: 12, marginTop: 12
            }}
          >
            Реализация <b>{currentRealization.order + 1}</b>
          </Typography>
          <SetNFCToRealization
            SetRealizationNFCTag={this.props.SetRealizationNFCTag.bind(this)}
            realizationNFCTag={currentRealization.nfc}
            nfcProblemSnackbarOpened={nfcProblemSnackbarOpened}
            isSetNFCToRealisationOpened={isSetNFCToRealisationOpened}
            CloseSetNFCRealizationDialog={this.props.CloseSetNFCRealizationDialog.bind(this)}
            OpenSetNFCRealizationDialog={this.props.OpenSetNFCRealizationDialog.bind(this)}
            CloseSnackbarNFCProblem={this.props.CloseSnackbarNFCProblem.bind(this)}
          />
          <TaskConveyor
            tasks={currentRealization.tasks}
            GetTask={this.props.GetTask.bind(this)}
            selectedTaskId={!_.isEmpty(currentTask) ? currentTask._id : ''}

            BeginTask={this.props.BeginTask.bind(this)}
            EndTask={this.props.EndTask.bind(this)}
            WaitingTask={this.props.WaitingTask.bind(this)}
          />
        </div>
        <div
          className='taskDescription'
        >
          {!_.isEmpty(currentTask) &&
            <TaskTemplate
              currentTask={currentTask}
              BeginTask={this.props.BeginTask.bind(this)}
              EndTask={this.props.EndTask.bind(this)}
              WaitingTask={this.props.WaitingTask.bind(this)}

              SetTaskParam={this.props.SetTaskParam.bind(this)}

              GetUsersByRoles={this.props.GetUsersByRoles.bind(this)}
              taskUsers={taskUsers}
              taskExecutorsPlan={taskExecutorsPlan}
              GetTaskExecutorsPlan={this.props.GetTaskExecutorsPlan.bind(this)}
            />
          }
          {_.isEmpty(currentTask) &&
            <div
              className='noSelectedTask'
            >
              <Typography
                variant='title'
              >
                ВЫБЕРИТЕ ЗАДАЧУ
              </Typography>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    currentRealization: state.Projects.currentRealization,
    currentProject: state.Projects.currentProject,
    currentTask: state.Projects.currentTask,
    taskUsers: state.Projects.taskUsers,
    taskExecutorsPlan: state.Projects.taskExecutorsPlan,
    isSetNFCToRealisationOpened: state.Interface.isSetNFCToRealisationOpened,
    nfcProblemSnackbarOpened: state.Projects.nfcProblemSnackbarOpened
  }),
  dispatch => ({
    CloseSnackbarNFCProblem: () => {
      dispatch(ProjectsActions.CloseSnackbarNFCProblem())
    },
    CloseSetNFCRealizationDialog: () => {
      dispatch(InterfaceActions.CloseSetNFCRealizationDialog());
    },
    OpenSetNFCRealizationDialog: () => {
      dispatch(InterfaceActions.OpenSetNFCRealizationDialog());
    },
    SetRealizationNFCTag: (nfcTag) => {
      dispatch(ProjectsActions.SetRealizationNFCTag(nfcTag));
    },
    GetTaskExecutorsPlan: (taskId) => {
      dispatch(ProjectsActions.GetTaskExecutorsPlan(taskId));
    },
    GetRealization: (realizationId) => {
      dispatch(ProjectsActions.GetRealization(realizationId));
    },
    GetTask: (taskId) => {
      dispatch(ProjectsActions.GetTask(taskId));
    },
    BeginTask: () => {
      dispatch(ProjectsActions.BeginTask());
    },
    EndTask: () => {
      dispatch(ProjectsActions.EndTask());
    },
    WaitingTask: () => {
      dispatch(ProjectsActions.WaitingTask())
    },
    SetTaskParam: (value) => {
      dispatch(ProjectsActions.SetTaskParam(value))
    },
    GetUsersByRoles: (roles) => {
      dispatch(ProjectsActions.GetUsersByRoles(roles))
    },
    GetProjectById: (projectId) => {
      dispatch(ProjectsActions.GetProjectById(projectId));
    },
  })
)(Tasks);