import _ from 'lodash';

import {
  TechDocCutConstants
} from '../Constants';

const initState = {
  cut: {
    id: '09asdasd9ad0asd',
    title: 'Подготовка материалов',
  },
  surfacesList: [],
  oblationalCuttingGroups: [],
  oblationalNotCuttingGroups: [],
  activeSurfaceId: -1,
};

const SET_ONCG = (newState, oldState) => {
  return {
    ...oldState,
    oblationalNotCuttingGroups: newState,
  }
}

const SET_OBLATIONAL_LAYERS = (newState, oldState) => {
  return {
    ...oldState,
    oblationalCuttingGroups: newState,
  }
}

const ADD_ARMOR_SURFACE = (oldState) => {
  let surfacesList = JSON.parse(JSON.stringify(oldState.surfacesList));
  return {
    ...oldState,
    surfacesList: _.concat(surfacesList, {
      title: 'ПОВЕРХНОСТЬ ' + (surfacesList.length + 1).toString(),
      color: '#000000',
      width: 0,
      height: 0,
      materialsList: [],
    }
  )}
}

const SET_SURFACES = (newState, oldState) => {
  return {
    ...oldState,
    surfacesList: newState,
  };
};

const DELETE_ARMOR_SURFACE = (newState, oldState) => {
  let surfacesList = JSON.parse(JSON.stringify(oldState.surfacesList));
  _.pullAt(surfacesList, [newState]);
  return {
    ...oldState,
    surfacesList: _.each(surfacesList, (surface, surfaceId) => {
      surface.title = 'ПОВЕРХНОСТЬ ' + (surfaceId + 1).toString();
    }),
    activeSurfaceId: -1,
  };
}

const SET_ACTIVE_ARMOR_SURFACE = (newState, oldState) => {
  return {
    ...oldState,
    activeSurfaceId: newState,
  }
};

const SET_SURFACE_WIDTH = (newState, oldState) => {
  let surfacesList = JSON.parse(JSON.stringify(oldState.surfacesList));
  surfacesList[oldState.activeSurfaceId].width = newState;
  return {
    ...oldState,
    surfacesList: surfacesList,
  }
}

const SET_SURFACE_HEIGHT = (newState, oldState) => {
  let surfacesList = JSON.parse(JSON.stringify(oldState.surfacesList));
  surfacesList[oldState.activeSurfaceId].height = newState;
  return {
    ...oldState,
    surfacesList: surfacesList,
  }
}

export default (state = initState, action) => {
  switch(action.type) {
    case TechDocCutConstants.ADD_ARMOR_SURFACE:
      return ADD_ARMOR_SURFACE(state);

    case TechDocCutConstants.SET_ACTIVE_ARMOR_SURFACE:
      return SET_ACTIVE_ARMOR_SURFACE(action.data, state);

    case TechDocCutConstants.DELETE_ARMOR_SURFACE:
      return DELETE_ARMOR_SURFACE(action.data, state);

    case TechDocCutConstants.SET_SURFACE_WIDTH:
      return SET_SURFACE_WIDTH(action.data, state);

    case TechDocCutConstants.SET_SURFACE_HEIGHT:
      return SET_SURFACE_HEIGHT(action.data, state);

    case TechDocCutConstants.SET_SURFACES:
      return SET_SURFACES(action.data, state);

    case TechDocCutConstants.SET_OBLATIONAL_LAYERS:
      return SET_OBLATIONAL_LAYERS(action.data, state);

    case TechDocCutConstants.SET_ONCG:
      return SET_ONCG(action.data, state);

    default:
      return state;
  }
}