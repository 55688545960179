import React, { Component } from 'react';

import SurfacesAdding from './SurfacesAdding/SurfacesAdding';
import ArmorSurfacesList from './ArmorSurfaces/ArmorSurfacesList';
import {MaterialPreparingHeader} from "../_NewDesignComponents";

// const styles = {
//   cutTitleHeader: {
//     marginLeft: 12,
//     marginTop: 12,
//   }
// };

class ArmorMaterialsCut extends Component {
  render() {
    const {
      armorSurfacesList,
      activeSurfaceId,
      angles,
      selectedMaterialId,
      layersCount,
      selectedAngle,
      armorMaterials

    } = this.props;
    return(
      <div>
        <MaterialPreparingHeader
          materialTypeTitle={'Армирующие слои'}
          materialTypeDescription={'Выделите поверхности армирующих слоёв на модели и задайте им материалы'}
        />
        <ArmorSurfacesList
          armorSurfacesList={armorSurfacesList}
          DeleteArmorSurface={this.props.DeleteArmorSurface}
          OpenAreYouSureDialog={this.props.OpenAreYouSureDialog}

          activeSurfaceId={activeSurfaceId}
          SetActiveArmorSurface={this.props.SetActiveArmorSurface}

          SetSurfaceWidth={this.props.SetSurfaceWidth}
          SetSurfaceHeight={this.props.SetSurfaceHeight}

          OpenAddingSurfaceMaterialDialog={this.props.OpenAddingSurfaceMaterialDialog}

          OpenColorPicker={this.props.OpenColorPicker}

          angles={angles}
          DeleteLayers={this.props.DeleteLayers}

          OpenChangingSurfaceMaterialDialog={this.props.OpenChangingSurfaceMaterialDialog}
          ConnectSurfaceWithEdges={this.props.ConnectSurfaceWithEdges}

          selectedMaterialId={selectedMaterialId}
          layersCount={layersCount}
          selectedAngle={selectedAngle}

          armorMaterials={armorMaterials}

          ChangeSelectedMaterial={this.props.ChangeSelectedMaterial}
          AddSurfaceLayers={this.props.AddSurfaceLayers}

          ChangeSelectedAngle={this.props.ChangeSelectedAngle}

          ChangeLayersCount={this.props.ChangeLayersCount}
          SetLayersCount={this.props.SetLayersCount}
        />
        <SurfacesAdding
          AddArmorSurface={this.props.AddArmorSurface}
        />
      </div>
    )
  }
};

export default ArmorMaterialsCut;
